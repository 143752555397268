<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">
      <div class="py-4 space-y-4">
        <p class="text-h6 text-info-500">Residency Information</p>
        <p class="text-caption text-neutral-500">Please provide us your current and previous residency information.</p>
      </div>

      <form [formGroup]="residenciesForm" class="form flex flex-col gap-3 mt-4">
        <div formArrayName="residencies" class="flex flex-col gap-4">

          @if (currentAddressForm) {
            <div class="current-address flex flex-col gap-3" [formGroup]="currentAddressForm">
              <span class="text-body2 text-info-500">CURRENT ADDRESS</span>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-text-input formControlName="address" [label]="'Address'" placeholder="Ex: John"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input formControlName="city" [label]="'City'" placeholder="Ex: California"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-dropdown
                  formControlName="country"
                  [title]="'Country'"
                  [display]="'countryName'"
                  [value]="'countryName'"
                  [enableSearch]="true"
                  [noDataMessage]="'No Options Available'"
                  class="w-full"
                  [fullWidth]="true"
                  [items]="countryListState.response()?.data ?? [] "
                  (valueChanged)="onCountrySelected($event, 0)">
                </app-dropdown>
                <app-dropdown formControlName="state"
                              [items]="statesList[0]"
                              [title]="'State'"
                              [display]="'stateName'"
                              [value]="'stateName'"
                              [enableSearch]="true"
                              [noDataMessage]="'No Options Available'"
                              class="w-full"
                              [fullWidth]="true">

                </app-dropdown>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-text-input formControlName="zipCode" [label]="'Zipcode'" placeholder="Ex: 62704"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input formControlName="yearsAtAddress" type="number" [label]="'No. of years at this address'"
                                        placeholder="Ex: 2" class="w-full" [fullWidth]="true"></app-outline-text-input>
              </div>
              <div class="flex items-center space-x-4">
                <span class="text-body2">Mailing address same as current address?</span>
                <app-radio-button formControlName="isMailingAddress" title="Yes" groupName="isCurrentAddress"
                                  [value]="true"></app-radio-button>
                <app-radio-button formControlName="isMailingAddress" title="No" groupName="isCurrentAddress"
                                  [value]="false"></app-radio-button>
              </div>
              <div class="line"></div>
            </div>
          }

          @if (mailingAddressForm) {
            <div class="mailing-address flex flex-col gap-3" [formGroup]="mailingAddressForm">
              <span class="text-body2 text-info-500">MAILING ADDRESS</span>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-text-input formControlName="address" [label]="'Address'" placeholder="Ex: John"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input formControlName="city" [label]="'City'" placeholder="Ex: California"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-dropdown
                  formControlName="country"
                  [title]="'Country'"
                  [display]="'countryName'"
                  [enableSearch]="true"
                  [value]="'countryName'"
                  [noDataMessage]="'No Options Available'"
                  class="w-full"
                  [fullWidth]="true"
                  [items]="countryListState.response()?.data ?? []"
                  (valueChanged)="onCountrySelected($event,1)">
                </app-dropdown>
                <app-dropdown formControlName="state"
                              [items]="statesList[1]"
                              [title]="'State'"
                              [display]="'stateName'"
                              [enableSearch]="true"
                              placeholder="California"
                              [value]="'stateName'"
                              [noDataMessage]="'No Options Available'"
                              [fullWidth]="true">
                </app-dropdown>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-text-input formControlName="zipCode" [label]="'Zipcode'" placeholder="Ex: 62704"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input formControlName="yearsAtAddress" type="number" [label]="'No. of years at this address'"
                                        placeholder="Ex: 2" class="w-full" [fullWidth]="true"></app-outline-text-input>
              </div>
              <div class="line"></div>
            </div>
          }

          @for (control of residenciesFormArray.controls; track control; let i = $index) {
            @if (i >= additionalAddressStartIndex()) {
              <div class="additional-address flex flex-col gap-3" [formGroupName]="i">
                <span class="text-body2 text-info-500">ADDITIONAL ADDRESS</span>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-text-input formControlName="address" [label]="'Address'" placeholder="Ex: John"
                                          class="w-full" [fullWidth]="true"></app-outline-text-input>
                  <app-outline-text-input formControlName="city" [label]="'City'" placeholder="Ex: California"
                                          class="w-full" [fullWidth]="true"></app-outline-text-input>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-dropdown
                    formControlName="country"
                    [items]="countryListState.response()?.data ?? []"
                    [title]="'Country'"
                    [display]="'countryName'"
                    [value]="'countryName'"
                    [enableSearch]="true"
                    [noDataMessage]="'No Options Available'"
                    class="w-full"
                    [fullWidth]="true"
                    (valueChanged)="onCountrySelected($event,i)">
                  </app-dropdown>
                  <app-dropdown formControlName="state"
                                [items]="statesList[i]"
                                [title]="'State'"
                                [display]="'stateName'"
                                [enableSearch]="true"
                                placeholder="California"
                                [value]="'stateName'"
                                [noDataMessage]="'No Options Available'"
                                [fullWidth]="true">
                  </app-dropdown>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-text-input formControlName="zipCode" [label]="'Zipcode'" placeholder="Ex: 62704"
                                          class="w-full" [fullWidth]="true"></app-outline-text-input>
                  <app-outline-text-input formControlName="yearsAtAddress" type="number" [label]="'No. of years at this address'"
                                          placeholder="Ex: 2" class="w-full"
                                          [fullWidth]="true"></app-outline-text-input>
                </div>
                <div class="flex justify-end">
                  <app-button appearance="outline" outlineColor="border-error-500" textButtonColor="text-error-500"
                              (click)="onDeleteClick(i)">Delete
                  </app-button>
                </div>
                <div class="line"></div>
              </div>
            }
          }
        </div>
        <div class="flex flex-col gap-4 my-3">
          <p class="text-caption text-neutral-500">Please add more addresses if you have stayed in more residency in
            past 3 years.</p>
          <app-button iconSrc="assets/icons/plus.svg" appearance="outline" [fullWidth]="true" (click)="onAddAddressClicked()">ADD ANOTHER ADDRESS</app-button>
        </div>
        <div class="line"></div>
      </form>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button (click)="onSaveAndNextClicked()" [loading]="saveApplicantResidencyState.loading()">Save & Next
      </app-button>
    </div>
  </div>
</div>
