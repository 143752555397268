<app-base-overlay [title]="'Application Link'" class="mx-6">
  <div body>
    <div class="flex flex-col gap-4 justify-center items-center my-6 w-full">
      <app-svg-icon class="text-success-600 items-center" [size]="60" src="assets/icons/success.svg"></app-svg-icon>
      <p class="text-neutral-700 text-caption text-nowrap">Success! The driver onboarding application has been sent to
        the provided email address.</p>
      <p class="text-neutral-700 text-caption">Application ID: <span
        class="px-2 text-info-500">{{ sendDriverApplicationResponse?.data?.code }}</span></p>
      <div class="flex flex-col gap-3">
        <p class="text-body2 text-neutral-900">Application Link to Share</p>
        <div class="flex items-center space-x-4">
          <div class="border border-neutral-300 p-2 rounded-lg w-full">
            <p class="text-info-500 break-all text-caption cursor-pointer">
              {{ getApplicationInviteUrl() }}
            </p>
          </div>
          <div class="border border-neutral-300 rounded-lg p-1 cursor-pointer">
            <app-svg-icon (click)="onCopyClicked()" class="text-neutral-700" src="assets/icons/link.svg"
                          [size]="25"></app-svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div actions>
    <div class="flex justify-center gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'"
                  (click)="onCloseClicked()">
        Close
      </app-button>
    </div>
  </div>
</app-base-overlay>
