import {Component, inject, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {OutlineTextInputComponent} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from "@angular/material/datepicker";
import {MatInput} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DatePipe, NgIf} from "@angular/common";
import {MatButton} from "@angular/material/button";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "../../../../../shared/inputs/outline-date-input/outline-date-input.component";
import type {
  CreateDriverReminderRequest,
  CreateDriverReminderResponse,
  DeleteDriverReminderResponse
} from "../../../../../core/api/model";
import {State} from "../../../../../shared/base/base-state";
import {ApiService} from "../../../../../core/api/api.service";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {DateUtils} from "../../../../../shared/utils/date-utils";

@Component({
  selector: 'app-create-reminder',
  standalone: true,
  imports: [
    BaseDialogComponent,
    AppSvgIconComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    MatFormField,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatDatepicker,
    MatInput,
    MatDatepickerModule,
    MatLabel,
    DatePipe,
    MatButton,
    NgIf,
    OutlineDateInputComponent
  ],
  templateUrl: './create-reminder.component.html',
  styleUrl: './create-reminder.component.scss'
})
export class CreateReminderComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialog);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  data= inject(MAT_DIALOG_DATA);

  createReminderForm!: FormGroup;

  createReminderState = new State<CreateDriverReminderResponse>();
  deleteReminderState= new State<DeleteDriverReminderResponse>();

  protected readonly InputDateFormat = InputDateFormat;

  ngOnInit() {
    this.createReminderForm = this.formBuilder.group({
      reminderDate: ["", Validators.required],
      reminderTime: ["", Validators.required],
      reminderMessage: ["", Validators.required],
    });
  }

  closeDialog() {
    this.dialog.closeAll();

  }


  onSaveClicked() {
    let dateUtils = new DateUtils();

    const reminderDateString = dateUtils.convertToDateTimeString(this.createReminderForm.controls['reminderDate'].value)!

    const reminderDateTimeString = `${reminderDateString}`;

    let request: CreateDriverReminderRequest = {
      driverId: this.data.driverId,
      reminderDate: reminderDateTimeString,
      description: this.createReminderForm.controls['reminderMessage'].value
    };

    this.executeRequest({
      state: this.createReminderState,
      request: this.apiService.createDriverReminder(request)
    });
  }
}
