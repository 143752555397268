import { Component } from '@angular/core';

@Component({
  selector: 'app-equipments-info',
  standalone: true,
  imports: [],
  templateUrl: './equipments-info.component.html',
  styleUrl: './equipments-info.component.scss'
})
export class EquipmentsInfoComponent {

}
