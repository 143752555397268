import {Component, inject, OnInit} from '@angular/core';
import {
  GetDriverApplicationListItem,
  GetDriverApplicationListItemApplicationStatusEnum,
} from "@core/api/model";
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {ApiService} from "@core/api/api.service";

@Component({
  selector: 'app-driver-application-actions-component',
  templateUrl: './table-cell-view-or-update.component.html',
  styleUrls: ['./table-cell-view-or-update.component.scss'],
  standalone: true,
})
export class TableCellViewOrUpdateComponent extends TableCustomComponent<GetDriverApplicationListItem> implements OnInit {

  apiService = inject(ApiService);
  applicationStatus!: GetDriverApplicationListItemApplicationStatusEnum;

  ngOnInit(): void {
    if (this.rowData) {
      this.applicationStatus = this.rowData.applicationStatusEnum;
    }
  }

  onViewClick() {
    this.actionPerformed.emit({actionKey: 'view', item: this.rowData});
  }

  protected readonly GetDriverApplicationListItemApplicationStatusEnum = GetDriverApplicationListItemApplicationStatusEnum;
}
