/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API
 * 
  <img src="https://dev-app.yoursafetycompliance.com/assets/images/branding/YSC-logo.png" alt="Safety Compliance" width="150"/>
  <h3>📌 Welcome to the <strong>Your Safety Compliance API Documentation (Company Module)</strong> 🚀</h3>
  
 * OpenAPI spec version: 1.0
 */

export type UploadDriverApplicationFileRequestType = typeof UploadDriverApplicationFileRequestType[keyof typeof UploadDriverApplicationFileRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadDriverApplicationFileRequestType = {
  ClearingHouseQuery: 'ClearingHouseQuery',
  DrugAlcoholPolicy: 'DrugAlcoholPolicy',
  PreEmploymentDrugTestResult: 'PreEmploymentDrugTestResult',
  PreEmploymentDrugTestCCF: 'PreEmploymentDrugTestCCF',
  ReturnToDuty: 'ReturnToDuty',
  ClearingHouseLimitedQueryPolicy: 'ClearingHouseLimitedQueryPolicy',
  DMVPrintOutOrMVR: 'DMVPrintOutOrMVR',
  ScreenshotOfNationalRegistryReference: 'ScreenshotOfNationalRegistryReference',
  ProficiencyTestResult: 'ProficiencyTestResult',
  EmployerPullNotice: 'EmployerPullNotice',
  SPABCertificate: 'SPABCertificate',
  T01Certificate: 'T01Certificate',
  T02Certificate: 'T02Certificate',
  HAZMATCertificate: 'HAZMATCertificate',
  CurrentDriverLicenseFront: 'CurrentDriverLicenseFront',
  CurrentDriverLicenseBack: 'CurrentDriverLicenseBack',
  DOTMedicalCertificate: 'DOTMedicalCertificate',
  SchoolBusCertificate: 'SchoolBusCertificate',
} as const;
