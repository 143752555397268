<div class="app-container !p-0">

  <div class="p-4 bg-white">
    <div class="container mx-auto flex gap-5 justify-between items-center">
      <app-page-header title="Job Applications" description="View and manage all job applications."></app-page-header>

      <div class="flex gap-3 items-center">
        <app-button
          (click)="onExportClicked()"
          [iconSize]="12"
          [iconSrc]="'assets/icons/export.svg'"
          [buttonColor]="'bg-white'"
          [appearance]="'outline'"
          [textButtonColor]="'text-primary-500'"
          [loading]="exportXlsxDriverApplicationSignedUrlState.loading()">
          Export
        </app-button>
        <app-button [iconSrc]="'assets/icons/link.svg'" appearance="outline"
                    outlineColor="border-primary-500" (click)="onJobApplicationLinkClicked()">Job Application Link
        </app-button>
        <app-button [iconSrc]="'assets/icons/plus.svg'" [matMenuTriggerFor]="menu">New Application</app-button>
      </div>
    </div>

  </div>

  <div class="app-page-content h-full overflow-auto">
    <app-data-table [columnDefs]="columnDefs" [state]="applicationState"
                    (onActionPerformed)="onAction($event)"
                    (tableStateChanged)="getDriverApplications($event)"
                    [(ngModel)]="tableState">
      <div class="flex gap-3 items-center">
        <app-dropdown title="Status"
                      [items]="statuses"
                      [display]="'label'"
                      [(ngModel)]="statusFilter"
                      [isTitlePrefix]="true"
                      (valueChanged)="onDropDownFilterSelected()">
        </app-dropdown>
      </div>
    </app-data-table>
  </div>
</div>

<mat-menu #menu="matMenu" class="mat-elevation-z3 mt-4">
  <button mat-menu-item class="flex items-center" (click)="onSendDriverApplicationClicked()">
    <div class="flex items-center gap-2">
      <app-svg-icon class="text-neutral-400" [src]="'assets/icons/share.svg'"></app-svg-icon>
      <p class="text-body2">Send Driver Application</p>
    </div>
  </button>
  <button (click)="onDownloadDriverApplicationClicked()" mat-menu-item class="flex items-center">
    <div class="flex items-center gap-2">
      <app-svg-icon class="text-neutral-400" [src]="'assets/icons/download.svg'"></app-svg-icon>
      <p class="text-body2">Download Application</p>
    </div>
  </button>
</mat-menu>
