import {Component, OnInit, inject} from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  ReactiveFormsModule,
} from "@angular/forms";
import {BaseComponent} from "../../../../shared/base/base-component";
import {State} from "../../../../shared/base/base-state";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {ApiService} from "../../../../core/api/api.service";
import {ForgotPasswordResponse} from "../../../../core/api/model";

@Component({
  selector: "app-forgot-password",
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    OutlineTextInputComponent,
  ],
  templateUrl: "./forgot-password.component.html",
  styleUrl: "./forgot-password.component.scss",
})
export class ForgotPasswordComponent extends BaseComponent implements OnInit {
  apiService = inject(ApiService);
  formBuilder = inject(FormBuilder);

  forgotPasswordForm!: FormGroup;
  forgotPasswordState = new State<ForgotPasswordResponse>();

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ["", [Validators.required]],
    });
  }

  onLoginClicked() {
    this.router.navigate(["/login"]);
  }

  onForgotPasswordClicked() {
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    let request = this.forgotPasswordForm.value;
    this.executeRequest<ForgotPasswordResponse>({
      state: this.forgotPasswordState,
      request: this.apiService.resetPasswordRequest(request),
      onSuccess: (response) => {
        this.router.navigate(["/login"]);
      },
      handleSuccess: true,
      successMessage:
        "We will send you the reset password link to entered email.",
    });
  }
}
