<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">
      <div class="mx-auto max-w-5xl h-screen">
        <div class="py-6 space-y-4">
          <p class="text-h6 text-info-500">License Information</p>
          <p class="py-2 text-overline text-info-500">CURRENT LICENSE</p>
        </div>
        <form [formGroup]="driverLicenseInfoForm" class="flex flex-col py-2 gap-4">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <app-outline-text-input formControlName="firstName" [label]="'First Name'" placeholder="Ex: John"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="lastName" [label]="'Last Name'" placeholder="Ex: Bravo"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <app-outline-text-input formControlName="licenseNo" [label]="'License #'" placeholder="Ex: LICXXXXX"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-dropdown formControlName="class"
                          [items]="licenseClassesState.response()?.data ?? []"
                          [title]="'Class'"
                          [display]="null"
                          [noDataMessage]="'No Options Available'"
                          class="w-full"
                          placeholder="Ex: United States - Class A"
                          [fullWidth]="true">
            </app-dropdown>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <app-dropdown
              formControlName="countryId"
              [title]="'Country'"
              [display]="'name'"
              [value]="'id'"
              [noDataMessage]="'No Options Available'"
              [enableSearch]="true"
              class="w-full"
              [fullWidth]="true"
              [items]="countryListState.response()?.data ?? [] "
              (valueChanged)="onCountrySelected($event)">
            </app-dropdown>
            <app-dropdown
              formControlName="stateId"
              [title]="'State'"
              [display]="'name'"
              [value]="'id'"
              [noDataMessage]="'No Options Available'"
              class="w-full"
              [fullWidth]="true"
              [items]="filteredStates"
              [enableSearch]="true">
            </app-dropdown>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <app-outline-date-input formControlName="issuedDate" [label]="'Issued Date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
            <app-outline-date-input formControlName="expirationDate" [label]="'Expiration Date'"
                                    class="w-full" [fullWidth]="true"
                                    [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
          </div>
          <p class="text-neutral-800 text-body2">Endorsements</p>
          <div class="grid grid-cols-1 lg:grid-cols-6 text-nowrap">
            <app-checkbox class="col-span-2" formControlName="hasPlacardedHazmat"
                          title="H - Placarded Hazmat"></app-checkbox>
            <app-checkbox class="col-span-2" formControlName="hasTankVehicles"
                          title="N - Tank Vehicles"></app-checkbox>
            <app-checkbox class="col-span-2" formControlName="hasPassengers" title="P - Passengers"></app-checkbox>
            <app-checkbox class="col-span-2" formControlName="hasDoubleOrTripleTrailers"
                          title="T - Double/Triple Trailers"></app-checkbox>
            <app-checkbox class="col-span-2" formControlName="hasSchoolBus"
                          title="S - School Bus"></app-checkbox>
            <app-checkbox class="col-span-2" formControlName="hasPlacardedHazmatAndTankVehicles"
                          title="X - Placarded Hazmat & Tank Vehicles"></app-checkbox>
          </div>
        </form>
      </div>
    </div>
    <div class="flex justify-end border-t border-neutral-200 px-4 py-4">
      <app-button [loading]="updateLicenseInfoState.loading()" type="submit" (click)="onUpdateClicked()">UPDATE
      </app-button>
    </div>
  </div>
</div>
