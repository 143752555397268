<app-base-overlay [title]="'Send Driver Application'">
  <div body>
    <form [formGroup]="sendDriverApplicationForm" (ngSubmit)="onSendApplicationClicked()">
      <div class="flex flex-col gap-4">
        <span class="text-body2">Provide the driver’s (Job Applicant) email and the Terminal they will be assigned to, and then send the invitation.</span>
        <app-text-input formControlName="email" [label]="'Email'" placeholder="Ex: name@company.com"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-dropdown formControlName="terminalId" title="Terminal" [fullWidth]="true"
                      [items]="terminalState.response()?.data ?? []" display="name"
                      value="id"></app-dropdown>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [textButtonColor]="'text-primary-600'"
                  [outlineColor]="'border-primary-500'" (click)="onCloseClicked()">Cancel
      </app-button>
      <app-button [loading]="sendDriverApplicationState.loading()" [type]="'submit'"
                  (click)="onSendApplicationClicked()">Send Application
      </app-button>
    </div>
  </div>
</app-base-overlay>
