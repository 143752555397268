<div class="flex gap-4 px-3 py-4" [ngClass]="!isLastRow ? ' border-b border-neutral-200' : ''">
  <div class="flex flex-col gap-2 grow">
    <div class="flex gap-4 items-center">
      <p>{{ rowData.name }}</p>
    </div>
    <p>{{ getAddress(rowData) }}</p>
  </div>

  @if (getSelectedTerminalId() == rowData.id) {
  <div class="flex gap-2 items-center h-min px-3 py-2 bg-info-100 rounded-full">
    <app-svg-icon src="assets/icons/home.svg" class="text-info-500" [size]="18"></app-svg-icon>
    <p class="text-body2 text-info-500">Home</p>
  </div>
  } @else {
  <div>
      <app-button (click)="onTransferClicked(rowData)" appearance="outlineRounded">Transfer</app-button>
    </div>
  }
</div>


