import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {ButtonComponent} from "../button/button.component";

@Component({
  selector: 'app-alert-dialog',
  standalone: true,
  imports: [
    ButtonComponent,
    MatDialogContent
  ],
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.scss'
})
export class AlertDialogComponent {

  dialogRef = inject(MatDialogRef);
  data = inject(MAT_DIALOG_DATA);

  onNoClicked() {
    this.dialogRef.close(false);
  }

  onYesClicked() {
    this.dialogRef.close(true);
  }
}
