<div class="app-container !p-0">
  <div class="p-4 bg-white">
    <div class="container mx-auto flex gap-5 justify-between items-center">

      <app-page-header title="Drivers" description="View and manage all drivers."></app-page-header>

      <div class="flex gap-3 items-center">
        <app-button [iconSize]="12"
                    [iconSrc]="'assets/icons/export.svg'"
                    appearance="outline"
                    outlineColor="border-primary-500"
                    (click)="onExportClicked()">Export
        </app-button>
        <app-button (click)="onNewDriverClicked()" [iconSrc]="'assets/icons/plus.svg'">
          New Driver
        </app-button>
      </div>

    </div>
  </div>
  <div class="app-page-content grow overflow-y-scroll">
    <app-data-table
      [state]="driverListState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      [(ngModel)]="tableState"
      (tableStateChanged)="getDriverList($event)"
      [expandableComponent]="DriverTableExpandedInfoComponent"
      [enableColumnsConfig]="true">
      <div class="flex gap-3 items-center">
        <app-dropdown title="Status"
                      [items]="driverStatusFilter"
                      [display]="'label'"
                      [(ngModel)]="selectedStatusFilter"
                      [isTitlePrefix]="true"
                      (valueChanged)="onDropDownFilterSelected()">
        </app-dropdown>
      </div>
    </app-data-table>
  </div>
</div>
