import {Injectable} from "@angular/core";
import {BaseApiService} from "../../../shared/base/base-api.service";

@Injectable({
  providedIn: "root",
})
export class DriverApplicationService extends BaseApiService {


  getEquipmentsList() {
    return this.fromFile('assets/json/mock-data/equipments.json');
  }

  getMaintenance() {
    return this.fromFile('assets/json/mock-data/maintenance.json');
  }

  getEquipmentRepairs() {
    return this.fromFile('assets/json/mock-data/repairs.json');
  }

  getInspections() {
    return this.fromFile('assets/json/mock-data/inspections.json');
  }

  getEquipmentReminders() {
    return this.fromFile('assets/json/mock-data/equipment-reminder.json');
  }

  getInspectionsSafetyMaintenance() {
    return this.fromFile('assets/json/mock-data/equipment-maintenance-safety-inspection.json');
  }
}
