export class DateUtils {
  convertToMMDDYYYY(dateString: string | undefined | null): string | null {
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  }

  convertToDateTimeString(mmddyyyy: string): string | null {
    if (!mmddyyyy || mmddyyyy.length !== 10) {
      return null;
    }

    const [month, day, year] = mmddyyyy.split('/');

    // Validate the extracted month, day, and year
    const date = new Date(`${year}-${month}-${day}T00:00:00`);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return null;
    }

    // Return the formatted date-time string
    return `${year}-${month}-${day}T00:00:00+00:00`;
  }

  convertToMMYYYYString(mmyyyy: string): string | null {
    if (!mmyyyy || mmyyyy.length !== 7) {
      return null;
    }

    const [month, year] = mmyyyy.split('/');

    return `${month}/${year}`;

  }
}
