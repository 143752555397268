import {Component, inject, input, OnInit, signal} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {MatTab, MatTabChangeEvent, MatTabGroup, MatTabLabel} from "@angular/material/tabs";
import {BaseComponent} from "@shared/base/base-component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {ApiService} from "@core/api/api.service";
import {
  type DownloadDriverDocumentListSignedUrlResponse,
  GetDriverGeneralResponse, type GetDriverLicenseItem, type GetDriverLicenseParams, type GetDriverLicenseResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ShimmerComponent} from "@shared/components/shimmer/shimmer.component";
import {CommonModule} from "@angular/common";
import {NavigationEnd, RouterOutlet} from "@angular/router";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-driver-info',
  standalone: true,
  imports: [
    ButtonComponent,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    AppSvgIconComponent,
    ShimmerComponent,
    CommonModule,
    RouterOutlet
  ],
  templateUrl: './driver-info.component.html',
  styleUrl: './driver-info.component.scss'
})
export class DriverInfoComponent extends BaseComponent implements OnInit {

  driverId = input<string>();

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);

  steps: DriverInformationStep[] = [
    {
      title: "General Info",
      type: "general-info",
    },
    {
      title: "Residency Info",
      type: "residencies",
    },
    {
      title: "License Info",
      type: "licenses",
    },
    {
      title: "SAP Program",
      type: "sapProgram"
    },
    {
      title: "Documents",
      type: "documents",
    },
    {
      title: "Reminders",
      type: "reminders",
    }
  ];

  activeStep = signal<DriverInformationStep | null>(null);
  activeStepIndex = signal<number | null>(null);
  driverGeneralInfoState = new State<GetDriverGeneralResponse>();
  downloadDriverDocumentsState = new State<DownloadDriverDocumentListSignedUrlResponse>();
  licensesState = new State<GetDriverLicenseResponse>();

  currentLicense = signal<GetDriverLicenseItem | undefined>(undefined);

  ngOnInit(): void {
    this.updateActiveTab(this.router.url);
    this.listenRouteChange();
    this.getDriverGeneralInfo();
    this.getLicenses();
  }

  listenRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateActiveTab(event.urlAfterRedirects);
      }
    });
  }

  private updateActiveTab(url: string) {
    let activeTab: DriverInformationStep | undefined;
    if (url.includes('general-info')) {
      activeTab = this.steps.find(step => step.type === 'general-info');
    } else if (url.includes('residencies')) {
      activeTab = this.steps.find(step => step.type === 'residencies');
    } else if (url.includes('licenses')) {
      activeTab = this.steps.find(step => step.type === 'licenses');
    } else if (url.includes('documents')) {
      activeTab = this.steps.find(step => step.type === 'documents');
    } else if (url.includes('sapProgram')) {
      activeTab = this.steps.find(step => step.type === 'sapProgram');
    } else if (url.includes('reminders')) {
      activeTab = this.steps.find(step => step.type === 'reminders');
    }

    if (activeTab) {
      this.activeStep.set(activeTab);
      let activeTabIndex = this.steps.indexOf(activeTab);
      this.activeStepIndex.set(activeTabIndex);
    }
  }

  private getDriverGeneralInfo() {
    this.executeRequest<GetDriverGeneralResponse>({
      request: this.apiService.getDriverGeneral(this.driverId() ?? ''),
      state: this.driverGeneralInfoState
    });
  }

  onTabChange($event: MatTabChangeEvent) {
    let step = this.steps[$event.index];
    this.activeStep.set(step);
    this.activeStepIndex.set($event.index);
    this.router.navigate([`/drivers/${this.driverId()}/${step.type}`]);
  }

  onDownloadAllClicked() {
    this.executeRequest<DownloadDriverDocumentListSignedUrlResponse>({
      request: this.apiService.downloadDriverDocumentListSignedUrl(this.driverId() ?? ''),
      state: this.downloadDriverDocumentsState,
      onSuccess: response => {
        if (response.data?.downloadUrl) {
          window.open(response.data.downloadUrl, '_blank');
        }
      }
    });
  }

  private getLicenses() {
    let request: GetDriverLicenseParams = {
      driverId: this.driverId() ?? '',
    };
    this.executeRequest<GetDriverLicenseResponse>({
      state: this.licensesState,
      request: this.apiService.getDriverLicense(request),
      onSuccess: (response) => {
        let licenseInfo = response.data;
        this.currentLicense.set(licenseInfo);
      },
    });
  }


}

export interface DriverInformationStep {
  title: string;
  type:
    | "general-info"
    | "residencies"
    | "licenses"
    | "documents"
    | "sapProgram"
    | "reminders";
}

