import {Component, inject, input, OnInit} from '@angular/core';
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {State} from "../../../../shared/base/base-state";
import {CommonModule} from "@angular/common";
import {ApiService} from "../../../../core/api/api.service";
import type {
  GetDriverDocumentListItem,
  GetDriverDocumentListParams,
  GetDriverDocumentListResponse, GetDriverGeneralResponse, UploadTempFileRequest, UploadTempFileResponse
} from "../../../../core/api/model";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Constants} from "../../../../core/constants/constants";
import {DocumentUploadComponent} from "./document-upload/document-upload.component";

@Component({
  selector: 'app-driver-documents',
  standalone: true,
  imports: [
    OutlineTextInputComponent,
    ButtonComponent,
    AppSvgIconComponent,
    CommonModule
  ],
  templateUrl: './driver-documents.component.html',
  styleUrl: './driver-documents.component.scss'
})
export class DriverDocumentsComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  dialog = inject(MatDialog);

  driverDocumentsState = new State<GetDriverDocumentListResponse>();

  driverId = input<string>();

  ngOnInit() {
    this.getDocuments();
  }

  private getDocuments(): void {
    let request: GetDriverDocumentListParams = {
      driverId: this.driverId() ?? ''
    }
    this.executeRequest(
      {
        state: this.driverDocumentsState,
        request: this.apiService.getDriverDocumentList(request),
      }
    );
  }

  onUploadClicked(document:GetDriverDocumentListItem ) {
    this.dialog.open(DocumentUploadComponent, {
    data:  {document},
      ...Constants.defaultDialogConfig,
    });

  }

}

