import {Component, inject, input, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../shared/base/base-component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ApiService} from "../../../../core/api/api.service";
import type {LoginWithDelegationTokenRequest, LoginWithDelegationTokenResponse} from "../../../../core/api/model";
import {State} from "../../../../shared/base/base-state";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";


@Component({
  selector: 'app-company-login',
  imports: [
    AppSvgIconComponent
  ],
  templateUrl: './company-login.component.html',
  styleUrl: './company-login.component.scss'
})
export class CompanyLoginComponent extends BaseComponent implements OnInit {
  token = input<string>();

  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  delegateTokenState = new State<LoginWithDelegationTokenResponse>();

  ngOnInit(): void {
    this.authenticate();
  }

  private authenticate() {
    let request: LoginWithDelegationTokenRequest = {
      token: this.token() ?? ''
    };
    this.executeRequest<LoginWithDelegationTokenResponse>({
      state: this.delegateTokenState,
      request: this.apiService.loginWithDelegationToken(request),
      handleError: false,
      onSuccess: response => {
        this.tokenService.clearAuthTokens();
        this.tokenService.saveToken(response.data?.refreshToken, response.data?.accessToken);
        this.router.navigate(['/dashboard']);
      }
    });
  }
}
