import { Component, inject, Input, input, OnInit } from "@angular/core";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BaseComponent } from "../../../../shared/base/base-component";
import { State } from "../../../../shared/base/base-state";
import { ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { SetupPasswordResponse } from "../../../../core/services/auth/setup-password/setup-password-response";
import { SetupPasswordRequest } from "../../../../core/services/auth/setup-password/setup-password-request";

@Component({
  selector: "app-setup-password",
  standalone: true,
  templateUrl: "./setup-password.component.html",
  styleUrls: ["./setup-password.component.scss"],
  imports: [OutlineTextInputComponent, ButtonComponent, ReactiveFormsModule],
})
export class SetupPasswordComponent extends BaseComponent implements OnInit {
  @Input() token?: string;

  authService = inject(AuthService);
  formBuilder = inject(FormBuilder);

  setupPasswordForm!: FormGroup;

  setupPasswordState = new State<SetupPasswordResponse>();

  ngOnInit(): void {
    this.setupPasswordForm = this.formBuilder.group({
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    });
  }

  onSetPasswordClicked() {
    if (!this.setupPasswordForm.valid) {
      return;
    }

    let formData = this.setupPasswordForm.value;

    let request: SetupPasswordRequest = {
      token: this.token,
      password: formData.password,
    };

    this.executeRequest<SetupPasswordResponse>({
      state: this.setupPasswordState,
      request: this.authService.setupPassword(request),
      onSuccess: (response) => {
        this.router.navigate(["/login"]);
      },
      handleSuccess: true,
      successMessage: "You have successfully set password.",
    });
  }
}
