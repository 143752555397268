import {GetDriverApplicationListItemApplicationStatusEnum} from "@core/api/model";
import {BadgeConfigProperty} from "@shared/components/data-table/data-table.component";

export let driverApplicationStatusConstants: BadgeConfigProperty[] = [
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.Invited,
    displayText: "Invited",
    backgroundColorClass: "bg-info-50",
    borderColorClass: "border-info-500",
    textColorClass: "text-info-500",
    indicatorColorClass: "bg-info-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.Applied,
    displayText: "Applied",
    backgroundColorClass: "bg-info-50",
    borderColorClass: "border-info-700",
    textColorClass: "text-info-700",
    indicatorColorClass: "bg-info-700"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.ApplicationStarted,
    displayText: "Application Started",
    backgroundColorClass: "bg-info-50",
    borderColorClass: "border-info-500",
    textColorClass: "text-info-500",
    indicatorColorClass: "bg-info-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.DriverVerificationPending,
    displayText: "Driver Verification Pending",
    backgroundColorClass: "bg-amber-50",
    borderColorClass: "border-amber-500",
    textColorClass: "text-amber-500",
    indicatorColorClass: "bg-amber-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.NeedsApproval,
    displayText: "Needs Approval",
    backgroundColorClass: "bg-success-50",
    borderColorClass: "border-success-500",
    textColorClass: "text-success-500",
    indicatorColorClass: "bg-success-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.PendingReview,
    displayText: "Pending review",
    backgroundColorClass: "bg-amber-50",
    borderColorClass: "border-amber-500",
    textColorClass: "text-amber-500",
    indicatorColorClass: "bg-amber-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.Hired,
    displayText: "Hired",
    backgroundColorClass: "bg-success-50",
    borderColorClass: "border-success-500",
    textColorClass: "text-success-500",
    indicatorColorClass: "bg-success-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.Rejected,
    displayText: "Rejected",
    backgroundColorClass: "bg-error-50",
    borderColorClass: "border-error-500",
    textColorClass: "text-error-500",
    indicatorColorClass: "bg-error-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.ResubmissionRequested,
    displayText: "Resubmission Requested",
    backgroundColorClass: "bg-orange-50",
    borderColorClass: "border-orange-500",
    textColorClass: "text-orange-500",
    indicatorColorClass: "bg-orange-500"
  },
  {
    data: GetDriverApplicationListItemApplicationStatusEnum.ReVerificationPending,
    displayText: "Rejected",
    backgroundColorClass: "bg-cyan-50",
    borderColorClass: "border-cyan-500",
    textColorClass: "text-cyan-500",
    indicatorColorClass: "bg-cyan-500"
  }
];
