import {Component, inject, input, OnInit, signal} from "@angular/core";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseTableComponent} from "../../../../shared/components/_base/base-table/base-table.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {MatMenu, MatMenuItem} from "@angular/material/menu";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {SortableTableDirective} from "../../../../shared/components/_base/base-table/sortable-table.directive";
import {
  TableResizableColumnsDirective
} from "../../../../shared/components/_base/base-table/table-resizable-columns.directive";
import {
  ExpiringDocumentsCardComponent
} from "../../../dashboard/pages/expiring-documents-card/expiring-documents-card.component";
import {OverviewCardComponent} from "../../../dashboard/pages/overview-card/overview-card.component";
import {FileUploaderComponent} from "../../../../shared/inputs/file-uploader/file-uploader.component";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {CheckboxComponent} from "../../../../shared/inputs/checkbox/checkbox.component";
import {State} from "../../../../shared/base/base-state";
import {LookupService} from "../../../../core/services/lookup/lookup.service";
import {DropdownComponent} from "../../../../shared/inputs/dropdown/dropdown.component";
import {ApiService} from "../../../../core/api/api.service";
import {
  clearingHouseQueryFileUploadFrequencyResponse
} from "../../../../core/services/driver-application/driver-application-hire/clearing-house-query-file-upload-frequency.response";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {
  sapTestFrequencyResponse
} from "../../../../core/services/driver-application/driver-application-hire/sap-test-frequency.response";
import {DateUtils} from "../../../../shared/utils/date-utils";
import {
  GetCompanyResponse,
  GetDriverApplicationParams,
  GetDriverApplicationResponse,
  type MarkDriverApplicationHiredRequest,
  type MarkDriverApplicationHiredResponse,
  type SavePreHiringDriverApplicationInfoRequest,
  SavePreHiringDriverApplicationInfoResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileRequestType,
  UploadDriverApplicationFileResponse
} from "../../../../core/api/model";
import {
  sapTestDurationResponse
} from "../../../../core/services/driver-application/driver-application-hire/sap-test-duration.response";

@Component({
  selector: "app-hire-driver",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BaseTableComponent,
    ButtonComponent,
    MatMenu,
    MatMenuItem,
    OutlineTextInputComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
    ExpiringDocumentsCardComponent,
    OverviewCardComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent,
    DropdownComponent,
  ],
  templateUrl: "./hire-driver.component.html",
  styleUrl: "./hire-driver.component.scss",
})
export class HireDriverComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  lookupService = inject(LookupService);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  companyInfoState = new State<GetCompanyResponse>();
  driverApplicationInfoState = new State<GetDriverApplicationResponse>();
  driverHiringState = new State<SavePreHiringDriverApplicationInfoResponse>();
  saveDriverHiringState = new State<SavePreHiringDriverApplicationInfoResponse>();
  clearingHouseQueryFileUploadFrequencyState = new State<clearingHouseQueryFileUploadFrequencyResponse>();
  sapTestFrequencyState = new State<sapTestFrequencyResponse>();
  clearingHouseQueryState = new State<UploadDriverApplicationFileResponse>();
  drugAlcoholPolicyState = new State<UploadDriverApplicationFileResponse>();
  drugTestResultState = new State<UploadDriverApplicationFileResponse>();
  drugTestCCFState = new State<UploadDriverApplicationFileResponse>();
  returnToDutyState = new State<UploadDriverApplicationFileResponse>();
  clearingHouseLimitedQueryPolicyState = new State<UploadDriverApplicationFileResponse>();
  dmvPrintOutOrMVRState = new State<UploadDriverApplicationFileResponse>();
  medicalExaminerCertificateState = new State<UploadDriverApplicationFileResponse>();
  screenshotOfNationalRegistryReferenceState = new State<UploadDriverApplicationFileResponse>();
  proficiencyTestResultState = new State<UploadDriverApplicationFileResponse>();
  employerPullNoticeState = new State<UploadDriverApplicationFileResponse>();
  spabCertificateState = new State<UploadDriverApplicationFileResponse>();
  t01CertificateState = new State<UploadDriverApplicationFileResponse>();
  t02CertificateState = new State<UploadDriverApplicationFileResponse>();
  onHAZMATCertificateState = new State<UploadDriverApplicationFileResponse>();
  sapTestDurationState = new State<sapTestDurationResponse>();
  hireDriverState = new State<MarkDriverApplicationHiredResponse>();

  driverHiringForm!: FormGroup;

  token = input<string>();
  applicationId = input<string>();
  validatorsCheck = input<boolean>(false);

  ngOnInit() {
    this.initForm();
    this.handleDriverApplicationId();
    this.getCompanyInfo();
    this.getDriverApplicationInfo();
    this.getclearingHouseQueryFileUploadFrequency();
    this.getSapTestDuration();
    this.getSapTestFrequency();
  }

  private initForm() {
    this.driverHiringForm = this.formBuilder.group({
      ClearingHouseQuery: ["", Validators.required],
      preEmploymentClearingHouseCompletedDate: ["", Validators.required],
      preEmploymentClearingHouseUploadFrequency: ["", Validators.required],
      drugAlcoholPolicy: ["", Validators.required],
      drugTestResult: ["", Validators.required],
      mroVerificationDate: ["", Validators.required],
      drugTestCCF: ["", Validators.required],
      drugTestDate: ["", Validators.required],
      isDrugTestResultPositive: [false, Validators.required],
      hasStartedSAPProgram: [false, Validators.required],
      sapTestFrequency: [""],
      sapTestDuration: [""],
      returnToDutyDocument: ["", Validators.required],
      clearingHouseQueryPolicy: ["", Validators.required],
      clearingHouseLimitedQueryPolicyExpiryDate: ["", Validators.required],
      dmvPrintout: ["", Validators.required],
      medicalExaminerCertificate: ["", Validators.required],
      screenShotOfNationalRegistryReference: ["", Validators.required],
      medicalCardExpirationDate: ["", Validators.required],
      proficiencyTestResult: [""],
      employerPullNotice: ["", Validators.required],
      hasSPABCertificate: [true, Validators.required],
      spabCertificate: [""],
      t1Certificate: [""],
      t2Certificate: [""],
      driverTransportsHazmat: [false, Validators.required],
      hazMatCertificate: ["", Validators.required],
      confirmationOfRequirements: ["", Validators.required],
    });
    this.checkConditionAndUpdateValidators();
  }

  checkConditionAndUpdateValidators() {
    if (this.validatorsCheck()) {
      this.driverHiringForm.get('sapTestFrequency')?.setValidators([Validators.required]);
      this.driverHiringForm.get('sapTestDuration')?.setValidators([Validators.required]);
      this.driverHiringForm.get('spabCertificate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('t1Certificate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('t2Certificate')?.setValidators([Validators.required]);
      this.driverHiringForm.get('proficiencyTestResult')?.setValidators([Validators.required]);
    } else {
      this.driverHiringForm.get('sapTestFrequency')?.clearValidators();
      this.driverHiringForm.get('sapTestDuration')?.clearValidators();
      this.driverHiringForm.get('spabCertificate')?.clearValidators();
      this.driverHiringForm.get('t1Certificate')?.clearValidators();
      this.driverHiringForm.get('t2Certificate')?.clearValidators();
      this.driverHiringForm.get('proficiencyTestResult')?.clearValidators();
    }
  }

  private getDriverApplicationInfo() {

    let request: GetDriverApplicationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<GetDriverApplicationResponse>({
      request: this.apiService.getDriverApplication(request),
      state: this.driverApplicationInfoState,
      onSuccess: response => {
        let data = response.data;
        let dateUtils = new DateUtils();
        let medicalCardExpirationDate = dateUtils.convertToMMDDYYYY(data.medicalCardExpirationDate)!;
        this.driverHiringForm.controls['medicalCardExpirationDate'].setValue(medicalCardExpirationDate);
        this.driverHiringForm.controls['hasStartedSAPProgram'].setValue(data.isUnderSapProgram);
      }
    });
  }

  onStartProficiencyTest() {
    this.router.navigate(["/proficiency-test"]);
  }

  private handleDriverApplicationId() {
    if (this.token() != null) {
      this.handleToken();
    } else if (this.applicationId() != null) {
      this.tokenService.saveDriverApplicationId(this.applicationId()!);
    }
  }

  handleToken() {
    let isValidToken = this.tokenService.isValidJWTToken(this.token());
    if (isValidToken) {
      this.tokenService.saveDriverApplicationToken(this.token());
    } else {
      this.toasterService.error("Invalid Driver Application");
      this.router.navigate(["/login"]);
    }
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
      onSuccess: response => {
        let data = response.data;
        this.driverHiringForm.controls['driverTransportsHazmat'].setValue(data.transportsHazmat);
      }
    });
  }

  getclearingHouseQueryFileUploadFrequency() {
    this.executeRequest({
      state: this.clearingHouseQueryFileUploadFrequencyState,
      request: this.lookupService.getClearingHouseQueryFileUploadFrequency(),
    });
  }

  getSapTestFrequency() {
    this.executeRequest({
      state: this.sapTestFrequencyState,
      request: this.lookupService.getSapTestFrequency(),
    });
  }

  getSapTestDuration() {
    this.executeRequest(
      {
        state: this.sapTestDurationState,
        request: this.lookupService.getSapTestDuration()
      }
    )
  }

  onClearingHouseFileSelected($event: File) {

    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ClearingHouseQuery,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.clearingHouseQueryState,
    });
  }

  onDrugAlcoholPolicyFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DrugAlcoholPolicy,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.drugAlcoholPolicyState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onDrugTestResultFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DrugTestResult,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.drugTestResultState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onDrugTestCCFFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DrugTestCCF,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.drugTestCCFState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onReturnToDutyFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ReturnToDuty,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.returnToDutyState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onClearingHouseLimitedQueryPolicyFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ClearingHouseLimitedQueryPolicy,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.clearingHouseLimitedQueryPolicyState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onDMVPrintOutOrMVRFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.DMVPrintOutOrMVR,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.dmvPrintOutOrMVRState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onMedicalExaminerCertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.MedicalExaminerCertificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.medicalExaminerCertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onScreenshotOfNationalRegistryReferenceFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ScreenshotOfNationalRegistryReference,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.screenshotOfNationalRegistryReferenceState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onProficiencyTestResultFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.ProficiencyTestResult,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.proficiencyTestResultState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onEmployerPullNoticeFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.EmployerPullNotice,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.employerPullNoticeState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onSPABCertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.SPABCertificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.spabCertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onT01CertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.T01Certificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.t01CertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onT02CertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.T02Certificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.t02CertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onHAZMATCertificateFileSelected($event: File) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: UploadDriverApplicationFileRequestType.HAZMATCertificate,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>(
      {
        state: this.onHAZMATCertificateState,
        request: this.apiService.uploadDriverApplicationFile(request),
      }
    );
  }

  onSaveChangesClicked() {
    let dateUtils = new DateUtils();
    let driverApplicationId = this.tokenService.getDriverApplicationId();
    let request: SavePreHiringDriverApplicationInfoRequest = {
      clearingHouseLimitedQueryPolicyExpiryDate: dateUtils.convertToDateTimeString(this.driverHiringForm.controls['clearingHouseLimitedQueryPolicyExpiryDate'].value ?? '')!,
      driverApplicationId: driverApplicationId,
      driverTransportsHazmat: this.driverHiringForm.controls['driverTransportsHazmat'].value ?? false,
      drugTestDate: dateUtils.convertToDateTimeString(this.driverHiringForm.controls['drugTestDate'].value ?? '')!,
      hasSPABCertificate: this.driverHiringForm.controls['hasSPABCertificate'].value ?? false,
      hasStartedSAPProgram: this.driverHiringForm.controls['hasStartedSAPProgram'].value ?? false,
      isDrugTestResultPositive: this.driverHiringForm.controls['isDrugTestResultPositive'].value ?? false,
      medicalCardExpirationDate: dateUtils.convertToDateTimeString(this.driverHiringForm.controls['medicalCardExpirationDate'].value ?? '')!,
      mroVerificationDate: dateUtils.convertToDateTimeString(this.driverHiringForm.controls['mroVerificationDate'].value ?? '')!,
      preEmploymentClearingHouseCompletedDate: dateUtils.convertToDateTimeString(this.driverHiringForm.controls['preEmploymentClearingHouseCompletedDate'].value ?? '')!,
      preEmploymentClearingHouseUploadFrequency: this.driverHiringForm.controls['preEmploymentClearingHouseUploadFrequency'].value ?? 0,
      sapTestDuration: this.driverHiringForm.controls['sapTestDuration'].value ?? 0,
      sapTestFrequency: this.driverHiringForm.controls['sapTestFrequency'].value ?? 0
    };
    this.executeRequest(
      {
        state: this.saveDriverHiringState,
        request: this.apiService.savePreHiringDriverApplicationInfo(request),
        onSuccess: () => {
          console.log("Successful API Integration");
        }
      }
    );
  }

  onHireDriverClicked() {
    let driverApplicationId = this.tokenService.getDriverApplicationId()
    let request: MarkDriverApplicationHiredRequest = {
      driverApplicationId: driverApplicationId
    }
    this.executeRequest(
      {
        state: this.hireDriverState,
        request: this.apiService.markDriverApplicationHired(request)
      }
    )
  }
}
