import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {State} from "@shared/base/base-state";
import type {StartSapProgramRequest, StartSapProgramResponse} from "@core/api/model";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {DialogRef} from "@angular/cdk/dialog";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyFutureDateValidator} from "@shared/validators/only-future-date-validator";

@Component({
  selector: 'app-start-sap-program',
  imports: [
    ButtonComponent,
    TextInputComponent,
    ReactiveFormsModule,
    BaseOverlayComponent,
    DateInputComponent
],
  templateUrl: './start-sap-program.component.html',
  standalone: true,
  styleUrl: './start-sap-program.component.scss'
})
export class StartSapProgramComponent extends BaseComponent implements OnInit {

  overlayService = inject(OverlayService);
  data = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  createSAPProgramState = new State<StartSapProgramResponse>();

  createSAPProgramForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.createSAPProgramForm = this.formBuilder.group({
      sapProgramStartDate: [null, [Validators.required,onlyFutureDateValidator()]],
      sapProgramDuration: [null, Validators.required],
      testFrequency: [null, Validators.required],
      notes: [null, Validators.required]
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

  onSaveClicked() {
    let request: StartSapProgramRequest = {
      driverId: this.data.driverId ?? '',
      duration: this.createSAPProgramForm.value.sapProgramDuration,
      frequency: this.createSAPProgramForm.value.testFrequency,
      startDate: this.createSAPProgramForm.value.sapProgramStartDate,
      notes: this.createSAPProgramForm.value.notes ?? ''
    }
    this.executeRequest({
      state: this.createSAPProgramState,
      request: this.apiService.startSapProgram(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      },
      handleSuccess: true,
      successMessage: "SAP program started successfully"
    });
  }
}
