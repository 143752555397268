/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Api
 * OpenAPI spec version: 1.0
 */

export type UploadDriverApplicationFileRequestType = typeof UploadDriverApplicationFileRequestType[keyof typeof UploadDriverApplicationFileRequestType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UploadDriverApplicationFileRequestType = {
  ClearingHouseQuery: 'ClearingHouseQuery',
  DrugAlcoholPolicy: 'DrugAlcoholPolicy',
  DrugTestResult: 'DrugTestResult',
  DrugTestCCF: 'DrugTestCCF',
  ReturnToDuty: 'ReturnToDuty',
  ClearingHouseLimitedQueryPolicy: 'ClearingHouseLimitedQueryPolicy',
  DMVPrintOutOrMVR: 'DMVPrintOutOrMVR',
  MedicalExaminerCertificate: 'MedicalExaminerCertificate',
  ScreenshotOfNationalRegistryReference: 'ScreenshotOfNationalRegistryReference',
  ProficiencyTestResult: 'ProficiencyTestResult',
  EmployerPullNotice: 'EmployerPullNotice',
  SPABCertificate: 'SPABCertificate',
  T01Certificate: 'T01Certificate',
  T02Certificate: 'T02Certificate',
  HAZMATCertificate: 'HAZMATCertificate',
} as const;
