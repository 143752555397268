import {Component, inject, input, Input, OnChanges, output, signal, SimpleChanges} from '@angular/core';
import {
  type DeleteDriverDocumentResponse,
  GetDriverDocumentListItem,
  type UpdateDriverDocumentItem
} from "../../api/model";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {DatePipe} from "@angular/common";
import {
  DocumentUploadComponent
} from "../document-upload/document-upload.component";
import {Constants} from "../../constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {BaseComponent} from "../../../shared/base/base-component";
import {ApiService} from "../../api/api.service";
import {State} from "../../../shared/base/base-state";

@Component({
  selector: 'app-document-view',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe
  ],
  templateUrl: './document-view.component.html',
  styleUrl: './document-view.component.scss'
})
export class DocumentViewComponent extends BaseComponent implements OnChanges {
  dialog = inject(MatDialog);
  document = input<GetDriverDocumentListItem | null>();
  apiService = inject(ApiService);
  deleteDocumentState = new State<DeleteDriverDocumentResponse>();

  _document = signal<GetDriverDocumentListItem | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    this._document.set(this.document() ?? null);
  }

  onViewClicked() {
    const filePath = this._document()?.filePath;
    if (filePath) {
      window.open(filePath, '_blank');
    }
  }

  onViewAllClicked() {

  }

  onUploadClicked() {
    this.dialog.open(DocumentUploadComponent, {
      ...Constants.defaultDialogConfig,
      data: this._document(),
    }).afterClosed().subscribe((document: UpdateDriverDocumentItem) => {
      if (document) {
        this._document.set(document);
      }
    });
  }

  onDownloadClicked() {
    const filePath = this._document()?.filePath;
    if (filePath) {
      window.open(filePath, '_blank');
    }
  }


  onDeleteClicked() {
    this.alertDialog
      .show('Delete Document', 'Are you sure you want to delete this document?')
      .subscribe((result) => {
        if (result) {
          this.deleteDocument();
        }
      });
  }

  private deleteDocument() {
    this.executeRequest<DeleteDriverDocumentResponse>({
      request: this.apiService.deleteDriverDocument(this._document()?.documentId ?? ''),
      state: this.deleteDocumentState,
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this._document.update(prev => {
          let document = prev;

          if (document != null) {
            document!.filePath = undefined;

            document.issuedDateFieldLabel = undefined;
            document.expiryDateFieldLabel = undefined;
            document.reviewedDateFieldLabel = undefined;
            document.completedDateFieldLabel = undefined;

            document.issuedDate = undefined;
            document.expiryDate = undefined;
            document.completedDate = undefined;
            document.reviewedDate = undefined;

            document.uploadedDate = undefined;
            document.notes = undefined;

          }
          return document;
        });
      }
    });
  }
}


