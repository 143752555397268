@if (!environment.production) {
  <div
    class="fixed bottom-5 left-5 align-middle font-medium sm:block space-x-2 select-none pointer-events-none">
    <div class="hidden sm:inline-flex sm:flex-col py-1 px-3 bg-black bg-opacity-50 text-white rounded-md text-caption pointer-events-none">
<!--      <span class="block sm:hidden">mob</span>-->
      <span class="hidden sm:block md:hidden">sm</span>
      <span class="hidden md:block lg:hidden">md</span>
      <span class="hidden lg:block xl:hidden">lg</span>
      <span class="hidden xl:block 2xl:hidden">xl</span>
      <span class="hidden 2xl:block">2xl</span>
    </div>

    <span class="text-caption py-1 px-3 bg-black text-white rounded-md bg-opacity-50 select-none pointer-events-none">{{ screenWidth() }}px</span>
  </div>
}
