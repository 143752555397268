<div class="app-container !p-0">

  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'"
                            placeholder="Search..."
                            type="search"
                            [fullWidth]="false">
    </app-outline-text-input>

    <div class="flex gap-3 items-center">
      <app-button
        (click)="onExportClicked()"
        [loading]="exportXlsxDriverState.loading()"
        [iconSrc]="'assets/icons/export.svg'"
        [buttonColor]="'bg-white'"
        [appearance]="'outline'"
        [textButtonColor]="'text-primary-500'">
        Export
      </app-button>
      <app-button (click)="onNewDriverClicked()" [iconSrc]="'assets/icons/plus.svg'">
        New Driver
      </app-button>
    </div>
  </div>

  <div class="grow m-4 overflow-y-scroll">
    <app-data-table
      [state]="driverListState"
      [columnDefs]="columnDefs"
      (onActionClicked)="onActionClicked($event)"
      (tableStateChanged)="getDriverList($event)"
      [expandableComponent]="DriverTableExpandedInfoComponent"
      [enableColumnsConfig]="true">
    </app-data-table>
  </div>

</div>
