import {Component, inject, input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ButtonComponent} from '@shared/components/button/button.component';
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import type {ApplyForJobRequest, ApplyForJobResponse, GetTerminalInfoResponse} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {Constants} from "@core/constants/constants";
import {
  JobApplicationConfirmationComponent
} from "./job-application-confirmation/job-application-confirmation.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-apply-for-driver-job',
  imports: [ButtonComponent, ReactiveFormsModule, TextInputComponent],
  templateUrl: './apply-for-driver-job.component.html',
  standalone: true,
  styleUrl: './apply-for-driver-job.component.scss'
})
export class ApplyForDriverJobComponent extends BaseComponent implements OnInit {

  terminalCode = input<string>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  overlayService = inject(OverlayService);

  getTerminalInfoState = new State<GetTerminalInfoResponse>();
  applyForJobState = new State<ApplyForJobResponse>();

  driverApplicationForm!: FormGroup;

  ngOnInit() {
    this.initForm();
    this.getTerminalInfo();
  }

  initForm() {
    this.driverApplicationForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null],
      email: [null, Validators.required],
      phoneNumber: [null, Validators.required]
    });
  }

  getTerminalInfo() {
    let terminalCode = this.terminalCode() ?? '';
    this.executeRequest({
      state: this.getTerminalInfoState,
      request: this.apiService.getTerminalInfo(terminalCode)
    });
  }

  onApplyForJobClicked() {
    this.validateForm(this.driverApplicationForm);
    if (this.driverApplicationForm.invalid) {
      return;
    }

    let formValue = this.driverApplicationForm.value;

    let request: ApplyForJobRequest = {
      companyId: this.getTerminalInfoState.response()?.data.companyId ?? '',
      terminalId: this.getTerminalInfoState.response()?.data.id ?? '',
      email: formValue.email,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: formValue.phoneNumber,
    }

    this.executeRequest({
      state: this.applyForJobState,
      request: this.apiService.applyForJob(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.driverApplicationForm.reset();
        this.showSuccessDialog();
        this.driverApplicationForm.reset();
      }
    });
  }

  showSuccessDialog() {
    this.overlayService.openModal(JobApplicationConfirmationComponent);
  }

  protected readonly Constants = Constants;
}
