import {Injectable} from '@angular/core';
import {BaseApiService} from "@shared/base/base-api.service";
import {CompanyTypesRequest} from "./company-types/company-types-request";
import {URLs} from "../../api/urls";
import {CountriesRequest} from './countries/countries-request';
import {StateListRequest} from './states/stateList-request';
import {BusinessTypeRequest} from './business-type/business-types-request';
import {BusinessSubTypeRequest} from './business-sub-type/business-sub-type-request';


@Injectable({
  providedIn: 'root'
})
export class LookupService extends BaseApiService {

  getAccountTypes() {
    return this.fromFile('assets/json/app-data/account-type.json');
  }


  getCarrierType() {
    return this.fromFile('assets/json/app-data/carrier-type.json');
  }

  getTerminalInfo() {
    return this.fromFile('assets/json/mock-data/terminal-job-application-response.json');
  }
}

