import {Component, input} from '@angular/core';
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {SvgIconComponent} from "angular-svg-icon";

@Component({
  selector: 'app-no-data',
  standalone: true,
  imports: [
  ],
  templateUrl: './no-data.component.html',
  styleUrl: './no-data.component.scss'
})
export class NoDataComponent {
  size = input(250);
  message = input.required<string>();
}
