import {Injectable} from '@angular/core';
import {BaseApiService} from "../../../shared/base/base-api.service";
import {CompanyTypesRequest} from "./company-types/company-types-request";
import {URLs} from "../../api/urls";
import {CountriesRequest} from './countries/countries-request';
import {StateListRequest} from './states/stateList-request';
import {BusinessTypeRequest} from './business-type/business-types-request';
import {BusinessSubTypeRequest} from './business-sub-type/business-sub-type-request';


@Injectable({
  providedIn: 'root'
})
export class LookupService extends BaseApiService {

  getCompanyTypes() {
    return this.get(URLs.getCompanyTypes);
  }

  getAccountTypes() {
    return this.fromFile('assets/json/app-data/account-type.json');
  }

  getDriversList() {
    return this.fromFile('assets/json/mock-data/drivers-list.json');
  }

  getCountry() {
    return this.get(URLs.getCountries);
  }

  getStateList() {
    return this.get(URLs.getStatesList);
  }

  getBusinessType(request: BusinessTypeRequest) {
    return this.get(URLs.getBusinessType, request);
  }

  getBusinessSubType(request: BusinessSubTypeRequest) {
    return this.get(URLs.getBusinessSubType, request);
  }

  getCarrierType() {
    return this.fromFile('assets/json/app-data/carrier-type.json');
  }

  getCompanyInfo() {
    return this.get(URLs.getCompanyInfo);
  }

  getClearingHouseQueryFileUploadFrequency() {
    return this.fromFile('assets/json/app-data/clearing-house-query-file-upload-frequency.json');
  }

  getSapTestFrequency() {
    return this.fromFile('assets/json/app-data/sap-test-frequency.json');
  }

  getSapTestDuration() {
    return this.fromFile('assets/json/app-data/sap-test-duration.json');
  }
}

