import {Directive, inject, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {PermissionService} from '@core/services/auth/permission.service';
import {TokenStoreService} from "@core/services/auth/token-store.service";

@Directive({
  standalone: true,
  selector: '[hasPermission]'
})
export class HasPermissionDirective {

  tokenService = inject(TokenStoreService);
  permissionService = inject(PermissionService);
  templateRef = inject(TemplateRef);
  viewContainer = inject(ViewContainerRef);

  private requiredPermission: string = '';

  @Input() set hasPermission(permission: string) {
    this.requiredPermission = permission;
    this.checkPermission();
  }

  private checkPermission() {
    if (this.permissionService.hasPermission(this.requiredPermission)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

}
