<app-base-overlay [title]="data.documentName">
  <div body>
    <form class="flex flex-col gap-3 my-2" [formGroup]="fileUploadForm">

      @let subTypes = data.subTypes;
      <div class="flex flex-col gap-4" formArrayName="driverDocumentAttachments">
        @for (group of documentSubTypesFormArray.controls; track group; let i = $index; ) {
          <div class="" [formGroupName]="i">
            <app-file-uploader formControlName="filePath"
                               [label]="'Attach ' + subTypes[i].name" [isRequired]="true"
                               [uploading]="tempFileUploadStates[i].loading()"
                               (fileSelected)="onAttachFileClicked($event, i, subTypes[i])"></app-file-uploader>
          </div>
        }
      </div>

      @if (data.hasIdentificationNumber) {
        <app-text-input formControlName="identificationNumber"
                        [label]="data.identificationNumberLabel ?? 'Identification No.'"
                        class="w-full" [fullWidth]="true"></app-text-input>
      }

      @if (data.hasIssuedDate) {
        <app-date-input formControlName="issuedDate" [label]="'Issued Date'"
                        class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
        </app-date-input>
      }
      @if (data.hasExpiryDate) {
        <app-date-input formControlName="expiryDate" [label]="'Expiry Date'"
                        class="w-full" [fullWidth]="true">
        </app-date-input>
      }
      @if (data.hasCompletedDate) {
        <app-date-input formControlName="completedDate" [label]="'Completed Date'"
                        class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
        </app-date-input>
      }
      @if (data.hasReviewedDate) {
        <app-date-input formControlName="reviewedDate" [label]="'Reviewed Date'"
                        class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
        </app-date-input>
      }

      <app-text-area-input formControlName="notes"
                           class="w-full"
                           [label]="'Notes'"
                           [fullWidth]="true">
      </app-text-area-input>


    </form>
  </div>
  <div actions class="flex justify-end gap-3">
    <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
    </app-button>
    <app-button (click)="onUploadClicked()" [loading]="uploadState.loading()">Upload</app-button>
  </div>
</app-base-overlay>

