import {
  Directive,
  OnDestroy,
  HostListener,
  inject
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {Router, NavigationStart, Event} from '@angular/router';
import {OverlayService} from '@shared/components/overlay/overlay.service';
import {Subscription} from 'rxjs';
import {environment} from "../../../environments/environment";
import {EnvironmentType} from "../../../environments/environment-types";

@Directive({
  selector: 'form[unSavedAware]'
})
export class UnsavedAwareDirective implements OnDestroy {

  HANDLE_UNSAVED_CHANGES = false;

  formGroupDirective = inject(FormGroupDirective);
  router = inject(Router);
  overlayService = inject(OverlayService);

  private routerSubscription?: Subscription;
  private isConfirming = false;

  constructor() {
    this.routerSubscription = this.router.events.subscribe((event: Event) => {

      let isDevelopment = environment.environment == EnvironmentType.LOCAL || environment.environment == EnvironmentType.DEVELOPMENT;
      if (isDevelopment && !this.HANDLE_UNSAVED_CHANGES) {
        return;
      }

      if (event instanceof NavigationStart && this.formGroupDirective.form?.dirty) {

        if (this.isConfirming) {
          return;
        }

        this.isConfirming = true;

        this.confirmUnsavedChanges().then((value) => {
          this.isConfirming = false;
          if (value) {
            this.formGroupDirective.resetForm();
            this.router.navigateByUrl(event.url);
          } else {
            this.router.navigateByUrl(this.router.url);
          }
        });

        this.router.navigateByUrl(this.router.url);
      }
    });
  }

  async confirmUnsavedChanges(): Promise<boolean> {
    try {
      const result = await this.overlayService.openAlert('Unsaved Changes', 'You have unsaved changes! Do you really want to leave?').closed.toPromise();
      return result;
    } catch (error) {
      console.error('Error while showing confirmation dialog:', error);
      return false;
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent): void {

    let isDevelopment = environment.environment == EnvironmentType.LOCAL || environment.environment == EnvironmentType.DEVELOPMENT;
    if (isDevelopment && !this.HANDLE_UNSAVED_CHANGES) {
      return;
    }

    if (this.formGroupDirective.form?.dirty) {
      $event.returnValue = 'You have unsaved changes! Do you really want to leave?';
    }
  }
}
