import {Component, inject, OnInit, signal} from '@angular/core';
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {
  GetActiveTerminal,
  GetCompanyUserTerminalListItem,
  GetTerminalListItem,
  type SwitchTerminalResponse
} from "@core/api/model";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {NgClass} from "@angular/common";
import {ApiService} from "@core/api/api.service";
import {AppDataService, SelectedTerminal} from "@core/services/app-data/app-data.service";
import {arrayToCSV} from "@shared/utils/string-utils";
import {BaseRequestService} from "@shared/base/base-request.service";
import {State} from "@shared/base/base-state";
import {TerminalsService} from "@core/services/terminals/terminals.service";

@Component({
  selector: 'app-row-terminal-selection',
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    NgClass
  ],
  templateUrl: './row-terminal-selection.component.html',
  standalone: true,
  styleUrl: './row-terminal-selection.component.scss'
})
export class RowTerminalSelectionComponent extends TableCustomComponent<GetCompanyUserTerminalListItem> implements OnInit {
  appDataService = inject(AppDataService);
  // requestService = inject(BaseRequestService);
  apiService = inject(ApiService);
  terminalsService = inject(TerminalsService);

  selectedTerminal = signal<GetActiveTerminal | null>(null);
  // switchTerminalState = new State<SwitchTerminalResponse>();

  ngOnInit(): void {
    let selectedTerminal = this.appDataService.selectedTerminal();
    this.selectedTerminal.set(selectedTerminal);
  }

  getAddress(terminal: GetCompanyUserTerminalListItem): string {
    return arrayToCSV([terminal.addressLine1, terminal.addressLine2, terminal.city, terminal.stateName, terminal.countryName, terminal.zipCode]);
  }

  onSelectedTerminal(terminal: GetTerminalListItem) {
    let selectedTerminal: SelectedTerminal = {
      id: terminal.id,
      name: terminal.name
    };
    this.appDataService.saveSelectedTerminal(selectedTerminal);
    this.actionPerformed.emit({item: terminal, actionKey: 'selected'});
    this.terminalsService.onTerminalSelected(terminal);

    // this.requestService.executeRequest<SwitchTerminalResponse>({
    //   request: this.apiService.switchTerminal(terminal.id),
    //   state: this.switchTerminalState,
    // });
  }

}
