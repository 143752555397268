<div class="flex justify-center items-center min-h-screen bg-gray-200 p-4">
  <div class="container p-4 mx-auto flex flex-col gap-4 min-h-full max-h-full">
    <div class="flex flex-col py-4 gap-4">
      <p class="text-body1 text-center">BUS MAINTENANCE & SAFETY INSPECTION</p>
      <p class="text-body2 text-center text-neutral-500">CHP 108A (REV 6-05) OPI 062</p>
    </div>
    <form [formGroup]="busMaintenanceSafetyForm">
      <div class="flex py-4 justify-end">
        <app-outline-text-input [label]="'Inspection Date'" placeholder="EX: 03/22/2024"></app-outline-text-input>
      </div>
      <div class="h-full overflow-auto">
        <app-base-table>
          <ng-container head>
            <th class="px-4 py-2 text-left border border-neutral-50"></th>
            <th class="px-4 py-2 text-left border border-neutral-50"></th>
            <th class="bg-neutral-200 px-4 py-2 text-left text-overline text-neutral-500 border border-neutral-50">OK
            </th>
            <th class="bg-neutral-200 px-4 py-2 text-left text-overline text-neutral-700 border border-neutral-50">Def
            </th>
            <th class="bg-neutral-200 px-4 py-2 text-left text-overline text-neutral-700 border border-neutral-50">
              Notes
            </th>
          </ng-container>
          <ng-container body>
            @for ( inspection of inspectionsFormArray.controls; track inspection; let i = $index) {
            <tr [formGroupName]="i">
              <td class="px-4 py-4 bg-neutral-200 border border-neutral-50" formControlName="id">{{ i + 1 }}</td>
              <td class="px-4 py-4 bg-neutral-200 border border-neutral-50" formControlName="title">{{
            inspection.get('title')?.value }}</td>
              <td class="px-4 py-4 border border-neutral-50">
                <app-radio-button formControlName="ok" [groupName]="'ok'" [value]="true"></app-radio-button>
              </td>
              <td class="px-4 py-4 border border-neutral-50">
                <app-radio-button formControlName="def" [groupName]="'ok'" [value]="false"></app-radio-button>
              </td>
              <td class="px-4 py-4 border border-neutral-50">
                <app-outline-text-input formControlName="notes" [label]="'Notes'"></app-outline-text-input>
              </td>
            </tr>}
          </ng-container>
        </app-base-table>
      </div>
      <div class="p-6 w-full">
        <app-button [fullWidth]="true">Complete Test</app-button>
      </div>
    </form>
  </div>
</div>


