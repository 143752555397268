import {Component, inject, OnInit} from '@angular/core';

import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {ButtonComponent} from "@shared/components/button/button.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@shared/base/base-component";
import {
  UploadSapProgramTestResultRequest,
  UploadSapProgramTestResultResponse, UploadTempFileRequest, type UploadTempFileResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from '@shared/inputs/date-input/date-input.component';
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: 'app-upload-test-result',
  imports: [
    ButtonComponent,
    FileUploaderComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    BaseOverlayComponent,
    DateInputComponent
  ],
  templateUrl: './upload-test-result.component.html',
  standalone: true,
  styleUrl: './upload-test-result.component.scss'
})
export class UploadTestResultComponent extends BaseComponent implements OnInit {

  dialogRef = inject(DialogRef);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  data = inject(DIALOG_DATA);

  uploadTestResultForm!: FormGroup;

  uploadTestResultState = new State<UploadSapProgramTestResultResponse>();
  attachFileState = new State<UploadTempFileResponse>();
  attachFileCCFState = new State<UploadTempFileResponse>();

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.uploadTestResultForm = this.formBuilder.group({
      programTestDate: [null, [Validators.required, onlyPastDateValidator()]],
      programTestResult: [null, Validators.required],
      programTestFilePath: [null, Validators.required],
      programTestCCFFilePath: [null, Validators.required],
      notes: [null, Validators.required],
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }


  onDrugTestAttached($event: File) {
    let request: UploadTempFileRequest = {
      file: $event
    }

    this.executeRequest({
        state: this.attachFileState,
        request: this.apiService.uploadTempFile(request),
      }
    );
  }

  onDrugTestCCFAttached($event: File) {
    let request: UploadTempFileRequest = {
      file: $event
    }

    this.executeRequest({
        state: this.attachFileCCFState,
        request: this.apiService.uploadTempFile(request),
      }
    );
  }

  onSubmitClicked(): void {
    let request: UploadSapProgramTestResultRequest = {
      notes: this.uploadTestResultForm.value.notes,
      programTestDate: this.uploadTestResultForm.value.programTestDate,
      programTestCCFFilePath: this.attachFileCCFState.response()?.filePath ?? '',
      programTestResult: this.uploadTestResultForm.value.programTestResult,
      programTestFilePath: this.attachFileState.response()?.filePath ?? '',
      sapProgramId: this.data.id
    }
    this.executeRequest({
      state: this.uploadTestResultState,
      request: this.apiService.uploadSapProgramTestResult(request),
      handleSuccess: true,
      successMessage: 'The Test result uploaded successfully',
      onSuccess: (response) => {
        this.dialogRef.close(true);

      }
    });
  }

}
