import {Component, inject, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ToasterComponent} from "@shared/components/toast/components/toaster/toaster.component";
import {LoaderComponent} from "@shared/components/loader/loader.component";
import {ResponsiveHelperComponent} from "@shared/components/responsive-helper/responsive-helper.component";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import type {
  GetCompanyUserTerminalListResponse
} from "@core/api/model";
import {AppDataService} from "@core/services/app-data/app-data.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToasterComponent, LoaderComponent, ResponsiveHelperComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  appDataService = inject(AppDataService);

  ngOnInit() {
    if (!this.tokenService.isLoggedIn()) {
      return;
    }

    if (this.appDataService.selectedTerminal() == null) {
      this.handleTerminalSelection();
    }
  }

  private handleTerminalSelection() {
    this.executeRequest<GetCompanyUserTerminalListResponse>({
      request: this.apiService.getCompanyUserTerminalList(),
      state: new State(),
      onSuccess: response => {
        let terminals = response.data ?? [];
        if (terminals.length == 1) {
          let firstTerminal = terminals[0];
          this.appDataService.saveSelectedTerminal(firstTerminal);
        }
      }
    });
  }

}
