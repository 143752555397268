<div class="bg-white rounded-lg h-full">
  <table class="w-full h-full min-w-full bg-white" [ngClass]="isHorizontallyScrollable() ? 'overflow-x-auto' : ''">

    <!--    Table header-->
    <thead class="sticky top-0 bg-white z-10">
    <tr class="text-overline text-neutral-500 text-nowrap h-min">
      <ng-content select="[head]"></ng-content>
    </tr>
    <tr>
      <th colspan="100">
        <div class="border-b border-neutral-200"></div>
      </th>
    </tr>
    </thead>

    <!--    List Items-->
    <tbody class="h-min">
    <ng-content select="[body]"></ng-content>
    </tbody>

    <!--    Shimmer-->
    @if (state()?.loading()) {
      <tbody class="bg-white text-center py-14">
      <tr>
        <td colspan="100" class="h-min">
          <div class="flex flex-col gap-10 px-6 py-8">
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
          </div>
        </td>
      </tr>
      </tbody>
    }

    <!--    No Table Indicator-->
    @if (state()?.success() && (state()?.response()?.data ?? []).length <= 0) {
      <tbody>
      <tr>
        <td colspan="1000">
          <div class="flex flex-col justify-center items-center gap-8 px-6 py-8">
            <app-no-data-table></app-no-data-table>
          </div>
        </td>
      </tr>
      </tbody>
    }



    <!--    Paginator-->
    <tbody class="bottom-0 sticky">
    <tr>
      <td colspan="100">
        <div class="bg-white w-full">
          <app-pagination
            class="w-full"
            [pageSize]="itemsPerPage()"
            [totalItems]="state()?.response()?.totalCount ?? 0"
            (pageChange)="onPageChange($event)">
          </app-pagination>
        </div>
      </td>
    </tr>
    </tbody>

  </table>
</div>


