<app-base-overlay [title]="'Copy Job Application Link'">

  <div body>

    <div class="flex flex-col gap-3">
      <p class="text-body2 text-neutral-500">Note: The job application link will be generated for the
        selected terminal. Drivers can be hired only for terminals</p>

      <app-dropdown
        [(ngModel)]="selectedTerminal"
        [title]="'Terminal'"
        [display]="'name'"
        placeholder="Ex: Headquarters"
        [noDataMessage]="'No Options Available'"
        [fullWidth]="true"
        [items]="companyUserTerminalsState.response()?.data??[]"
        (ngModelChange)="onTerminalSelectionChanged()">
      </app-dropdown>


      <div class="flex items-center gap-4 justify-between border border-success-500 bg-success-50 py-3 px-4 rounded-lg w-full">
        <p class="text-body2">{{ terminalJobApplicationUrl() ?? 'Please select a terminal' }}</p>
        <app-button size="small" appearance="textType" [textButtonColor]="'text-neutral-900'"
                    [iconColor]="'text-neutral-400'"
                    iconSrc="assets/icons/content-copy.svg" (click)="onCopyClicked()">
          Copy Link
        </app-button>
      </div>


    </div>
  </div>

  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'"
                  (click)="onCloseClicked()">Close
      </app-button>
    </div>
  </div>

</app-base-overlay>
