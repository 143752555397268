import {Component, OnInit, inject, signal} from "@angular/core";
import {ButtonComponent} from "@shared/components/button/button.component";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {CommonModule} from "@angular/common";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Constants} from "@core/constants/constants";
import {
  ExportXlsxDriverSignedUrlResponse, GetDriverListDriverStatus,
  GetDriverListItem, GetDriverListItemDriverStatus,
  GetDriverListParams,
  GetDriverListResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {CreateNewDriverComponent} from "../../overlays/create-new-driver/create-new-driver.component";
import {DriverTableExpandedInfoComponent} from "./driver-table-expanded-info/driver-table-expanded-info.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent, TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";
import {DriverTransferToTerminalComponent} from "./driver-transfer-to-terminal/driver-transfer-to-terminal.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {TerminalsService} from "@core/services/terminals/terminals.service";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {
  TableCellDriverActionsComponent
} from "./component/table-cell-driver-actions/table-cell-driver-actions.component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";

@Component({
  selector: "app-drivers-list",
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    DataTableComponent,
    PageHeaderComponent,
    DropdownComponent,
    FormsModule
  ],
  templateUrl: "./drivers-list.component.html",
  styleUrl: "./drivers-list.component.scss",
})
export class DriversListComponent extends BaseComponent implements OnInit {

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);
  terminalsService = inject(TerminalsService);
  appDataService = inject(AppDataService);

  driverListState = new State<GetDriverListResponse>();
  exportXlsxDriverState = new State<ExportXlsxDriverSignedUrlResponse>();

  tableState = signal<TableStateEvent | null>(null);
  selectedStatusFilter = signal<DriverStatusFilter | null>(null);

  statuses: { label: string; value: GetDriverListItemDriverStatus | null; }[] = [];

  protected readonly DriverTableExpandedInfoComponent = DriverTableExpandedInfoComponent;

  driverStatusFilter: DriverStatusFilter[] = [
    {label: 'All', value: null, default: true},
    {label: 'Active', value: 'Active'},
    {label: 'Inactive', value: 'Inactive'},
    {label: 'Terminated', value: 'Terminated'},
  ];

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'Name',
      type: 'text',
      displayTemplate: '$fullName',
      alignment: 'center',
      sortKey: 'firstName',
      pinned: 'left'
    },
    {
      title: 'Email',
      type: 'text',
      displayTemplate: '$email',
      alignment: 'center',
      sortKey: 'email',
      pinned: 'left'
    },
    {title: 'Phone', type: 'text', displayTemplate: '$phoneNumber', alignment: 'center', visible: false},
    {
      title: 'Hired date', type: 'date', displayTemplate: '$hiredDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd MMM, yyyy'
      }
    },
    {
      title: 'Terminal',
      type: 'text',
      displayTemplate: '$homeTerminal',
      alignment: 'center',
      pinned: 'left'
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$driverStatus', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: GetDriverListItemDriverStatus.Active,
            displayText: 'Active',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: 'bg-success-500',
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
          {
            data: GetDriverListItemDriverStatus.Inactive,
            displayText: 'Inactive',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-600'
          },
          {
            data: GetDriverListItemDriverStatus.DriverAtRisk,
            displayText: 'Driver At Risk',
            backgroundColorClass: 'bg-warning-50',
            indicatorColorClass: "bg-warning-500",
            borderColorClass: 'border-warning-200',
            textColorClass: 'text-warning-500'
          },
          {
            data: GetDriverListItemDriverStatus.Terminated,
            displayText: 'Terminated',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
        ]
      }
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [
          {iconPath: 'assets/icons/eye-outline.svg', actionKey: 'view', label: 'View Driver'},
        ],
        components: [
          TableCellDriverActionsComponent
        ]
      }
    }
  ];

  ngOnInit(): void {
    this.terminalsService.terminalSelected = (terminal) => {
      this.getDriverList(Constants.defaultTableStateEvent);
    };
    this.handleDriverStatus();
  }

  onNewDriverClicked() {
    this.overlayService.openModal(CreateNewDriverComponent)
      .closed.subscribe((success) => {
        if (success) {
          this.getDriverList(Constants.defaultTableStateEvent);
        }
      }
    );
  }

  onExportClicked() {
    this.executeRequest<ExportXlsxDriverSignedUrlResponse>({
      request: this.apiService.exportXlsxDriverSignedUrl(),
      state: this.exportXlsxDriverState,
      onSuccess: response => {
        if (response.data?.downloadUrl) {
          window.open(response.data.downloadUrl, '_blank');
        }
      }
    });
  }

  private handleDriverStatus() {
    if (this.driverStatusFilter.length > 0) {
      let defaultFilter = this.driverStatusFilter.find(x => x.default) ?? null;
      this.selectedStatusFilter.set(defaultFilter);
    }
  }


  onDropDownFilterSelected() {
    this.getDriverList(Constants.defaultTableStateEvent, true);
  }

  getDriverList(event: TableStateEvent, filterChanged: boolean = false) {
    const request: GetDriverListParams = {
      pageNumber: filterChanged ? 1 : event.paginationEvent?.pageNumber,
      pageSize: filterChanged ? Constants.defaultPageSize : event.paginationEvent?.pageSize,
      sortingDirection: event.tableSortEvent?.direction ?? 'asc',
      orderByPropertyName: event.tableSortEvent?.key ?? undefined,
      search: event.searchText,
      terminalId: this.appDataService.selectedTerminal()?.id ?? '',
      driverStatus: this.selectedStatusFilter()?.value ?? undefined
    };
    this.executeRequest<GetDriverListResponse>({
      state: this.driverListState,
      request: this.apiService.getDriverList(request),
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetDriverListItem = $event.item;
    switch ($event.actionKey) {
      case 'view':
        this.router.navigate([`drivers/${item.id}/general-info`], {queryParams: {tab: 'general'}}).then();
        break;
      case 'transfer':
        let dialogRef = this.overlayService.openModal(DriverTransferToTerminalComponent, {
          data: item
        });
        dialogRef.closed.subscribe((selected) => {
          if (selected) {
            this.getDriverList(Constants.defaultTableStateEvent);
          }
        })
        break;
    }
  }

}

export interface DriverStatusFilter {
  label: string;
  value?: GetDriverListDriverStatus | null;
  default?: boolean;
}
