import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {Constants} from "../../../../core/constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {CreateReminderComponent} from "./create-reminder/create-reminder.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {State} from "../../../../shared/base/base-state";
import {DriverApplicationService} from "../../../../core/services/driver-application/driver-application.service";
import {DatePipe} from "@angular/common";
import {ApiService} from "../../../../core/api/api.service";
import type {
  DeleteDriverReminderResponse,
  GetDriverReminderListResponse
} from "../../../../core/api/model";
import {BaseTableComponent} from "../../../../shared/components/_base/base-table/base-table.component";
import {
  SortableTableDirective,
  TableSortEvent
} from "../../../../shared/components/_base/base-table/sortable-table.directive";

@Component({
  selector: 'app-driver-reminders',
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    DatePipe,
    BaseTableComponent,
    SortableTableDirective,
  ],
  templateUrl: './driver-reminders.component.html',
  styleUrl: './driver-reminders.component.scss'
})
export class DriverRemindersComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  driverService = inject(DriverApplicationService);
  apiService = inject(ApiService);

  remindersState = new State<GetDriverReminderListResponse>();
  deleteReminderState = new State<DeleteDriverReminderResponse>();

  driverId = input<string>();


  ngOnInit() {
    this.getReminders(1, 10);
  }

  createReminder() {
    this.dialog.open(CreateReminderComponent, {
      ...Constants.defaultDialogConfig,
      data: {
        driverId: this.driverId(),
      },

    }).afterClosed().subscribe(() =>
      this.getReminders(1, 10));
  }

  sortData($event: TableSortEvent) {

  }

  private getReminders(pageNumber: number, pageSize: number) {
    this.executeRequest({
      state: this.remindersState,
      request: this.apiService.getDriverReminderList(),
    });
  }

  onEditClicked(reminderId: string) {
    this.dialog.open(CreateReminderComponent, {
      ...Constants.defaultDialogConfig,
      data: {
        driverId: this.driverId(),
        reminderId: reminderId,
      },
    }).afterClosed().subscribe(() => {
      this.getReminders(1, 10);
    });
  }


  onDeleteClicked(id: string) {
    this.alertDialog.show('Delete Reminder', 'Are you sure you want to delete this reminder?').subscribe((result) => {
      if (result) {
        this.deleteReminder(id);
      }
    });
  }

  deleteReminder(id: string) {
    this.executeRequest({
      state: this.deleteReminderState,
      request: this.apiService.deleteDriverReminder(id),
      onSuccess: (response) => {
        const currentState = this.remindersState.response();
        if (currentState && currentState.data) {
          currentState.data = currentState.data.filter((reminder) => reminder.id !== id);
        }
      }
    });
  }

}
