import {Component, inject, input, OnInit} from "@angular/core";
import {BaseComponent} from "../../../../shared/base/base-component";
import {ApiService} from "../../../../core/api/api.service";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {MatDialog} from "@angular/material/dialog";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {CommonModule} from "@angular/common";
import {State} from "../../../../shared/base/base-state";
import {
  GetDriverDocumentListItem,
  GetDriverDocumentListParams,
  GetDriverDocumentListResponse, GetDriverSectionListItem
} from "../../../../core/api/model";
import {DocumentUploadComponent} from "../../../../core/components/document-upload/document-upload.component";
import {Constants} from "../../../../core/constants/constants";
import {DocumentViewComponent} from "../../../../core/components/document-view/document-view.component";
import {ShimmerComponent} from "../../../../shared/components/shimmer/shimmer.component";

@Component({
  selector: 'app-driver-documents',
  standalone: true,
  imports: [
    OutlineTextInputComponent,
    CommonModule,
    DocumentViewComponent,
    ShimmerComponent
  ],
  templateUrl: './driver-documents.component.html',
  styleUrl: './driver-documents.component.scss'
})
export class DriverDocumentsComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  dialog = inject(MatDialog);

  driverDocumentsState = new State<GetDriverDocumentListResponse>();

  driverId = input<string>();

  ngOnInit() {
    this.getDocuments();
  }

  private getDocuments(): void {
    let request: GetDriverDocumentListParams = {
      driverId: this.driverId() ?? ''
    }
    this.executeRequest(
      {
        state: this.driverDocumentsState,
        request: this.apiService.getDriverDocumentList(request),
      }
    );
  }

  onDocumentsSearch(searchDocumentName: string) {
    let sections = this.driverDocumentsState.response()?.data ?? [];
    sections.forEach((section) => {
      section.documents.forEach((document) => {
        (document as any).matched = document.documentName
          .toLowerCase()
          .includes(searchDocumentName.toLowerCase());
      });
    });

  }

  isFiltered(document: GetDriverDocumentListItem) {
    return (document as any).matched || (document as any).matched === undefined;
  }

  matchedDocumentCount(documentSection: GetDriverSectionListItem): number {
    let documents = documentSection.documents ?? [];
    return documents.filter((document) => ((document as any).matched) || (document as any).matched === undefined).length;
  }
}
