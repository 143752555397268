import {Component, inject, input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../shared/base/base-component";
import {
  OutlineTextInputComponent
} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import type {GetDriverGeneralResponse, SaveDriverGeneralRequest} from "../../../../core/api/model";
import {State} from "../../../../shared/base/base-state";
import {ApiService} from "../../../../core/api/api.service";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {DateUtils} from "../../../../shared/utils/date-utils";

@Component({
  selector: 'app-driver-general-info',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    OutlineTextInputComponent,
    RadioButtonComponent,
    ButtonComponent
  ],
  templateUrl: './driver-general-info.component.html',
  styleUrl: './driver-general-info.component.scss'
})
export class DriverGeneralInfoComponent extends BaseComponent implements OnInit {

  driverId = input<string>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  saveGeneralInfoState = new State<any>();
  getDriverGeneralInfoState = new State<GetDriverGeneralResponse>();

  driverGeneralInfoForm!: FormGroup;

  ngOnInit() {
    this.initForm();
    this.getDriverGeneralInfo();
  }

  private initForm() {
    this.driverGeneralInfoForm = this.formBuilder.group({
        id: [""],
        driverApplicationId: [''],
        firstName: ["", Validators.required],
        middleName: [""],
        lastName: ["", Validators.required],
        phoneNumber: ["", Validators.required],
        email: ["", Validators.required],
        dateOfBirth: ["", Validators.required],
        hasUsWorkLegality: [true, Validators.required],
      }
    );
  }

  getDriverGeneralInfo() {
    this.executeRequest<GetDriverGeneralResponse>({
      state: this.getDriverGeneralInfoState,
      request: this.apiService.getDriverGeneral(this.driverId() ?? ''),
      onSuccess: (response) => {
        let dateUtils = new DateUtils();
        let generalInfo = response.data;

        this.driverGeneralInfoForm.controls["id"].setValue(generalInfo.id);
        this.driverGeneralInfoForm.controls["driverApplicationId"].setValue(
          this.tokenService.getDriverApplicationId()
        );
        this.driverGeneralInfoForm.controls["firstName"].setValue(generalInfo.firstName);
        this.driverGeneralInfoForm.controls["middleName"].setValue(generalInfo.middleName);
        this.driverGeneralInfoForm.controls["lastName"].setValue(generalInfo.lastName);
        this.driverGeneralInfoForm.controls["phoneNumber"].setValue(generalInfo.phoneNumber);
        this.driverGeneralInfoForm.controls["email"].setValue(generalInfo.email);
        this.driverGeneralInfoForm.controls["dateOfBirth"].setValue(dateUtils.convertToMMDDYYYY(generalInfo.dateOfBirth));
        this.driverGeneralInfoForm.controls["hasUsWorkLegality"].setValue(generalInfo.hasUsWorkLegality);
      },
    });
  }

  onUpdateClicked() {
    if (this.driverGeneralInfoForm.invalid) {
      return;
    }

    let formValue = this.driverGeneralInfoForm.value;
    let dateUtils = new DateUtils();
    let generalInfo = this.getDriverGeneralInfoState.response()?.data;
    let request: SaveDriverGeneralRequest = {
      driverId: this.driverId() ?? '',
      id: generalInfo?.id ?? '',
      dateOfBirth: dateUtils.convertToDateTimeString(formValue.dateOfBirth) ?? '',
      email: formValue.email,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      hasUsWorkLegality: formValue.hasUsWorkLegality,
      phoneNumber: formValue.phoneNumber,
      middleName: formValue.middleName,
    }

    this.executeRequest(
      {
        state: this.saveGeneralInfoState,
        request: this.apiService.saveDriverGeneral(request),
      });
  }
}
