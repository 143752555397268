<app-base-overlay [title]="'New Equipment'" class="mx-6">
  <div body>
    <form [formGroup]="createEquipmentForm">
      <div class="flex flex-col items-start gap-4">
        <span class="items-start text-body1 text-primary-500">General Information</span>
        <app-single-selection-field
          formControlName="classOfEquipment"
          title="Equipment Type"
          [items]="classOfEquipmentState.response()?.data ?? []"
          [display]="'displayName'"
          [value]="'name'"
          [noDataMessage]="'No items available'"
          [fullWidth]="true"
        >
        </app-single-selection-field>
        <app-text-input formControlName="unit" label="Unit #" placeholder="Ex: LM12345"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <div class="grid lg:grid-cols-2 gap-6 w-full">
          <app-text-input formControlName="year" [label]="'Year'" placeholder="Ex: 2021" class="w-full"
                                  [fullWidth]="true"></app-text-input>
          <app-text-input formControlName="make" [label]="'Make'" placeholder="Ex: FRGHT" class="w-full"
                                  [fullWidth]="true"></app-text-input>
        </div>
        <app-text-input formControlName="model" label="Model" placeholder="Ex: 2021"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="vin" label="VIN" placeholder="Ex: 1GVW23456789"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="licensePlate" label="License Plate #" placeholder="Ex: 1ABC234"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="licensePlateExpiryDate" label="License Plate Expiry Date"
                                placeholder="Ex: 1ABC234"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="registrationState" label="Registration State"
                                placeholder="Ex: California"
                                class="w-full" [fullWidth]="true"></app-text-input>

        <span class="items-start text-body1 text-primary-500">Additional Information</span>
        <app-text-input formControlName="tireSize" label="Tire Size" placeholder="Ex: 24000"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="gvwrInIBS" label="GVWR in lbs." placeholder="Ex: 24000"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="currentMilage" label="Current Milage" placeholder="Ex: 24000"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="ownerIfLeased" label="Owner (if leased)" placeholder="Ex: John Bravo"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="lastServiceDate" label="Last Service Date" placeholder="Ex: 03/22/2024"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="nextServiceDate" label="Next Service Date" placeholder="Ex: 03/22/2024"
                                class="w-full" [fullWidth]="true"></app-text-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex flex-col items-center gap-4">
      <app-button>Save</app-button>
    </div>
  </div>
</app-base-overlay>


