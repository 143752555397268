import {Component, inject, OnInit, output} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {
  TextInputComponent
} from "@shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import type {
  GetDriverApplicationAccidentItem,
  GetDriverApplicationAccidentParams,
  GetDriverApplicationAccidentResponse,
  SaveDriverApplicationAccident, SaveDriverApplicationAccidentRequest,
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: "app-save-applicant-accident-info",
  standalone: true,
  imports: [
    FormsModule,
    RadioButtonComponent,
    ReactiveFormsModule,
    TextInputComponent,
    ButtonComponent,
    DateInputComponent
  ],
  templateUrl: "./save-applicant-accident-info.component.html",
  styleUrl: "./save-applicant-accident-info.component.scss",
})
export class SaveApplicantAccidentInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  accidentsForm!: FormGroup;
  accidentsFormArray!: FormArray;

  applicantAccidentsState = new State<GetDriverApplicationAccidentResponse>();
  saveAccidentsState = new State<any>();

  ngOnInit() {
    this.initForm();
    this.getAccidents();
  }

  private initForm() {
    this.accidentsFormArray = this.formBuilder.array([]);
    this.accidentsForm = this.formBuilder.group({
      causedDrivingAccident: [false],
      accidents: this.accidentsFormArray,
    });
  }

  getAccidents() {
    let request: GetDriverApplicationAccidentParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationAccidentResponse>({
      state: this.applicantAccidentsState,
      request: this.apiService.getDriverApplicationAccident(request),
      onSuccess: response => {
        let causedDrivingAccident = response.causedDrivingAccident;
        this.accidentsForm.controls['causedDrivingAccident'].setValue(causedDrivingAccident ?? false);
        this.populateForm(response.data ?? []);
      }
    });
  }

  private populateForm(accidents: GetDriverApplicationAccidentItem[]) {
    if (accidents.length <= 0) {
      let form = this.createApplicantAccidentForm(null);
      this.accidentsFormArray.push(form);
    } else {
      accidents.forEach(accident => {
        let form = this.createApplicantAccidentForm(accident);
        this.accidentsFormArray.push(form);
      });
    }
  }

  createApplicantAccidentForm(accident: GetDriverApplicationAccidentItem | null) {
    let accidentDate = accident?.accidentDate ? new Date(accident.accidentDate) : null;
    return this.formBuilder.group({
      id: [accident?.id],
      accidentDate: [accidentDate, [Validators.required, onlyPastDateValidator()]],
      natureOfAccident: [accident?.natureOfAccident, Validators.required],
      fatalities: [accident?.fatalities, Validators.required],
      injuries: [accident?.injuries, Validators.required],
      hasChemicalSpills: [accident?.hasChemicalSpills ?? false],
    });
  }

  onAddMoreAccidentsClicked() {
    let newForm = this.createApplicantAccidentForm(null);
    this.accidentsFormArray.push(newForm);
  }

  onDeleteClicked(index: number): void {
    if (this.accidentsFormArray.length > 1)
      this.accidentsFormArray.removeAt(index);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.accidentsForm);
    let causedDrivingAccident = this.accidentsForm.value.causedDrivingAccident;
    if (this.accidentsForm.invalid && causedDrivingAccident) {
      return;
    }

    let request: SaveDriverApplicationAccidentRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      causedDrivingAccident: causedDrivingAccident,
      accidents: []
    };

    if (causedDrivingAccident) {
      this.accidentsFormArray.controls.forEach((control, index) => {
        let accident = control.value;
        let driverAccident: SaveDriverApplicationAccident = {
          displayOrder: index + 1,
          accidentDate: accident.accidentDate,
          natureOfAccident: accident.natureOfAccident,
          fatalities: accident.fatalities,
          hasChemicalSpills: accident.hasChemicalSpills,
          injuries: accident.injuries,
        }
        request.accidents.push(driverAccident);
      });
    }

    this.executeRequest({
      state: this.saveAccidentsState,
      request: this.apiService.saveDriverApplicationAccident(request),
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.onNextClick.emit();
      }
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
