import {
  Component,
  inject,
  OnInit, signal,
} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  DriverApplication,
} from "@core/services/driver-application/application/driver-application-response";
import {State} from "@shared/base/base-state";
import {
  SendDriverApplicationComponent
} from "../../../drivers/overlays/send-driver-application/send-driver-application.component";
import {Constants} from "@core/constants/constants";
import {
  DownloadDriverApplicationFormResponse,
  ExportXlsxDriverApplicationSignedUrlResponse, GetDriverApplicationListItemApplicationStatusEnum,
  GetDriverApplicationListParams,
  GetDriverApplicationListResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {
  ColumnDef, CustomRendererConfig,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";
import {
  TableCellDriverApplicationActionsComponent
} from "./table-cell-driver-application-actions/table-cell-driver-application-actions.component";
import {driverApplicationStatusConstants} from "@core/constants/driver-application-status-constants";
import {
  CopyJobApplicationLinkComponent
} from "../../overlays/copy-job-application-link/copy-job-application-link.component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {FormsModule} from "@angular/forms";
import {toSentenceCase} from "@shared/utils/string-utils";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {TerminalsService} from "@core/services/terminals/terminals.service";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {
  TableCellViewOrUpdateComponent
} from "./table-cell-view-or-update/table-cell-view-or-update.component";


@Component({
  selector: "app-driver-application-list",
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    AppSvgIconComponent,
    PageHeaderComponent,
    DataTableComponent,
    DropdownComponent,
    FormsModule,
  ],
  templateUrl: "./driver-application-list.component.html",
  styleUrl: "./driver-application-list.component.scss",
})
export class DriverApplicationListComponent extends BaseComponent implements OnInit {

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);
  terminalsService = inject(TerminalsService);
  appDataService = inject(AppDataService);

  applicationState = new State<GetDriverApplicationListResponse>();
  exportXlsxDriverApplicationSignedUrlState = new State<ExportXlsxDriverApplicationSignedUrlResponse>();
  exportDriverApplicationSignedUrlState = new State<DownloadDriverApplicationFormResponse>();

  tableState = signal<TableStateEvent | null>(null);
  statusFilter = signal<{
    label: string;
    value: GetDriverApplicationListItemApplicationStatusEnum | null;
  } | null>(null);

  statuses: { label: string; value: GetDriverApplicationListItemApplicationStatusEnum | null; }[] = [];

  columnDefs: ColumnDef[] = [
    {
      title: 'Email',
      type: 'text',
      alignment: 'left',
      displayTemplate: '$email',
      sortKey: 'email'
    },
    {
      title: 'Application Date',
      type: 'date',
      alignment: 'center',
      displayTemplate: '$applicationDate',
      sortKey: 'applicationDate',
      dateConfig: {
        dateFormat: 'dd MMM, yyyy hh:mm a',
        showIcon: false
      }
    },
    {
      title: 'Terminal',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$terminalName',
    },
    {
      title: 'Invited By',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$invitedBy',
      sortKey: 'invitedBy',
    },
    {
      title: 'Status',
      type: 'badge',
      displayTemplate: '$applicationStatusEnum',
      alignment: 'center',
      badgeConfig: {
        properties: driverApplicationStatusConstants
      }
    },
    {
      title: '',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        components: [
          TableCellViewOrUpdateComponent,
        ],
      }
    },
    {
      title: '',
      type: 'custom',
      alignment: 'center',
      component: TableCellDriverApplicationActionsComponent
    }
  ]

  ngOnInit(): void {
    this.getDriverApplications(Constants.defaultTableStateEvent);
    this.populateDropDownStatus();
    this.terminalsService.terminalSelected = (terminal) => {
      this.getDriverApplications(Constants.defaultTableStateEvent);
    };
  }

  populateDropDownStatus() {

    let statusList: {
      label: string,
      value: GetDriverApplicationListItemApplicationStatusEnum | null
    }[] = Object.values(GetDriverApplicationListItemApplicationStatusEnum).map((status) => {
      return {
        label: toSentenceCase(status),
        value: status
      }
    });

    statusList.unshift({
      label: 'All',
      value: null
    });

    this.statuses = statusList;

    this.statusFilter.set(this.statuses[0]);

  }

  onSendDriverApplicationClicked() {
    let dialogRef = this.overlayService.openModal(SendDriverApplicationComponent);

    dialogRef.closed.subscribe((isSuccess) => {
      if (isSuccess) {
        this.getDriverApplications(Constants.defaultTableStateEvent);
      }
    });
  }

  onDropDownFilterSelected() {
    this.getDriverApplications(this.tableState()!, true);
  }

  getDriverApplications($event: TableStateEvent, filterChanged: boolean = false) {
    const request: GetDriverApplicationListParams = {
      pageNumber: filterChanged ? 1 : $event?.paginationEvent?.pageNumber ?? 1,
      pageSize: filterChanged ? Constants.defaultPageSize : $event?.paginationEvent?.pageSize ?? 50,
      search: $event.searchText,
      driverApplicationStatus: this.statusFilter()?.value ?? '',
      terminalId: this.appDataService.selectedTerminal()?.id ?? ''
    };
    this.executeRequest<GetDriverApplicationListResponse>({
      state: this.applicationState,
      request: this.apiService.getDriverApplicationList(request)
    });
  }

  onJobApplicationLinkClicked() {
    this.overlayService.openModal(CopyJobApplicationLinkComponent);
  }

  onExportClicked() {
    this.executeRequest<ExportXlsxDriverApplicationSignedUrlResponse>({
      request: this.apiService.exportXlsxDriverApplicationSignedUrl(),
      state: this.exportXlsxDriverApplicationSignedUrlState,
      onSuccess: (response) => {
        if (response.data.downloadUrl) {
          window.open(response.data.downloadUrl, "_blank");
        }
      }
    });
  }

  onDownloadDriverApplicationClicked() {
    this.executeRequest<DownloadDriverApplicationFormResponse>({
      request: this.apiService.downloadDriverApplicationFormSignedUrl(),
      state: this.exportDriverApplicationSignedUrlState,
      onSuccess: (response) => {
        if (response.data.downloadUrl) {
          window.open(response.data.downloadUrl, "_blank");
        }
      }
    });
  }

  onAction($event: TableActionEvent) {
    let item = $event.item as DriverApplication;
    switch ($event.actionKey) {
      case 'view': {
        this.router.navigate([`/driver-application/apply`], {
          queryParams: {applicationId: item.id},
        }).then();
        break;
      }
      case 'statusChange':
        this.getDriverApplications(Constants.defaultTableStateEvent);
        break;
    }
  }
}
