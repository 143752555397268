import {Component, inject, OnInit} from '@angular/core';
import {
  DriverApplicationInfo
} from "../../../../../core/services/driver-application/driver-application-info/driver-application-info-response";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NgClass} from "@angular/common";
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {FileUploaderComponent} from "../../../../../shared/inputs/file-uploader/file-uploader.component";

@Component({
  selector: 'app-driver-incomplete-application',
  templateUrl: './driver-incomplete-application.component.html',
  styleUrls: ['./driver-incomplete-application.component.scss'],
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    NgClass,
    BaseDialogComponent,
  ],
  standalone: true
})
export class DriverIncompleteApplicationComponent implements OnInit {

  data: DriverApplicationInfo = inject(MAT_DIALOG_DATA);
  dialogRef = inject(MatDialogRef);

  onCloseClicked() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  protected readonly inject = inject;

}
