import {AfterViewInit, Component, input, output, Type} from '@angular/core';
import {InfiniteScrollDirective} from 'ngx-infinite-scroll';
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {NoDataTableComponent} from "@shared/components/no-data-table/no-data-table.component";
import {DynamicRendererComponent} from "@shared/components/dynamic-renderer/dynamic-renderer.component";
import {ShimmerComponent} from "@shared/components/shimmer/shimmer.component";
import {PaginationState} from "@shared/base/base-pagination-state";
import {TableActionEvent, TableStateEvent} from "@shared/components/data-table/data-table.component";
import {PaginationEvent} from "@shared/components/pagination/pagination.component";

@Component({
  selector: 'app-listview',
  imports: [
    InfiniteScrollDirective,
    TextInputComponent,
    DynamicRendererComponent,
    ShimmerComponent,
    NoDataTableComponent,
    TextInputComponent,
    NoDataTableComponent,
    DynamicRendererComponent,
  ],
  standalone: true,
  templateUrl: './listview.component.html',
  styleUrl: './listview.component.scss'
})
export class ListviewComponent implements AfterViewInit {

  state = input.required<PaginationState<any>>();
  data = input<any>();
  pageSize = input(20);
  component = input.required<Type<any>>();
  enableSearch = input(true);
  enableSearchFullWidth = input(true);

  onStateChanged = output<TableStateEvent>();
  onActionPerformed = output<TableActionEvent>();

  private searchText: string = '';

  ngAfterViewInit(): void {
    let paginationEvent: PaginationEvent = {
      pageNumber: 1, pageSize: this.pageSize()
    }

    let state: TableStateEvent = {
      searchText: '',
      paginationEvent: paginationEvent,
    };
    this.onStateChanged.emit(state);
  }

  onSearchTextChanged($event: string) {
    this.state().clearState();
    this.searchText = $event;
    let paginationEvent: PaginationEvent = {
      pageNumber: 1, pageSize: this.pageSize()
    }
    let tableStateEvent: TableStateEvent = {
      searchText: this.searchText,
      paginationEvent: paginationEvent,
    };
    this.onStateChanged.emit(tableStateEvent);
  }

  onScroll() {
    let nextPage = this.state().pageNumber() + 1;
    let paginationEvent: PaginationEvent = {
      pageNumber: nextPage, pageSize: this.pageSize()
    }
    let tableStateEvent: TableStateEvent = {
      searchText: this.searchText,
      paginationEvent: paginationEvent,
    };
    this.onStateChanged.emit(tableStateEvent);
  }

  onRowAction($event: TableActionEvent) {
    this.onActionPerformed.emit($event);
  }
}
