@let attachments = _document()?.attachments ?? [];
<!--@let isMultiple = _document()?.isMultiple;-->
@let isSingle = !(_document()?.isMultiple);

<div class="document flex flex-col gap-2 px-3 py-2 shadow-1 rounded-lg border border-neutral-100">
  <div class="flex items-center gap-5 w-full">

    <div class="flex justify-center items-center rounded-lg h-16 w-16 bg-info-50">
      <app-svg-icon class="text-info-700" [src]="_document()?.icon ?? 'assets/icons/file.svg'"></app-svg-icon>
    </div>

    <div class="flex flex-col gap-1 grow">

      <div class="flex items-center w-full gap-3">
        <div class="grow flex items-center gap-3">
          <span class="text-body2 font-semibold">{{ _document()?.documentName }}</span>
          @if (_document()?.identificationNumber) {
            <span class="text-caption">({{ _document()?.identificationNumber }})</span>
          }
        </div>
        <div class="flex gap-3 items-center">

          @if (attachments.length > 0) {
            <span
              class="text-caption text-neutral-500">Uploaded on {{ _document()?.uploadedDate | date:'short' }}</span>
          }

          @if (attachments.length > 0) {
            <div
              (click)="onDeleteClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-neutral-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-error-500" [size]="18" src="assets/icons/bin.svg"></app-svg-icon>
              <span class="text-body2 text-error-500">Delete</span>
            </div>
          }

        </div>
      </div>

      <div class="flex items-center w-full gap-3">

        <div class="flex items-center gap-3 grow">
          @if (attachments.length == 0) {
            <span class="text-caption text-neutral-400">No file(s) uploaded</span>
          }

          @if (_document()?.completedDate) {
            <div class="flex items-center gap-1 bg-success-50 px-3 py-2 rounded-md">
              <span
                class="text-caption text-success-500"> {{ _document()?.completedDateFieldLabel ?? 'Completed on' }} </span>
              <span class="text-caption text-success-500"> : </span>
              <span
                class="text-caption text-success-600"> {{ _document()?.completedDate ?? '2024-04-22T14:20:00Z' | date:'dd MMM yyyy' }} </span>
            </div>
          }

          @if (_document()?.expiryDate) {
            <div class="flex items-center gap-1 bg-error-50 px-3 py-2 rounded-md">
              <span class="text-caption text-error-500"> {{ _document()?.expiryDateFieldLabel ?? 'Expires on' }} </span>
              <span class="text-caption text-error-500"> : </span>
              <span
                class="text-caption text-error-600"> {{ _document()?.expiryDate ?? '2024-04-22T14:20:00Z' | date:'dd MMM yyyy' }} </span>
            </div>
          }

          @if (_document()?.issuedDate) {
            <div class="flex items-center gap-1 bg-info-50 px-3 py-2 rounded-md">
              <span class="text-caption text-info-500"> {{ _document()?.issuedDateFieldLabel ?? 'Issued date' }} </span>
              <span class="text-caption text-info-500"> : </span>
              <span
                class="text-caption text-info-600"> {{ _document()?.issuedDate ?? '2024-04-22T14:20:00Z' | date:'dd MMM yyyy' }} </span>
            </div>
          }

          @if (_document()?.reviewedDate) {
            <div class="flex items-center gap-1 bg-info-50 px-3 py-2 rounded-md">
              <span
                class="text-caption text-info-500"> {{ _document()?.reviewedDateFieldLabel ?? 'Reviewed on' }} </span>
              <span class="text-caption text-info-500"> : </span>
              <span
                class="text-caption text-info-600"> {{ _document()?.reviewedDate ?? '2024-04-22T14:20:00Z' | date:'dd MMM yyyy' }} </span>
            </div>
          }
        </div>

        <div class="flex items-center gap-3">

          @if (isSingle && attachments.length > 0) {
            <div
              (click)="onViewClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/eye.svg"></app-svg-icon>
              <span class="text-caption text-info-500">View</span>
            </div>
          }

          @if (attachments.length > 0 && _document()?.isMultiple) {
            <div
              (click)="onViewAllClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/eye.svg"></app-svg-icon>
              <span class="text-caption text-info-500">View All</span>
            </div>
          }

          @if (_document()?.canUpload) {
            <div
              (click)="onUploadClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/upload.svg"></app-svg-icon>
              <span class="text-caption text-info-500">Upload</span>
            </div>
          }

          @if (true) {
            <div
              (click)="onDownloadClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/download.svg"></app-svg-icon>
              <span class="text-caption text-info-500">Download</span>
            </div>
          }

        </div>


      </div>

    </div>

  </div>

  @if (_document()?.notes != null) {
    <div class="border-b border-neutral-100 w-full"></div>
  }

  @if (_document()?.notes != null) {
    <div class="flex items-center gap-1">
      <span class="text-caption text-neutral-400">Notes</span>
      <span class="text-caption text-neutral-400">:</span>
      <span class="text-caption text-neutral-500">{{ _document()?.notes }}</span>
    </div>
  }
</div>
