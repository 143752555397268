<app-base-overlay [title]="'Create new driver'">
  <div body>
    <form [formGroup]="createDriverForm" class="flex flex-col gap-3">

      <app-single-selection-field
        formControlName="terminalId"
        title="Terminal"
        [items]="companyUserTerminalsState.response()?.data ?? []"
        display="name"
        value="id">
      </app-single-selection-field>

      <div class="flex flex-col gap-3">
        <p class="text-body1 text-info-500">General Information</p></div>
      <div class="grid grid-cols-2 gap-2">
        <app-text-input formControlName="firstName" label="First Name" placeholder="Ex: John" class="w-full"
                        [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="middleName" label="Middle Name" placeholder="Ex: Michael"
                        class="w-full"
                        [fullWidth]="true"></app-text-input>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <app-text-input formControlName="lastName" label="Last Name" placeholder="Ex: Bravo"
                        class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="employeeReferenceNumber" label="Employee ID" placeholder="Ex: EMP1234"
                        class="w-full" [fullWidth]="true"></app-text-input>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <app-text-input formControlName="phoneNumber" label="Phone Number" placeholder="Ex: +1 123-456-78XX"
                        class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="email" label="Email" placeholder="Ex: johnBravo@gmail.com"
                        class="w-full"
                        [fullWidth]="true"></app-text-input>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <app-date-input formControlName="dateOfBirth" [label]="'Date of Birth'"
                        class="w-full" [fullWidth]="true" [allowOnlyPast]="true"></app-date-input>
        <app-date-input formControlName="dateOfHire" [label]="'Date of Hire'"
                        class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
        </app-date-input>
      </div>

      <div class="flex items-center gap-6">
        <span class="text-body2">Have legal right to work in united states?</span>
        <app-radio-button formControlName="hasUsWorkLegality" [groupName]="'hasUsWorkLegality'" title="Yes"
                          [value]="true"></app-radio-button>
        <app-radio-button formControlName="hasUsWorkLegality" [groupName]="'hasUsWorkLegality'" title="No"
                          [value]="false"></app-radio-button>
      </div>

      <div class="line my-2"></div>

      <div class="flex flex-col gap-3">
        <p class="text-body1 text-info-500">License Information</p></div>
      <div class="grid grid-cols-2 gap-2">
        <app-text-input formControlName="licenseNo" label="License Number" placeholder="Ex: ABC1234XXX"
                        class="w-full"
                        [fullWidth]="true"></app-text-input>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <app-dropdown
          formControlName="countryId"
          [title]="'Country'"
          [display]="'name'"
          placeholder="Ex: United States"
          [value]="'id'"
          [noDataMessage]="'No Options Available'"
          [enableSearch]="true"
          class="w-full"
          [fullWidth]="true"
          [items]="countryListState.response()?.data ?? [] "
          (valueChanged)="onCountrySelected($event)"
        ></app-dropdown>
        <app-dropdown formControlName="stateId" placeholder="Ex: California" [title]="'State'"
                      [display]="'name'" [value]="'id'"
                      [noDataMessage]="'No Options Available'" class="w-full" [fullWidth]="true"
                      [items]="filteredStates" [enableSearch]="true"></app-dropdown>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <app-dropdown formControlName="class"
                      [items]="licenseClassesState.response()?.data ?? []"
                      [title]="'Class'"
                      [noDataMessage]="'No Options Available'"
                      [enableSearch]="true"
                      class="w-full"
                      placeholder="Ex: United States - Class A"
                      [fullWidth]="true"
                      [display]="null">
        </app-dropdown>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <app-date-input formControlName="issueDate" [label]="'Issued Date'"
                        class="w-full" [fullWidth]="true" [allowOnlyPast]="true" >
        </app-date-input>
        <app-date-input formControlName="expirationDate" [label]="'Expiration Date'"
                        class="w-full" [fullWidth]="true"
        ></app-date-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
      </app-button>
      <app-button (click)="onSaveClicked()" [loading]="createDriverState.loading()">Save</app-button>
    </div>
  </div>
</app-base-overlay>




