import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {
  CreateTerminalRequest,
  CreateTerminalResponse, type GetCompanyUserTerminalListItem, UpdateTerminalRequest, UpdateTerminalResponse
} from "@core/api/model";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {CommonService} from "@core/services/common/common.service";
import {CountriesResponse, Country, CountryState} from "@core/services/common/countries/countries-response";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-create-or-update-terminal',
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    ButtonComponent,
    DropdownComponent,
    BaseOverlayComponent
  ],
  templateUrl: './create-or-update-terminal.component.html',
  standalone: true,
  styleUrl: './create-or-update-terminal.component.scss'
})
export class CreateOrUpdateTerminalComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialog = inject(DialogRef);
  apiService = inject(ApiService);
  data?: GetCompanyUserTerminalListItem = inject(DIALOG_DATA);
  commonService = inject(CommonService);

  createOrUpdateTerminalForm!: FormGroup;

  countryListState = new State<CountriesResponse>();
  createTerminalState = new State<CreateTerminalResponse>();
  updateTerminalState = new State<UpdateTerminalResponse>();

  filteredStates: CountryState[] = [];

  ngOnInit() {
    console.log(this.data);
    this.initForm();
    this.getCountries();
  }

  initForm() {
    this.createOrUpdateTerminalForm = this.formBuilder.group({
      name: [this.data?.name, Validators.required],
      addressLine1: [this.data?.addressLine1, Validators.required],
      addressLine2: [this.data?.addressLine2],
      city: [this.data?.city, Validators.required],
      countryId: [this.data?.countryId, Validators.required],
      stateId: [this.data?.stateId, Validators.required],
      zipCode: [this.data?.zipCode, Validators.required],
    });
  }

  getCountries() {
    this.executeRequest<CountriesResponse>({
      request: this.commonService.getCountries(),
      state: this.countryListState,
      onSuccess: response => {
        if (this.data?.countryId) {
          let selectedCountry = response.data.find(c => c.id === this.data?.countryId) ?? null;
          this.filteredStates = selectedCountry?.states ?? [];
        }
      }
    });
  }

  onCountrySelected(selectedItem: Country | null) {
    this.createOrUpdateTerminalForm.controls['stateId'].setValue(null);
    this.filteredStates = selectedItem?.states ?? [];
  }

  onSubmitClicked() {
    this.validateForm(this.createOrUpdateTerminalForm);
    if (this.createOrUpdateTerminalForm.invalid) {
      return;
    }

    if (this.data == null) {
      this.createTerminal();
    } else {
      this.updateTerminal();
    }
  }

  createTerminal() {
    let createTerminalInfo = this.createOrUpdateTerminalForm.value;
    let request: CreateTerminalRequest = {
      name: createTerminalInfo.name,
      addressLine1: createTerminalInfo.addressLine1,
      addressLine2: createTerminalInfo.addressLine2,
      city: createTerminalInfo.city,
      countryId: createTerminalInfo.countryId,
      stateId: createTerminalInfo.stateId,
      zipCode: createTerminalInfo.zipCode,
    }
    this.executeRequest({
      state: this.createTerminalState,
      request: this.apiService.createTerminal(request),
      onSuccess: (response) => {
        this.dialog.close(true);
      }
    });
  }

  updateTerminal() {
    let formValue = this.createOrUpdateTerminalForm.value;
    let request: UpdateTerminalRequest = {
      name: formValue.name,
      addressLine1: formValue.addressLine1,
      addressLine2: formValue.addressLine2,
      city: formValue.city,
      countryId: formValue.countryId,
      stateId: formValue.stateId,
      zipCode: formValue.zipCode,
    }
    this.executeRequest({
      state: this.updateTerminalState,
      request: this.apiService.updateTerminal(this.data?.id ?? '', request),
      onSuccess: (response) => {
        this.dialog.close(true);
      }
    });
  }

  closeDialog() {
    this.dialog.close(true);
  }
}
