<div class="flex flex-col gap-2 min-w-[200px] py-2">
  @for (action of actions; track action.actionKey) {
    <div (click)="_onActionClicked($event, action)"
         class="flex items-center gap-3 py-3 px-3 hover:bg-neutral-100/80 cursor-pointer">
      @if (action.iconPath != null && action.iconPath != '') {
        <app-svg-icon class="text-neutral-600" [src]="action.iconPath" [size]="20"></app-svg-icon>
      }
      <span class="text-caption text-neutral-600 font-normal">{{ action.label }}</span>
    </div>
  }
</div>
