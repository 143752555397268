import { Component, inject } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { OutlineTextInputComponent } from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { AppSvgIconComponent } from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { ButtonComponent } from "../../../../../shared/components/button/button.component";
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
@Component({
  selector: "app-signup-success",
  standalone: true,
  imports: [
    MatDialogModule,
    OutlineTextInputComponent,
    AppSvgIconComponent,
    ButtonComponent,
    BaseDialogComponent,
  ],
  templateUrl: "./signup-success.component.html",
  styleUrl: "./signup-success.component.scss",
})
export class SignupSuccessComponent {
  readonly dialog = inject(MatDialog);
  closeDialogue() {
    this.dialog.closeAll();
  }
}
