import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {BaseComponent} from "@shared/base/base-component";
import type {
  CreateDriverReminderRequest,
  CreateDriverReminderResponse, GetDriverReminderListItem,
  UpdateDriverReminderRequest, UpdateDriverReminderResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {DateUtils} from "@shared/utils/date-utils";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyFutureDateValidator} from "@shared/validators/only-future-date-validator";

@Component({
  selector: 'app-create-or-update-reminder',
  standalone: true,
  imports: [
    ButtonComponent,
    TextInputComponent,
    ReactiveFormsModule,
    MatDatepickerModule,
    TextAreaInputComponent,
    BaseOverlayComponent,
    DateInputComponent
  ],
  templateUrl: './create-or-update-reminder.component.html',
  styleUrl: './create-or-update-reminder.component.scss'
})
export class CreateOrUpdateReminderComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  dialogRef = inject(DialogRef);

  apiService = inject(ApiService);
  data?: { driverId: string, reminder?: GetDriverReminderListItem } = inject(DIALOG_DATA);

  createOrUpdateReminderForm!: FormGroup;

  createReminderState = new State<CreateDriverReminderResponse>();
  updateReminderState = new State<UpdateDriverReminderResponse>();

  ngOnInit() {
    this.initForm();
  }


  private initForm() {
    let reminder = this.data?.reminder;

    let dateUtils = new DateUtils();
    let date = reminder?.reminderDate ? new Date(reminder?.reminderDate) : null;
    let time = dateUtils.convertTohhmm(reminder?.reminderDate);

    this.createOrUpdateReminderForm = this.formBuilder.group({
      reminderDate: [date, [Validators.required, onlyFutureDateValidator()]],
      reminderTime: [time, Validators.required],
      reminderMessage: [reminder?.description, Validators.required],
    });
  }

  onSaveClicked() {

    this.validateForm(this.createOrUpdateReminderForm);
    if (this.createOrUpdateReminderForm.invalid) {
      return;
    }

    if (this.data?.reminder) {
      this.update();
    } else {
      this.create();
    }
  }

  create() {

    let date: Date = this.createOrUpdateReminderForm.controls['reminderDate'].value;

    let timeString: string = this.createOrUpdateReminderForm.controls['reminderTime'].value;

    let updatedDate = new Date(date);
    let [hours, minutes] = timeString.split(':').map(Number);
    updatedDate.setHours(hours, minutes, 0, 0);

    let reminderDateTimeString = updatedDate.toISOString();

    let request: CreateDriverReminderRequest = {
      driverId: this.data?.driverId ?? '',
      reminderDate: reminderDateTimeString,
      description: this.createOrUpdateReminderForm.controls['reminderMessage'].value
    };

    this.executeRequest({
      state: this.createReminderState,
      request: this.apiService.createDriverReminder(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  update(): void {
    let reminderId = this.data?.reminder?.id;

    let date: Date = this.createOrUpdateReminderForm.controls['reminderDate'].value;

    let timeString: string = this.createOrUpdateReminderForm.controls['reminderTime'].value;

    let updatedDate = new Date(date);
    let [hours, minutes] = timeString.split(':').map(Number);
    updatedDate.setHours(hours, minutes, 0, 0);

    let reminderDateTimeString = updatedDate.toISOString();

    let request: UpdateDriverReminderRequest = {
      reminderDate: reminderDateTimeString,
      description: this.createOrUpdateReminderForm.controls['reminderMessage'].value
    };

    this.executeRequest({
      state: this.updateReminderState,
      request: this.apiService.updateDriverReminder(reminderId ?? '', request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
