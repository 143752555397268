<app-base-overlay [title]="'Thank you for completing you application!'">
  <div body>
    <div class="flex flex-col items-center justify-center gap-3 py-3">
      <app-svg-icon [src]="'assets/icons/success.svg'" [size]="32" class="text-success-500"></app-svg-icon>
      <p class="text-body2 text-center">Thank you for completing your
        application! </p>
      <p class="overflow-hidden max-w-full text-caption text-success-500 text-center">Our Terminal Manager will review
        it and get back to you shortly.</p>
    </div>
  </div>
  <div actions>
    <div class="flex flex-col items-center gap-4">
      <app-button (click)="closeDialog()">Close</app-button>
    </div>
  </div>
</app-base-overlay>
