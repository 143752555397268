import {Component, input} from '@angular/core';
import {AppSvgIconComponent} from "../app-svg-icon/app-svg-icon.component";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-context-menu-item',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass
  ],
  templateUrl: './context-menu-item.component.html',
  styleUrl: './context-menu-item.component.scss'
})
export class ContextMenuItemComponent {
  label = input.required<string>();
  iconPath = input<string>();
}
