<app-base-input #inputElement [title]="label()" [fullWidth]="fullWidth()" [isRequiredField]="hasRequiredValidator()">
  <div input class="relative flex items-center w-full text-neutral-400 bg-white shadow-1 rounded-md">
    @if (iconSrc()) {
      <app-svg-icon [size]="18" [src]="iconSrc()!"
                    class="absolute pointer-events-none ml-3 text-neutral-400"></app-svg-icon>
    }


    <!--    <p>{{  formControl.getError('required') ? placeholder + ' ' : placeholder }}</p>-->

    <input
      [mask]="'00/00/0000'"
      [showMaskTyped]="isFocused()"
      class="w-full h-[46px] placeholder-neutral-400 text-black rounded-md border-none outline-none focus:border-none ring-1 ring-neutral-300 focus:ring-primary-500 focus:ring-2 transition-all duration-300"
      [class.text-neutral-400]="formControl.disabled"
      [class.disabled-placeholder]="formControl.disabled"
      [(ngModel)]="textInputValue"
      (focus)="onFocus()"
      [ngClass]="getClass()"
      [placeholder]="placeHolder"
      (blur)="onBlur()"
      (input)="onInputChanged($event)">


    <div #trigger (click)="onDatePickerIconClicked()"
         class="absolute right-2 p-2 flex justify-center items-center hover:bg-neutral-100 rounded-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
           class="w-[18px] h-[18px] text-neutral-500">
        <path
          d="M19 19H5V8H19M16 1V3H8V1H6V3H5C3.89 3 3 3.89 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 3.89 20.1 3 19 3H18V1M17 12H12V17H17V12Z"
          fill="currentColor"/>
      </svg>
    </div>

  </div>
</app-base-input>

@if (formControl.errors && hasErrors) {
  <div class="mt-1">
    <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>
  </div>
} @else {
  @if (showErrorSpace()) {
    <div class="flex mt-1">
      <span class="text-caption text-transparent">.</span>
    </div>
  }
}
