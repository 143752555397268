<div class="flex justify-center container mx-auto h-full  w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md px-4 ">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">
      <div class="mx-auto max-w-5xl h-screen">
        <div class="flex flex-col gap-3">
          <p class="text-h6 text-info-500">General Information</p>
        </div>
        <form [formGroup]="driverGeneralInfoForm"  class="flex flex-col py-4 gap-4">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <app-outline-text-input formControlName="firstName" [label]="'First Name'" placeholder="Ex: John"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="middleName" [label]="'Middle Name'" placeholder="Ex: John"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <app-outline-text-input formControlName="lastName" [label]="'Last Name'" placeholder="Ex: Bravo"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <app-outline-text-input formControlName="phoneNumber" [label]="'Phone Number'"
                                    placeholder="Ex: +1 (555) 123-XXXX" class="w-full"
                                    [fullWidth]="true"></app-outline-text-input>
            <app-outline-text-input formControlName="email" [label]="'Email'"
                                    placeholder="Ex: Ex: john.bravo@company.com"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <app-outline-text-input formControlName="dateOfBirth" [label]="'Date of Birth'" mask="00/00/0000"
                                    class="w-full" [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="flex items-center gap-6">
            <span class="text-body2">Have legal rights to work in united states?</span>
            <app-radio-button formControlName="hasUsWorkLegality" [groupName]="'hasUsWorkLegality'" title="Yes"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="hasUsWorkLegality" [groupName]="'hasUsWorkLegality'" title="No"
                              [value]="false"></app-radio-button>
          </div>
        </form>
      </div>
    </div>
    <div class="flex justify-end border-t border-neutral-200 px-4 py-4">
      <app-button type="submit" (click)="onUpdateClicked()">
        UPDATE
      </app-button>
    </div>
  </div>
</div>


