import {Component, inject} from '@angular/core';
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import type {DeleteDriverDocumentResponse, GetDriverDocumentListByType} from "@core/api/model";

import {DatePipe} from "@angular/common";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {BaseRequestService} from "@shared/base/base-request.service";
import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import {
  DocumentSubtypeListComponent
} from "@core/components/document-view/document-subtype-list/document-subtype-list.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-row-document',
  imports: [
    AppSvgIconComponent,
    DatePipe
  ],
  templateUrl: './row-document.component.html',
  styleUrl: './row-document.component.scss'
})
export class RowDocumentComponent extends TableCustomComponent<GetDriverDocumentListByType> {

  requestService = inject(BaseRequestService);
  apiService = inject(ApiService);
  overlayService = inject(OverlayService);

  deleteDocumentState = new State<DeleteDriverDocumentResponse>();

  onViewClicked() {
    const attachments = this.rowData.attachments ?? [];
    if (attachments.length == 1) {
      window.open(attachments[0].filePath, '_blank');
    }

    if (attachments.length > 1) {
      this.overlayService.openModal(DocumentSubtypeListComponent, {
        data: attachments
      });
    }
  }

  onDownloadClicked() {
    const attachments = this.rowData.attachments ?? [];
    if (attachments.length == 1) {
      window.open(attachments[0].filePath, '_blank');
    }

    if (attachments.length > 1) {
      this.overlayService.openModal(DocumentSubtypeListComponent, {
        data: attachments
      });
    }
  }

  onDeleteClicked() {
    let dialogRef = this.overlayService
      .openAlert('Delete Document', 'Are you sure you want to delete this document?')
      .closed;

    dialogRef.subscribe((result) => {
      if (result) {
        this.deleteDocument();
      }
    });
  }

  private deleteDocument() {
    this.requestService.executeRequest<DeleteDriverDocumentResponse>({
      request: this.apiService.deleteDriverDocument(this.rowData.documentId ?? ''),
      state: this.deleteDocumentState,
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.actionPerformed.emit({actionKey: 'delete', item: this.rowData});
      }
    });
  }
}
