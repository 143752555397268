import {Component, input, InputSignal} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {HumanizeFormMessagesPipe} from "@shared/inputs/humanize-form-messages.pipe";

@Component({
  selector: 'app-error',
  imports: [
    HumanizeFormMessagesPipe
  ],
  templateUrl: './app-error.component.html',
  styleUrl: './app-error.component.scss'
})
export class AppErrorComponent {

  control = input.required<AbstractControl<any, any> | null>();
  errorMessages = input<{ [key: string]: string }>({});

  get hasErrors() {
    return (this.control() && this.control()?.touched && this.control()?.errors) ?? false;
  }
}
