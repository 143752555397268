<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-2xl">

    <div class="flex p-4 items-center justify-between w-full border-b border-neutral-100">
      <p class="text-body1 text-info-500">Documents</p>
      <app-button class="px-2"
                  appearance="textType"
                  textButtonColor="text-neutral-600" [iconColor]="'text-neutral-500'"
                  [iconSrc]="'assets/icons/arrow-left.svg'" (buttonClick)="onBackClicked()">Back
      </app-button>
    </div>

    <div class="grow overflow-auto py-8 px-4 overflow-y-scroll">
      <app-listview
        [component]="RowDocumentComponent"
        [state]="documentsState"
        (onStateChanged)="getDocuments($event)"
        (onActionPerformed)="onActionPerformed($event)"
        [enableSearch]="false">
      </app-listview>
    </div>
  </div>
</div>
