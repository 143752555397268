import {
  Component,
  computed,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { AppSvgIconComponent } from "@shared/components/app-svg-icon/app-svg-icon.component";
import { NavigationEnd } from "@angular/router";
import { BaseComponent } from "@shared/base/base-component";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { NgClass } from "@angular/common";
import { LayoutService } from "../../../layout.service";
import { TooltipPosition } from "@shared/components/tooltip/tooltip.enums";
import { MatTooltip } from "@angular/material/tooltip";

@Component({
  selector: "app-sidebar-menu",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    NgClass,
    MatTooltip,
  ],
  templateUrl: "./sidebar-menu.component.html",
  styleUrl: "./sidebar-menu.component.scss",
})
export class SidebarMenuComponent extends BaseComponent implements OnInit, OnDestroy {
  layoutService = inject(LayoutService);

  activeRoute = signal(this.router.url);
  private routeSubscription!: Subscription;
  menuItem = input.required<SideMenuItem>();

  isActive = computed(() => {
    return this.activeRoute() === this.menuItem().link;
  });

  ngOnInit(): void {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.activeRoute.set(this.router.url);
      });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  onMenuClicked(menuItem: SideMenuItem) {
    if(menuItem.link) {
      this.router.navigate([menuItem.link]);
    }

    if(menuItem.externalLink) {
      window.open(menuItem.externalLink, '_blank');
    }

    const subMenus = menuItem.submenus ?? [];
    if(subMenus.length > 0) {
      this.menuItem().expanded = !this.menuItem().expanded;
    }
  }

  protected readonly TooltipPosition = TooltipPosition;
}

export interface SideMenuGroup {
  label: string | null;
  items: SideMenuItem[];
}

export interface SideMenuItem {
  iconPath: string;
  link: string;
  externalLink?: string;
  label: string;
  isEnabled: boolean;
  expanded?: boolean;
  submenus?: SideMenuItem[];
}
