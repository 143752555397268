import {Component, inject, input, OnInit} from '@angular/core';
import {ApiService} from '@core/api/api.service';
import {BaseComponent} from "@shared/base/base-component";
import type {GetDriverDocumentListByTypeParams, GetDriverDocumentListByTypeResponse} from "@core/api/model";
import {ListviewComponent} from "@shared/new/listview/listview.component";
import {PaginationState} from "@shared/base/base-pagination-state";
import {RowDocumentComponent} from "./row-document/row-document.component";
import {TableActionEvent, TableStateEvent} from "@shared/components/data-table/data-table.component";
import {ButtonComponent} from "@shared/components/button/button.component";

@Component({
  selector: 'app-driver-all-documents',
    imports: [
        ListviewComponent,
        ButtonComponent
    ],
  templateUrl: './driver-all-documents.component.html',
  styleUrl: './driver-all-documents.component.scss'
})
export class DriverAllDocumentsComponent extends BaseComponent implements OnInit {

  documentTypeId = input<string>();

  apiService = inject(ApiService);
  driverId?: string;
  documentsState = new PaginationState<GetDriverDocumentListByTypeResponse>();

  ngOnInit() {
    this.listenToRoute();
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? undefined;
    });
  }

  getDocuments($event?: TableStateEvent) {
    let request: GetDriverDocumentListByTypeParams = {
      driverId: this.driverId ?? '',
      driverDocumentTypeId: this.documentTypeId() ?? '',
      pageNumber: $event?.paginationEvent?.pageNumber ?? 1,
      pageSize: $event?.paginationEvent?.pageSize ?? 100,
    };
    this.executeRequest<GetDriverDocumentListByTypeResponse>({
      request: this.apiService.getDriverDocumentListByType(request),
      state: this.documentsState,
    });
  }

  onBackClicked() {
    this.router.navigate([`/drivers/${this.driverId}/documents`]);
  }

  onActionPerformed($event: TableActionEvent) {
    if ($event.actionKey === 'delete') {
      this.documentsState.clearState();
      this.getDocuments();
    }
  }

  protected readonly RowDocumentComponent = RowDocumentComponent;
}
