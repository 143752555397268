import { Component, input, output } from "@angular/core";
import { AppSvgIconComponent } from "@shared/components/app-svg-icon/app-svg-icon.component";
import { NgClass } from "@angular/common";

@Component({
  selector: "app-stats-card",
  standalone: true,
  imports: [AppSvgIconComponent, NgClass],
  templateUrl: "./stats-card.component.html",
  styleUrl: "./stats-card.component.scss",
})
export class StatsCardComponent {
  iconPath = input.required<string>();
  label = input.required<string>();
  count = input.required<number>();
  color = input.required<string>();

  onViewClick = output<void>();

  onViewClicked() {
    this.onViewClick.emit();
  }
}
