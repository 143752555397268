<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">
      <div class="py-4 space-y-4">
        <p class="text-h6 text-info-500">General Information</p>
        <p class="text-caption text-neutral-500">Please fill the general information</p>
      </div>
      <form [formGroup]="applicantGeneralInfoForm" class="flex flex-col gap-3">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="firstName" [label]="'First Name'" placeholder="Ex: John" class="w-full" [fullWidth]="true"></app-outline-text-input>
          <app-outline-text-input formControlName="middleName" [label]="'Middle Name'" placeholder="Ex: John" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="lastName" [label]="'Last Name'" placeholder="Ex: Bravo" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="phoneNumber" [label]="'Phone Number'" placeholder="Ex: +1 (555) 123-XXXX" class="w-full" [fullWidth]="true"></app-outline-text-input>
          <app-outline-text-input formControlName="email" [label]="'Email'" placeholder="Ex: john.bravo@company.com" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="dateOfBirth" [label]="'Date of Birth'" mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>
          <app-outline-text-input formControlName="socialSecurityNumber" [label]="'Social Security #'" placeholder="Ex: 123-45-XXXX" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="dateOfApplication" [label]="'Date of Application'" mask="00/00/0000" placeholder="Ex: mm/dd/yyyy" class="w-full" [fullWidth]="true"></app-outline-text-input>
          <app-outline-text-input formControlName="position" [label]="'Position'" placeholder="Ex: Driver" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="dateAvailableForWork" [label]="'Date Available for Work'" mask="00/00/0000" placeholder="Ex: mm/dd/yyyy" class="w-full" [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="flex items-center space-x-4">
          <app-radio-button title="Yes" formControlName="hasUsWorkLegality" groupName="hasUsWorkLegality" [value]="true"></app-radio-button>
          <app-radio-button title="No" formControlName="hasUsWorkLegality" groupName="hasUsWorkLegality" [value]="false"></app-radio-button>
          <span class="text-body2">Do you have legal right to work in united states?</span>
        </div>
      </form>
    </div>
    <div class="flex justify-end border-t border-neutral-200 px-4 py-4">
      <app-button (click)="onSaveAndNextClicked()" type="submit" [loading]="saveApplicantGeneralInfoState.loading()">
        Save & Next
      </app-button>
    </div>
  </div>
</div>
