import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {State} from "@shared/base/base-state";
import {DriverApplication} from "@core/services/driver-application/application/driver-application-response";
import {
  DriverApplicationStatusAction
} from "../table-cell-driver-application-actions/table-cell-driver-application-actions.component";
import {ApiService} from "@core/api/api.service";
import {BaseComponent} from "@shared/base/base-component";
import {
  MarkDriverApplicationRejectedRequest,
  MarkDriverApplicationRejectedResponse,
  type MarkDriverApplicationResubmissionRequest
} from "@core/api/model";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-driver-application-status-message',
  standalone: true,
  imports: [
    ButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    TextAreaInputComponent,
    BaseOverlayComponent
  ],
  templateUrl: './driver-application-status-message.component.html',
  styleUrl: './driver-application-status-message.component.scss'
})
export class DriverApplicationStatusMessageComponent extends BaseComponent implements OnInit {

  overlayService = inject(OverlayService);
  formBuilder = inject(FormBuilder);
  data: { application: DriverApplication, changeToStatus: DriverApplicationStatusAction } = inject(DIALOG_DATA);
  apiService = inject(ApiService);
  dialogRef = inject(DialogRef);

  rejectApplicationState = new State<MarkDriverApplicationRejectedResponse>();

  changeDriverApplicationMessageForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.changeDriverApplicationMessageForm = this.formBuilder.group({
      message: [null, Validators.required],
    });
  }

  onSubmitClicked() {
    if (this.changeDriverApplicationMessageForm.invalid) {
      return;
    }

    let message = this.changeDriverApplicationMessageForm.value.message;

    switch (this.data.changeToStatus) {
      case DriverApplicationStatusAction.reject:
        this.rejectApplication(message);
        break;
      case DriverApplicationStatusAction.requestToResubmit:
        this.requestToResubmitApplication(message);
        break;
    }
  }

  protected readonly DriverApplicationStatusAction = DriverApplicationStatusAction;

  getTitle() {
    switch (this.data.changeToStatus) {
      case DriverApplicationStatusAction.reject:
        return 'Reject Application';
      case DriverApplicationStatusAction.requestToResubmit:
        return 'Request for Changes';
    }

    return 'Update Application';
  }

  getActionText() {
    switch (this.data.changeToStatus) {
      case DriverApplicationStatusAction.reject:
        return 'Reject';
      case DriverApplicationStatusAction.requestToResubmit:
        return 'Submit';
    }

    return 'Submit';
  }

  rejectApplication(message: string) {
    let request: MarkDriverApplicationRejectedRequest = {
      driverApplicationId: this.data.application.id,
      message: message
    }
    this.executeRequest({
      state: this.rejectApplicationState,
      request: this.apiService.markDriverApplicationRejected(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  private requestToResubmitApplication(message: string) {
    let request: MarkDriverApplicationResubmissionRequest = {
      driverApplicationId: this.data.application.id,
      message: message
    };

    this.executeRequest({
      state: this.rejectApplicationState,
      request: this.apiService.markDriverApplicationResubmission(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });

  }
}
