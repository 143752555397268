<app-base-dialog [title]="'Reason for changing the status of the application'">
  <div body>
    <form [formGroup]="changeDriverApplicationMessageForm">
      <div class="flex flex-col gap-4 my-6">
        <span class="text-body2">Kindly provide the reason.</span>
        <textarea
          formControlName="messageChange"
          class="p-2 border rounded-md resize-none h-32 w-full"
          placeholder="Enter reason here..."
        ></textarea>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [loading]="changeDriverApplicationMessageState.loading()" [type]="'submit'"
                  (click)="onSubmitClicked()">Submit
      </app-button>
    </div>
  </div>
</app-base-dialog>

