import {Component, inject} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {DialogRef} from "@angular/cdk/dialog";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-application-submission-success-message',
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    BaseOverlayComponent
  ],
  templateUrl: './application-submission-success-message.component.html',
  standalone: true,
  styleUrl: './application-submission-success-message.component.scss'
})
export class ApplicationSubmissionSuccessMessageComponent {

  dialog: DialogRef<ApplicationSubmissionSuccessMessageComponent> = inject(DialogRef);

  closeDialog() {
    this.dialog.close();
  }

}
