import {ApplicationConfig, provideExperimentalZonelessChangeDetection, provideZoneChangeDetection} from '@angular/core';
import {provideRouter, withComponentInputBinding, withViewTransitions} from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {provideHttpClient, withInterceptors} from "@angular/common/http";
import {provideAngularSvgIcon} from "angular-svg-icon";
import {provideNativeDateAdapter} from "@angular/material/core";
import {httpTokenInterceptor} from "./core/api/interceptors/http-token.interceptor";
import {provideNgxMask} from "ngx-mask";

export const appConfig: ApplicationConfig = {
  providers: [
    // provideExperimentalZonelessChangeDetection(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes, withComponentInputBinding(), withViewTransitions()),
    provideHttpClient(withInterceptors([httpTokenInterceptor])),
    provideAnimationsAsync(),
    provideAngularSvgIcon(),
    provideNativeDateAdapter(),
    provideNgxMask()
  ]
};
