import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {BaseComponent} from "@shared/base/base-component";
import type {
  CreateRepairTypeRequest,
  CreateRepairTypeResponse, GetServiceType,
  UpdateRepairTypeRequest,
  UpdateRepairTypeResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-create-or-update-repair-type',
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    ButtonComponent,
    TextAreaInputComponent,
    BaseOverlayComponent
  ],
  templateUrl: './create-or-update-repair-type.component.html',
  standalone: true,
  styleUrl: './create-or-update-repair-type.component.scss'
})
export class CreateOrUpdateRepairTypeComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialogRef = inject(DialogRef);
  apiService = inject(ApiService);
  dialogData?: GetServiceType = inject(DIALOG_DATA);

  createOrUpdateRepairTypeForm!: FormGroup;

  updateRepairTypeState = new State<UpdateRepairTypeResponse>();
  createRepairTypeState = new State<CreateRepairTypeResponse>();

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.createOrUpdateRepairTypeForm = this.formBuilder.group({
      name: [this.dialogData?.name ?? '', Validators.required],
      description: [this.dialogData?.description ?? ''],
    });
  }

  onSaveClicked() {
    this.validateForm(this.createOrUpdateRepairTypeForm);
    if (!this.createOrUpdateRepairTypeForm.valid) {
      return;
    }
    if (this.dialogData) {
      this.update();
    } else {
      this.create();
    }
  }

  private create() {
    let formValue = this.createOrUpdateRepairTypeForm.value;
    let request: CreateRepairTypeRequest = {
      description: formValue.description,
      name: formValue.name,
    }
    this.executeRequest<CreateRepairTypeResponse>({
      state: this.createRepairTypeState,
      request: this.apiService.createRepairType(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  private update() {
    let formValue = this.createOrUpdateRepairTypeForm.value;
    let request: UpdateRepairTypeRequest = {
      name: formValue.name,
      description: formValue.description
    };

    this.executeRequest<UpdateRepairTypeResponse>({
      state: this.updateRepairTypeState,
      request: this.apiService.updateRepairType(this.dialogData?.id ?? '', request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
