import {inject, Injector} from "@angular/core";

export abstract class TableCustomComponent<T> {

  public rowData!: T;
  injector = inject(Injector);

  constructor() {
    let token: any = 'rowData';
    this.rowData = this.injector.get<T>(token);
  }

}
