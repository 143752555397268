import {Component, inject, input, OnInit, signal} from '@angular/core';
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {
  ColumnDef,
  DataTableComponent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {Constants, DocumentExpirationFilter} from "@core/constants/constants";
import {
  TableCellExpiringDocumentsLinkComponent
} from "./table-cell-expiring-documents-link/table-cell-expiring-documents-link.component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {FormsModule} from "@angular/forms";
import {
  GetDriverDocumentTypeListItem,
  GetDriverDocumentTypeListResponse,
  GetExpiringDocumentsDriverDocumentExpiryType, GetExpiringDocumentsExpiringThresholdtype, GetExpiringDocumentsParams,
  GetExpiringDocumentsResponse
} from "@core/api/model";

@Component({
  selector: 'app-expiring-documents',
  imports: [
    DataTableComponent,
    DropdownComponent,
    FormsModule
  ],
  templateUrl: './expiring-documents.component.html',
  standalone: true,
  styleUrl: './expiring-documents.component.scss'
})
export class ExpiringDocumentsComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);

  documentType = input<string>();
  days = input<string>();

  tableState = signal<TableStateEvent | null>(null);
  selectedDocumentTypeFilter = signal<GetDriverDocumentTypeListItem | null | undefined>(null);
  selectedExpirationFilter = signal<DocumentExpirationFilter | null>(null);
  documentTypes = signal<GetDriverDocumentTypeListItem[]>([]);

  documentTypeListState = new State<GetDriverDocumentTypeListResponse>();
  getExpiringDocumentsReportsState = new State<GetExpiringDocumentsResponse>();

  columnDefs: ColumnDef[] = [
    {
      title: 'Document Name',
      type: 'text',
      alignment: 'left',
      displayTemplate: '$documentTypeName',
      sortKey: 'documentName'
    },
    {
      title: 'Expiration Date',
      type: 'date',
      alignment: 'center',
      key: 'expiryDate',
      sortKey: 'expiryDate',
      textConfig: {
        textColorClass: 'text-error-400'
      },
      dateConfig: {
        dateFormat: 'dd MMM yyyy',
        showIcon: false
      }
    },
    {
      title: 'Driver Name',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$driverFirstName $driverLastName',
      sortKey: 'driverName'
    },
    {
      title: 'Date of Birth',
      alignment: 'center',
      displayTemplate: '$dateOfBirth',
      sortKey: 'dateOfBirth',
      type: 'date',
      dateConfig: {
        dateFormat: 'dd MMM yyyy',
        showIcon: false
      }
    },
    {
      title: 'License Number',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$currentDriverLicenseNumber',
      sortKey: 'currentDrivingLicenseNumber'
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        components: [TableCellExpiringDocumentsLinkComponent]
      }
    },
  ];

  ngOnInit(): void {
    this.handleExpirationFilter();
    this.getDocumentTypeList();
  }

  private handleExpirationFilter() {

    let expirationFilter = Constants.documentExpirationFilter;

    if (expirationFilter.length > 0) {
      let defaultFilter: DocumentExpirationFilter | null = null;

      if (this.days()) {
        defaultFilter = expirationFilter.find(x => x.value === this.days()) ?? null;
      }

      if (defaultFilter == null) {
        defaultFilter = expirationFilter.find(x => x.default) ?? null;
      }

      this.selectedExpirationFilter.set(defaultFilter);
    }
  }

  getExpiringDocuments(event: TableStateEvent, filterChanged: boolean = false) {
    let request: GetExpiringDocumentsParams = {
      pageNumber: filterChanged ? 1 : event?.paginationEvent?.pageNumber ?? 1,
      pageSize: filterChanged ? Constants.defaultPageSize : event?.paginationEvent?.pageSize ?? 50,
      search: event.searchText,
      driverDocumentTypeId: this.selectedDocumentTypeFilter()?.id ?? '',
      driverDocumentExpiryType: GetExpiringDocumentsDriverDocumentExpiryType.all,
      expiringThresholdtype: GetExpiringDocumentsExpiringThresholdtype.nextDays,
      expiringThresholdValue: this.selectedExpirationFilter()?.value ?? '30'
    }
    this.executeRequest<GetExpiringDocumentsResponse>({
      state: this.getExpiringDocumentsReportsState,
      request: this.apiService.getExpiringDocuments(request),
      showLoader: true
    });
  }

  getDocumentTypeList() {
    this.executeRequest<GetDriverDocumentTypeListResponse>({
      state: this.documentTypeListState,
      request: this.apiService.getDriverDocumentTypeList(),
      onSuccess: response => {
        this.handleDocumentTypesFilter(response.data ?? []);
        let documentTypeFilter = this.documentTypes()?.find(type => type.id === this.documentType());
        this.selectedDocumentTypeFilter.set(documentTypeFilter);
        this.getExpiringDocuments(Constants.defaultTableStateEvent, true);
      }
    });
  }

  handleDocumentTypesFilter(driverDocumentTypeListItems?: GetDriverDocumentTypeListItem[]) {
    let allTypeFilter: GetDriverDocumentTypeListItem = {name: 'All', id: '', description: 'null', priority: 0};
    let documentTypes = driverDocumentTypeListItems ?? [];

    let types = [allTypeFilter, ...documentTypes];
    this.documentTypes.set(types);
    if (types.length > 0) {
      this.selectedDocumentTypeFilter.set(types[0]);
    }
    let selectedItem = types.find(type => type.id === this.documentType());
    this.selectedDocumentTypeFilter.set(selectedItem!);
  }


  onDropDownFilterSelected() {
    this.getExpiringDocuments(this.tableState()!, true);
    this.mergeQueryParams({documentType: this.selectedDocumentTypeFilter()?.id});
  }

  protected readonly Constants = Constants;
}

