import {AfterViewInit, Component, inject, Injector, input, Output, output, signal, ViewChild} from '@angular/core';
import {State} from "../../base/base-state";
import {PaginationComponent, PaginationEvent} from "../pagination/pagination.component";
import {NoDataTableComponent} from "../no-data-table/no-data-table.component";
import {ShimmerComponent} from "../shimmer/shimmer.component";
import {DatePipe, NgClass, NgComponentOutlet} from "@angular/common";
import {resolveTemplateWithObject} from "../../common-utils/template-resolver";
import {ContextMenuAction, ContextMenuComponent} from "../context-menu/context-menu.component";
import {AppSvgIconComponent} from "../app-svg-icon/app-svg-icon.component";
import {StatusBadgeComponent} from "../status-badge/status-badge.component";
import {SortableTableDirective, TableSortEvent} from "../_base/base-table/sortable-table.directive";
import {TableResizableColumnsDirective} from "../_base/base-table/table-resizable-columns.directive";
import {MatTooltip} from "@angular/material/tooltip";
import {Overlay, OverlayConfig} from "@angular/cdk/overlay";
import {CdkPortal} from "@angular/cdk/portal";
import {CheckboxComponent} from "../../inputs/checkbox/checkbox.component";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    PaginationComponent,
    NoDataTableComponent,
    ShimmerComponent,
    NgClass,
    DatePipe,
    AppSvgIconComponent,
    MatTooltip,
    ContextMenuComponent,
    StatusBadgeComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
    NgComponentOutlet,
    CdkPortal,
    CheckboxComponent,
    FormsModule
  ],
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss',
})
export class DataTableComponent<T> implements AfterViewInit {

  columnDefs = input<ColumnDef[]>();
  state = input<State<any>>();
  pageSize = input(10);
  itemsPerPage = input(10);
  enableHorizontallyScrollable = input(false);
  enableResizableColumns = input(false);
  enableClickableRows = input(false);
  expandableComponent = input<any>();
  enableColumnsConfig = input(false);

  pageChange = output<PaginationEvent>();
  sortChanged = output<TableSortEvent>();
  tableStateChanged = output<TableStateEvent>();
  onActionClicked = output<TableActionEvent>();
  onRowClicked = output<any>();

  overlay = inject(Overlay);
  @ViewChild(CdkPortal) portal!: CdkPortal;

  paginationEvent?: PaginationEvent;
  tableSortEvent?: TableSortEvent;

  ngAfterViewInit(): void {
    let paginationEvent: PaginationEvent = {
      pageNumber: 1, pageSize: this.pageSize()
    }
    this.pageChange.emit(paginationEvent);

    let tableStateEvent: TableStateEvent = {
      paginationEvent: paginationEvent,
      tableSortEvent: this.tableSortEvent
    };

    this.tableStateChanged.emit(tableStateEvent);
  }

  createInjector(row: any): Injector {
    return Injector.create({
      providers: [{provide: 'rowData', useValue: row}],
    });
  }

  onColumnSettingsClicked(columnsConfigTriggerElement: HTMLDivElement) {
    const config = new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(columnsConfigTriggerElement)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 20,
            offsetY: 10,
          }
        ]),
      hasBackdrop: true,
      backdropClass: ['bg-black', 'bg-opacity-0', 'shadow-1']
    });

    const overlayRef = this.overlay.create(config);
    overlayRef.attach(this.portal);

    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  onColumnVisibleOrHide($event: boolean, column: ColumnDef) {
    column.visible = $event;
  }

  onSelectAllColumnClicked() {
    let columns = this.columnDefs() ?? [];
    columns.forEach(column => {
      column.visible = true;
    });
  }

  getPropertyValue(item: any, column: ColumnDef,): any {

    if (column.displayTemplate) {
      return resolveTemplateWithObject(item, column.displayTemplate);
    }

    let value = '';

    // if (column.key) {
    //   value = column.key.split('.').reduce((acc, part) => acc && acc[part], item);
    // }

    if (column.formatter) {
      return column.formatter ? column.formatter(value) : value;
    } else if (column.objectFormatter) {
      return column.objectFormatter ? column.objectFormatter(item) : item;
    } else {
      return value;
    }
  }

  onPageChange(event: PaginationEvent) {
    let tableStateEvent: TableStateEvent = {
      paginationEvent: event,
      tableSortEvent: this.tableSortEvent
    };
    this.pageChange.emit(event);
    this.tableStateChanged.emit(tableStateEvent);
  }

  onSortChanged(event: TableSortEvent) {
    let tableStateEvent: TableStateEvent = {
      paginationEvent: {
        pageNumber: 1,
        pageSize: this.pageSize()
      },
      tableSortEvent: event
    };
    this.sortChanged.emit(event);
    this.tableStateChanged.emit(tableStateEvent);
  }

  getThTrClass(column: ColumnDef) {
    switch (column.alignment) {
      case 'left':
        return 'text-left';
      case 'center':
        return 'text-center';
      case 'right':
        return 'text-right';
      default:
        return 'text-left';
    }
  }

  getFlexJustify(column: ColumnDef) {
    switch (column.alignment) {
      case 'left':
        return 'justify-start';
      case 'center':
        return 'justify-center';
      case 'right':
        return 'justify-end';
      default:
        return 'justify-start';
    }

  }

  getBadgeProperty(item: any, column: ColumnDef): BadgeConfigProperty | null {
    let badgeConfigProperties = column.configOfBadge?.properties ?? [];
    let matchedBadgeConfigProperty: BadgeConfigProperty | null = null;

    badgeConfigProperties.forEach(badgeConfigProperty => {
      let value = this.getPropertyValue(item, column);
      if (value === badgeConfigProperty.data) {
        matchedBadgeConfigProperty = badgeConfigProperty;
      }
    });
    return matchedBadgeConfigProperty;
  }

  getContextMenuActions(actions: ContextMenuActionConfig[]) {
    return actions.map(action => {
      let configAction: ContextMenuAction = {
        iconPath: action.iconPath,
        label: action.label,
        type: action.actionType
      };
      return configAction;
    })
  }

  _onActionClicked($event: string, item: any, mouseEvent: MouseEvent | null) {
    if (mouseEvent) {
      mouseEvent.stopPropagation();
    }
    let tableActionEvent: TableActionEvent = {
      actionType: $event,
      item: item
    };
    this.onActionClicked.emit(tableActionEvent);
  }

  expandedRowIndex = signal<number | null>(null);

  onRowExpandedClicked(i: number) {
    if (this.expandedRowIndex() == i) {
      this.expandedRowIndex.set(null);
    } else {
      this.expandedRowIndex.set(i);
    }
  }

  _onRowClicked(item: any) {
    this.onRowClicked.emit(item);
  }
}

export interface ColumnDef {
  title: string;
  displayTemplate?: string;
  sortKey?: string;
  alignment?: 'left' | 'center' | 'right';
  type: 'text' | 'date' | 'badge' | 'custom' | 'actions';
  pinned?: 'left' | 'right' | null;
  visible?: boolean | null;

  configOfText?: TextConfig;
  configOfDate?: DateConfig;
  configOfBadge?: BadgeConfig;
  configOfCustom?: CustomRendererConfig;
  configOfActions?: ActionConfig;

  formatter?: (value: any) => any;
  objectFormatter?: (value: any) => any;
  propertyStyle?: (value: any) => any;
}


export interface TextConfig {
  textColorClass?: string;
}

export interface DateConfig {
  dateFormat?: string;
  showIcon?: boolean;
}

export interface BadgeConfig {
  properties: BadgeConfigProperty[];
}

export interface BadgeConfigProperty {
  data: string;
  displayText: string;
  backgroundColorClass?: string;
  borderColorClass?: string;
  textColorClass?: string;
  indicatorColorClass?: string;
}

export interface CustomRendererConfig {
  component: any;
}

export interface ActionConfig {
  iconActions?: IconAction[];
  threeDotMenuActions?: ContextMenuActionConfig[] | null;
}

export interface IconAction {
  iconPath: string;
  actionType: string;
  label?: string;
}

export interface ContextMenuActionConfig {
  iconPath?: string;
  label: string;
  actionType: string;
}

export interface TableActionEvent {
  actionType: string;
  item: any;
}


export interface TableStateEvent {
  paginationEvent?: PaginationEvent;
  tableSortEvent?: TableSortEvent;
}
