import {Component, inject, input, OnInit} from '@angular/core';
import {BaseDialogComponent} from "../../../../../shared/components/_base/base-dialog/base-dialog.component";
import {FileUploaderComponent} from "../../../../../shared/inputs/file-uploader/file-uploader.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {OutlineTextInputComponent} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import type {
  GetDriverDocumentListParams,
  GetDriverDocumentListResponse, UpdateDriverDocumentRequest, UpdateDriverDocumentResponse,
  UploadTempFileRequest, UploadTempFileResponse
} from "../../../../../core/api/model";
import {State} from "../../../../../shared/base/base-state";
import {ApiService} from "../../../../../core/api/api.service";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {CommonModule, NgIf} from "@angular/common";
import {DateUtils} from "../../../../../shared/utils/date-utils";

@Component({
  selector: 'app-document-upload',
  standalone: true,
  imports: [
    BaseDialogComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    OutlineTextInputComponent,
    ButtonComponent,
    NgIf,
  ],
  templateUrl: './document-upload.component.html',
  styleUrl: './document-upload.component.scss'
})
export class DocumentUploadComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  data = inject(MAT_DIALOG_DATA);
  readonly dialog = inject(MatDialog);


  fileUploadForm!: FormGroup;

  driverDocumentsState = new State<GetDriverDocumentListResponse>();
  attachFileState = new State<UploadTempFileResponse>();
  uploadState = new State<UpdateDriverDocumentResponse>();

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.fileUploadForm = this.formBuilder.group({
      issuedDate: [true, Validators.required],
      expiryDate: ["", Validators.required],
      attachFile: ["", Validators.required],
      completedDate: ["", Validators.required],
      reviewedDate: ["", Validators.required],
    });
  }

  onAttachFileClicked($event: File) {
    let request: UploadTempFileRequest = {
      file: $event
    }
    this.executeRequest(
      {
        state: this.attachFileState,
        request: this.apiService.uploadTempFile(request),
      }
    );
  }

  onCancelClicked() {
    this.dialog.closeAll();
  }

  onUploadClicked() {
    let dateUtils = new DateUtils();
    let request: UpdateDriverDocumentRequest = {
      completedDate: dateUtils.convertToDateTimeString(this.data.document.completedDate) ?? '',
      driverDocumentTypeId: this.data.document.documentTypeId ?? '',
      expiryDate: dateUtils.convertToDateTimeString(this.fileUploadForm.controls["expiryDate"].value) ?? '',
      filePath: this.attachFileState.response()?.filePath ?? '',
      issuedDate: dateUtils.convertToDateTimeString(this.fileUploadForm.controls["issuedDate"].value) ?? '',
      driverId: this.data.document.driverId,
      notes: this.data.document.notes
    }
    this.executeRequest({
      state: this.uploadState,
      request: this.apiService.updateDriverDocument(request)
    });
  }
}


