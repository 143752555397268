import {Component, OnInit, inject, output} from "@angular/core";
import {FormArray, ReactiveFormsModule} from "@angular/forms";
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {CommonModule, NgIf} from "@angular/common";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {State} from "../../../../../shared/base/base-state";
import {LookupService} from "../../../../../core/services/lookup/lookup.service";
import {printInvalidFields} from "../../../../../shared/utils/form-utils";
import {
  InvalidControlScrollContainerDirective
} from "../../../../../shared/directives/invalid-control-scroll-container.directive";
import {CommonService} from "../../../../../core/services/common/common.service";
import {
  CountriesResponse,
  Country,
  CountryState,
} from "../../../../../core/services/common/countries/countries-response";
import {
  ApplicantResidenciesResponse,
  ApplicantResidency,
} from "../../../../../core/services/driver-application/applicant-residency-info/applicant-residencies-response";
import {DriverApplicationService} from "../../../../../core/services/driver-application/driver-application.service";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {
  ApplicantResidenciesRequest
} from "../../../../../core/services/driver-application/applicant-residency-info/applicant-residencies-request";
import {
  SaveApplicantResidenciesRequest,
  SaveApplicantResidency,
} from "../../../../../core/services/driver-application/save-applicant-residencies/save-applicant-residencies-request";
import {AlertDialogService} from "../../../../../shared/components/alert-dialog/alert-dialog.service";

@Component({
  selector: "app-save-applicant-residency-info",
  standalone: true,
  imports: [
    OutlineTextInputComponent,
    DropdownComponent,
    ButtonComponent,
    ReactiveFormsModule,
    NgIf,
    CommonModule,
    RadioButtonComponent,
    InvalidControlScrollContainerDirective,
  ],
  templateUrl: "./save-applicant-residency-info.component.html",
  styleUrl: "./save-applicant-residency-info.component.scss",
})
export class SaveApplicantResidencyInfoComponent
  extends BaseComponent
  implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  lookupService = inject(LookupService);
  commonService = inject(CommonService);
  driverApplicationService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);
  alertService = inject(AlertDialogService);

  countryListState = new State<CountriesResponse>();
  applicantResidencyInfoState = new State<ApplicantResidenciesResponse>();
  saveApplicantResidencyState = new State<any>();

  residenciesForm!: FormGroup;
  residenciesFormArray!: FormArray;
  currentAddressForm!: FormGroup;
  mailingAddressForm?: FormGroup;

  statesList: CountryState[][] = [];

  ngOnInit(): void {
    this.initForm();
    this.getCountries();
    this.getResidencies();
  }

  initForm() {
    this.residenciesFormArray = this.formBuilder.array([]);
    this.residenciesForm = this.formBuilder.group({
      residencies: this.residenciesFormArray,
    });
  }

  getCountries() {
    this.executeRequest<CountriesResponse>({
      state: this.countryListState,
      request: this.commonService.getCountries(),
    });
  }

  private getResidencies() {
    let request: ApplicantResidenciesRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<ApplicantResidenciesResponse>({
      state: this.applicantResidencyInfoState,
      request: this.driverApplicationService.getApplicantResidencies(request),
      onSuccess: (response) => {
        let residencies = response.data ?? [];
        this.populateForm(residencies);
      },
    });
  }

  private populateForm(residencies: ApplicantResidency[]) {
    let currentAddress =
      residencies.find((residency) => residency.isCurrentAddress) ?? null;
    let mailingAddress =
      residencies.find(
        (residency) => residency.isMailingAddress && !residency.isCurrentAddress
      ) ?? null;

    let isMailingSameAsCurrent = mailingAddress == null;

    let totalResidenciesCount = residencies.length;
    let additionalAddressStartIndex = 1;
    // Subtract 1 because the current address will be always present
    let additionalAddressCount = totalResidenciesCount - 1;
    if (mailingAddress != null) {
      // Subtract 1 because the mailing address is present
      additionalAddressCount = additionalAddressCount - 1;
      additionalAddressStartIndex = 2;
    }

    this.currentAddressForm = this.createResidencyForm(
      currentAddress,
      null,
      null,
      true,
      isMailingSameAsCurrent
    );
    this.initCurrentAddressFormListener();
    this.residenciesFormArray.push(this.currentAddressForm);
    this.initMailingAddressChangeListener();

    if (mailingAddress != null) {
      this.mailingAddressForm = this.createResidencyForm(
        null,
        mailingAddress,
        null,
        false,
        true
      );
      this.residenciesFormArray.push(this.mailingAddressForm);
    }

    for (let i = additionalAddressStartIndex; i < totalResidenciesCount; i++) {
      let residency = residencies[i];
      let newAddress = this.createResidencyForm(
        null,
        null,
        residency,
        false,
        false
      );
      this.residenciesFormArray.push(newAddress);
    }

    this.residenciesForm = this.formBuilder.group({
      residencies: this.residenciesFormArray,
    });
  }

  initCurrentAddressFormListener() {

    this.residenciesFormArray.valueChanges.subscribe(() => {
      this.residenciesFormArray.controls.forEach((control, index) => {
        const yearsAtAddressControl = control.get("yearsAtAddress");
        if (yearsAtAddressControl) {
          yearsAtAddressControl.valueChanges.subscribe((value: number) => {
            if (value <= 2) {
              if (index === 0 && this.residenciesFormArray.length <= 1) {
                const newAddressForm = this.createResidencyForm(
                  null,
                  null,
                  null,
                  false,
                  false
                );
                this.residenciesFormArray.push(newAddressForm);
                this.statesList.push([]);
              }
            } else {
              if (index === 0 && this.residenciesFormArray.length > 1) {
                this.residenciesFormArray.removeAt(1);
                this.statesList.splice(1, 1);
              }
            }
          });
        }
      });
    });

    this.currentAddressForm.controls["yearsAtAddress"].valueChanges.subscribe((value: string) => {
        let yearsAtCurrentAddress = (value === '' || value == null) ? 0 : Number(value);
        if (yearsAtCurrentAddress > 2 || yearsAtCurrentAddress == 0) {
          return;
        }

        if (this.mailingAddressForm != null) {
          if (this.residenciesFormArray.length >= 2) {
            const additionalAddress = this.createResidencyForm(null, null, null, false, false);
            this.residenciesFormArray.push(additionalAddress);
            this.statesList.push([]);
            return;
          }
        }
        //
        // if (this.mailingAddressForm == null) {
        //   if (this.residenciesFormArray.length >= 1) {
        //     const additionalAddress = this.createResidencyForm(null, null, null, false, false);
        //     this.residenciesFormArray.push(additionalAddress);
        //     this.statesList.push([]);
        //     return;
        //   }
        // }
      }
    );
  }

  createResidencyForm(currentAddress: ApplicantResidency | null, mailingAddress: ApplicantResidency | null, additionalAddress: ApplicantResidency | null, isCurrentAddress: boolean, isMailingAddress: boolean): FormGroup {
    let residency: ApplicantResidency | null = null;
    if (isCurrentAddress) {
      residency = currentAddress;
    } else if (isMailingAddress) {
      residency = mailingAddress;
    } else if (additionalAddress != null) {
      residency = additionalAddress;
    }
    let countries = this.countryListState.response()?.data ?? [];
    let matchedCountry = countries.find(
      (country) => country.countryName === residency?.country
    );
    if (matchedCountry) {
      this.statesList.push(matchedCountry.states);
    }
    return this.formBuilder.group({
      id: [residency?.id],
      address: [residency?.address, Validators.required],
      country: [residency?.country, Validators.required],
      state: [residency?.state, Validators.required],
      city: [residency?.city, Validators.required],
      zipCode: [residency?.zipCode, Validators.required],
      yearsAtAddress: [residency?.yearsAtAddress, Validators.required],
      isCurrentAddress: [isCurrentAddress],
      isMailingAddress: [isMailingAddress],
    });
  }

  private initMailingAddressChangeListener() {
    this.currentAddressForm.controls["isMailingAddress"].valueChanges.subscribe(
      (mailingSameAsCurrent) => {
        if (mailingSameAsCurrent) {
          if (this.mailingAddressForm?.value?.isMailingAddress) {
            this.residenciesFormArray.removeAt(1);
            this.mailingAddressForm = undefined;
            this.statesList.splice(1, 1);
          }
        } else {
          this.mailingAddressForm = this.createResidencyForm(
            null,
            null,
            null,
            false,
            true
          );
          this.residenciesFormArray.insert(1, this.mailingAddressForm);
          // array.splice(index, 0, element);
          this.statesList.splice(1, 0, []);
        }
      }
    );
  }

  onCountrySelected(selectedItem: Country | null, index: number) {
    let states = selectedItem?.states ?? [];
    const formGroup = this.residenciesFormArray.at(index) as FormGroup;
    formGroup.patchValue({state: null});
    this.statesList[index] = states;
  }

  onAddAddressClicked() {
    let newAddress = this.createResidencyForm(null, null, null, false, false);
    this.residenciesFormArray.push(newAddress);
    this.statesList.push([]);
  }

  additionalAddressStartIndex(): number {
    let start = 1;
    if (this.mailingAddressForm?.value?.isMailingAddress) {
      start = 2;
    }
    return start;
  }

  onSaveAndNextClicked() {
    this.validateForm(this.residenciesForm);
    if (this.residenciesForm.invalid) {
      printInvalidFields(this.residenciesForm);
      return;
    }

    let residencies: SaveApplicantResidency[] = [];
    this.residenciesFormArray.controls.forEach((control) => {
      let residency = control.value;
      residencies.push({
        address: residency.address,
        country: residency.country,
        state: residency.state,
        city: residency.city,
        zipCode: residency.zipCode,
        yearsAtAddress: residency.yearsAtAddress,
        isCurrentAddress: residency.isCurrentAddress,
        isMailingAddress: residency.isMailingAddress,
      });
    });

    let request: SaveApplicantResidenciesRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      residencies: residencies,
    };

    this.executeRequest<any>({
      state: this.saveApplicantResidencyState,
      request: this.driverApplicationService.saveApplicantResidencies(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }

  onDeleteClick(index: number) {
    this.residenciesFormArray.removeAt(index);
    this.statesList.splice(index, 1);
    // this.alertService.show('Delete Address', 'Are you sure you want to delete this address?')
    //   .subscribe(result => {
    //     if (result) {
    //       this.residenciesFormArray.removeAt(index);
    //       this.statesList.splice(index, 1);
    //     }
    //   });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
