import {Component, inject} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {DialogRef} from "@angular/cdk/dialog";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-job-application-confirmation',
  imports: [
    ButtonComponent,
    BaseOverlayComponent
  ],
  templateUrl: './job-application-confirmation.component.html',
  standalone: true,
  styleUrl: './job-application-confirmation.component.scss'
})
export class JobApplicationConfirmationComponent {

  dialogRef = inject(DialogRef)

  closeDialog() {
    this.dialogRef.close(true);
  }
}
