import {Component, input, output} from '@angular/core';
import {MatMenu, MatMenuModule, MatMenuTrigger} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatIconButton} from "@angular/material/button";
import {AppSvgIconComponent} from "../app-svg-icon/app-svg-icon.component";
import {ContextMenuIconComponent} from "@shared/components/context-menu-icon/context-menu-icon.component";

@Component({
  selector: 'app-context-menu',
  standalone: true,
  imports: [
    MatMenu,
    MatMenuTrigger,
    MatIconModule,
    MatMenuModule,
    MatIconButton,
    AppSvgIconComponent,
    ContextMenuIconComponent
  ],
  templateUrl: './context-menu.component.html',
  styleUrl: './context-menu.component.scss'
})
export class ContextMenuComponent {
  actions = input<ContextMenuAction[]>();
  onActionClicked = output<string>();

  _onActionClicked($event: MouseEvent, action: ContextMenuAction) {
    $event.stopPropagation();
    this.onActionClicked.emit(action.actionKey);
  }
}


export interface ContextMenuAction {
  iconPath?: string;
  label: string;
  actionKey: string;
}

