import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  inject,
  input,
  OnDestroy,
  output,
  Output,
  signal,
  ViewEncapsulation
} from '@angular/core';
import {AppSvgIconComponent} from "../../components/app-svg-icon/app-svg-icon.component";
import {BaseInputComponent} from "../_base/base-input/base-input.component";
import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {NgxMaskDirective} from "ngx-mask";
import {FormControl, NgControl, ReactiveFormsModule} from "@angular/forms";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {debounceTime, Subject} from "rxjs";
import {NgClass} from "@angular/common";
import {MatDatepicker, MatDatepickerInput, MatDatepickerInputEvent} from "@angular/material/datepicker";

@Component({
  selector: 'app-outline-date-input',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    BaseInputComponent,
    HumanizeFormMessagesPipe,
    NgxMaskDirective,
    ReactiveFormsModule,
    NgClass,
    MatDatepicker,
    MatDatepickerInput
  ],
  templateUrl: './outline-date-input.component.html',
  styleUrl: './outline-date-input.component.scss'
})
export class OutlineDateInputComponent extends BaseControlValueAccessor implements AfterContentInit, AfterViewInit, OnDestroy {

  appearance = input<'fill' | 'outline'>('outline');
  viewType = input<'text' | 'text-area'>('text');
  iconSrc = input<string | null>();
  showDatePickerIcon = input<boolean>(true);
  label = input<string | null>();
  fullWidth = input<boolean>(false);
  errorMessages = input<{ [key: string]: string }>({});
  showErrorSpace = input<boolean>(false);
  debounceSearchEnabled = input<boolean>(true);
  inputDateFormat = input<InputDateFormat>(InputDateFormat.mmddyyyy);

  @Output()
  changeValue: EventEmitter<string> = new EventEmitter<any>();

  actionIconClicked = output();

  ngControl = inject(NgControl, {optional: true, self: true});
  override formControl: any;

  private searchSubject: Subject<string> = new Subject<string>();

  isFocused = signal(false);

  get placeHolder(): string {
    switch (this.inputDateFormat()) {
      case InputDateFormat.mmddyyyy:
        return 'mm/dd/yyyy';
      case InputDateFormat.ddmmyyyy:
        return 'dd/mm/yyyy';
    }
  }

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  override onWriteValue(value: any): void {

  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  ngAfterViewInit(): void {
    if (this.debounceSearchEnabled()) {
      this.searchSubject.pipe(debounceTime(600)).subscribe((value: string) => {
        this.changeValue.emit(value);  // Emit the value after debounce
      });
    }
  }

  ngOnDestroy(): void {
    try {
      this.searchSubject.complete();
    } catch (e) {
    }
  }

  onValueChange(event: any) {
    const target = event.target as HTMLInputElement;
    this.onChange(target.value);
    if (this.debounceSearchEnabled()) {
      this.searchSubject.next(target.value);
    } else {
      this.changeValue.emit(target.value);
    }
  }

  getClass() {
    let cls = '';
    if (this.iconSrc()) {
      cls = cls + 'pr-3 pl-10';
    } else {
      cls = cls + 'px-3';
    }

    if (this.fullWidth()) {
      cls = cls + ' ' + 'w-full';
    }

    return cls;
  }

  onFocus() {
    this.isFocused.set(true);
  }

  onBlur() {
    this.onTouched();
    this.isFocused.set(false);
  }

  onDateChanged($event: MatDatepickerInputEvent<any, any>) {
    let value = this.getmmddyyyyFormatDate($event.value);
    switch (this.inputDateFormat()) {
      case InputDateFormat.mmddyyyy:
        value = this.getmmddyyyyFormatDate($event.value);
        break;
      case InputDateFormat.ddmmyyyy:
        value = this.getddmmyyyyFormatDate($event.value);
        break;
    }

    this.formControl.setValue(value);
    this.onChange(value);
    this.changeValue.emit(value);
  }

  getmmddyyyyFormatDate(date: Date): string {
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
  }

  getddmmyyyyFormatDate(date: Date): string {
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
    const dd = String(date.getDate()).padStart(2, '0');
    const yyyy = date.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
  }
}

export enum InputDateFormat {
  mmddyyyy,
  ddmmyyyy
}
