import {Component, OnInit, inject} from "@angular/core";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {DropdownComponent} from "../../../../shared/inputs/dropdown/dropdown.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {State} from "../../../../shared/base/base-state";
import {BaseComponent} from "../../../../shared/base/base-component";
import {LookupService} from "../../../../core/services/lookup/lookup.service";
import {CommonModule} from "@angular/common";
import {
  AccountType,
  AccountTypesResponse,
} from "../../../../core/services/lookup/account-type/account-type-response";
import {
  CarrierType,
  CarrierTypeResponse,
} from "../../../../core/services/lookup/carrier-type/carrier-type-response";
import {InvalidControlScrollDirective} from "../../../../shared/directives/invalid-control-scroll.directive";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";
import {ApiService} from "../../../../core/api/api.service";
import type {
  BusinessSubTypeListResponse,
  CompleteProfileResponse,
  GetBusinessSubTypeListParams,
  GetBusinessTypeListParams,
  GetBusinessTypeListResponse,
  GetCompany,
  GetCompanyResponse,
  GetCompanyTypeListResponse,
  GetCountryListItem,
  GetCountryListResponse,
  GetStateListItem,
  GetStateListResponse
} from "../../../../core/api/model";
import {
  MultiSelectionFieldComponent
} from "../../../../shared/inputs/multi-selection-field/multi-selection-field.component";

@Component({
  selector: "app-setup-company-profile",
  standalone: true,
  templateUrl: "./setup-company-profile.component.html",
  styleUrl: "./setup-company-profile.component.scss",
  imports: [
    OutlineTextInputComponent,
    DropdownComponent,
    ButtonComponent,
    ReactiveFormsModule,
    CommonModule,
    InvalidControlScrollDirective,
    RadioButtonComponent,
    MultiSelectionFieldComponent,
  ],
})
export class SetupCompanyProfileComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  lookupService = inject(LookupService);
  apiService = inject(ApiService);
  setupCompanyProfileForm!: FormGroup;

  setCompanyProfileState = new State<CompleteProfileResponse>();
  companyTypesState = new State<GetCompanyTypeListResponse>();
  accountTypeState = new State<AccountTypesResponse>();
  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  businessTypeState = new State<GetBusinessTypeListResponse>();
  businessSubTypeState = new State<BusinessSubTypeListResponse>();
  carrierTypeState = new State<CarrierTypeResponse>();
  companyInfoState = new State<GetCompanyResponse>();

  selectedAccountType: AccountType | null | undefined;
  selectedCarrierType: CarrierType | null | undefined;
  filteredStates: GetStateListItem[] = [];

  ngOnInit(): void {
    this.initForm();
    this.getCompanyInfo();
    this.getCompanyTypes();
    this.getAccountTypes();
    this.getCountry();
    this.getStateList();
    this.getBusinessType();
    this.getBusinessSubType();
    this.getCarrierTypes();
  }

  getCompanyTypes() {
    this.executeRequest<GetCompanyTypeListResponse>({
      state: this.companyTypesState,
      request: this.apiService.getCompanyTypeList(),
    });
  }

  initForm(): void {
    this.setupCompanyProfileForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      dba: [""],
      accountType: ["", Validators.required],
      ownerSsnNumber: ["", Validators.required],
      einNumber: ["", Validators.required],
      email: ["", Validators.email],
      phoneNumber: ["", Validators.required],
      companyType: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: ["", Validators.required],
      countryId: ["", Validators.required],
      stateId: ["", Validators.required],
      city: ["", Validators.required],
      zipCode: ["", Validators.required],
      businessTypeId: ["", Validators.required],
      businessSubTypeId: ["", Validators.required],
      businessRepresentativeFirstName: ["", Validators.required],
      businessRepresentativeLastName: ["", Validators.required],
      dotNumber: ["", Validators.required],
      caMcpNumber: ["", Validators.required],
      carrierType: ["", Validators.required],
      mcNumber: ["", Validators.required],
      mcpNumber: ["", Validators.required],
      transportsHazmat: [false, Validators.required],
      transportsHazwaste: [false, Validators.required],
    });
  }

  getAccountTypes() {
    this.executeRequest({
      state: this.accountTypeState,
      request: this.lookupService.getAccountTypes(),
    });
  }

  getCountry() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
    });
  }

  getBusinessType() {
    const request: GetBusinessTypeListParams = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<GetBusinessTypeListResponse>({
      state: this.businessTypeState,
      request: this.apiService.getBusinessTypeList(request),
    });
  }

  getBusinessSubType() {
    const request: GetBusinessSubTypeListParams = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<BusinessSubTypeListResponse>({
      state: this.businessSubTypeState,
      request: this.apiService.getBusinessSubTypeList(request),
    });
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
      onSuccess: (response) => {
        this.handleInfoForm(response.data);
      },
    });
  }

  private handleInfoForm(companyInfo: GetCompany) {
    this.setupCompanyProfileForm.controls["companyName"].setValue(companyInfo.companyName ?? "");
    this.setupCompanyProfileForm.controls["phoneNumber"].setValue(companyInfo.phoneNumber ?? "");
    this.setupCompanyProfileForm.controls["email"].setValue(companyInfo.email ?? "");
    this.setupCompanyProfileForm.controls["email"].disable();
    this.setupCompanyProfileForm.controls["dotNumber"].setValue(companyInfo.dotNumber);
    this.setupCompanyProfileForm.controls["companyType"].setValue(companyInfo.companyType);
  }

  getCarrierTypes() {
    this.executeRequest({
      state: this.carrierTypeState,
      request: this.lookupService.getCarrierType(),
    });
  }

  onAccountTypeChanged(selectedItem: AccountType | null) {
    this.selectedAccountType = selectedItem;
    switch (selectedItem?.name) {
      case "Individual":
        this.setupCompanyProfileForm.controls["ownerSsnNumber"].enable();
        this.setupCompanyProfileForm.controls["einNumber"].disable();
        break;
      case "Company":
        this.setupCompanyProfileForm.controls["einNumber"].enable();
        this.setupCompanyProfileForm.controls["ownerSsnNumber"].disable();
        break;
    }
  }

  onSelectedCarrierChanged(selectedItems: CarrierType[] | null) {
    if (selectedItems) {
      selectedItems.forEach((item) => {
        switch (item?.name) {
          case "InterState":
            this.setupCompanyProfileForm.controls["mcNumber"].enable();
            this.setupCompanyProfileForm.controls["mcpNumber"].disable();
            break;
          case "IntraState":
            this.setupCompanyProfileForm.controls["mcNumber"].disable();
            this.setupCompanyProfileForm.controls["mcpNumber"].enable();
            break;
          default:
            break;
        }
      });
    }
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  onClickCompleteProfile() {
    // this.validateForm(this.setupCompanyProfileForm);
    if (this.setupCompanyProfileForm.invalid) {
      return;
    }
    let request = this.setupCompanyProfileForm.value;
    this.executeRequest({
      state: this.setCompanyProfileState,
      request: this.apiService.updateProfile(request),
      onSuccess: (response) => {
        this.setupCompanyProfileForm.reset();
        this.router.navigate(["/dashboard"]);
      },
      handleSuccess: true,
      successMessage: "You have successfully set your profile.",
    });
  }
}
