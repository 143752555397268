import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import type {
  DeleteServiceTypeResponse,
  GetServiceType, GetServiceTypeListParams,
  GetServiceTypeListResponse,
} from "@core/api/model";
import {Constants} from "@core/constants/constants";
import {
  CreateOrUpdateServiceTypeComponent
} from "./create-or-update-service-type/create-or-update-service-type.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-service-types',
  imports: [
    ButtonComponent,
    DataTableComponent,
    PageHeaderComponent
  ],
  templateUrl: './service-types.component.html',
  standalone: true,
  styleUrl: './service-types.component.scss'
})
export class ServiceTypesComponent extends BaseComponent implements OnInit {

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);

  getServiceTypeListState = new State<GetServiceTypeListResponse>();
  deleteServiceTypeState = new State<DeleteServiceTypeResponse>();

  ngOnInit() {

  }

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'Name',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$name',
      sortKey: 'name'
    },
    {
      title: 'Description',
      type: 'text',
      displayTemplate: '$description',
      alignment: 'center',
      sortKey: 'description',
      pinned: 'left'
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$status', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'Active',
            displayText: 'Active',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: 'bg-success-500',
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
          {
            data: 'Inactive',
            displayText: 'Inactive',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: "bg-error-500",
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
        ]
      }
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [
          {iconPath: 'assets/icons/edit.svg', actionKey: 'edit', label: 'Edit Service Type'},
          {iconPath: 'assets/icons/bin.svg', actionKey: 'delete', label: 'Delete Service Type'},
        ],
      }
    }
  ];

  getServiceTypeList($event: TableStateEvent) {
    let request: GetServiceTypeListParams = {
      pageNumber: $event?.paginationEvent?.pageNumber ?? 1,
      pageSize: $event?.paginationEvent?.pageSize ?? 50,
      search: $event.searchText
    };
    this.executeRequest({
      state: this.getServiceTypeListState,
      request: this.apiService.getServiceTypeList(request),
    });
  }

  onNewServiceTypeClicked() {
    this.overlayService.openModal(CreateOrUpdateServiceTypeComponent).closed.subscribe((success) => {
      if (success) {
        this.getServiceTypeList(Constants.defaultTableStateEvent);
      }
    });
  }

  onUpdateServiceType(item: GetServiceType) {
    this.overlayService.openModal(CreateOrUpdateServiceTypeComponent, {
      data: item
    }).closed.subscribe((success) => {
      if (success) {
        this.getServiceTypeList(Constants.defaultTableStateEvent);
      }
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetServiceType = $event.item;
    switch ($event.actionKey) {
      case 'edit':
        this.onUpdateServiceType(item);
        break;
      case 'delete':
        this.onDeleteServiceClicked(item);
        break;
    }
  }

  onDeleteServiceClicked(item: GetServiceType) {
    let id = item.id;
    this.overlayService.openAlert('Delete Service', 'Are you sure you want to delete this service type?')
      .closed
      .subscribe((result) => {
        if (result) {
          this.executeRequest({
            state: this.deleteServiceTypeState,
            request: this.apiService.deleteServiceType(id),
            onSuccess: (response) => {
              this.getServiceTypeList(Constants.defaultTableStateEvent);
            }
          });
        }
      });
  }

}

