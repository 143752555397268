<app-base-overlay [title]="'Re-sign Required'">
  <div body>
    <div class="flex flex-col gap-3">
      <div class="flex items-center gap-4">
        <app-svg-icon src="assets/icons/info.svg" class="text-neutral-400"></app-svg-icon>
        <p class="text-body1">The company has updated some information. Please review and re-sign the document to
          proceed.</p>
      </div>
    </div>
  </div>
  <div actions>
    <div class="flex justify-end items-center">
      <app-button (click)="onOkClicked()">Ok</app-button>
    </div>
  </div>
</app-base-overlay>
