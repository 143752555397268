import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {GetSapProgramListParams, GetSapProgramListResponse} from "@core/api/model";

@Component({
  selector: 'app-view-sap-program-history',
  imports: [
    ButtonComponent,
    DataTableComponent
  ],
  templateUrl: './sap-program-history.component.html',
  standalone: true,
  styleUrl: './sap-program-history.component.scss'
})
export class SapProgramHistoryComponent extends BaseComponent implements OnInit {

  driverId: string | undefined;

  apiService = inject(ApiService);
  getSapProgramListState = new State<GetSapProgramListResponse>();

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'started date', type: 'date', displayTemplate: '$startDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd MMM, yyyy', showIcon: false
      }
    },
    {
      title: 'End date', type: 'date', displayTemplate: '$endDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd MMM, yyyy', showIcon: false
      }
    },
    {
      title: 'Duration',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$duration',
      sortKey: 'duration'
    },
    {
      title: 'Frequency',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$frequency',
      sortKey: 'frequency'
    },
    {
      title: 'Notes',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$notes',
      sortKey: 'notes'
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        threeDotMenuActions: [
          {actionKey: 'view', label: 'View Results'},
        ]
      }
    }
  ];

  ngOnInit() {
    if (!this.driverId) {
      this.listenToRoute();
    }
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? undefined;
    });
  }

  getSapProgramHistory($event: TableStateEvent) {
    let request: GetSapProgramListParams = {
      driverId: this.driverId ?? '',
      pageNumber: $event.paginationEvent?.pageNumber,
      pageSize: $event.paginationEvent?.pageSize,
      search: $event.searchText
    };
    this.executeRequest<GetSapProgramListResponse>({
      request: this.apiService.getSapProgramList(request),
      state: this.getSapProgramListState
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item = $event.item;

    switch ($event.actionKey) {
      case 'view':
        this.router.navigate([`drivers/${this.driverId}/sap-program-results`], {queryParams: {sapProgramId: item.id}}).then();
        break;
    }
  }

  onBackClicked() {
    if (this.driverId) {
      this.router.navigate([`/drivers/${this.driverId}/sapProgram`]);
    }
  }

}
