import {Component, inject, OnInit} from '@angular/core';
import {ListviewComponent} from "@shared/new/listview/listview.component";
import {
  RowTransferTerminalSelectionComponent
} from "./row-transfer-terminal-selection/row-transfer-terminal-selection.component";
import {BaseComponent} from "@shared/base/base-component";
import {TableActionEvent, TableStateEvent} from "@shared/components/data-table/data-table.component";
import {
  type GetCompanyUserTerminalListItem,
  GetCompanyUserTerminalListParams,
  GetCompanyUserTerminalListResponse,
  type GetDriverListItem
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {PaginationState} from "@shared/base/base-pagination-state";
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-driver-transfer-to-terminal',
  imports: [
    ListviewComponent,
    BaseOverlayComponent
  ],
  templateUrl: './driver-transfer-to-terminal.component.html',
  standalone: true,
  styleUrl: './driver-transfer-to-terminal.component.scss'
})
export class DriverTransferToTerminalComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  data: GetDriverListItem = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  companyUserTerminalsState = new PaginationState<GetCompanyUserTerminalListResponse>();

  ngOnInit() {
  }

  onListAction($event: TableActionEvent) {
    let terminal: GetCompanyUserTerminalListItem = $event.data;
    if ($event.actionKey === 'selected') {
      this.dialogRef.close(true);
    }
  }

  getCompanyUserTerminals($event: TableStateEvent) {
    let request: GetCompanyUserTerminalListParams = {
      pageNumber: $event.paginationEvent?.pageNumber,
      pageSize: $event.paginationEvent?.pageSize,
      search: $event .searchText
    };
    this.executeRequest<GetCompanyUserTerminalListResponse>({
      state: this.companyUserTerminalsState,
      request: this.apiService.getCompanyUserTerminalList(request),
    });
  }

  protected readonly RowTransferTerminalSelectionComponent = RowTransferTerminalSelectionComponent;

}
