import {
  AfterViewInit,
  Component,
  ContentChildren,
  inject,
  Injector,
  input,
  output, OutputEmitterRef, OutputRefSubscription,
  QueryList,
  signal, Type,
  ViewChild
} from '@angular/core';
import {State} from "../../base/base-state";
import {PaginationComponent, PaginationEvent} from "../pagination/pagination.component";
import {NoDataTableComponent} from "../no-data-table/no-data-table.component";
import {ShimmerComponent} from "../shimmer/shimmer.component";
import {DatePipe, NgClass, NgComponentOutlet} from "@angular/common";
import {resolveTemplateWithObject} from "../../common-utils/template-resolver";
import {ContextMenuAction, ContextMenuComponent} from "../context-menu/context-menu.component";
import {AppSvgIconComponent} from "../app-svg-icon/app-svg-icon.component";
import {StatusBadgeComponent} from "../status-badge/status-badge.component";
import {SortableTableDirective, TableSortEvent} from "../_base/base-table/sortable-table.directive";
import {TableResizableColumnsDirective} from "../_base/base-table/table-resizable-columns.directive";
import {MatTooltip} from "@angular/material/tooltip";
import {Overlay, OverlayConfig} from "@angular/cdk/overlay";
import {CdkPortal} from "@angular/cdk/portal";
import {CheckboxComponent} from "../../inputs/checkbox/checkbox.component";
import {FormsModule} from "@angular/forms";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {TextButtonSmall} from "@shared/components/text-button-small/text-button-small.component";
import {DynamicRendererComponent} from "@shared/components/dynamic-renderer/dynamic-renderer.component";
import {BaseControlValueAccessorV3} from "@shared/base/base-control-value-accessor-v3";

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    PaginationComponent,
    NoDataTableComponent,
    ShimmerComponent,
    NgClass,
    DatePipe,
    AppSvgIconComponent,
    MatTooltip,
    ContextMenuComponent,
    StatusBadgeComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
    CdkPortal,
    CheckboxComponent,
    FormsModule,
    TextInputComponent,
    TextButtonSmall,
    DynamicRendererComponent
  ],
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss',
})
export class DataTableComponent<T> extends BaseControlValueAccessorV3<TableStateEvent> implements AfterViewInit {

  @ContentChildren('filter') headerComponents!: QueryList<any>;

  columnDefs = input.required<ColumnDef[]>();
  state = input.required<State<any>>();
  pageSize = input(50);
  enableHorizontallyScrollable = input(false);
  enableResizableColumns = input(false);
  enableClickableRows = input(false);
  expandableComponent = input<any>();
  enableColumnsConfig = input(false);
  filterComponent = input<any>();
  enableSearch = input(true);
  disableInitialLoad = input(false);

  pageChange = output<PaginationEvent>();
  sortChanged = output<TableSortEvent>();
  tableStateChanged = output<TableStateEvent>();
  onActionPerformed = output<TableActionEvent>();
  filterChanged = output<any>();
  onRowClicked = output<any>();

  subscriptions: OutputRefSubscription[] = [];

  overlay = inject(Overlay);
  @ViewChild(CdkPortal) portal!: CdkPortal;

  paginationEvent?: PaginationEvent;
  tableSortEvent?: TableSortEvent;
  private searchText: string = '';

  ngAfterViewInit(): void {
    let paginationEvent: PaginationEvent = {
      pageNumber: 1, pageSize: this.pageSize()
    }
    this.pageChange.emit(paginationEvent);

    let tableStateEvent: TableStateEvent = {
      searchText: '',
      paginationEvent: paginationEvent,
      tableSortEvent: this.tableSortEvent
    };

    if (!this.disableInitialLoad()) {
      this.tableStateChanged.emit(tableStateEvent);
    }

    this.onValueChange(tableStateEvent);

    this.headerComponents.forEach((component) => {
      if (component.filtersChanged) {
        let emitter: OutputEmitterRef<any> = component.filtersChanged;
        const sub = emitter.subscribe((newFilters: any) => {
          this.filterChanged.emit(newFilters);
        });
        this.subscriptions.push(sub);
      }
    });
  }

  protected override onValueReady(value: TableStateEvent): void {
  }

  onColumnSettingsClicked(columnsConfigTriggerElement: HTMLDivElement) {
    const config = new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(columnsConfigTriggerElement)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 20,
            offsetY: 10,
          }
        ]),
      hasBackdrop: true,
      backdropClass: ['bg-black', 'bg-opacity-0', 'shadow-1']
    });

    const overlayRef = this.overlay.create(config);
    overlayRef.attach(this.portal);

    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  onSearchTextChanged($event: string) {
    this.searchText = $event;
    let paginationEvent: PaginationEvent = {
      pageNumber: 1, pageSize: this.pageSize()
    }
    let tableStateEvent: TableStateEvent = {
      searchText: this.searchText,
      paginationEvent: paginationEvent,
      tableSortEvent: this.tableSortEvent
    };
    this.tableStateChanged.emit(tableStateEvent);
    this.onValueChange(tableStateEvent);
  }

  onColumnVisibleOrHide($event: boolean, column: ColumnDef) {
    column.visible = $event;
  }

  onSelectAllColumnClicked() {
    let columns = this.columnDefs() ?? [];
    columns.forEach(column => {
      column.visible = true;
    });
  }

  getPropertyValue(item: any, column: ColumnDef,): any {

    if (column.displayTemplate) {
      return resolveTemplateWithObject(item, column.displayTemplate);
    }

    let value = '';

    if (column.key) {
      value = column.key.split('.').reduce((acc, part) => acc && acc[part], item);
    }

    if (column.formatter) {
      return column.formatter ? column.formatter(value) : value;
    } else if (column.objectFormatter) {
      return column.objectFormatter ? column.objectFormatter(item) : item;
    } else {
      return value;
    }
  }

  onPageChange(event: PaginationEvent) {
    let tableStateEvent: TableStateEvent = {
      searchText: this.searchText,
      paginationEvent: event,
      tableSortEvent: this.tableSortEvent
    };
    this.pageChange.emit(event);
    this.tableStateChanged.emit(tableStateEvent);
    this.onValueChange(tableStateEvent);
  }

  onSortChanged(event: TableSortEvent) {
    let tableStateEvent: TableStateEvent = {
      searchText: '',
      paginationEvent: {
        pageNumber: 1,
        pageSize: this.pageSize()
      },
      tableSortEvent: event
    };
    this.sortChanged.emit(event);
    this.tableStateChanged.emit(tableStateEvent);
    this.onValueChange(tableStateEvent);
  }

  getThTrClass(column: ColumnDef) {
    switch (column.alignment) {
      case 'left':
        return 'text-left';
      case 'center':
        return 'text-center';
      case 'right':
        return 'text-right';
      default:
        return 'text-left';
    }
  }

  getFlexJustify(column: ColumnDef) {
    switch (column.alignment) {
      case 'left':
        return 'justify-start';
      case 'center':
        return 'justify-center';
      case 'right':
        return 'justify-end';
      default:
        return 'justify-start';
    }

  }

  getBadgeProperty(item: any, column: ColumnDef): BadgeConfigProperty | null {
    let badgeConfigProperties = column.badgeConfig?.properties ?? [];
    let matchedBadgeConfigProperty: BadgeConfigProperty | null = null;

    badgeConfigProperties.forEach(badgeConfigProperty => {
      let value = this.getPropertyValue(item, column);
      if (value === badgeConfigProperty.data) {
        matchedBadgeConfigProperty = badgeConfigProperty;
      }
    });
    return matchedBadgeConfigProperty;
  }

  getContextMenuActions(actions: ContextMenuActionConfig[]) {
    return actions.map(action => {
      let configAction: ContextMenuAction = {
        iconPath: action.iconPath,
        label: action.label,
        actionKey: action.actionKey
      };
      return configAction;
    })
  }

  _onActionClicked($event: string, item: any, mouseEvent: MouseEvent | null) {
    if (mouseEvent) {
      mouseEvent.stopPropagation();
    }
    let tableActionEvent: TableActionEvent = {
      actionKey: $event,
      item: item
    };
    this.onActionPerformed.emit(tableActionEvent);
  }

  expandedRowIndex = signal<number | null>(null);

  onRowExpandedClicked(i: number) {
    if (this.expandedRowIndex() == i) {
      this.expandedRowIndex.set(null);
    } else {
      this.expandedRowIndex.set(i);
    }
  }

  _onRowClicked(item: any) {
    this.onRowClicked.emit(item);
  }

  onCellActionPerformed($event: TableActionEvent) {
    this.onActionPerformed.emit($event);
  }

  onRowActionPerformed($event: TableActionEvent) {
    this.onActionPerformed.emit($event);
  }
}

export interface ColumnDef {
  title: string;
  key?: string;
  displayTemplate?: string;
  sortKey?: string;
  alignment?: 'left' | 'center' | 'right';
  type: 'text' | 'date' | 'badge' | 'custom' | 'actions';
  pinned?: 'left' | 'right' | null;
  visible?: boolean | null;

  component?: Type<any>;

  textConfig?: TextConfig;
  dateConfig?: DateConfig;
  badgeConfig?: BadgeConfig;
  customConfig?: CustomRendererConfig;
  actionsConfig?: ActionConfig;

  formatter?: (value: any) => any;
  objectFormatter?: (value: any) => any;
  propertyStyle?: (value: any) => any;
}


export interface TextConfig {
  textColorClass?: string;
}

export interface DateConfig {
  dateFormat?: string;
  showIcon?: boolean;
}

export interface BadgeConfig {
  properties: BadgeConfigProperty[];
}

export interface BadgeConfigProperty {
  data: string;
  displayText: string;
  backgroundColorClass?: string;
  borderColorClass?: string;
  textColorClass?: string;
  indicatorColorClass?: string;
}

export interface CustomRendererConfig {
  data?: any;
}

export interface ActionConfig {
  iconActions?: IconAction[];
  threeDotMenuActions?: ContextMenuActionConfig[] | null;
  textMenuActions?: ContextMenuActionConfig[] | null;
  components?: Type<any>[];
}

export interface IconAction {
  iconPath: string;
  actionKey: string;
  label?: string;
}

export interface ContextMenuActionConfig {
  iconPath?: string;
  actionKey: string;
  label: string;
}

export interface TableActionEvent {
  actionKey: string;
  item: any;
  data?: any;
}


export interface TableStateEvent {
  searchText: string;
  paginationEvent?: PaginationEvent;
  tableSortEvent?: TableSortEvent;
}
