<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll no-scrollbar">
      <div class="py-4 space-y-4">
        <p class="text-h6 text-info-500">License Information</p>
        <p class="text-caption text-neutral-500">Please provide us all the information as per the license</p>
        <div class="flex items-center gap-4 border border-neutral-200 p-2">
          <app-svg-icon src="assets/icons/info.svg" [size]="18" class="text-neutral-500"></app-svg-icon>
          <p class="text-neutral-500 text-caption">
            No person who operates a commercial motor vehicle shall at any time have more than one driver’s license (49
            CFR 383.21). I certify that I do not have more than one motor vehicle license, the information for which is
            listed below. Include all licenses held for the past 3 years; attach additional sheets if needed.
          </p>
        </div>
      </div>
      <form [formGroup]="licenseForm" class="form flex flex-col gap-3 mt-4">
        <div class="flex flex-col gap-3" formGroupName="licenses">

          @if (currentLicenseForm) {
            <div [formGroup]="currentLicenseForm" class="licenses-form flex flex-col gap-3 my-4">
              <span class="text-body2 text-info-500">CURRENT LICENSE</span>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-text-input formControlName="firstName" [label]="'First Name'" placeholder="Ex: John"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
                <app-outline-text-input formControlName="lastName" [label]="'Last Name'" placeholder="Ex: Bravo"
                                        class="w-full" [fullWidth]="true"></app-outline-text-input>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-text-input formControlName="licenseNo" [label]="'License #'"
                                        placeholder="Ex: 123-456-789-10" class="w-full"
                                        [fullWidth]="true"></app-outline-text-input>
                <app-dropdown formControlName="class"
                              [items]="licenseClassesState.response()?.data ?? []"
                              [title]="'Class'"
                              [display]="null"
                              [enableSearch]="true"
                              [noDataMessage]="'No Options Available'"
                              class="w-full"
                              placeholder="Ex: United States - Class A"
                              [fullWidth]="true">
                </app-dropdown>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-dropdown
                  formControlName="country"
                  [title]="'Country'"
                  [display]="'countryName'"
                  [enableSearch]="true"
                  [value]="'countryName'"
                  [noDataMessage]="'No Options Available'"
                  class="w-full"
                  placeholder="Ex: United States"
                  [items]="countryListState.response()?.data ?? []"
                  (valueChanged)="onCountrySelected($event, 0)"
                  [fullWidth]="true">
                </app-dropdown>
                <app-dropdown
                  formControlName="state"
                  [title]="'State'"
                  [display]="'stateName'"
                  [value]="'stateName'"
                  [enableSearch]="true"
                  [noDataMessage]="'No Options Available'"
                  class="w-full"
                  [items]="statesList[0]"
                  placeholder="Ex: California"
                  [fullWidth]="true">
                </app-dropdown>
              </div>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-outline-date-input formControlName="issuedDate" [label]="'Issued Date'"
                                        class="w-full" [fullWidth]="true"
                                        [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
                <app-outline-date-input formControlName="expirationDate" [label]="'Expiration Date'"
                                        class="w-full" [fullWidth]="true"
                                        [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
              </div>
              <p class="text-neutral-800 text-body2">Endorsements</p>
              <div class="grid grid-cols-6 text-nowrap">

                <app-checkbox class="col-span-2" formControlName="hasPlacardedHazmat"
                              title="H - Placarded Hazmat"></app-checkbox>
                <app-checkbox class="col-span-2" formControlName="hasTankVehicles"
                              title="N - Tank Vehicles"></app-checkbox>


                <app-checkbox class="col-span-2" formControlName="hasPassengers" title="P - Passengers"></app-checkbox>
                <app-checkbox class="col-span-2" formControlName="hasDoubleOrTripleTrailers"
                              title="T - Double/Triple Trailers"></app-checkbox>


                <app-checkbox class="col-span-2" formControlName="hasSchoolBus"
                              title="S - School Bus"></app-checkbox>
                <app-checkbox class="col-span-2" formControlName="hasPlacardedHazmatAndTankVehicles"
                              title="X - Placarded Hazmat & Tank Vehicles"></app-checkbox>

              </div>
              <div class="flex items-center gap-3 w-full">
                <span class="text-body2">Has there been any name change on your driving license?</span>
                <app-radio-button title="Yes" [value]="true"
                                  groupName="canShowNameChangeForm[i]"
                                  formControlName="hasNameChange"></app-radio-button>
                <app-radio-button title="No" [value]="false"
                                  groupName="canShowNameChangeForm[i]"
                                  formControlName="hasNameChange"></app-radio-button>
              </div>
            </div>
          }

          <div class="line"></div>

          @for (group of licensesFormArray.controls; track group; let i = $index; let isLast = $last; let isFirst = $first) {
            @if (i > 0) {
              <div class="licenses-form flex flex-col gap-3 my-4" [formGroupName]="i">
                <span class="text-body2 text-info-500">ADDITIONAL LICENSE</span>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-text-input formControlName="firstName" [label]="'First Name'" placeholder="Ex: John"
                                          class="w-full" [fullWidth]="true"></app-outline-text-input>
                  <app-outline-text-input formControlName="lastName" [label]="'Last Name'" placeholder="Ex: Bravo"
                                          class="w-full" [fullWidth]="true"></app-outline-text-input>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-text-input formControlName="licenseNo" [label]="'License #'"
                                          placeholder="Ex: 123-456-789-10" class="w-full"
                                          [fullWidth]="true"></app-outline-text-input>
                  <app-dropdown formControlName="class"
                                [items]="licenseClassesState.response()?.data ?? []"
                                [title]="'Class'"
                                [noDataMessage]="'No Options Available'"
                                [enableSearch]="true"
                                class="w-full"
                                placeholder="Ex: United States - Class A"
                                [fullWidth]="true"
                                [display]="null">
                  </app-dropdown>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-dropdown
                    formControlName="country"
                    [items]="countryListState.response()?.data ?? []"
                    [title]="'Country'"
                    [display]="'countryName'"
                    [value]="'countryName'"
                    [enableSearch]="true"
                    [noDataMessage]="'No Options Available'"
                    class="w-full"
                    placeholder="Ex: United States"
                    (valueChanged)="onCountrySelected($event, i)"
                    [fullWidth]="true">
                  </app-dropdown>
                  <app-dropdown
                    formControlName="state"
                    [items]="statesList[i]"
                    [title]="'State'"
                    [display]="'stateName'"
                    [value]="'stateName'"
                    [enableSearch]="true"
                    [noDataMessage]="'No Options Available'"
                    class="w-full"
                    placeholder="Ex: California"
                    [fullWidth]="true">
                  </app-dropdown>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-outline-date-input formControlName="issuedDate" [label]="'Issued Date'"
                                          class="w-full" [fullWidth]="true"
                                          [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>

                  <app-outline-date-input formControlName="expirationDate" [label]="'Expiration Date'"
                                          class="w-full" [fullWidth]="true"
                                          [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
                </div>
                <p class="text-neutral-800 text-body2">Endorsements</p>
                <div class="grid grid-cols-6 text-nowrap">

                  <app-checkbox class="col-span-2" formControlName="hasPlacardedHazmat"
                                title="H - Placarded Hazmat"></app-checkbox>
                  <app-checkbox class="col-span-2" formControlName="hasTankVehicles"
                                title="N - Tank Vehicles"></app-checkbox>


                  <app-checkbox class="col-span-2" formControlName="hasPassengers"
                                title="P - Passengers"></app-checkbox>
                  <app-checkbox class="col-span-2" formControlName="hasDoubleOrTripleTrailers"
                                title="T - Double/Triple Trailers"></app-checkbox>


                  <app-checkbox class="col-span-2" formControlName="hasSchoolBus"
                                title="S - School Bus"></app-checkbox>
                  <app-checkbox class="col-span-2" formControlName="hasPlacardedHazmatAndTankVehicles"
                                title="X - Placarded Hazmat & Tank Vehicles"></app-checkbox>

                </div>
                @if (!isFirst)
                {
                <div class="flex justify-end">
                  <app-button appearance="outline" outlineColor="border-error-500"
                              textButtonColor="text-error-500" (click)="onDeleteClicked(i)">Delete
                  </app-button>
                </div>
                }

                @if (!isLast) {
                  <div class="line"></div>
                }
              </div>
            }
          }
          <div class="flex flex-col gap-4 my-3">
            <app-button iconSrc="assets/icons/plus.svg" appearance="outline" [fullWidth]="true" (click)="onAddPreviousLicenseClicked()">ADD PREVIOUS
              LICENSE
            </app-button>
          </div>

        </div>
      </form>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button (click)="onSaveAndNextClicked()" [loading]="saveLicenseState.loading()">Save & Next</app-button>
    </div>
  </div>
</div>
