<app-base-dialog [title]="'Send Driver Application'">
  <div body>
    <form [formGroup]="sendDriverApplicationForm" (ngSubmit)="onSendApplicationClicked()">
      <div class="flex flex-col gap-4 my-6">
        <span class="text-body2">Please enter a valid email address to start the driver onboarding process.</span>
        <app-outline-text-input formControlName="email" [label]="'Email'" placeholder="Ex: name@company.com"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [textButtonColor]="'text-primary-600'"
                  [outlineColor]="'border-primary-500'" (click)="close()">Cancel
      </app-button>
      <app-button [loading]="sendDriverApplicationState.loading()" [type]="'submit'" (click)="onSendApplicationClicked()">Send Application</app-button>
    </div>
  </div>
</app-base-dialog>
