import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@shared/base/base-component";
import {
  TextInputComponent
} from "@shared/inputs/text-input/text-input.component";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {State} from "@shared/base/base-state";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {ApiService} from "@core/api/api.service";
import type {
  CreateDriverApplicationProficiencyRequest,
  CreateDriverApplicationProficiencyResponse, GetDriverApplicationLicenseItem,
  GetDriverApplicationLicenseParams,
  GetDriverApplicationLicenseResponse,
  GetDriverApplicationProficiencyResponse
} from "@core/api/model";
import {arrayToSpaceSeparator} from "@shared/utils/string-utils";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";

@Component({
  selector: 'app-proficiency-test',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    SignaturePadComponent,
    CheckboxComponent,
    ButtonComponent,
    RadioButtonComponent,
    BaseOverlayComponent,
    DateInputComponent
],
  templateUrl: './proficiency-test.component.html',
  styleUrls: ['./proficiency-test.component.scss']
})
export class ProficiencyTestComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  proficiencyTestState = new State<CreateDriverApplicationProficiencyResponse>();
  getProficiencyTestState = new State<GetDriverApplicationProficiencyResponse>();
  licensesState = new State<GetDriverApplicationLicenseResponse>();
  proficiencyTestForm!: FormGroup;
  private currentDrivingLicense: GetDriverApplicationLicenseItem | undefined;

  ngOnInit() {
    this.initForm();
    this.getLicenses();
  }

  private initForm() {
    this.proficiencyTestForm = this.formBuilder.group({
      licenseNo: ["", Validators.required],
      proficiencyTestEquipmentType: ["truck", Validators.required],
      equipmentCategory: ["straightTruck", Validators.required],
      busWithAirBrakes: [true, Validators.required],
      busWithWheelChair: [true, Validators.required],
      busWithHydraulicBrakes: [true, Validators.required],
      numberOfPassengers: ["", Validators.required],
      transmission: ["standardShiftTransmission", Validators.required],
      airBrakes: [true, Validators.required],
      hazmatEndorsement: [true, Validators.required],
      specialEquipment: [true, Validators.required],
      specialEquipmentName: ["", Validators.required],
      driversName: ["", Validators.required],
      powerUnit: ["", Validators.required],
      trailer: ["", Validators.required],
      proficiencyTestConductedDate: ["", Validators.required],
      distanceDriven: ["", Validators.required],
      proficiencyTestExaminerFirstName: ["", Validators.required],
      proficiencyTestExaminerLastName: ["", Validators.required],
      proficiencyTestExaminerSignature: ["", Validators.required],
      proficiencyTestResultConductedJson: ["", Validators.required],
    });
  }

  onSignatureCleared() {
    this.proficiencyTestForm.controls['proficiencyTestExaminerSignature'].setValue(null);
  }

  onSignatureSaved(signatureBase64: string) {
    this.proficiencyTestForm.controls["proficiencyTestExaminerSignature"].setValue(signatureBase64);
  }

  private getLicenses() {
    let request: GetDriverApplicationLicenseParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<GetDriverApplicationLicenseResponse>({
      state: this.licensesState,
      request: this.apiService.getDriverApplicationLicense(request),
      onSuccess: (response) => {
        let licenses = response.data ?? [];
        this.currentDrivingLicense = licenses.find((license) => license.isCurrent);
        let driverName = arrayToSpaceSeparator([this.currentDrivingLicense?.firstName, this.currentDrivingLicense?.lastName]);

        this.proficiencyTestForm.controls["licenseNo"].setValue(this.currentDrivingLicense?.licenseNo);
        this.proficiencyTestForm.controls["driversName"].setValue(driverName);
        this.getProficiencyTest();
      },
    });
  }

  getProficiencyTest() {
    const id = this.tokenService.getDriverApplicationId();
    this.executeRequest({
      state: this.getProficiencyTestState,
      request: this.apiService.getDriverApplicationProficiency(id),
      onSuccess: (response) => {
        let proficiencyInfo: any = response.data;
        let proficiencyData: any = response.data.proficiencyTestResultConductedJson;
        let driverName = arrayToSpaceSeparator([this.currentDrivingLicense?.firstName, this.currentDrivingLicense?.lastName]);

        this.proficiencyTestForm.controls['proficiencyTestConductedDate'].setValue(new Date(proficiencyInfo.proficiencyTestConductedDate));
        this.proficiencyTestForm.controls["proficiencyTestEquipmentType"].setValue(proficiencyInfo.proficiencyTestEquipmentType ?? "truck");
        this.proficiencyTestForm.controls["proficiencyTestExaminerFirstName"].setValue(proficiencyInfo.proficiencyTestExaminerFirstName);
        this.proficiencyTestForm.controls["proficiencyTestExaminerLastName"].setValue(proficiencyInfo.proficiencyTestExaminerLastName);
        this.proficiencyTestForm.controls["proficiencyTestExaminerSignature"].setValue(proficiencyInfo.proficiencyTestExaminerSignature);
        this.proficiencyTestForm.controls["equipmentCategory"].setValue(proficiencyData?.equipmentCategory);
        this.proficiencyTestForm.controls["busWithAirBrakes"].setValue(proficiencyData?.busWithAirBrakes);
        this.proficiencyTestForm.controls["busWithHydraulicBrakes"].setValue(proficiencyData?.busWithHydraulicBrakes);
        this.proficiencyTestForm.controls["numberOfPassengers"].setValue(proficiencyData?.numberOfPassengers);
        this.proficiencyTestForm.controls["transmission"].setValue(proficiencyData?.transmission);
        this.proficiencyTestForm.controls["airBrakes"].setValue(proficiencyData?.airBrakes);
        this.proficiencyTestForm.controls["hazmatEndorsement"].setValue(proficiencyData?.hazmatEndorsement);
        this.proficiencyTestForm.controls["specialEquipment"].setValue(proficiencyData?.specialEquipment);
        this.proficiencyTestForm.controls["specialEquipmentName"].setValue(proficiencyData?.specialEquipmentName);
        this.proficiencyTestForm.controls["driversName"].setValue(driverName ?? proficiencyData?.driversName);
        this.proficiencyTestForm.controls["powerUnit"].setValue(proficiencyData?.powerUnit);
        this.proficiencyTestForm.controls["trailer"].setValue(proficiencyData?.trailer);
        this.proficiencyTestForm.controls["distanceDriven"].setValue(proficiencyData?.distanceDriven);
      }
    });
  }

  onCompleteTest() {
    const driverApplicationId = this.tokenService.getDriverApplicationId();

    const proficiencyTestResultConductedJson = {
      proficiencyTestConductedDate: this.proficiencyTestForm.controls['proficiencyTestConductedDate'].value,
      proficiencyTestEquipmentType: this.proficiencyTestForm.controls['proficiencyTestEquipmentType'].value,
      proficiencyTestExaminerFirstName: this.proficiencyTestForm.controls['proficiencyTestExaminerFirstName'].value,
      proficiencyTestExaminerLastName: this.proficiencyTestForm.controls['proficiencyTestExaminerLastName'].value,
      proficiencyTestExaminerSignature: this.proficiencyTestForm.controls['proficiencyTestExaminerSignature'].value,
      equipmentCategory: this.proficiencyTestForm.controls['equipmentCategory'].value,
      busWithAirBrakes: this.proficiencyTestForm.controls['busWithAirBrakes'].value,
      busWithHydraulicBrakes: this.proficiencyTestForm.controls['busWithHydraulicBrakes'].value,
      numberOfPassengers: this.proficiencyTestForm.controls['numberOfPassengers'].value,
      transmission: this.proficiencyTestForm.controls['transmission'].value,
      airBrakes: this.proficiencyTestForm.controls['airBrakes'].value,
      hazmatEndorsement: this.proficiencyTestForm.controls['hazmatEndorsement'].value,
      specialEquipment: this.proficiencyTestForm.controls['specialEquipment'].value,
      specialEquipmentName: this.proficiencyTestForm.controls['specialEquipmentName'].value,
      driversName: this.proficiencyTestForm.controls['driversName'].value,
      powerUnit: this.proficiencyTestForm.controls['powerUnit'].value,
      trailer: this.proficiencyTestForm.controls['trailer'].value,
      distanceDriven: this.proficiencyTestForm.controls['distanceDriven'].value,
    };

    const request: CreateDriverApplicationProficiencyRequest = {
      driverApplicationId: driverApplicationId,
      proficiencyTestConductedDate: this.proficiencyTestForm.controls['proficiencyTestConductedDate'].value,
      proficiencyTestEquipmentType: this.proficiencyTestForm.controls['proficiencyTestEquipmentType'].value,
      proficiencyTestExaminerFirstName: this.proficiencyTestForm.controls['proficiencyTestExaminerFirstName'].value,
      proficiencyTestExaminerLastName: this.proficiencyTestForm.controls['proficiencyTestExaminerLastName'].value,
      proficiencyTestExaminerSignature: this.proficiencyTestForm.controls['proficiencyTestExaminerSignature'].value,
      proficiencyTestResultConductedJson: proficiencyTestResultConductedJson,
    };

    this.executeRequest({
      state: this.proficiencyTestState,
      request: this.apiService.createDriverApplicationProficiency(request)
    });
  }
}
