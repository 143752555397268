<div class="app-container !p-0">

  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'"
                            placeholder="Search..."
                            type="search"
                            [fullWidth]="false"
                            (changeValue)="onSearchTextChanged($event)">
    </app-outline-text-input>
    <div class="flex gap-3 items-center">
      <app-button iconSrc="assets/icons/plus.svg" (click)="onAddUserClicked()">New User</app-button>
    </div>
  </div>

  <div class="app-container-padding h-full overflow-auto">
    <app-base-table [state]="userListState" (pageChange)="getUserList($event)">
      <ng-container head>
        <th class="p-4 text-left" data-sortable-key="email">NAME</th>
        <th class="p-4 text-center" data-sortable-key="applicationDate">EMAIL</th>
        <th class="p-4 text-center" data-sortable-key="applicationDate">CONTACT</th>
        <th class="p-4 text-center" data-sortable-key="status">ROLE</th>
        <th class="p-4 text-center" data-sortable-key="invitedBy">CREATED DATE</th>
        <th class="p-4 text-center"></th>
      </ng-container>
      <ng-container body>
        @for (item of userListState.response()?.data; track item.id; ) {
          <tr class="border-b border-neutral-100 w-full">
            <td
              class="px-4 text-body2 text-left text-info-500 cursor-pointer">{{ item.firstName }} {{ item.lastName }}
            </td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.email }}</td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.phoneNumber }}</td>
            <td class="px-4 py-2 text-body2 text-center">
              <span class="py-2 px-3 rounded-full bg-info-50 text-info-700 text-nowrap">{{ item.roleName }}</span>
            </td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.createdDate | date: 'MMMM d, yyyy h:mm a' }}</td>
            <td>
              <app-context-menu-icon class="flex gap-4 items-center cursor-pointer"
                                     [matMenuTriggerFor]="menu"></app-context-menu-icon>
            </td>
          </tr>

          <!--          <app-context-menu menuId="userMenu" #userMenu>-->
            <!--            <app-context-menu-item label="Edit"></app-context-menu-item>-->
            <!--          </app-context-menu>-->

          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEditClicked(item)">Edit</button>
          </mat-menu>
        }
      </ng-container>
    </app-base-table>
  </div>
</div>
