import {Component, inject, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {TokenStoreService} from "@core/services/auth/token-store.service";
import {LayoutService} from "../../layout.service";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import type {GetUserResponse} from "@core/api/model";
import {BreadcrumbComponent} from "@shared/components/breadcrumb/breadcrumb.component";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {arrayToCSV, arrayToSpaceSeparator} from "@shared/utils/string-utils";

@Component({
  selector: "app-top-bar",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    CommonModule,
    MatMenu,
    MatMenuTrigger,
    BreadcrumbComponent,
  ],
  templateUrl: "./top-bar.component.html",
  styleUrl: "./top-bar.component.scss",
})
export class TopBarComponent extends BaseComponent implements OnInit {

  layoutService = inject(LayoutService);
  apiService = inject(ApiService);
  appDataService = inject(AppDataService);

  userInfoState = new State<GetUserResponse>();

  currentMenu = this.layoutService.currentMenu;

  ngOnInit() {
    this.getUserInfo();
  }

  onAccountClicked() {
    this.router.navigate(['/profile-settings']);
  }

  onLogoutClick() {
    this.tokenService.logout();
    this.router.navigate(['/login']);
  }

  getUserInfo() {
    this.executeRequest({
      state: this.userInfoState,
      request: this.apiService.getUserInfo(),
      onSuccess: (response) => {
        this.appDataService.setUserInfo(response.data);
        const userData = response.data;
        this.appDataService.setUserInfo(userData);
      }
    });
  }

  onCompanySettingsClicked() {
    this.router.navigate(['/company-settings']);
  }

  getUserName() {
    let userInfo = this.appDataService.userInfo();
    return arrayToSpaceSeparator([userInfo?.firstName, userInfo?.lastName]);
  }
}
