import {Component, HostListener, inject, OnInit, signal} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {DOCUMENT} from "@angular/common";
import {EnvironmentType} from "../../../../environments/environment-types";

@Component({
  selector: 'app-responsive-helper',
  standalone: true,
  imports: [],
  templateUrl: './responsive-helper.component.html',
  styleUrl: './responsive-helper.component.scss'
})
export class ResponsiveHelperComponent implements OnInit {
  public env: any = environment;
  document = inject(DOCUMENT);
  screenWidth = signal(0);
  protected readonly environment = environment;

  constructor() {
    this.screenWidth.set(this.document.documentElement.clientWidth);
  }

  ngOnInit(): void {
    this.document.addEventListener('resize', this.onResize.bind(this));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth.set(this.document.documentElement.clientWidth);
  }

  protected readonly EnvironmentType = EnvironmentType;
}
