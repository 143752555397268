<div class="bg-white h-screen overflow-hidden">
  <div class="grid grid-cols-1 lg:grid-cols-2 h-dvh w-screen">
    <div class="relative hidden items-center justify-center lg:flex bg-black bg-opacity-55">
      <div class="absolute inset-0 auth-bg"></div>
      <img [height]="34" class="absolute z-50 top-6 text-white"
           src="../../../../assets/images/branding/YSC-logo-white.svg" alt="Logo"/>
      <div class="absolute inset-0 bg-black bg-opacity-50"></div>
      <div class="z-10 max-w-2xl space-y-4 p-8 text-center text-white">
        <div class="flex flex-col gap-3">
          <h1 class="text-h4 text-center">Streamline Your Fleet Management</h1>
          <h1 class="text-h4 text-center">and Driver Compliance with Ease</h1>
        </div>
        <p class="text-body2">
          We help you stay compliant and ready for any FMCSA / DOT audit or inspection.
        </p>
      </div>
    </div>

    <div class="flex justify-center bg-white h-dvh overflow-y-scroll">
      <div class="grow mx-8 sm:mx-16 py-6 my-auto">
        <router-outlet/>
      </div>
    </div>
  </div>
</div>
