<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md px-4">
    <div class="grow overflow-auto p-4">
      <div class="flex flex-col gap-3">
        <p class="text-h6 text-info-500">Driving Experience</p>
        <p class="text-caption text-neutral-500">Please provide us your driving experience</p>

        <form [formGroup]="drivingExperienceForm">
          <div class="flex items-center gap-3 w-full">
            <span class="text-body2">Do you have experience driving a commercial vehicle?</span>
            <app-radio-button formControlName="hasCommercialDrivingExperience" groupName="hasCommercialDrivingExperience" title="Yes" [value]="true"></app-radio-button>
            <app-radio-button formControlName="hasCommercialDrivingExperience" groupName="hasCommercialDrivingExperience" title="No" [value]="false"></app-radio-button>
          </div>

          @if (drivingExperienceForm.controls['hasCommercialDrivingExperience'].value) {
            <div formArrayName="drivingExperiences">
              @for (group of drivingExperienceFormArray.controls; track group; let i = $index; let isLast = $last) {
                <div class="driving-experience-form flex flex-col gap-3 my-4" [formGroupName]="i">
                  <span class="text-body2 text-info-500">ADDITIONAL DRIVING EXPERIENCE</span>
                  <app-single-selection-field
                    formControlName="classOfEquipment"
                    title="Class of Equipment"
                    [items]="classOfEquipmentState.response()?.data ?? []"
                    [display]="'displayName'"
                    [value]="'name'"
                    [noDataMessage]="'No items available'"
                    [fullWidth]="true"
                  >
                  </app-single-selection-field>

                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-text-input formControlName="dateFrom" [label]="'Date From'" placeholder="mm/yyyy" mask="00/0000" class="w-full" [fullWidth]="true"> </app-text-input>
                    <app-text-input formControlName="dateTo" [label]="'Date To'" mask="00/0000" placeholder="mm/yyyy" class="w-full" [fullWidth]="true"> </app-text-input>
                  </div>
                  <div class="grid grid-cols-2 gap-4">
                    <app-text-input formControlName="approximateMiles" type="number" [label]="'Approx Miles'" placeholder="Ex: 3500" class="w-full" [fullWidth]="true"> </app-text-input>
                  </div>
                </div>
                @if(i > 0) {
                  <div class="flex justify-end">
                    <app-button appearance="outline" outlineColor="border-error-500" textButtonColor="text-error-500" (click)="onDeleteClicked(i)">Delete</app-button>
                  </div>
                } @if (!isLast) {
                  <div class="w-full h-[1px] bg-neutral-100 my-8"></div>
                } }
            </div>
            <div class="flex flex-col gap-4 my-3">
              <app-button iconSrc="assets/icons/plus.svg" appearance="outline" [fullWidth]="true" (click)="onAddExperienceClicked()">ADD MORE EXPERIENCE</app-button>
            </div>
          }
        </form>
      </div>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button type="submit" (click)="onSaveAndNextClicked()" [loading]="saveDrivingExperienceState.loading()">Save & Next </app-button>
    </div>
  </div>
</div>
