<app-base-overlay [title]="'Re-sign Document'">
  <div body>
    <div class="flex flex-col gap-3">
      <p class="text-body2">Due to updates made by the company in your application, you are required to re-sign the application to confirm the changes.</p>
      <p class="text-body2">Please ensure all the information is correct and sign to confirm the changes.</p>
      <div class="flex gap-3 items-center">
        <app-checkbox [(ngModel)]="isAgreed" title="I have verified my Job application"></app-checkbox>
      </div>
      <app-signature-pad (signatureChanged)="onSignatureChanged($event)"></app-signature-pad>
    </div>
  </div>

  <div actions>
    <div class="flex items-center justify-end gap-4">
      <app-button (click)="onCancelClicked()" appearance="outline">Cancel</app-button>
      <app-button (click)="onReSignClicked()" [loading]="reSignState.loading()" appearance="primary">Re-sign</app-button>
    </div>
  </div>
</app-base-overlay>
