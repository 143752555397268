import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "../../../../shared/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseDialogComponent} from "../../../../shared/components/_base/base-dialog/base-dialog.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {
  SingleSelectionFieldComponent
} from "../../../../shared/inputs/single-selection-field/single-selection-field.component";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "../../../../shared/inputs/outline-date-input/outline-date-input.component";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {State} from "../../../../shared/base/base-state";
import {ApiService} from "../../../../core/api/api.service";
import type {CreateDriverRequest, CreateDriverResponse} from "../../../../core/api/model";
import {DateUtils} from "../../../../shared/utils/date-utils";

@Component({
  selector: 'app-create-new-driver',
  standalone: true,
  imports: [
    BaseDialogComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    OutlineDateInputComponent,
    RadioButtonComponent
  ],
  templateUrl: './create-new-driver.component.html',
  styleUrl: './create-new-driver.component.scss'
})
export class CreateNewDriverComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  dialog = inject(MatDialog);
  dialogRef = inject(MatDialogRef);
  apiService = inject(ApiService);

  createDriverState = new State<CreateDriverResponse>();

  createDriverForm!: FormGroup;

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.createDriverForm = this.formBuilder.group({
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null, Validators.required],
      dateOfBirth: [null, Validators.required],
      dateOfHire: [null],
      hasUsWorkLegality: [true, Validators.required],
    });
  }


  onSaveClicked() {

    if (this.createDriverForm.invalid) {
      return;
    }

    let dateUtils = new DateUtils();

    let formValue = this.createDriverForm.value;

    let request: CreateDriverRequest = {
      firstName: formValue.firstName,
      middleName: formValue.middleName,
      lastName: formValue.lastName,
      phoneNumber: formValue.phoneNumber,
      email: formValue.email,
      dateOfBirth: dateUtils.convertToDateTimeString(formValue.dateOfBirth) ?? '',
      hiredDate: dateUtils.convertToDateTimeString(formValue.dateOfHire) ?? '',
      hasUsWorkLegality: formValue.hasUsWorkLegality
    };

    this.executeRequest<CreateDriverResponse>({
      state: this.createDriverState,
      request: this.apiService.createDriver(request),
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }
    });

  }

  onCancelClicked() {
    this.dialog.closeAll()
  }

  protected readonly InputDateFormat = InputDateFormat;
}
