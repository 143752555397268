<app-base-overlay [title]="dialogData ? 'Update Service Type' : 'Create Service Type'">
  <div body>
    <form [formGroup]="createOrUpdateServiceTypeForm">
      <div class="flex flex-col gap-4 justify-center items-center my-6 w-full">
        <app-text-input formControlName="name" [label]="'Name'" type="text"
                                class="w-full mx-6" placeholder="Ex: Brake Service" [fullWidth]="true"></app-text-input>
        <app-text-area-input formControlName="description" [label]="'Description'" type="text"
                                class="w-full mx-6" placeholder="Ex: Regular servicing of the braking system." [fullWidth]="true"></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'" [fullWidth]="true" (click)="onSaveClicked()"
                  [loading]="createServiceTypeState.loading() || updateServiceTypeState.loading()">
        {{ dialogData ? 'Update Service Type' : 'Create Service Type' }}
      </app-button>
    </div>
  </div>
</app-base-overlay>
