/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API
 * 
  <img src="https://dev-app.yoursafetycompliance.com/assets/images/branding/YSC-logo.png" alt="Safety Compliance" width="150"/>
  <h3>📌 Welcome to the <strong>Your Safety Compliance API Documentation (Company Module)</strong> 🚀</h3>
  
 * OpenAPI spec version: 1.0
 */

export type GetDriverApplicationListItemApplicationStatusEnum = typeof GetDriverApplicationListItemApplicationStatusEnum[keyof typeof GetDriverApplicationListItemApplicationStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetDriverApplicationListItemApplicationStatusEnum = {
  Applied: 'Applied',
  Invited: 'Invited',
  ApplicationStarted: 'ApplicationStarted',
  DriverVerificationPending: 'DriverVerificationPending',
  NeedsApproval: 'NeedsApproval',
  PendingReview: 'PendingReview',
  ResubmissionRequested: 'ResubmissionRequested',
  ReVerificationPending: 'ReVerificationPending',
  Hired: 'Hired',
  Rejected: 'Rejected',
} as const;
