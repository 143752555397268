import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "../../../../shared/base/base-component";
import {
  OutlineTextInputComponent
} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {SignaturePadComponent} from "../../../../core/components/signature-pad/signature-pad.component";
import {CheckboxComponent} from "../../../../shared/inputs/checkbox/checkbox.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {State} from "../../../../shared/base/base-state";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";
import {ApiService} from "../../../../core/api/api.service";
import type {
  CreateDriverApplicationProficiencyRequest,
  CreateDriverApplicationProficiencyResponse, GetDriverApplicationProficiencyResponse
} from "../../../../core/api/model";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {DateUtils} from "../../../../shared/utils/date-utils";

@Component({
  selector: 'app-proficiency-test',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    OutlineTextInputComponent,
    SignaturePadComponent,
    CheckboxComponent,
    ButtonComponent,
    RadioButtonComponent
  ],
  templateUrl: './proficiency-test.component.html',
  styleUrls: ['./proficiency-test.component.scss']
})
export class ProficiencyTestComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  proficiencyTestState = new State<CreateDriverApplicationProficiencyResponse>();
  getProficiencyTestState = new State<GetDriverApplicationProficiencyResponse>();
  proficiencyTestForm!: FormGroup;

  ngOnInit() {
    this.initForm();
    this.getProficiencyTest();
  }

  private initForm() {
    this.proficiencyTestForm = this.formBuilder.group({
      proficiencyTestEquipmentType: ["truck", Validators.required],
      equipmentCategory: ["straightTruck", Validators.required],
      busWithAirBrakes: [true, Validators.required],
      busWithHydraulicBrakes: [true, Validators.required],
      numberofPassengers: ["", Validators.required],
      transmission: ["standardShiftTransmission", Validators.required],
      airBrakes: [true, Validators.required],
      hazmatEndorsement: [true, Validators.required],
      specialEquipment: [true, Validators.required],
      specialEquipmentName: ["", Validators.required],
      driversName: ["", Validators.required],
      powerUnit: ["", Validators.required],
      trailer: ["", Validators.required],
      proficiencyTestConductedDate: ["", Validators.required],
      distanceDriven: ["", Validators.required],
      proficiencyTestExaminerFirstName: ["", Validators.required],
      proficiencyTestExaminerLastName: ["", Validators.required],
      proficiencyTestExaminerSignature: ["", Validators.required],
      proficiencyTestResultConductedJson: ["", Validators.required],
    });
  }

  onSignatureCleared() {
    this.proficiencyTestForm.controls['proficiencyTestExaminerSignature'].setValue(null);
  }

  onSignatureSaved(signatureBase64: string) {
    this.proficiencyTestForm.controls["proficiencyTestExaminerSignature"].setValue(signatureBase64);
  }

  getProficiencyTest() {
    const id = this.tokenService.getDriverApplicationId();
    this.executeRequest({
      state: this.getProficiencyTestState,
      request: this.apiService.getDriverApplicationProficiency(id),
      onSuccess: (response) => {
        let proficiencyInfo = response.data;
        let proficiencyData = response.data.proficiencyTestResultConductedJson;
        let dateUtils = new DateUtils();
        let testConductedDate = dateUtils.convertToMMDDYYYY(response.data.proficiencyTestConductedDate);
        this.proficiencyTestForm.controls['proficiencyTestConductedDate'].setValue(testConductedDate);
        this.proficiencyTestForm.controls["proficiencyTestEquipmentType"].setValue(proficiencyInfo.proficiencyTestEquipmentType);
        this.proficiencyTestForm.controls["proficiencyTestExaminerFirstName"].setValue(proficiencyInfo.proficiencyTestExaminerFirstName);
        this.proficiencyTestForm.controls["proficiencyTestExaminerLastName"].setValue(proficiencyInfo.proficiencyTestExaminerLastName);
        this.proficiencyTestForm.controls["proficiencyTestExaminerSignature"].setValue(proficiencyInfo.proficiencyTestExaminerSignature);
        this.proficiencyTestForm.controls["equipmentCategory"].setValue(proficiencyData["equipmentCategory"]);
        this.proficiencyTestForm.controls["busWithAirBrakes"].setValue(proficiencyData["busWithAirBrakes"]);
        this.proficiencyTestForm.controls["busWithHydraulicBrakes"].setValue(proficiencyData["busWithHydraulicBrakes"]);
        this.proficiencyTestForm.controls["numberofPassengers"].setValue(proficiencyData["numberofPassengers"]);
        this.proficiencyTestForm.controls["transmission"].setValue(proficiencyData["transmission"]);
        this.proficiencyTestForm.controls["airBrakes"].setValue(proficiencyData["airBrakes"]);
        this.proficiencyTestForm.controls["hazmatEndorsement"].setValue(proficiencyData["hazmatEndorsement"]);
        this.proficiencyTestForm.controls["specialEquipment"].setValue(proficiencyData["specialEquipment"]);
        this.proficiencyTestForm.controls["specialEquipmentName"].setValue(proficiencyData["specialEquipmentName"]);
        this.proficiencyTestForm.controls["driversName"].setValue(proficiencyData["driversName"]);
        this.proficiencyTestForm.controls["powerUnit"].setValue(proficiencyData["powerUnit"]);
        this.proficiencyTestForm.controls["trailer"].setValue(proficiencyData["trailer"]);
        this.proficiencyTestForm.controls["distanceDriven"].setValue(proficiencyData["distanceDriven"]);
      }
    });
  }

  onCompleteTest() {
    const driverApplicationId = this.tokenService.getDriverApplicationId();

    const proficiencyTestResultConductedJson = {
      proficiencyTestConductedDate: this.proficiencyTestForm.controls['proficiencyTestConductedDate'].value,
      proficiencyTestEquipmentType: this.proficiencyTestForm.controls['proficiencyTestEquipmentType'].value,
      proficiencyTestExaminerFirstName: this.proficiencyTestForm.controls['proficiencyTestExaminerFirstName'].value,
      proficiencyTestExaminerLastName: this.proficiencyTestForm.controls['proficiencyTestExaminerLastName'].value,
      proficiencyTestExaminerSignature: this.proficiencyTestForm.controls['proficiencyTestExaminerSignature'].value,
      equipmentCategory: this.proficiencyTestForm.controls['equipmentCategory'].value,
      busWithAirBrakes: this.proficiencyTestForm.controls['busWithAirBrakes'].value,
      busWithHydraulicBrakes: this.proficiencyTestForm.controls['busWithHydraulicBrakes'].value,
      numberofPassengers: this.proficiencyTestForm.controls['numberofPassengers'].value,
      transmission: this.proficiencyTestForm.controls['transmission'].value,
      airBrakes: this.proficiencyTestForm.controls['airBrakes'].value,
      hazmatEndorsement: this.proficiencyTestForm.controls['hazmatEndorsement'].value,
      specialEquipment: this.proficiencyTestForm.controls['specialEquipment'].value,
      specialEquipmentName: this.proficiencyTestForm.controls['specialEquipmentName'].value,
      driversName: this.proficiencyTestForm.controls['driversName'].value,
      powerUnit: this.proficiencyTestForm.controls['powerUnit'].value,
      trailer: this.proficiencyTestForm.controls['trailer'].value,
      distanceDriven: this.proficiencyTestForm.controls['distanceDriven'].value,
    };

    const request: CreateDriverApplicationProficiencyRequest = {
      driverApplicationId: driverApplicationId,
      proficiencyTestConductedDate: this.proficiencyTestForm.controls['proficiencyTestConductedDate'].value,
      proficiencyTestEquipmentType: this.proficiencyTestForm.controls['proficiencyTestEquipmentType'].value,
      proficiencyTestExaminerFirstName: this.proficiencyTestForm.controls['proficiencyTestExaminerFirstName'].value,
      proficiencyTestExaminerLastName: this.proficiencyTestForm.controls['proficiencyTestExaminerLastName'].value,
      proficiencyTestExaminerSignature: this.proficiencyTestForm.controls['proficiencyTestExaminerSignature'].value,
      proficiencyTestResultConductedJson: proficiencyTestResultConductedJson,
    };

    this.executeRequest({
      state: this.proficiencyTestState,
      request: this.apiService.createDriverApplicationProficiency(request),
      onSuccess: (response) => {
        console.log("Proficiency test submitted successfully:", response);
      }
    });
  }

}
