import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@shared/base/base-component";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import type {
  CreateServiceTypeRequest,
  CreateServiceTypeResponse,
  GetServiceType,
  UpdateServiceTypeRequest, UpdateServiceTypeResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-create-or-update-service-type',
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    ButtonComponent,
    TextAreaInputComponent,
    BaseOverlayComponent
  ],
  templateUrl: './create-or-update-service-type.component.html',
  standalone: true,
  styleUrl: './create-or-update-service-type.component.scss'
})
export class CreateOrUpdateServiceTypeComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  dialogRef = inject(DialogRef);
  dialogData?: GetServiceType = inject(DIALOG_DATA);

  createOrUpdateServiceTypeForm!: FormGroup;

  createServiceTypeState = new State<CreateServiceTypeResponse>();
  updateServiceTypeState = new State<UpdateServiceTypeResponse>();


  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.createOrUpdateServiceTypeForm = this.formBuilder.group({
      name: [this.dialogData?.name ?? '', Validators.required],
      description: [this.dialogData?.description ?? ''],
    });
  }

  onSaveClicked() {
    this.validateForm(this.createOrUpdateServiceTypeForm);
    if (!this.createOrUpdateServiceTypeForm.valid) {
      return;
    }

    if (this.dialogData) {
      this.update();
    } else {
      this.create();
    }

  }

  private create() {
    let formValue = this.createOrUpdateServiceTypeForm.value;
    let request: CreateServiceTypeRequest = {
      description: formValue.description,
      name: formValue.name,
    }
    this.executeRequest<CreateServiceTypeResponse>({
      state: this.createServiceTypeState,
      request: this.apiService.createServiceType(request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  private update() {
    let formValue = this.createOrUpdateServiceTypeForm.value;
    let request: UpdateServiceTypeRequest = {
      name: formValue.name,
      description: formValue.description
    };

    this.executeRequest<UpdateServiceTypeResponse>({
      state: this.updateServiceTypeState,
      request: this.apiService.updateServiceType(this.dialogData?.id ?? '', request),
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
