<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">
      <div class="flex gap-5 justify-end items-center ml-auto">
        <div class="m-2 flex gap-2">
          <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                                  [fullWidth]="false"></app-outline-text-input>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        @for (documentSection of driverDocumentsState.response()?.data; track documentSection.sectionName; ) {

          <span class="text-body1 text-primary-900">{{ documentSection.sectionName }}</span>

          <div class="flex flex-col gap-3">
            @for (document of documentSection.documents; track document) {
              <div class="px-4 py-1.5 border border-neutral-100 shadow-1 w-full rounded-lg">
                <div class="flex items-center gap-4">
                  <div class="p-5 w-fit rounded-lg bg-info-100">
                    <app-svg-icon [src]="'assets/icons/notice.svg'" class="text-info-700"></app-svg-icon>
                  </div>

                  <div class="flex flex-col gap-2 w-full">
                    <div class="flex items-center">
                      <span class="text-body2 font-semibold">{{ document.documentName }}</span>
                      <span class="text-caption text-neutral-500 ml-auto">Uploaded
                        on {{ document.uploadFrequency | date:'MMM d, y' }}</span>
                      <app-button class="p-0" [iconSrc]="'assets/icons/bin.svg'" [appearance]="'textType'"
                                  [iconColor]="'text-error-500'" [textButtonColor]="'text-error-500'">Delete
                      </app-button>
                    </div>

                    <div class="flex justify-between">

                      <div class="flex items-center gap-4">
                        @if (document.hasCompletedDate) {
                          <div class="p-2 bg-success-50 rounded-lg flex items-center">
                            <p class="text-caption text-success-500">Completed
                              on: {{ document.completedDate | date:'MMM d, y' }}</p>
                            <app-svg-icon [src]="'assets/icons/info.svg'" [size]="18"
                                          class="text-neutral-500 ml-1"></app-svg-icon>
                          </div>
                        }
                        @if (document.hasExpiryDate) {
                          <div class="p-2 bg-info-50 rounded-lg flex items-center">
                            <span class="text-caption text-info-700">Expires in: {{ document.expiryDate }}</span>
                            <app-svg-icon [src]="'assets/icons/info.svg'" [size]="18"
                                          class="text-neutral-500 ml-1"></app-svg-icon>
                          </div>
                        }
                      </div>

                      <div class="flex items-center gap-4 ml-auto">
                        <div class="flex items-center gap-2">
                          <app-button [iconSrc]="'assets/icons/eye.svg'" [appearance]="'textType'"
                                      [iconColor]="'text-info-500'" [textButtonColor]="'text-info-500'">View
                          </app-button>
                          <app-button [iconSrc]="'assets/icons/upload.svg'" [appearance]="'textType'"
                                      [iconColor]="'text-info-500'" [textButtonColor]="'text-info-500'"
                                      (click)="onUploadClicked(document)">
                            Upload
                          </app-button>
                          <app-button [iconSrc]="'assets/icons/download.svg'" [appearance]="'textType'"
                                      [iconColor]="'text-info-500'" [textButtonColor]="'text-info-500'">Download
                          </app-button>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                @if (document.notes) {
                  <span class="text-neutral-500 py-1">Note: {{ document.notes }}</span>
                }
              </div>
            }
          </div>

        }
      </div>


    </div>
  </div>
</div>
