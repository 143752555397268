<div class="px-4 space-y-4">
  <img src="../../../../../assets/images/branding/YSC-logo.png" />
  <div class="py-6 space-y-4">
    <p class="text-h6">Log in to your account</p>
    <p class="text-body2">Welcome back!</p>
  </div>
  <form [formGroup]="loginForm" (ngSubmit)="onLoginClicked()">
    <div class="flex flex-col gap-3">
      <app-outline-text-input formControlName="username" [label]="'Email'" placeholder="Ex: john.bravo@company.com" class="w-full" [fullWidth]="true"></app-outline-text-input>
      <app-outline-text-input [type]="'password'" formControlName="password" [label]="'Password'" placeholder="*********" class="w-full" [fullWidth]="true"></app-outline-text-input>
      <div class="flex justify-end text-body2 text-info-500 py-4 cursor-pointer" (click)="onForgotPasswordClicked()">
        Forgot Password?
      </div>
    </div>
    <app-button [fullWidth]="true" type="submit" [loading]="loginState.loading()">Login</app-button>
    <div class="flex justify-center items-center py-6 gap-2">
      <p class="text-body2">Don't have an account?</p>
      <a routerLink="/sign-up" class="cursor-pointer text-info-500">Create an account</a>
    </div>
  </form>
</div>
