import {
  Component,
  inject,
  OnInit,
} from "@angular/core";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {CommonModule} from "@angular/common";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {
  DriverApplicationResponse,
  DriverApplication,
} from "../../../../core/services/driver-application/application/driver-application-response";
import {State} from "../../../../shared/base/base-state";
import {BaseTableComponent} from "../../../../shared/components/_base/base-table/base-table.component";
import {ContextMenuIconComponent} from "../../../../shared/components/context-menu-icon/context-menu-icon.component";
import {DriverApplicationService} from "../../../../core/services/driver-application/driver-application.service";
import {TextButtonSmall} from "../../../../shared/components/text-button-small/text-button-small.component";
import {
  TableResizableColumnsDirective
} from "../../../../shared/components/_base/base-table/table-resizable-columns.directive";
import {
  SortableTableDirective,
  TableSortEvent,
} from "../../../../shared/components/_base/base-table/sortable-table.directive";
import {
  SendDriverApplicationComponent
} from "../../../drivers/overlays/send-driver-application/send-driver-application.component";
import {Constants} from "../../../../core/constants/constants";
import {
  DriverApplicationStatusMessageComponent
} from "./driver-application-status-message/driver-application-status-message.component";
import type {
  DownloadDriverApplicationFormResponse,
  ExportXlsxDriverApplicationSignedUrlResponse,
  GetDriverApplicationListParams, GetDriverApplicationListResponse,
  MarkDriverApplicationRejectedRequest,
  MarkDriverApplicationRejectedResponse,
  MarkDriverApplicationResubmissionRequest, MarkDriverApplicationResubmissionResponse,
  MarkDriverApplicationUnderVerificationRequest,
  MarkDriverApplicationUnderVerificationResponse
} from "../../../../core/api/model";
import {PaginationEvent} from "../../../../shared/components/pagination/pagination.component";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {ApiService} from "../../../../core/api/api.service";


@Component({
  selector: "app-driver-application-list",
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    OutlineTextInputComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    BaseTableComponent,
    ContextMenuIconComponent,
    TextButtonSmall,
    SortableTableDirective,
  ],
  templateUrl: "./driver-application-list.component.html",
  styleUrl: "./driver-application-list.component.scss",
})
export class DriverApplicationListComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  driverService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);
  apiService = inject(ApiService);

  applicationState = new State<GetDriverApplicationListResponse>();
  markApplicationUnderVerificationState = new State<MarkDriverApplicationUnderVerificationResponse>();
  markApplicationRejectedState = new State<MarkDriverApplicationRejectedResponse>();
  markDriverApplicationResubmissionState = new State<MarkDriverApplicationResubmissionResponse>()
  exportXlsxDriverApplicationSignedUrlState = new State<ExportXlsxDriverApplicationSignedUrlResponse>();
  exportDriverApplicationSignedUrlState = new State<DownloadDriverApplicationFormResponse>();

  ngOnInit(): void {
    this.getDriverApplications(1, 10);
  }

  getApplicationStatus(applicationStatus: string) {
    switch (applicationStatus) {
      case "Invited": {
        return ["Reject",];
      }
      case "Application Started": {
        return ["Reject", "Invite"];
      }
      case "Needs Approval": {
        return [
          "Hire",
          "Under Verification",
          "Request to Re-submit",
          "Reject"
        ];
      }
      case "Under Verification": {
        return ["Hire", "Request to Re-submit", "Reject",];
      }
      case "Re-submit": {
        return [
          "Hire",
          "Re-Verification Pending",
          "Mark under verification",
          "Reject",
        ];
      }
      case "Re-verification Pending": {
        return [
          "Hire",
          "Request to Re-submit",
          "Mark under verification",
          "Reject",
        ];
      }
      case "Resubmission Requested": {
        return ["Hire", "Re-verification Pending", "Under Verification", "Reject"]
      }
      default: {
        return [];
      }
    }
  }

  onStatusChange(item: DriverApplication, newStatus: string) {
    if (newStatus === "Invite") {
      item.applicationStatus = "Invited";
    } else if (newStatus === "Hire") {
      item.applicationStatus = "Hired";
    } else if (newStatus === "Reject") {
      item.applicationStatus = "Rejected";
      this.markApplicationRejected(item.id);
    } else if (newStatus === "Under Verification") {
      item.applicationStatus = "Under Verification";
      this.markApplicationUnderVerification(item.id);
    }

    if (newStatus === "Hire") {
      this.router.navigate(['/hire-driver'], {
        queryParams: {applicationId: item.id},
      });
    } else {
      this.dialog
        .open(DriverApplicationStatusMessageComponent, {
          ...Constants.defaultDialogConfig,
        })
        .afterClosed()
        .subscribe(() => {
          item.applicationStatus = newStatus;
        });
    }
    this.getDriverApplications(1, 10);
  }


  private markDriverApplicationResubmission(driverApplicationId: string) {
    let request: MarkDriverApplicationResubmissionRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId() ?? "",
    }
    this.executeRequest({
      state: this.markDriverApplicationResubmissionState,
      request: this.apiService.markDriverApplicationResubmission(request)
    })
  }

  private markApplicationUnderVerification(driverApplicationId: string) {
    let request: MarkDriverApplicationUnderVerificationRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId()
    }
    this.executeRequest({
      state: this.markApplicationUnderVerificationState,
      request: this.apiService.markDriverApplicationUnderVerification(request)
    })
  }


  private markApplicationRejected(driverApplicationId: string) {
    let request: MarkDriverApplicationRejectedRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId()
    }
    this.executeRequest({
      state: this.markApplicationRejectedState,
      request: this.apiService.markDriverApplicationRejected(request)
    });
  }

  onSendDriverApplicationClicked() {
    this.dialog.open(SendDriverApplicationComponent, {
      ...Constants.defaultDialogConfig,
    }).afterClosed().subscribe(() => {
      this.getDriverApplications(1, 10);
    });
  }

  getDriverApplications(pageNumber: number, pageSize: number) {
    const request: GetDriverApplicationListParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    this.executeRequest<GetDriverApplicationListResponse>({
      state: this.applicationState,
      request: this.apiService.getDriverApplicationList(request),
    });
  }

  onPageChange($event: PaginationEvent) {
    this.getDriverApplications($event.pageNumber, $event.pageSize);
  }

  onEmailClick(application: DriverApplication) {
    this.router.navigate([`/driver-application/apply`], {
      queryParams: {applicationId: application.id},
    }).then();
  }

  sortData($event: TableSortEvent) {

  }

  onExportClicked() {
    this.executeRequest<ExportXlsxDriverApplicationSignedUrlResponse>({
      request: this.apiService.exportXlsxDriverApplicationSignedUrl(),
      state: this.exportXlsxDriverApplicationSignedUrlState,
      onSuccess: (response) => {
        if (response.data.downloadUrl) {
          window.open(response.data.downloadUrl, "_blank");
        }
      }
    });
  }

  onDownloadDriverApplicationClicked() {
    this.executeRequest<DownloadDriverApplicationFormResponse>({
      request: this.apiService.downloadDriverApplicationFormSignedUrl(),
      state: this.exportDriverApplicationSignedUrlState,
      onSuccess: (response) => {
        if (response.data.downloadUrl) {
          window.open(response.data.downloadUrl, "_blank");
        }
      }
    });
  }
}
