import {Component, OnInit, inject} from "@angular/core";
import {Clipboard, ClipboardModule} from '@angular/cdk/clipboard';
import {ButtonComponent} from "@shared/components/button/button.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {BaseComponent} from "@shared/base/base-component";
import {SendDriverApplicationResponse} from "@core/api/model";
import {environment} from "../../../../../environments/environment";
import {EnvironmentType} from "../../../../../environments/environment-types";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: "app-driver-application-sent-confirmation",
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    ClipboardModule,
    BaseOverlayComponent
  ],
  templateUrl: "./driver-application-sent-confirmation.component.html",
  styleUrl: "./driver-application-sent-confirmation.component.scss",
})
export class DriverApplicationSentConfirmationComponent extends BaseComponent implements OnInit {

  data = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);
  clipboard = inject(Clipboard);

  sendDriverApplicationResponse: SendDriverApplicationResponse | null = null;

  ngOnInit() {
    this.sendDriverApplicationResponse = this.data.message;
  }

  getApplicationInviteUrl(): string {
    let token = this.sendDriverApplicationResponse?.data?.token;
    let url = '';
    switch (environment.environment) {
      case EnvironmentType.LOCAL:
        url = `http://localhost:4200/driver-application/apply?token=${token}`;
        break;
      case EnvironmentType.DEVELOPMENT:
        url = `https://dev-app.yoursafetycompliance.com/driver-application/apply?token=${token}`;
        break;
      case EnvironmentType.UAT:
        url = `https://uat-app.yoursafetycompliance.com/driver-application/apply?token=${token}`;
        break;
      case EnvironmentType.PRODUCTION:
        url = `https://app.yoursafetycompliance.com//driver-application/apply?token=${token}`;
        break;
    }

    return url;
  }

  onCopyClicked() {
    let url = this.getApplicationInviteUrl();
    this.clipboard.copy(url);
    this.toasterService.success('Link copied successfully.');
  }

  onCloseClicked() {
    this.dialogRef.close();
  }
}
