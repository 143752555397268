import {Component, inject} from '@angular/core';

import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {
  GetDriverApplicationListItem,
  GetDriverApplicationListItemApplicationStatusEnum,
  MarkDriverApplicationPendingReviewRequest,
  MarkDriverApplicationPendingReviewResponse,
} from "@core/api/model";
import {DriverApplication} from "@core/services/driver-application/application/driver-application-response";
import {Router} from "@angular/router";
import {State} from "@shared/base/base-state";
import {TokenStoreService} from "@core/services/auth/token-store.service";
import {ApiService} from "@core/api/api.service";
import {BaseRequestService} from "@shared/base/base-request.service";

import {
  DriverApplicationStatusMessageComponent
} from "../driver-application-status-message/driver-application-status-message.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {ContextMenuAction} from "@shared/components/context-menu/context-menu.component";
import {ContextMenuButtonComponent} from "@shared/components/context-menu-button/context-menu-button.component";

@Component({
  selector: 'app-table-cell-driver-application-status',
  imports: [
    ContextMenuButtonComponent
],
  templateUrl: './table-cell-driver-application-actions.component.html',
  standalone: true,
  styleUrl: './table-cell-driver-application-actions.component.scss'
})
export class TableCellDriverApplicationActionsComponent extends TableCustomComponent<GetDriverApplicationListItem> {

  router = inject(Router);
  overlayService = inject(OverlayService);
  requestService = inject(BaseRequestService);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);

  markApplicationUnderVerificationState = new State<MarkDriverApplicationPendingReviewResponse>();

  getApplicationStatus(applicationStatus: GetDriverApplicationListItemApplicationStatusEnum): DriverApplicationStatusAction[] {
    switch (applicationStatus) {
      case GetDriverApplicationListItemApplicationStatusEnum.Invited:
        return [
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.ApplicationStarted:
        return [
          DriverApplicationStatusAction.reject,
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.DriverVerificationPending:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.NeedsApproval:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.pendingReview,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.reject
        ];

      case GetDriverApplicationListItemApplicationStatusEnum.PendingReview:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.ResubmissionRequested:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.pendingReview,
          // DriverApplicationStatusAction.reVerificationPending,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.reject
        ]
      case GetDriverApplicationListItemApplicationStatusEnum.ReVerificationPending:
        return [
          DriverApplicationStatusAction.hire,
          DriverApplicationStatusAction.requestToResubmit,
          DriverApplicationStatusAction.pendingReview,
          DriverApplicationStatusAction.reject
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.Hired:
        return [
          DriverApplicationStatusAction.hire,
        ];
      case GetDriverApplicationListItemApplicationStatusEnum.Rejected:
        return [];
      default:
        return [];

    }
  }

  onStatusChange(item: DriverApplication, status: string) {
    switch (status) {
      case DriverApplicationStatusAction.hire: {
        this.router.navigate(['/hire-driver'], {queryParams: {applicationId: item.id},});
        break;
      }
      case DriverApplicationStatusAction.pendingReview:
        this.markApplicationUnderVerification(item);
        break;
      case DriverApplicationStatusAction.reVerificationPending:

        break;
      case DriverApplicationStatusAction.requestToResubmit:
        this.showStatsChangeDialog(item, DriverApplicationStatusAction.requestToResubmit);
        break;
      case DriverApplicationStatusAction.reject: {
        this.showStatsChangeDialog(item, DriverApplicationStatusAction.reject);
        break;
      }
    }
  }

  private markApplicationUnderVerification(item: DriverApplication) {
    let request: MarkDriverApplicationPendingReviewRequest = {
      driverApplicationId: item.id
    };
    this.requestService.executeRequest({
      state: this.markApplicationUnderVerificationState,
      showLoader: true,
      request: this.apiService.markDriverApplicationPendingReview(request),
      onSuccess: (response) => {
        this.actionPerformed.emit({item: item, actionKey: 'statusChange'});
      }
    });
  }


  private showStatsChangeDialog(item: DriverApplication, status: DriverApplicationStatusAction) {
    let dialogRef = this.overlayService.openModal(DriverApplicationStatusMessageComponent, {
      data: {application: item, changeToStatus: status}
    });
    dialogRef.closed.subscribe((isSuccess) => {
      if (isSuccess) {
        this.actionPerformed.emit({item: item, actionKey: 'statusChange'});
      }
    });
  }

  get statusActions(): ContextMenuAction[] {
    return this.getApplicationStatus(this.rowData.applicationStatusEnum).map(status => ({
      label: status,
      actionKey: status
    }));
  }

  handleStatusChange(actionType: string) {
    this.onStatusChange(this.rowData, actionType);
  }


}

export enum DriverApplicationStatusAction {
  hire = 'Hire',
  pendingReview = 'Pending Review',
  requestToResubmit = 'Request to Re-submit',
  reVerificationPending = 'Re-verification Pending',
  reject = 'Reject',
}
