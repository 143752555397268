<app-base-overlay [title]="'Test Overlay'">
  <div body>
    <div class="flex flex-col justify-center gap-10">
      <p class="">1</p>
      <p class="">2</p>
      <p class="">3</p>
      <p class="">4</p>
      <p class="">5</p>
      <p class="">6</p>
      <p class="">7</p>
      <p class="">8</p>
      <p class="">9</p>
      <p class="">10</p>
      <p class="">11</p>
      <p class="">12</p>
      <p class="">13</p>
      <p class="">14</p>
      <p class="">15</p>
      <p class="">16</p>
    </div>
  </div>

  <div actions>
    <div class="flex justify-end items-center">
      <app-button>Submit</app-button>
    </div>
  </div>

</app-base-overlay>
