import {Injectable, signal} from '@angular/core';
import {
  GetActiveTerminal,
  GetCompanyUserInfo,
  GetTerminalListItem,
  type GetUserInfo,
  GetUserResponse
} from "@core/api/model";

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  static IS_SIDEBAR_EXPANDED = "is_sidebar_expanded";
  static SELECTED_TERMINAL = 'selected_terminal';
  static USER_INFO = 'user_info';
  static COMPANY_USER_INFO = 'company_user_info';

  storage: Storage;

  isSidebarExpanded = signal(true);
  selectedTerminal = signal<GetActiveTerminal | null>(null);
  userInfo = signal<GetUserInfo | null>(null);
  companyUserInfo = signal<GetCompanyUserInfo | null>(null);

  constructor() {
    this.storage = localStorage;
    const isSidebarExpanded = this.storage.getItem(AppDataService.IS_SIDEBAR_EXPANDED);
    switch (isSidebarExpanded) {
      case "true":
        this.isSidebarExpanded.update(() => true);
        break;
      case "false":
        this.isSidebarExpanded.update(() => false);
        break;
      default:
        this.isSidebarExpanded.update(() => true);
        break;
    }

    this.selectedTerminal.set(JSON.parse(this.storage.getItem(AppDataService.SELECTED_TERMINAL) || 'null'));
    this.userInfo.set(JSON.parse(this.storage.getItem(AppDataService.USER_INFO) || 'null'));
    this.companyUserInfo.set(JSON.parse(this.storage.getItem(AppDataService.COMPANY_USER_INFO) || 'null'));
  }

  toggleSidebar() {
    this.isSidebarExpanded.update((curr) => !curr);
    this.storage.setItem(AppDataService.IS_SIDEBAR_EXPANDED, this.isSidebarExpanded().toString());
  }

  saveSelectedTerminal(terminal: GetActiveTerminal | null) {
    if (terminal) {
      this.selectedTerminal.update(() => terminal);
      this.storage.setItem(AppDataService.SELECTED_TERMINAL, JSON.stringify(terminal));
    } else {
      this.selectedTerminal.set(null);
      this.storage.removeItem(AppDataService.SELECTED_TERMINAL);
    }
  }

  reset() {
    this.selectedTerminal.set(null);
  }

  setUserInfo(data: GetUserInfo) {
    this.userInfo.set(data);
    this.storage.setItem(AppDataService.USER_INFO, JSON.stringify(data));
  }

  saveCompanyUserInfo(data: GetCompanyUserInfo) {
    this.companyUserInfo.set(data);
    this.storage.setItem(AppDataService.COMPANY_USER_INFO, JSON.stringify(data));
  }
}

export interface SelectedTerminal {
  id: string;
  name: string;
}
