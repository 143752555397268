import {
  AfterContentInit, AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  input, OnDestroy,
  output,
  Output, signal,
  ViewChild
} from '@angular/core';
import {AbstractControl, FormControl, NgControl, ReactiveFormsModule} from "@angular/forms";
import {NgClass} from "@angular/common";
import {CommonModule} from '@angular/common';
import {HumanizeFormMessagesPipe} from "../humanize-form-messages.pipe";
import {AppSvgIconComponent} from "../../components/app-svg-icon/app-svg-icon.component";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";
import {BaseInputComponent} from "../_base/base-input/base-input.component";
import {NgxMaskDirective} from "ngx-mask";
import {debounceTime, Subject} from "rxjs";

@Component({
  selector: 'app-outline-text-input',
  standalone: true,
  imports: [
    NgClass,
    ReactiveFormsModule,
    HumanizeFormMessagesPipe,
    AppSvgIconComponent,
    CommonModule,
    BaseInputComponent,
    NgxMaskDirective
  ],
  templateUrl: './outline-text-input.component.html',
  styleUrl: './outline-text-input.component.scss'
})
export class OutlineTextInputComponent extends BaseControlValueAccessor implements AfterContentInit, AfterViewInit, OnDestroy {

  appearance = input<'fill' | 'outline'>('outline');
  type = input<'text' | 'email' | 'password' | 'number' | 'search' | 'tel' | 'url'>('text');
  viewType = input<'text' | 'text-area'>('text');
  iconSrc = input<string | null>();
  actionIcon = input<string | null>();
  label = input<string | null>();
  fullWidth = input<boolean>(false);
  placeholder = input<string>('');
  errorMessages = input<{ [key: string]: string }>({});
  showErrorSpace = input<boolean>(false);
  mask = input<string | null>();
  debounceSearchEnabled = input<boolean>(true);

  changeValue = output<string>();
  actionIconClicked = output();

  isFocused = signal(false);

  ngControl = inject(NgControl, {optional: true, self: true});
  override formControl: any;

  private searchSubject: Subject<string> = new Subject<string>();

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  override onWriteValue(value: any): void {

  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  ngAfterViewInit(): void {
    if(this.debounceSearchEnabled()) {
      this.searchSubject.pipe(debounceTime(600)).subscribe((value: string) => {
        this.changeValue.emit(value);  // Emit the value after debounce
      });
    }
  }

  ngOnDestroy(): void {
    try {
      this.searchSubject.complete();
    } catch (e) {
    }
  }

  onValueChange(event: any) {
    const target = event.target as HTMLInputElement;
    this.onChange(target.value);
    if(this.debounceSearchEnabled()) {
      this.searchSubject.next(target.value);
    } else {
      this.changeValue.emit(target.value);
    }
  }

  getClass() {
    let cls = '';
    if (this.iconSrc()) {
      cls = cls + 'pr-3 pl-10';
    } else {
      cls = cls + 'px-3';
    }

    if (this.fullWidth()) {
      cls = cls + ' ' + 'w-full';
    }

    return cls;
  }

  onActionClicked() {
    this.actionIconClicked.emit();
  }

  onFocus() {
    this.isFocused.set(true);
  }

  onBlur() {
    this.onTouched();
    this.isFocused.set(false);
  }
}
