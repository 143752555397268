<div class="px-4 space-y-4">
  <img src="../../../../../assets/images/branding/YSC-logo.png" />
  <div class="py-6 space-y-4">
    <p class="text-h6">Reset Password</p>
  </div>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetPasswordClicked()">
    <app-outline-text-input [type]="'password'" formControlName="password" [label]="'Password'" placeholder="********" class="w-full" [fullWidth]="true"></app-outline-text-input>
    <app-outline-text-input [type]="'password'" formControlName="confirmPassword" [label]="'Confirm Password'" placeholder="********" class="w-full" [fullWidth]="true"></app-outline-text-input>
    <div class="my-5">
      <app-button [fullWidth]="true" [loading]="resetPasswordState.loading()" type="submit">Reset Password</app-button>
    </div>
  </form>
  <div class="flex justify-center items-center py-6 gap-2">
    <p class="text-body2">Already have an account?</p>
    <a class="cursor-pointer text-info-500" (click)="onLoginClicked()">Login</a>
  </div>
</div>
