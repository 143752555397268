<div class="app-container !p-0">

  <div class="p-4 bg-white">
    <div class="container mx-auto flex gap-5 justify-between items-center">
      <app-page-header title="Users" description="View and manage all Users."></app-page-header>

      <div *hasPermission="permission.createUser" class="flex gap-3 items-center">
        <app-button (click)="onAddUserClicked()" [iconSrc]="'assets/icons/plus.svg'">
          New User
        </app-button>
      </div>
    </div>
  </div>

  <div class="app-page-content grow overflow-y-scroll">
    <app-data-table
      [state]="userListState"
      [columnDefs]="columnDefs"
      [enableColumnsConfig]="true"
      (onActionPerformed)="onActionClicked($event)"
      (tableStateChanged)="getUserList($event)">
    </app-data-table>
  </div>
</div>

