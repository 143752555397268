import { Component } from '@angular/core';

@Component({
  selector: 'app-text-button-small',
  standalone: true,
  imports: [],
  templateUrl: './text-button-small.component.html',
  styleUrl: './text-button-small.component.scss'
})
export class TextButtonSmall {

}
