import {Component, input, ViewChild} from '@angular/core';
import {MatMenu, MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-context-menu',
  standalone: true,
  imports: [
    MatMenu
  ],
  templateUrl: './context-menu.component.html',
  styleUrl: './context-menu.component.scss'
})
export class ContextMenuComponent {
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;

  menuId = input.required<string>();

  openMenu() {
    this.menuTrigger.openMenu();
  }

}
