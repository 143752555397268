import {inject, Injectable} from '@angular/core';
import {TokenStoreService, UserRole} from './token-store.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  tokenService = inject(TokenStoreService);

  private rolePermissions: Map<UserRole, string[]> = new Map([
    [UserRole.yscAdmin, [Permission.createUser, Permission.createTerminal]],
    [UserRole.companyAdmin, [Permission.createUser, Permission.createTerminal]],
    [UserRole.companyRegionalManager, []],
    [UserRole.companyTerminalManager, []],
    [UserRole.driverApplicant, [Permission.submitJobApplication]],
    [UserRole.driver, []]
  ]);

  private readonly currentUserRole: UserRole | null = null;

  constructor() {
    this.currentUserRole = this.tokenService.getUserRole();
  }

  hasPermission(permission: string): boolean {
    if (this.currentUserRole == null) {
      return false;
    }

    let rolePermissions = this.rolePermissions.get(this.currentUserRole) ?? [];
    return rolePermissions.includes(permission) || false;
  }
}

export class Permission {
  static createUser = 'create-user';
  static createTerminal = 'create-terminal';
  static submitJobApplication = 'submit-job-application';
}
