<app-base-overlay [title]=" data == null ? 'Create new user' : 'Update user'">
  <div body>
    <form [formGroup]="createUserForm" class="flex flex-col gap-3">
      <app-text-input formControlName="firstName" label="First Name" placeholder="Ex: John" class="w-full"
                              [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="lastName" label="Last Name" placeholder="Ex: Bravo" class="w-full"
                              [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="email" label="Email" placeholder="Ex: john.Bravo@gmail.com"
                              class="w-full" [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="phoneNumber" label="Phone Number" placeholder="Ex: +1 123-456-78XX"
                              class="w-full" [fullWidth]="true"></app-text-input>

      <app-single-selection-field
        formControlName="roleId"
        title="Role"
        [items]="roleListState.response()?.data ?? []"
        [display]="'name'"
        [value]="'id'"
        [noDataMessage]="'No items available'"
        [fullWidth]="true">
      </app-single-selection-field>

      @switch (createUserForm.value?.roleId) {
        @case (UserRole.companyAdmin) {

        }
        @case (UserRole.companyRegionalManager) {
          <app-multi-selection-field
            formControlName="terminalIds"
            title="Terminals"
            [items]="companyTerminalsState.response()?.data ?? []"
            display="name"
            [value]="'id'"
            identifier="id">
          </app-multi-selection-field>
        }
        @case (UserRole.companyTerminalManager) {
          <app-single-selection-field
            formControlName="terminalIds"
            title="Terminals"
            [items]="companyTerminalsState.response()?.data ?? []"
            display="name"
            [value]="'id'">
          </app-single-selection-field>
        }
      }

    </form>
  </div>
  <div actions>
    <app-button [fullWidth]="true" type="submit" [loading]="createUserState.loading() || updateUserState.loading()"
                (click)="onSubmitClicked()">
      {{ data == null ? 'Create new user' : 'Update user' }}
    </app-button>
  </div>
</app-base-overlay>




