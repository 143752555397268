<div class="py-4 space-y-4">
  <img src="../../../../../assets/images/branding/YSC-logo.png"/>
  <div class="py-2 space-y-4">
    <p class="text-h6">Sign Up</p>
    <div class="flex gap-4 items-center">
      <p class="text-body2">Already have an account?</p>
      <a (click)="onLoginClicked()" class="text-body2 text-info-500 cursor-pointer">Log in</a>
    </div>
  </div>
  <form unSavedAware class="flex flex-col gap-3" [formGroup]="signUpForm" (ngSubmit)="onSignUpClicked()">
    <div class="grid lg:grid-cols-2 gap-4">
      <app-text-input formControlName="firstName" [label]="'First Name'" placeholder="Ex: John" class="w-full"
                      [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="lastName" [label]="'Last Name'" placeholder="Ex: Bravo" class="w-full"
                      [fullWidth]="true"></app-text-input>
    </div>
    <app-text-input formControlName="companyName" [label]="'Company Name'" placeholder="Ex.XYZ LLC" class="w-full"
                    [fullWidth]="true"></app-text-input>
    <app-text-input formControlName="email" [label]="'Email'" placeholder="Ex.john.bravo@company.com" class="w-full"
                    [fullWidth]="true"></app-text-input>
    <app-text-input formControlName="phoneNumber" [label]="'Phone Number'" placeholder="Ex: (123) 456-XXXX"
                    class="w-full" [fullWidth]="true"></app-text-input>
    <div class="flex flex-col sm:flex-row gap-4">
      <app-dropdown formControlName="companyType" [title]="'Business Entity'"
                    [items]="companyTypesState.response()?.data ?? []" display="name" value="id" [enableSearch]="true"
                    [noDataMessage]="'No Options Available'"></app-dropdown>
      <app-text-input formControlName="dotNumber" [label]="'DOT #'" placeholder="Ex: USDOT152XXX" class="w-full"
                      [fullWidth]="true"></app-text-input>
    </div>

    <app-button class="my-2" [fullWidth]="true" type="submit" [loading]="signUpState.loading()">Sign up</app-button>
  </form>
</div>
