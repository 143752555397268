<div class="flex gap-4 px-3 py-4"
     [ngClass]="!isLastRow ? ' border-b border-neutral-200' : ''">
  <div class="flex flex-col gap-2 grow">
    <div class="flex gap-4 items-center">
      <p class="text-body1 text-neutral-800">{{ rowData.name }}</p>
    </div>
    <p class="text-body2 text-neutral-500">{{ getAddress(rowData) }}</p>
  </div>

  @if (selectedTerminal()?.id == rowData.id) {
    <div class="flex gap-3 items-center h-min px-3 py-2 bg-success-100 rounded-full">
      <app-svg-icon src="assets/icons/check-circle.svg" class="text-success-500" [size]="18"></app-svg-icon>
      <p class="text-body2 text-success-500">Active</p>
    </div>
  } @else {
    <div>
      <app-button appearance="outlineRounded" type="submit" (click)="onSelectedTerminal(rowData)">Select
      </app-button>
    </div>
  }
</div>
