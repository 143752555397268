import {Component, OnInit, inject, output} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ReactiveFormsModule} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {DateUtils} from "@shared/utils/date-utils";
import {
  SaveApplicantGeneralInfoRequest
} from "@core/services/driver-application/save-applicant-general-info/save-applicant-general-info-request";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {
  SaveApplicantGeneralInfoResponse
} from "@core/services/driver-application/save-applicant-general-info/save-applicant-general-info-response";
import {ApiService} from "@core/api/api.service";
import type {GetDriverApplicationGeneralResponse} from "@core/api/model";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {UnsavedAwareDirective} from "@shared/base/unsaved-aware.directive";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";
import {onlyFutureDateValidator} from "@shared/validators/only-future-date-validator";


@Component({
  selector: "app-save-applicant-general-info",
  standalone: true,
  imports: [
    ButtonComponent,
    TextInputComponent,
    ReactiveFormsModule,
    RadioButtonComponent,
    DateInputComponent,
    UnsavedAwareDirective
  ],
  templateUrl: "./save-applicant-general-info.component.html",
  styleUrl: "./save-applicant-general-info.component.scss",
})
export class SaveApplicantGeneralInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  applicantGeneralInfoState = new State<GetDriverApplicationGeneralResponse>();
  saveApplicantGeneralInfoState = new State<SaveApplicantGeneralInfoResponse>();

  applicantGeneralInfoForm!: FormGroup;

  ngOnInit(): void {
    this.initForm();
    this.getApplicantGeneralInfo();
  }

  private initForm() {
    this.applicantGeneralInfoForm = this.formBuilder.group({
      id: [null],
      driverApplicationId: [],
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null],
      dateOfBirth: [null, [Validators.required, onlyPastDateValidator()]],
      socialSecurityNumber: [null, Validators.required],
      dateOfApplication: [null],
      position: [null],
      dateAvailableForWork: [null, [Validators.required, onlyFutureDateValidator()]],
      hasUsWorkLegality: [true, Validators.required],
    });
  }

  getApplicantGeneralInfo() {

    this.executeRequest<GetDriverApplicationGeneralResponse>({
      state: this.applicantGeneralInfoState,
      request: this.apiService.getDriverApplicationGeneral(this.tokenService.getDriverApplicationId() ?? ""),
      onSuccess: (response) => {
        let dateUtils = new DateUtils();
        let generalInfo = response.data;

        let dateOfBirth = generalInfo.dateOfBirth ? new Date(generalInfo.dateOfBirth) : null;
        let dateAvailableForWork = generalInfo.dateAvailableForWork ? new Date(generalInfo.dateAvailableForWork) : null;

        this.applicantGeneralInfoForm.controls["id"].setValue(generalInfo.id);
        this.applicantGeneralInfoForm.controls["driverApplicationId"].setValue(this.tokenService.getDriverApplicationId());
        this.applicantGeneralInfoForm.controls["firstName"].setValue(generalInfo.firstName);
        this.applicantGeneralInfoForm.controls["middleName"].setValue(generalInfo.middleName);
        this.applicantGeneralInfoForm.controls["lastName"].setValue(generalInfo.lastName);
        this.applicantGeneralInfoForm.controls["phoneNumber"].setValue(generalInfo.phoneNumber);
        this.applicantGeneralInfoForm.controls["email"].setValue(generalInfo.email);
        this.applicantGeneralInfoForm.controls["dateOfBirth"].setValue(dateOfBirth);
        this.applicantGeneralInfoForm.controls["socialSecurityNumber"].setValue(generalInfo.socialSecurityNumber);
        this.applicantGeneralInfoForm.controls["dateOfApplication"].setValue(dateUtils.convertToMMDDYYYY((generalInfo.dateOfApplication)));
        this.applicantGeneralInfoForm.controls["position"].setValue(generalInfo.position);
        this.applicantGeneralInfoForm.controls["dateAvailableForWork"].setValue(dateAvailableForWork);
        this.applicantGeneralInfoForm.controls["hasUsWorkLegality"].setValue(generalInfo.hasUsWorkLegality ?? true);
        this.applicantGeneralInfoForm.controls["dateOfApplication"].disable();
        this.applicantGeneralInfoForm.controls["email"].disable();
      },
    });
  }

  onSaveAndNextClicked() {
    this.validateForm(this.applicantGeneralInfoForm);
    if (this.applicantGeneralInfoForm.invalid) {
      return;
    }

    let request: SaveApplicantGeneralInfoRequest = {
      id: this.applicantGeneralInfoForm.controls["id"].value,
      driverApplicationId:
      this.applicantGeneralInfoForm.controls["driverApplicationId"].value,
      firstName: this.applicantGeneralInfoForm.controls["firstName"].value,
      middleName: this.applicantGeneralInfoForm.controls["middleName"].value,
      lastName: this.applicantGeneralInfoForm.controls["lastName"].value,
      phoneNumber: this.applicantGeneralInfoForm.controls["phoneNumber"].value,
      dateOfBirth: this.applicantGeneralInfoForm.controls["dateOfBirth"].value,
      socialSecurityNumber:
      this.applicantGeneralInfoForm.controls["socialSecurityNumber"].value,
      position: this.applicantGeneralInfoForm.controls["position"].value,
      dateAvailableForWork:
      this.applicantGeneralInfoForm.controls["dateAvailableForWork"].value,
      hasUsWorkLegality:
      this.applicantGeneralInfoForm.controls["hasUsWorkLegality"].value,
    };

    this.executeRequest({
      state: this.saveApplicantGeneralInfoState,
      request: this.apiService.saveDriverApplicationGeneral(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }
}
