<div class="app-container !p-0">
  <div class="flex p-4 gap-5 justify-between bg-white items-center">
    <app-page-header title="Service Types" description="View all your Service types."></app-page-header>
    <div class="flex gap-3 items-center">
      <app-button appearance="outline" [iconSize]="12" iconSrc="assets/icons/export.svg">Export</app-button>
      <app-button (click)="onNewServiceTypeClicked()" [iconSrc]="'assets/icons/plus.svg'">
        New Service Type
      </app-button>
    </div>
  </div>
  <div class="grow m-4 overflow-y-scroll">
    <app-data-table
      [state]="getServiceTypeListState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      [enableColumnsConfig]="true"
      (tableStateChanged)="getServiceTypeList($event)">
    </app-data-table>
  </div>
</div>

