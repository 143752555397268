<div class="app-container-padding h-full overflow-auto">
  <div class="bg-white rounded-lg p-4">
    <div class="flex justify-end items-center m-4">
      <div class="flex m-2 gap-2 items-center border text-primary-500 rounded-lg">
        <app-button [iconSrc]="'assets/icons/plus.svg'"
                    [textButtonColor]="'text-primary-500'"
                    (click)="createReminder()">New Reminder
        </app-button>
      </div>
    </div>
    <app-base-table sortableTable [state]="remindersState"
                    (sortChange)="sortData($event)">
      <ng-container head>
        <th class="p-4 text-left" data-sortable-key="reminderDate">REMINDER DATE</th>
        <th class="p-4 text-center" data-sortable-key="reminderTime">REMINDER TIME</th>
        <th class="p-4 text-center" data-sortable-key="notes">NOTES</th>
        <th class="p-4 text-center"></th>
      </ng-container>
      <ng-container body>
        @for (item of remindersState.response()?.data; track item.id; ) {
          <tr class="border-b border-neutral-100 w-full">
            <td class="px-4 text-body2 text-left cursor-pointer all">
              {{ item.reminderDate | date:'MMM d, y' }}
            </td>
            <td class="px-4 py-2 text-body2 text-center">
              {{ item.reminderDate | date:'shortTime' }}
            </td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.description }}</td>
            <td class="flex items-center px-6 py-2 gap-4">
              <app-svg-icon src="assets/icons/edit.svg" class="text-neutral-500"
                            (click)="onEditClicked(item.id)"></app-svg-icon>
              <app-svg-icon src="assets/icons/bin.svg" class="text-neutral-500"
                            (click)="onDeleteClicked(item.id)"></app-svg-icon>
            </td>
          </tr>
        }
      </ng-container>
    </app-base-table>
  </div>
</div>
