<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-2xl">
    <div class="grow overflow-auto p-8 px-4 overflow-y-scroll">
      <div class="flex flex-col gap-3 mx-auto max-w-5xl h-screen">
        <div class="py-3">
          <span class="text-body1 text-info-500">Residency Information</span>
        </div>

        <div class="line-light"></div>


        @if (driverResidencyInfoState.loading()) {
          <div class="flex flex-col gap-5">
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
          </div>
        }

        <form [formGroup]="residenciesForm" class="form flex flex-col gap-3">

          <div formArrayName="residencies" class="flex flex-col gap-4">
            @if (currentAddressForm) {
              <div class="current-address flex flex-col gap-4" [formGroup]="currentAddressForm">
                <span class="py-2 text-body2 text-info-500">CURRENT ADDRESS</span>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-text-input formControlName="address" [label]="'Address'" placeholder="Ex: John"
                                  class="w-full" [fullWidth]="true"></app-text-input>
                  <app-text-input formControlName="city" [label]="'City'" placeholder="Ex: San Jose"
                                  class="w-full" [fullWidth]="true"></app-text-input>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-dropdown
                    formControlName="countryId"
                    [title]="'Country'"
                    [display]="'name'"
                    [value]="'id'"
                    [enableSearch]="true"
                    [noDataMessage]="'No Options Available'"
                    class="w-full"
                    [items]="countryListState.response()?.data ?? [] "
                    (valueChanged)="onCountrySelected($event, 0)"
                    [fullWidth]="true">
                  </app-dropdown>
                  <app-dropdown formControlName="stateId"
                                [items]="statesList[0]"
                                [title]="'State'"
                                [display]="'name'"
                                [value]="'id'"
                                [enableSearch]="true"
                                [noDataMessage]="'No Options Available'"
                                class="w-full"
                                [fullWidth]="true">

                  </app-dropdown>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-text-input formControlName="zipCode" [label]="'Zip Code'" placeholder="Ex: 95148"
                                  class="w-full" [fullWidth]="true"></app-text-input>
                  <app-text-input formControlName="yearsAtAddress" type="number"
                                  [label]="'No. of years at this address'"
                                  placeholder="Ex: 2" class="w-full"
                                  [fullWidth]="true"></app-text-input>
                </div>

                <div class="flex items-center gap-3">
                  <span class="text-body2">Mailing address same as current address?</span>
                  <app-radio-button formControlName="isMailingAddress" title="Yes" groupName="isCurrentAddress"
                                    [value]="true" (valueChanged)="onMailingAddressFormRemoved()"></app-radio-button>
                  <app-radio-button formControlName="isMailingAddress" title="No" groupName="isCurrentAddress"
                                    [value]="false" (valueChanged)="onMailingAddressFormAdded()"></app-radio-button>
                </div>
              </div>
            }

            <div class="line"></div>

            @if (mailingAddressForm) {
              <div class="mailing-address flex flex-col gap-4" [formGroup]="mailingAddressForm">
                <span class="py-2 text-body2 text-info-500">MAILING ADDRESS</span>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-text-input formControlName="address" [label]="'Address'" placeholder="Ex: John"
                                  class="w-full" [fullWidth]="true"></app-text-input>
                  <app-text-input formControlName="city" [label]="'City'" placeholder="Ex: San Jose"
                                  class="w-full" [fullWidth]="true"></app-text-input>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-dropdown
                    formControlName="countryId"
                    [title]="'Country'"
                    [display]="'name'"
                    [value]="'id'"
                    [enableSearch]="true"
                    [noDataMessage]="'No Options Available'"
                    class="w-full"
                    [items]="countryListState.response()?.data ?? [] "
                    (valueChanged)="onCountrySelected($event, 1)"
                    [fullWidth]="true">
                  </app-dropdown>
                  <app-dropdown formControlName="stateId"
                                [items]="statesList[1]"
                                [title]="'State'"
                                [display]="'name'"
                                [value]="'id'"
                                [enableSearch]="true"
                                [noDataMessage]="'No Options Available'"
                                class="w-full"
                                [fullWidth]="true">

                  </app-dropdown>
                </div>
                <div class="flex items-center gap-4">
                  <app-text-input formControlName="zipCode" [label]="'Zip Code'" placeholder="Ex: 95148"
                                  class="w-full" [fullWidth]="true"></app-text-input>
                  <app-text-input formControlName="yearsAtAddress" type="number"
                                  [label]="'No. of years at this address'"
                                  placeholder="Ex: 2" class="w-full"
                                  [fullWidth]="true"></app-text-input>
                </div>
              </div>

              <div class="line"></div>
            }

            @for (control of residenciesFormArray.controls; track control; let index = $index) {
              @if (index >= additionalAddressStartIndex()) {
                <div class="additional-address flex flex-col gap-3" [formGroupName]="index">
                  <span class="py-4 text-body2 text-info-500">ADDITIONAL ADDRESS</span>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-text-input formControlName="address" [label]="'Address'" placeholder="Ex: John"
                                    class="w-full" [fullWidth]="true"></app-text-input>
                    <app-text-input formControlName="city" [label]="'City'" placeholder="Ex: San Jose"
                                    class="w-full" [fullWidth]="true"></app-text-input>
                  </div>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-dropdown
                      formControlName="countryId"
                      [title]="'Country'"
                      [display]="'name'"
                      [value]="'id'"
                      [enableSearch]="true"
                      [noDataMessage]="'No Options Available'"
                      class="w-full"
                      [items]="countryListState.response()?.data ?? [] "
                      (valueChanged)="onCountrySelected($event, index)"
                      [fullWidth]="true">
                    </app-dropdown>
                    <app-dropdown formControlName="stateId"
                                  [items]="statesList[index] || []"
                                  [title]="'State'"
                                  [display]="'name'"
                                  [value]="'id'"
                                  [enableSearch]="true"
                                  [noDataMessage]="'No Options Available'"
                                  [fullWidth]="true">
                    </app-dropdown>
                  </div>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-text-input formControlName="zipCode" [label]="'Zip Code'" placeholder="Ex: 95148"
                                    class="w-full" [fullWidth]="true"></app-text-input>
                    <app-text-input formControlName="yearsAtAddress" type="number"
                                    [label]="'No. of years at this address'"
                                    placeholder="Ex: 2" class="w-full"
                                    [fullWidth]="true"></app-text-input>
                  </div>
                  <div class="flex justify-end">
                    <app-button appearance="outline"
                                outlineColor="border-error-500"
                                textButtonColor="text-error-500"
                                iconColor="text-error-500"
                                (click)="onDeleteClick(index)">Delete address
                    </app-button>
                  </div>
                </div>
              }
            }
          </div>

          <div class="flex flex-col gap-4 my-3">
            <p class="text-caption text-neutral-500">Please add more addresses if you have stayed in more residency in
              past 3 years.</p>
            <app-button [fullWidth]="true" (click)="onAddAddressClicked()">ADD ANOTHER ADDRESS</app-button>
          </div>
          <div class="line"></div>
        </form>
      </div>
    </div>

    <div class="flex justify-end border-t border-neutral-200 px-4 py-4">
      <app-button type="submit" [loading]="saveDriverResidencyState.loading()" (click)="onUpdateClicked()">
        UPDATE
      </app-button>
    </div>
  </div>
</div>
