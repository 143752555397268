<div class="h-full flex flex-col gap-4">

  @if (enableSearch()) {
    <app-text-input
      title="Search..."
      [iconSrc]="'assets/icons/search.svg'"
      placeholder="Search..."
      type="search"
      [fullWidth]="enableSearchFullWidth()"
      (changeValue)="onSearchTextChanged($event)">
    </app-text-input>
  }

  @if (state().success() && state().items().length <= 0) {
    <div class="flex flex-col justify-center items-center gap-8 px-6 py-8">
      <app-no-data-table></app-no-data-table>
    </div>
  }

  @if (state().items().length > 0) {
    <div class="flex flex-col overflow-auto"
         infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         (scrolled)="onScroll()">
      @for (item of state().items(); track $index) {
        <app-dynamic-renderer
          [component]="component()"
          [rowData]="item"
          [data]="data()"
          [rowPosition]="$index"
          [isLastRow]="$last"
          (actionPerformed)="onRowAction($event)">
        </app-dynamic-renderer>
      }
    </div>
  }

  <!--    Shimmer-->
  @if (state().loading()) {
    <div class="flex flex-col gap-6 py-4">
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
      <app-shimmer type="multiline"></app-shimmer>
    </div>
  }


</div>
