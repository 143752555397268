import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  TextInputComponent
} from "@shared/inputs/text-input/text-input.component";
import {
  CreateUserRequest,
  CreateUserResponse, GetCompanyUserTerminalListItem,
  GetCompanyUserTerminalListResponse,
  GetRoleListResponse, GetUserListItem, UpdateUserRequest, type UpdateUserResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {
  SingleSelectionFieldComponent
} from "@shared/inputs/single-selection-field/single-selection-field.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {ApiService} from "@core/api/api.service";
import {MultiSelectionFieldComponent} from "@shared/inputs/multi-selection-field/multi-selection-field.component";
import {UserRole} from "@core/services/auth/token-store.service";
import {Subscription} from "rxjs";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-create-user',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    TextInputComponent,
    ReactiveFormsModule,
    SingleSelectionFieldComponent,
    MultiSelectionFieldComponent,
    BaseOverlayComponent
  ],
  templateUrl: './create-or-update-user.component.html',
  styleUrl: './create-or-update-user.component.scss'
})
export class CreateOrUpdateUserComponent extends BaseComponent implements OnInit, OnDestroy {

  apiService = inject(ApiService);
  formBuilder = inject(FormBuilder);
  dialogRef = inject(DialogRef);
  data?: GetUserListItem = inject(DIALOG_DATA);

  roleListState = new State<GetRoleListResponse>();
  companyTerminalsState = new State<GetCompanyUserTerminalListResponse>();
  createUserState = new State<CreateUserResponse>();
  updateUserState = new State<UpdateUserResponse>();

  createUserForm!: FormGroup;
  roleListener?: Subscription;

  ngOnInit() {
    this.initForm();
    this.getRole();
    this.getTerminals();
  }

  ngOnDestroy(): void {
    this.roleListener?.unsubscribe();
  }

  private initForm(): void {
    this.createUserForm = this.formBuilder.group({
      id: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      roleId: [null, Validators.required],
      terminalIds: [[]]
    });

    this.listenForChanges();
    this.updateForm();
  }

  private updateForm(): void {

    let terminalIds = this.data?.terminalIds ?? [];

    this.createUserForm.controls["id"].setValue(this.data?.id);
    this.createUserForm.controls["firstName"].setValue(this.data?.firstName);
    this.createUserForm.controls["lastName"].setValue(this.data?.lastName);
    this.createUserForm.controls["email"].setValue(this.data?.email);
    this.createUserForm.controls["phoneNumber"].setValue(this.data?.phoneNumber);
    this.createUserForm.controls["roleId"].setValue(this.data?.roleId);
    this.createUserForm.controls["terminalIds"].setValue([...terminalIds]);

    if (this.data != null) {
      this.createUserForm.controls["email"].disable();
    }
  }

  listenForChanges() {
    this.roleListener = this.createUserForm.controls['roleId'].valueChanges.subscribe(value => {
      if (value === UserRole.companyAdmin) {
        this.createUserForm.controls['terminalIds'].setValue([]);
        this.createUserForm.controls['terminalIds'].disable();
      } else {
        this.createUserForm.controls['terminalIds'].enable();
      }
    });
  }

  getRole() {
    this.executeRequest<GetRoleListResponse>({
      state: this.roleListState,
      request: this.apiService.getCompanyRoleList(),
    });
  }

  getTerminals() {
    this.executeRequest({
      request: this.apiService.getCompanyUserTerminalList(),
      state: this.companyTerminalsState,
    });
  }

  onSubmitClicked() {
    this.validateForm(this.createUserForm);
    if (this.createUserForm.invalid) {
      return;
    }

    if (this.data == null) {
      this.createUser();
    } else {
      this.updateUser();
    }
  }

  private createUser() {

    let terminalIds = this.createUserForm.value.terminalIds ?? [];
    if(!Array.isArray(terminalIds)) {
      terminalIds = [terminalIds];
    }


    let formValue = this.createUserForm.value;
    let request: CreateUserRequest = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      email: formValue.email,
      phoneNumber: formValue.phoneNumber,
      roleId: formValue.roleId,
      terminalIds: terminalIds
    };

    this.executeRequest({
      state: this.createUserState,
      request: this.apiService.createUser(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.dialogRef.close(true);
      },
    });
  }

  updateUser() {
    let id = this.data?.id ?? '';

    let terminalIds = this.createUserForm.value.terminalIds ?? [];
    if(!Array.isArray(terminalIds)) {
      terminalIds = [terminalIds];
    }

    let formValue = this.createUserForm.value;
    const updateUserRequest: UpdateUserRequest = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      phoneNumber: this.data?.phoneNumber ?? formValue.phoneNumber,
      roleId: formValue.roleId,
      terminalIds: terminalIds
    };

    this.executeRequest({
      request: this.apiService.updateUser(id, updateUserRequest),
      state: this.updateUserState,
      handleSuccess: true,
      onSuccess: (response) => {
        this.dialogRef.close(true);
      }
    });
  }

  protected readonly UserRole = UserRole;
}
