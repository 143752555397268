import {
  Component,
  inject,
  OnInit,
  output,
} from "@angular/core";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {FileUploaderComponent} from "../../../../../shared/inputs/file-uploader/file-uploader.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {
  CompleteDriverApplicationRequest,
  GetDriverApplicationParams,
  GetDriverApplicationResponse,
  UploadDriverApplicationCurrentDrivingLicenseRequest,
  UploadDriverApplicationCurrentDrivingLicenseResponse,
  type UploadDriverApplicationDmvPrintoutRequest,
  type UploadDriverApplicationDmvPrintoutResponse,
  UploadDriverApplicationDotMedicalCertificateRequest,
  UploadDriverApplicationDotMedicalCertificateResponse,
} from "../../../../../core/api/model";
import {State} from "../../../../../shared/base/base-state";
import {DriverApplicationService} from "../../../../../core/services/driver-application/driver-application.service";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {DateUtils} from "../../../../../shared/utils/date-utils";
import {SignaturePadComponent} from "../../../../../core/components/signature-pad/signature-pad.component";
import {
  ApplicantLicensesInfoResponse
} from "../../../../../core/services/driver-application/applicant-licenses-info/applicant-licenses-info-response";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {ApiService} from "../../../../../core/api/api.service";
import {CheckboxComponent} from "../../../../../shared/inputs/checkbox/checkbox.component";

@Component({
  selector: "app-save-applicant-complete-application",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    FileUploaderComponent,
    SignaturePadComponent,
    RadioButtonComponent,
    CheckboxComponent,
  ],
  templateUrl: "./save-applicant-complete-application.component.html",
  styleUrl: "./save-applicant-complete-application.component.scss",
})
export class SaveApplicantCompleteApplicationComponent extends BaseComponent implements OnInit {

  onNextClick = output<void>();

  formBuilder = inject(FormBuilder);
  driverApplicationService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);
  apiService = inject(ApiService);

  completeApplicationForm!: FormGroup;

  driverApplicationInfoState = new State<GetDriverApplicationResponse>();
  uploadDmvPrintoutFileState = new State<UploadDriverApplicationDmvPrintoutResponse>();
  uploadDotMedicalCertificateFileState = new State<UploadDriverApplicationDotMedicalCertificateResponse>();
  uploadCurrentDrivingLicenseFileState = new State<UploadDriverApplicationCurrentDrivingLicenseResponse>();
  completeApplicationState = new State<any>();
  licensesState = new State<ApplicantLicensesInfoResponse>();
  hazmatFileState = new State<any>();

  ngOnInit() {
    this.initForm();
    this.getDriverApplicationInfo();
    this.getLicenses();
  }

  private initForm() {
    this.completeApplicationForm = this.formBuilder.group({
      dmvPrintoutFileUrl: ["", Validators.required],
      licenseDotMedicalCertificateFileUrl: ["", Validators.required],
      currentDrivingLicenseFileUrl: ["", Validators.required],
      hazmatFileURL: ["", Validators.required],
      licenseExpiryDate: ["", Validators.required],
      medicalCardExpirationDate: ["", Validators.required],
      isUnderSapProgram: ["" || false, Validators.required],
      applicantName: ["", Validators.required],
      signDate: ["", Validators.required],
      signature: [""],
      acknowledgement:["", Validators.required],
    });
  }

  private getDriverApplicationInfo() {
    let request: GetDriverApplicationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationResponse>({
      request: this.apiService.getDriverApplication(request),
      state: this.driverApplicationInfoState,
      onSuccess: response => {
        let data = response.data;
        let dateUtils = new DateUtils();
        let medicalCardExpiryDate = dateUtils.convertToMMDDYYYY(data.medicalCardExpirationDate);
        let signatureDate = dateUtils.convertToMMDDYYYY(data.signatureDate);
        this.completeApplicationForm.controls['dmvPrintoutFileUrl'].setValue(data.dmvPrintFilePath);
        this.completeApplicationForm.controls['licenseDotMedicalCertificateFileUrl'].setValue(data.dotMedicalCertificateFilePath);
        this.completeApplicationForm.controls['currentDrivingLicenseFileUrl'].setValue(data.currentDrivingLicenseFilePath);
        this.completeApplicationForm.controls['licenseExpiryDate'].setValue(data.drivingLicenseExpirationDate);
        this.completeApplicationForm.controls['medicalCardExpirationDate'].setValue(medicalCardExpiryDate);
        this.completeApplicationForm.controls['isUnderSapProgram'].setValue(data.isUnderSapProgram);
        this.completeApplicationForm.controls['applicantName'].setValue(data.applicantName);
        this.completeApplicationForm.controls['signDate'].setValue(signatureDate);
        this.completeApplicationForm.controls['signature'].setValue(data.signature);
      }
    });
  }

  private getLicenses() {
    let request = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<ApplicantLicensesInfoResponse>({
      state: this.licensesState,
      request: this.driverApplicationService.getApplicantLicenses(request),
      onSuccess: (response) => {
        let licenses = response.data ?? [];
        let currentLicense = licenses.find((license) => license.isCurrent);
        if (currentLicense) {
          let dateUtils = new DateUtils();
          let expiryDate = dateUtils.convertToMMDDYYYY(currentLicense.expirationDate);
          this.completeApplicationForm.controls['licenseExpiryDate'].setValue(expiryDate);
        }
      },
    });
  }

  onSignatureCleared() {
    this.completeApplicationForm.controls['signature'].setValue(null);
  }

  onSubmitClicked() {
    console.log('Form values:', this.completeApplicationForm.value);
    if (this.completeApplicationForm.invalid) {
      return;
    }
    let dateUtils = new DateUtils();
    const drivingLicenseExpirationDate = this.completeApplicationForm.controls["licenseExpiryDate"].value;
    const signatureDate = this.completeApplicationForm.controls["signDate"].value;
    const medicalCardExpirationDate = this.completeApplicationForm.controls ["medicalCardExpirationDate"].value;

    let request: CompleteDriverApplicationRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      drivingLicenseExpirationDate: dateUtils.convertToDateTimeString(drivingLicenseExpirationDate) ?? '',
      applicantName: this.completeApplicationForm.controls["applicantName"].value ?? '',
      signature: this.completeApplicationForm.controls["signature"].value,
      signatureDate: dateUtils.convertToDateTimeString(signatureDate) ?? '',
      isUnderSapProgram: this.completeApplicationForm.controls["isUnderSapProgram"].value ?? '',
      medicalCardExpirationDate: dateUtils.convertToDateTimeString(medicalCardExpirationDate) ?? '',
    };

    this.executeRequest<CompleteDriverApplicationRequest>({
      state: this.completeApplicationState,
      request: this.driverApplicationService.saveCompleteApplication(request),
      handleSuccess: true,
      onSuccess: response => {
        console.log("API integration is successful");
      },
    });
  }

  onDMVPrintoutFileSelected($event: File) {

    let request: UploadDriverApplicationDmvPrintoutRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
    };

    this.executeRequest<UploadDriverApplicationDmvPrintoutResponse>({
      request: this.apiService.uploadDriverApplicationDmvPrintoutFile(request),
      state: this.uploadDmvPrintoutFileState,
    });
  }


  onDotMedicalCertificatePrintoutFileSelected($event: File) {

    let request: UploadDriverApplicationDotMedicalCertificateRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
    };

    this.executeRequest<UploadDriverApplicationDotMedicalCertificateResponse>({
      request: this.apiService.uploadDriverApplicationDotMedicalCertificate(request),
      state: this.uploadDotMedicalCertificateFileState,
    });
  }

  onAttachCurrentDrivingLicenseFileSelected($event: File) {
    let request: UploadDriverApplicationCurrentDrivingLicenseRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
    };

    this.executeRequest<UploadDriverApplicationCurrentDrivingLicenseResponse>({
      request: this.apiService.uploadDriverApplicationCurrentDrivingLicenseFile(request),
      state: this.uploadCurrentDrivingLicenseFileState,
    });
  }

  onSignatureSaved(signatureBase64: string) {
    this.completeApplicationForm.controls["signature"].setValue(signatureBase64);
  }
}
