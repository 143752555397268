import {Component, inject, input, OnChanges, output, signal, SimpleChanges} from '@angular/core';
import {
  type DeleteDriverDocumentResponse,
  GetDriverDocumentListItem,
} from "../../api/model";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {DatePipe} from "@angular/common";
import {
  DocumentUploadComponent
} from "../document-upload/document-upload.component";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "../../api/api.service";
import {State} from "@shared/base/base-state";
import {
  DocumentSubtypeListComponent
} from "@core/components/document-view/document-subtype-list/document-subtype-list.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-document-view',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe
  ],
  templateUrl: './document-view.component.html',
  styleUrl: './document-view.component.scss'
})
export class DocumentViewComponent extends BaseComponent implements OnChanges {

  onDocumentChanged = output<void>();

  overlayService = inject(OverlayService);
  document = input<GetDriverDocumentListItem | null>();
  apiService = inject(ApiService);
  deleteDocumentState = new State<DeleteDriverDocumentResponse>();

  _document = signal<GetDriverDocumentListItem | null>(null);

  ngOnChanges(changes: SimpleChanges): void {
    this._document.set(this.document() ?? null);
  }

  onViewClicked() {
    const attachments = this._document()?.attachments ?? [];
    if (attachments.length == 1) {
      window.open(attachments[0].filePath, '_blank');
    }

    if(attachments.length > 1) {
      this.overlayService.openModal(DocumentSubtypeListComponent, {
        data: attachments
      });
    }
  }

  onViewAllClicked() {
    this.router.navigate([`drivers/${this._document()?.driverId}/all-documents`], {
      queryParams: {documentTypeId: this._document()?.documentTypeId}
    });
  }

  onUploadClicked() {
    this.overlayService.openModal(DocumentUploadComponent, {
      data: this._document(),
    }).closed.subscribe((document: any) => {
      if (document) {
        // this._document.set(document);
        this.onDocumentChanged.emit();
      }
    });
  }

  onDownloadClicked() {
    const attachments = this._document()?.attachments ?? [];
    if (attachments.length == 1) {
      window.open(attachments[0].filePath, '_blank');
    }

    if(attachments.length > 1) {
      this.overlayService.openModal(DocumentSubtypeListComponent, {
        data: attachments
      });
    }
  }


  onDeleteClicked() {
    this.overlayService
      .openAlert('Delete Document', 'Are you sure you want to delete this document?')
      .closed
      .subscribe((result) => {
        if (result) {
          this.deleteDocument();
        }
      });
  }

  private deleteDocument() {
    this.executeRequest<DeleteDriverDocumentResponse>({
      request: this.apiService.deleteDriverDocument(this._document()?.documentId ?? ''),
      state: this.deleteDocumentState,
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.onDocumentChanged.emit();
        // this._document.update(prev => {
        //   let document = prev;
        //
        //   if (document != null) {
        //
        //     document.issuedDateFieldLabel = undefined;
        //     document.expiryDateFieldLabel = undefined;
        //     document.reviewedDateFieldLabel = undefined;
        //     document.completedDateFieldLabel = undefined;
        //     document.attachments = [];
        //
        //     document.issuedDate = undefined;
        //     document.expiryDate = undefined;
        //     document.completedDate = undefined;
        //     document.reviewedDate = undefined;
        //
        //     document.uploadedDate = undefined;
        //     document.notes = undefined;
        //
        //   }
        //   return document;
        // });
      }
    });
  }
}


