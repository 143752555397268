<div class="app-container">
  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
    <div class="mx-auto py-4">
      <p class="p-2 text-body1 text-center">HIRE APPLICANT</p>
      <p class="p-2 text-body2 text-neutral-800 text-center">Please upload the required documents to complete the hiring
        process</p>
    </div>
    <form [formGroup]="driverHiringForm">
      <div class="flex flex-col p-6 gap-6">
        <app-file-uploader formControlName="ClearingHouseQuery"
                           label="Attach Pre-employment Clearinghouse query" [isRequired]="true"
                           [uploading]="clearingHouseQueryState.loading()"
                           (fileSelected)="onClearingHouseFileSelected($event)"></app-file-uploader>
        <div class="flex flex-col gap-4">
          <p class="text-body2 text-neutral-800">Please complete full clearing house using the provided link below</p>
          <p class="text-body2 text-info-500 cursor-pointer underline">https://clearinghouse.fmcsa.dot.gov</p>
        </div>
        <div class="grid lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="preEmploymentClearingHouseCompletedDate"
                                  [label]="'Full clearinghouse query completed date'" mask="00/00/0000" class="w-full"
                                  [fullWidth]="true"></app-outline-text-input>
          <app-dropdown
            formControlName="preEmploymentClearingHouseUploadFrequency"
            [title]="'Clearinghouse query upload frequency'"
            [display]="'name'"
            [value]="'value'"
            [noDataMessage]="'No Options Available'"
            [items]="clearingHouseQueryFileUploadFrequencyState.response()?.data??[]"
            [fullWidth]="true"
          >
          </app-dropdown>
        </div>
        <app-file-uploader formControlName="drugAlcoholPolicy" label="Drug & Alcohol policy"
                           [isRequired]="true" [uploading]="drugAlcoholPolicyState.loading()"
                           (fileSelected)="onDrugAlcoholPolicyFileSelected($event)"></app-file-uploader>
        <app-file-uploader formControlName="drugTestResult" label="Attach drug test result"
                           [isRequired]="true" [uploading]="drugTestResultState.loading()"
                           (fileSelected)="onDrugTestResultFileSelected($event)"></app-file-uploader>
        <div class="grid lg:grid-cols-2 gap-6">
          <app-outline-text-input formControlName="mroVerificationDate" [label]="'MRO verification date'"
                                  mask="00/00/0000" class="w-full"
                                  [fullWidth]="true"></app-outline-text-input>
        </div>
        <app-file-uploader formControlName="drugTestCCF" label="Attach drug test CCF (Custody Control Form)"
                           [isRequired]="true" [uploading]="drugTestCCFState.loading()"
                           (fileSelected)="onDrugTestCCFFileSelected($event)"></app-file-uploader>
        <div class="grid lg:grid-cols-2 gap-4">
          <app-outline-text-input formControlName="drugTestDate" [label]="'Drug test date'"
                                  mask="00/00/0000" class="w-full"
                                  [fullWidth]="true"></app-outline-text-input>
        </div>
        <div class="flex flex-col py-4 gap-3">
          <span class="text-primary-600">Please provide the drug test result</span>
          <div class="flex gap-3">
            <app-radio-button formControlName="isDrugTestResultPositive" groupName="isDrugTestResultPositive"
                              title="Negative"
                              [value]="true"></app-radio-button>
            <app-radio-button formControlName="isDrugTestResultPositive" groupName="isDrugTestResultPositive"
                              title="Positive"
                              [value]="false"></app-radio-button>
          </div>
        </div>
        <div class="flex flex-col py-3 gap-3">
          <span class="text-primary-600">Do you want to start the SAP programme for this applicant?</span>
          <div class="flex gap-3">
            <app-radio-button formControlName="hasStartedSAPProgram" groupName="hasStartedSAPProgram" title="Yes"
                              [value]="true"></app-radio-button>

            <app-radio-button formControlName="hasStartedSAPProgram" groupName="hasStartedSAPProgram" title="No"
                              [value]="false"></app-radio-button>
          </div>
          @if (driverHiringForm.get('hasStartedSAPProgram')?.value) {
          <div class="grid lg:grid-cols-2 gap-3 py-2">
            <app-dropdown
              formControlName="sapTestFrequency"
              [title]="'SAP test frequency'"
              [display]="'name'"
              [value]="'value'"
              [noDataMessage]="'No Options Available'"
              [items]="sapTestFrequencyState.response()?.data??[]"
              [fullWidth]="true"
            >
            </app-dropdown>
           <app-dropdown
              formControlName="sapTestDuration"
              [title]="'SAP test duration'"
              [display]="'name'"
              [value]="'value'"
              [noDataMessage]="'No Options Available'"
               [items]="sapTestDurationState.response()?.data??[]"
              [fullWidth]="true"
            ></app-dropdown>
          </div>
          }
          <div class="flex flex-col py-3 gap-6">
            <p class="text-body1 text-primary-600">Please upload the "Return to Duty" if the applicant was already in
              SAP Program</p>
            <app-file-uploader formControlName="returnToDutyDocument"
                               label="Return to Duty document" [uploading]="returnToDutyState.loading()"
                               (fileSelected)="onReturnToDutyFileSelected($event)"></app-file-uploader>
            <app-file-uploader formControlName="clearingHouseQueryPolicy"
                               label="Clearinghouse Limited Query Policy"
                               [uploading]="clearingHouseLimitedQueryPolicyState.loading()"
                               (fileSelected)="onClearingHouseLimitedQueryPolicyFileSelected($event)"></app-file-uploader>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input formControlName="clearingHouseLimitedQueryPolicyExpiryDate"
                                    [label]="'Clearinghouse limited query policy expiry date'"
                                    mask="00/00/0000" class="w-full"
                                    [fullWidth]="true"></app-outline-text-input>
          </div>
          <div class="flex flex-col py-3 gap-6">
            <app-file-uploader formControlName="dmvPrintout" label="DMV Printout / Motor Vehicle Record (MVR) "
                               [isRequired]="true" [uploading]="dmvPrintOutOrMVRState.loading()"
                               (fileSelected)="onDMVPrintOutOrMVRFileSelected($event)"></app-file-uploader>
            <app-file-uploader formControlName="medicalExaminerCertificate" label="Medical examiner certificate"
                               [isRequired]="true" [uploading]="medicalExaminerCertificateState.loading()"
                               (fileSelected)="onMedicalExaminerCertificateFileSelected($event)"></app-file-uploader>
            <p class="text-body2 text-neutral-800">Please verify the medical examiner's listed on the National
              Registry</p>
            <p class="text-body2 text-info-500 cursor-pointer underline">
              https://nationalregistry.fmcsa.dot.gov/search-medical-examiners</p>
            <app-file-uploader formControlName="screenShotOfNationalRegistryReference"
                               label="Screenshot of National Registry Reference"
                               [uploading]="screenshotOfNationalRegistryReferenceState.loading()"
                               (fileSelected)="onScreenshotOfNationalRegistryReferenceFileSelected($event)"></app-file-uploader>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-outline-text-input
              formControlName="medicalCardExpirationDate"
              [label]="'Medical examiner certificate expiration date'"
              mask="00/00/0000"
              class="w-full"
              [fullWidth]="true"
            ></app-outline-text-input>
          </div>
          @if (driverHiringForm.get('hasStartedSAPProgram')?.value ) {
          <div class="flex flex-col py-6 gap-6">

            <app-file-uploader formControlName="proficiencyTestResult" label="Proficiency Test Result (Road test)"
                               [isRequired]="true" [uploading]="proficiencyTestResultState.loading()"
                               (fileSelected)="onProficiencyTestResultFileSelected($event)"></app-file-uploader>
            <p class="mx-auto items-center text-body2">OR</p>
            <div
              class="p-2 flex justify-center items-center w-full border rounded-lg  border-success-500 cursor-pointer"
              (click)="onStartProficiencyTest()">
              <app-svg-icon src="assets/icons/menu-right.svg" class="text-success-500" [size]="32"></app-svg-icon>
              <a class="text-success-500 text-body1">Start Proficiency Test (Road Test)</a>
            </div>
          </div>
          }
          @if (companyInfoState.response()?.data?.stateId === 'CALIFORNIA') {
          <p class="text-body2 text-primary-600">California company related documents</p>
          <app-file-uploader formControlName="employerPullNotice"
                             label="Employer Pull Notice (EPN)" [uploading]="employerPullNoticeState.loading()"
                             (fileSelected)="onEmployerPullNoticeFileSelected($event)"></app-file-uploader>
          }
          <div class="flex flex-col py-4 gap-6">
            <p class="text-body2 text-primary-500">Required for SPAB (or) School Bus Company</p>
            <div class="flex flex-col py-3 gap-3">
              <span class="text-primary-600">Does the driver has a SPAB Certificate?</span>
              <div class="flex gap-3">
                <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="Yes"
                                  [value]="true"></app-radio-button>
                <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="No"
                                  [value]="false"></app-radio-button>
              </div>
            </div>
            @if (companyInfoState.response()?.data?.businessTypeId === 'BUS' &&
            driverHiringForm.get('hasSPABCertificate')?.value ?? false) {
            <app-file-uploader formControlName="spabCertificate" label="SPAB Certificate"
                               [isRequired]="true" [uploading]="spabCertificateState.loading()"
                               (fileSelected)="onSPABCertificateFileSelected($event)"></app-file-uploader>
            <app-file-uploader formControlName="t1Certificate"
                               label="T-01 Certification (Optional)" [uploading]="t01CertificateState.loading()"
                               (fileSelected)="onT01CertificateFileSelected($event)"></app-file-uploader>
            <app-file-uploader formControlName="t2Certificate"
                               label="T-02 Certification (Optional)" [uploading]="t02CertificateState.loading()"
                               (fileSelected)="onT02CertificateFileSelected($event)"></app-file-uploader>
            }
          </div>
          <p class="text-body1 text-primary-500">HAZMAT related documents</p>
          <div class="flex flex-col py-3 gap-3">
            <span class="text-primary-600">Would this driver transports HAZMAT material?</span>
            <div class="flex gap-3">
              <app-radio-button formControlName="driverTransportsHazmat" groupName="hazMat" title="Yes"
                                [value]="true"></app-radio-button>
              <app-radio-button formControlName="driverTransportsHazmat" groupName="hazMat" title="No"
                                [value]="false"></app-radio-button>
            </div>
          </div>
          @if (companyInfoState.response()?.data?.transportsHazmat === true &&
          companyInfoState.response()?.data?.transportsHazwaste === true &&
          driverHiringForm.get('driverTransportsHazmat')?.value?? false) {
          <div class="py-4">
          <app-file-uploader formControlName="hazMatCertificate" label="HAZMAT Certificate"
                             [isRequired]="true" [uploading]="onHAZMATCertificateState.loading()"
                             (fileSelected)="onHAZMATCertificateFileSelected($event)"></app-file-uploader>
                             </div>
          }
          <app-checkbox formControlName="confirmationOfRequirements"
                        title="I confirm that all details provided by the job applicant have been verified, and the candidate meets the necessary requirements."></app-checkbox>
        </div>
        <div class="grid grid-cols-2 gap-3">
          <app-button [fullWidth]="true" [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'"
                      appearance="outline"
                      (click)="onSaveChangesClicked()"
                      [loading]="saveDriverHiringState.loading()">SAVE CHANGES
          </app-button>
          <app-button [fullWidth]="true" type="submit" [loading]="hireDriverState.loading()"
                      (click)="onHireDriverClicked()"
          >HIRE APPLICANT
          </app-button>
        </div>
      </div>
    </form>
  </div>
</div>
