import {Component, inject, input, OnInit} from '@angular/core';
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import {
  GetSapProgramResponse, type GetSapProgramTestResultListItem, type GetSapProgramTestResultListParams,
  type GetSapProgramTestResultListResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {DateUtils} from "@shared/utils/date-utils";

@Component({
  selector: 'app-view-sap-program-history-results',
  imports: [
    ButtonComponent,
    DataTableComponent,
  ],
  templateUrl: './view-sap-program-results.component.html',
  standalone: true,
  styleUrl: './view-sap-program-results.component.scss'
})
export class ViewSapProgramResultsComponent extends BaseComponent implements OnInit {

  sapProgramId = input<string>();
  apiService = inject(ApiService);


  columnDefs: ColumnDef[] = [
    {
      title: 'Date', type: 'date', displayTemplate: '$programTestDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd MMM yyyy', showIcon: false
      }
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$programTestResult', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'Positive',
            displayText: 'Positive',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: 'bg-error-500',
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
          {
            data: 'Negative',
            displayText: 'Negative',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: "bg-success-500",
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
        ]
      }
    },
    {title: 'Notes', type: 'text', displayTemplate: '$notes', alignment: 'center'},
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        textMenuActions: [
          {iconPath: 'assets/icons/eye-outline.svg', actionKey: 'viewResult', label: 'Result'},
          {iconPath: 'assets/icons/eye-outline.svg', actionKey: 'viewCCF', label: 'CCF'},
        ]
      }
    }
  ]

  driverId: string | null = null;

  getSapProgramState = new State<GetSapProgramResponse>();
  getSapProgramResultsState = new State<GetSapProgramTestResultListResponse>();


  ngOnInit() {
    if (!this.driverId) {
      this.listenToRoute();
    }
    this.getSapProgram();
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? '';
    });
  }

  getSapProgram() {
    this.executeRequest({
      state: this.getSapProgramState,
      request: this.apiService.getSapProgram(this.sapProgramId() ?? ''),
      onSuccess: (response) => {
        let dateUtils = new DateUtils();
        let sapInfo = response.data;
        sapInfo.startDate = dateUtils.convertToMMDDYYYY(sapInfo.startDate) ?? '';
      }
    });
  }

  protected getSapProgramResults($event: TableStateEvent) {
    let request: GetSapProgramTestResultListParams = {
      sapProgramId: this.sapProgramId() ?? '',
      pageNumber: $event.paginationEvent?.pageNumber,
      pageSize: $event.paginationEvent?.pageSize,
      search: $event.searchText
    };
    this.executeRequest({
      state: this.getSapProgramResultsState,
      request: this.apiService.getSapProgramTestResultList(request),
    });
  }

  onBackClicked() {
    this.router.navigate([`/drivers/${this.driverId}/sap-program-history`]);
  }

  onTableActionPerformed($event: TableActionEvent) {
    let item: GetSapProgramTestResultListItem = $event.item;
    switch ($event.actionKey) {
      case 'viewResult':
        window.open(item.programTestFilePath, '_blank');
        break;
      case 'viewCCF':
        window.open(item.programTestCCFFilePath, '_blank');
        break;
    }
  }
}
