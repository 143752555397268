import {TableStateEvent} from "@shared/components/data-table/data-table.component";


export class Constants {

  static defaultDialogConfig = {
    // minWidth: '200px',
    // maxWidth: '600px',
    panelClass: ['w-full', 'sm:w-4/5', 'md:w-3/5', 'lg:w-2/5'],
    maxHeight: '95vh',
  };

  static defaultPageSize = 50;

  static defaultPaginationConfig = {
    pageNumber: 1,
    pageSize: 50
  };

  static defaultTableStateEvent: TableStateEvent = {
    searchText: '',
    paginationEvent: {
      pageNumber: 1,
      pageSize: 50
    },
    tableSortEvent: {
      key: undefined,
      direction: undefined
    }
  };

  static documentExpirationFilter: DocumentExpirationFilter[] = [
    {label: '10 Days', value: '10'},
    {label: '15 Days', value: '15', default: true},
    {label: '30 Days', value: '30'},
    {label: '60 Days', value: '60'}
  ];

  static phoneNumberPlaceholder = 'Ex: (123) 456-XXXX';
  static zipCodePlaceholder = 'Ex: 95148';

}

export interface DocumentExpirationFilter {
  label: string;
  value: string;
  default?: boolean;
}

