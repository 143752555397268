import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {State} from "@shared/base/base-state";
import {Constants} from "@core/constants/constants";
import {CreateOrUpdateTerminalComponent} from "./create-or-update-terminal/create-or-update-terminal.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  type GetCompanyUserTerminalListItem,
  type GetCompanyUserTerminalListParams, GetCompanyUserTerminalListResponse,
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {PageHeaderComponent} from "@core/components/page-header/page-header.component";
import {HasPermissionDirective} from "@core/directives/has-permission.directive";
import {arrayToCSV} from "@shared/utils/string-utils";
import {OverlayService} from "@shared/components/overlay/overlay.service";


@Component({
  selector: 'app-terminals',
  imports: [
    ButtonComponent,
    DataTableComponent,
    PageHeaderComponent,
    HasPermissionDirective
  ],
  templateUrl: './terminals.component.html',
  standalone: true,
  styleUrl: './terminals.component.scss'
})
export class TerminalsComponent extends BaseComponent implements OnInit {

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);
  getCompanyUserTerminalListState = new State<GetCompanyUserTerminalListResponse>();

  columnDefs: ColumnDef[] = [
    {
      title: 'Code',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$code',
      sortKey: 'code'
    },
    {
      title: 'Name',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$name',
      sortKey: 'name'
    },
    {
      title: 'Address',
      type: 'text',
      alignment: 'center',
      sortKey: 'address',
      pinned: 'left',
      objectFormatter: this.getAddress
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [{
          iconPath: 'assets/icons/edit.svg',
          actionKey: 'edit', label: 'Update Terminal'
        }
        ],
      }
    },
  ];

  ngOnInit() {
  }

  getTerminalList($event: TableStateEvent) {
    let request: GetCompanyUserTerminalListParams = {
      pageNumber: $event?.paginationEvent?.pageNumber ?? 1,
      pageSize: $event?.paginationEvent?.pageSize ?? 50,
      search: $event.searchText
    }
    this.executeRequest<GetCompanyUserTerminalListResponse>({
      state: this.getCompanyUserTerminalListState,
      request: this.apiService.getCompanyUserTerminalList(request)
    })
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetCompanyUserTerminalListItem = $event.item;
    switch ($event.actionKey) {
      case 'edit':
        this.onUpdateTerminalClicked(item);
        break;
    }
  }

  getAddress(item: GetCompanyUserTerminalListItem) {
    return arrayToCSV([item.addressLine1, item.addressLine2, item.city, item.stateName, item.zipCode]);
  }

  onUpdateTerminalClicked(item: GetCompanyUserTerminalListItem) {
    this.overlayService.openModal(CreateOrUpdateTerminalComponent, {
      data: item,
    }).closed.subscribe((success) => {
      if (success) {
        this.getTerminalList(Constants.defaultTableStateEvent);
      }
    });
  }

  onNewTerminalClicked() {
    this.overlayService.openModal(CreateOrUpdateTerminalComponent).closed.subscribe((success) => {
      if (success) {
        this.getTerminalList(Constants.defaultTableStateEvent);
      }
    })
  }

}
