@let stats = statsState.response()?.data;

<div class="app-container">
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
      <div class="overview flex flex-col gap-4">
        <p class="text-body1">Overview</p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <app-stats-card [iconPath]="'assets/icons/file-sign.svg'"
                          [label]="'Total Applications'"
                          [count]="stats?.totalDriverApplications ?? 0"
                          [color]="'bg-info-500'"
                          (onViewClick)="onApplicationsClicked()">
          </app-stats-card>

          <app-stats-card [iconPath]="'assets/icons/file-sign.svg'" [label]="'In Progress Applications'"
                          [count]="stats?.pendingDriverApplications ?? 0"
                          [color]="'bg-green-500'"
                          (onViewClick)="onApplicationsClicked()">
          </app-stats-card>

          <app-stats-card [iconPath]="'assets/icons/drivers.svg'" [label]="'Drivers'"
                          [count]="stats?.totalDrivers ?? 0"
                          [color]="'bg-purple-400'"
                          (onViewClick)="onDriversClicked()">
          </app-stats-card>

          <app-stats-card [iconPath]="'assets/icons/terminals.svg'"
                          [label]="'Terminals'"
                          [count]="stats?.totalTerminals ?? 0"
                          [color]="'bg-orange-300'"
                          (onViewClick)="onTerminalsClicked()">
          </app-stats-card>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 bg-white p-4 rounded-md">
      <div class="overview flex flex-col gap-4">
        <div class="flex justify-between items-center">
          <p class="text-body1">Expiring Documents</p>
          <app-dropdown title="Expires In"
                        [items]="Constants.documentExpirationFilter"
                        [display]="'label'"
                        [(ngModel)]="selectedExpirationFilter"
                        [isTitlePrefix]="true"
                        (valueChanged)="onDropDownFilterSelected()">
          </app-dropdown>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <app-stats-card [iconPath]="'assets/icons/license-card.svg'"
                          [label]="'Expiring License'"
                          [count]="getDocumentExpiryCount(DocumentTypeEnum.DRIVER_LICENSE)"
                          [color]="'bg-error-300'"
                          (onViewClick)="onViewClicked(DocumentTypeEnum.DRIVER_LICENSE)">
          </app-stats-card>
          <app-stats-card [iconPath]="'assets/icons/license-card.svg'"
                          [label]="'Expiring Medical Card'"
                          [count]="getDocumentExpiryCount(DocumentTypeEnum.DOT_MEDICAL_EXAMINER_CERTIFICATE)"
                          [color]="'bg-error-300'"
                          (onViewClick)="onViewClicked(DocumentTypeEnum.DOT_MEDICAL_EXAMINER_CERTIFICATE)">
          </app-stats-card>
          <app-stats-card [iconPath]="'assets/icons/license-card.svg'"
                          [label]="'Expiring Annual Clearinghouse'"
                          [count]="getDocumentExpiryCount(DocumentTypeEnum.ANNUAL_LIMITED_CLEARINGHOUSE_QUERY)"
                          [color]="'bg-error-300'"
                          (onViewClick)="onViewClicked(DocumentTypeEnum.ANNUAL_LIMITED_CLEARINGHOUSE_QUERY)">
          </app-stats-card>
        </div>
      </div>
    </div>
  </div>
</div>
