<app-base-dialog [title]="'Upload Annual Employer Pull Notice'">
  <div body>
    <form [formGroup]="fileUploadForm">
      <app-file-uploader formControlName="attachFile"
                         label="Attach File" [isRequired]="true" [uploading]="attachFileState.loading()"
                         (fileSelected)="onAttachFileClicked($event)"></app-file-uploader>
      <div class="my-2">

        @if(data.document.hasIssuedDate){
        <app-outline-text-input formControlName="issuedDate" [label]="'Issued Date'" placeholder="Ex: mm/dd/yyyy"
                                mask="00/00/0000" class="py-4 w-full" [fullWidth]="true"></app-outline-text-input>}
        @if(data.document.hasExpiryDate){
        <app-outline-text-input formControlName="expiryDate" [label]="'Expiry Date'" placeholder="Ex: mm/dd/yyyy"
                                mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>
        }

        <app-outline-text-input formControlName="completedDate" [label]="'Completed Date'" placeholder="Ex: mm/dd/yyyy"
                                mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>

        <app-outline-text-input formControlName="reviewedDate" [label]="'Reviewed Date'" placeholder="Ex: mm/dd/yyyy"
                                mask="00/00/0000" class="w-full" [fullWidth]="true"></app-outline-text-input>
        <div class="w-full max-w-lg">
          <label class="block text-body2 text-neutral-800">Notes</label>
          <textarea rows="4"
                    class="block w-full my-2 px-2 py-3  border border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500"
                    placeholder="Enter your message" formControlName="notes"></textarea>
        </div>


      </div>
    </form>
  </div>
  <div actions class="flex justify-end gap-3">
    <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
    </app-button>
    <app-button (click)="onUploadClicked()">Upload</app-button>
  </div>
</app-base-dialog>

