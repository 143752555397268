import {Routes} from "@angular/router";
import {AuthComponent} from "./features/auth/pages/auth.component";
import {SignUpComponent} from "./features/auth/pages/sign-up/sign-up.component";
import {LoginComponent} from "./features/auth/pages/login/login.component";
import {ResetPasswordComponent} from "./features/auth/pages/reset-password/reset-password.component";
import {
  SetupCompanyProfileComponent
} from "./features/auth/pages/setup-company-profile/setup-company-profile.component";
import {DashboardComponent} from "./features/dashboard/pages/dashboard.component";
import {LayoutComponent} from "./features/layout/layout.component";
import {DriversListComponent} from "./features/drivers/pages/drivers-list/drivers-list.component";
import {ForgotPasswordComponent} from "./features/auth/pages/forgot-password/forgot-password.component";
import {SetupPasswordComponent} from "./features/auth/pages/setup-password/setup-password.component";
import {authGuard} from "@core/api/auth.guard";
import {PageNotFoundComponent} from "./features/layout/page-not-found/page-not-found.component";
import {
  DriverApplicationComponent
} from "./features/driver-application/pages/complete-driver-application/driver-application.component";
import {
  DriverApplicationListComponent
} from "./features/driver-application/pages/driver-application-list/driver-application-list.component";
import {TestComponent} from "./features/test/test.component";
import {
  SaveApplicantCompleteApplicationComponent
} from "./features/driver-application/pages/complete-driver-application/save-applicant-complete-application/save-applicant-complete-application.component";
import {
  HireDriverComponent
} from "./features/driver-application/pages/hire-driver/hire-driver.component";
import {
  ProficiencyTestComponent
} from "./features/driver-application/pages/proficiency-test/proficiency-test.component";
import {UsersComponent} from "./features/users/pages/users.component";
import {
  DriverInfoComponent
} from "./features/drivers/pages/driver-info.component";
import {EquipmentsListComponent} from "./features/equipments/equipment-list/equipments-list.component";
import {
  BusMaintenanceSafetyInspectionComponent
} from "./features/equipments/equipments-inspections/bus-maintenance-safety-inspection/bus-maintenance-safety-inspection.component";
import {EquipmentsInfoComponent} from "./features/equipments/equipments-info/equipments-info.component";
import {CompanySettingsComponent} from "./features/settings/company-settings/company-settings.component";
import {UserProfileSettingsComponent} from "./features/settings/user-profile-settings/user-profile-settings.component";
import {CompanyLoginComponent} from "./features/auth/pages/company-login/company-login.component";
import {
  SapProgramHistoryComponent
} from "./features/drivers/pages/driver-sap-program/sap-program-history/sap-program-history.component";
import {
  ViewSapProgramResultsComponent
} from "./features/drivers/pages/driver-sap-program/view-sap-program-results/view-sap-program-results.component";
import {TerminalsComponent} from "./features/terminals/terminals.component";
import {DriverGeneralInfoComponent} from "./features/drivers/pages/driver-general-info/driver-general-info.component";
import {
  DriverResidencyInfoComponent
} from "./features/drivers/pages/driver-residency-info/driver-residency-info.component";
import {DriverLicenseInfoComponent} from "./features/drivers/pages/driver-license-info/driver-license-info.component";
import {DriverDocumentsComponent} from "./features/drivers/pages/driver-documents/driver-documents.component";
import {DriverSapProgramComponent} from "./features/drivers/pages/driver-sap-program/driver-sap-program.component";
import {DriverRemindersComponent} from "./features/drivers/pages/driver-reminders/driver-reminders.component";
import {
  DrugAndAlcoholPolicyAgreementComponent
} from "./features/driver-application/pages/complete-driver-application/save-applicant-complete-application/drug-and-alcohol-policy-agreement/drug-and-alcohol-policy-agreement.component";
import {
  ClearinghousePolicyAgreementComponent
} from "./features/driver-application/pages/complete-driver-application/save-applicant-complete-application/clearinghouse-policy-agreement/clearinghouse-policy-agreement.component";
import {ServiceTypesComponent} from "./features/maintenance-settings/service-types/service-types.component";
import {
  OutOfServiceReasonsComponent
} from "./features/maintenance-settings/out-of-service-reasons/out-of-service-reasons.component";
import {RepairTypesComponent} from "./features/maintenance-settings/repair-types/repair-types.component";
import {
  DriverAllDocumentsComponent
} from "./features/drivers/pages/driver-all-documents/driver-all-documents.component";
import {
  ApplyForDriverJobComponent
} from "./features/terminals/apply-for-driver-job/apply-for-driver-job.component";
import {ExpiringDocumentsComponent} from "./features/reports/expiring-documents/expiring-documents.component";

export const routes: Routes = [
  {path: "", redirectTo: "/login", pathMatch: "full"},
  {path: "test", component: TestComponent},
  {path: "apply-for-job/:terminalCode", component: ApplyForDriverJobComponent},

  {
    path: "",
    component: AuthComponent,
    children: [
      {path: "sign-up", component: SignUpComponent},
      {path: "login", component: LoginComponent},
      {path: "setup-password/:token", component: SetupPasswordComponent},
      {path: "forgot-password", component: ForgotPasswordComponent},
      {path: "reset-password/:token", component: ResetPasswordComponent},
    ],
  },
  {path: "company-login", component: CompanyLoginComponent},
  {
    path: "setup-company-profile",
    component: SetupCompanyProfileComponent,
    canActivate: [authGuard],
  },
  {
    path: "driver-application/apply",
    component: DriverApplicationComponent,
    canActivate: [],
  },
  {
    path: "",
    canActivate: [authGuard],
    component: LayoutComponent,
    children: [
      {path: "dashboard", component: DashboardComponent},
      {path: "drivers", component: DriversListComponent},
      {path: "driver-applications", component: DriverApplicationListComponent},
      {path: "users", component: UsersComponent},
      {path: "hire-driver", component: HireDriverComponent},
      {path: "proficiency-test", component: ProficiencyTestComponent},
      {
        path: "drivers/:driverId", component: DriverInfoComponent, children: [
          {path: 'general-info', component: DriverGeneralInfoComponent},
          {path: 'residencies', component: DriverResidencyInfoComponent},
          {path: 'licenses', component: DriverLicenseInfoComponent},
          {path: 'documents', component: DriverDocumentsComponent},
          {path: 'all-documents', component: DriverAllDocumentsComponent},
          {path: 'sapProgram', component: DriverSapProgramComponent},
          {path: 'reminders', component: DriverRemindersComponent},
          {path: "sap-program-history", component: SapProgramHistoryComponent},
          {path: "sap-program-results", component: ViewSapProgramResultsComponent},
        ]
      },
      {path: "equipments-list", component: EquipmentsListComponent},
      {path: "equipments", component: EquipmentsInfoComponent},
      {path: "terminals", component: TerminalsComponent},
      {path: "reports/expiring-documents", component: ExpiringDocumentsComponent},
      {path: "maintenance-safety-inspection", component: BusMaintenanceSafetyInspectionComponent},
      {path: 'company-settings', component: CompanySettingsComponent},
      {path: 'profile-settings', component: UserProfileSettingsComponent},
      {path: 'maintenance-settings/service-types', component: ServiceTypesComponent},
      {path: 'maintenance-settings/repair-types', component: RepairTypesComponent},
      {path: 'maintenance-settings/out-of-service-reasons', component: OutOfServiceReasonsComponent},
    ],
  },
  {
    path: "complete-driver-application",
    component: SaveApplicantCompleteApplicationComponent
  },
  {path: "drug-and-alcohol-policy-agreement", component: DrugAndAlcoholPolicyAgreementComponent},
  {path: "clearinghouse-policy-agreement", component: ClearinghousePolicyAgreementComponent},
  {path: "**", component: PageNotFoundComponent},
];
