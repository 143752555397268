import {inject, Injectable, OnInit, signal} from "@angular/core";
import {Router} from "@angular/router";
import {AppDataService} from "../app-data/app-data.service";
import {jwtDecode, JwtPayload} from "@shared/utils/jwt-decode-utils";
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class TokenStoreService {
  router = inject(Router);
  appDataService = inject(AppDataService);
  route = inject(ActivatedRoute);

  storage: Storage;

  private static REFRESH_TOKEN_KEY = "refresh_token";
  private static ACCESS_TOKEN_KEY = "access_token";
  private static DRIVER_APPLICATION_ID = "driver_application_id";

  isLoggedIn = signal<boolean>(false);

  constructor() {
    this.storage = localStorage;
    const token = this.getToken();
    if (token.refreshToken && token.accessToken) {
      this.isLoggedIn.update(() => true);
    } else {
      this.isLoggedIn.update(() => false);
    }
  }

  saveToken(refreshToken: string, accessToken: string) {
    this.storage.setItem(TokenStoreService.REFRESH_TOKEN_KEY, refreshToken);
    this.storage.setItem(TokenStoreService.ACCESS_TOKEN_KEY, accessToken);
    this.isLoggedIn.update(() => true);
  }

  getToken() {
    return {
      refreshToken: this.storage.getItem(TokenStoreService.REFRESH_TOKEN_KEY),
      accessToken: this.storage.getItem(TokenStoreService.ACCESS_TOKEN_KEY),
    };
  }

  saveDriverApplicationToken(token: string | undefined) {
    this.storage.clear();
    if (token) {
      this.storage.setItem(TokenStoreService.ACCESS_TOKEN_KEY, token);
    }
  }

  saveDriverApplicationId(driverApplicationId: string) {
    this.storage.setItem(TokenStoreService.DRIVER_APPLICATION_ID, driverApplicationId);
  }

  getDriverApplicationId(): string {
    let driverApplicationId = this.storage.getItem(TokenStoreService.DRIVER_APPLICATION_ID);
    if (driverApplicationId != null) {
      return driverApplicationId;
    }
    let token = this.getToken().accessToken;
    try {
      const decoded = jwtDecode<any>(token ?? '');
      return decoded.driverApplicationId;
    } catch (error) {
      return '';
    }
  }

  isValidJWTToken(token: string | undefined) {
    try {
      jwtDecode<JwtPayload>(token ?? "");
      return true;
    } catch (error) {
      return false;
    }
  }

  logout() {
    this.isLoggedIn.set(false);
    this.storage.removeItem(TokenStoreService.REFRESH_TOKEN_KEY);
    this.storage.removeItem(TokenStoreService.ACCESS_TOKEN_KEY);
    this.storage.removeItem(TokenStoreService.DRIVER_APPLICATION_ID);
    this.storage.removeItem(AppDataService.IS_SIDEBAR_EXPANDED);

    this.storage.clear();
    this.appDataService.reset();
    this.router.navigate(["/"]);
  }

  clearAuthTokens() {
    this.storage.removeItem(TokenStoreService.REFRESH_TOKEN_KEY);
    this.storage.removeItem(TokenStoreService.ACCESS_TOKEN_KEY);
    this.storage.removeItem(TokenStoreService.DRIVER_APPLICATION_ID);
  }

  getTokenPayload(): LoggedInUserTokenPayload | null {
    const token = this.getToken().accessToken;
    if (token) {
      return jwtDecode<LoggedInUserTokenPayload>(token);
    }
    return null;
  }

  getUserRole() {
    let userInfo = this.getTokenPayload();
    let role: UserRole = userInfo?.role as UserRole;
    return role;
  }

  isCompanyAdmin(): boolean {
    let userInfo = this.getTokenPayload();
    if (userInfo?.role == UserRole.companyAdmin) {
      return true;
    }
    return false;
  }
}

export enum UserRole {
  yscAdmin = 'YSC_ADMIN',
  companyAdmin = 'COMPANY_ADMIN',
  companyRegionalManager = "COMPANY_REGIONAL_MANAGER",
  companyTerminalManager = "COMPANY_TERMINAL_MANAGER",
  driverApplicant = "DRIVER_APPLICANT",
  driver = "DRIVER",
}


export interface LoggedInUserTokenPayload {
  username: string;
  id: string;
  driverApplicationId?: string;
  name: string;
  role: string;
  companyId: string;
  companyUserId: string;
  module: string;
  isProfileCompleted: boolean;
  iat: number;
  exp: number;
}
