import {Component, inject, input, OnInit} from "@angular/core";
import {ButtonComponent} from "@shared/components/button/button.component";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "@shared/base/base-component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {
  GetCompanyResponse, GetDriverApplication,
  GetDriverApplicationParams,
  GetDriverApplicationResponse,
  type MarkDriverApplicationHiredRequest,
  type MarkDriverApplicationHiredResponse,
  type SavePreHiringDriverApplicationInfoRequest,
  SavePreHiringDriverApplicationInfoResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileRequestType,
  UploadDriverApplicationFileResponse
} from "@core/api/model";
import {BaseInputComponent} from "@shared/inputs/_base/base-input/base-input.component";
import {printInvalidFields} from "@shared/utils/form-utils";
import {ProficiencyTestComponent} from "../proficiency-test/proficiency-test.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: "app-hire-driver",
  standalone: true,
  imports: [
    ButtonComponent,
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent,
    BaseInputComponent,
    TextInputComponent,
    DateInputComponent
  ],
  templateUrl: "./hire-driver.component.html",
  styleUrl: "./hire-driver.component.scss",
})
export class HireDriverComponent extends BaseComponent implements OnInit {

  token = input<string>();
  applicationId = input<string>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  overlayService = inject(OverlayService);


  companyInfoState = new State<GetCompanyResponse>();
  driverApplicationInfoState = new State<GetDriverApplicationResponse>();

  uploadClearingHouseLimitedQueryPolicyState = new State<UploadDriverApplicationFileResponse>();
  uploadPreEmploymentClearingHouseQueryState = new State<UploadDriverApplicationFileResponse>();
  uploadDrugAlcoholPolicyState = new State<UploadDriverApplicationFileResponse>();
  uploadDrugTestResultState = new State<UploadDriverApplicationFileResponse>();
  uploadDrugTestCCFState = new State<UploadDriverApplicationFileResponse>();
  uploadReturnToDutyState = new State<UploadDriverApplicationFileResponse>();
  uploadDmvPrintOutOrMVRState = new State<UploadDriverApplicationFileResponse>();
  uploadMedicalExaminerCertificateState = new State<UploadDriverApplicationFileResponse>();
  uploadScreenshotOfNationalRegistryReferenceState = new State<UploadDriverApplicationFileResponse>();
  uploadProficiencyTestResultState = new State<UploadDriverApplicationFileResponse>();
  uploadEmployerPullNoticeState = new State<UploadDriverApplicationFileResponse>();
  uploadSpabCertificateState = new State<UploadDriverApplicationFileResponse>();
  uploadT01CertificateState = new State<UploadDriverApplicationFileResponse>();
  uploadT02CertificateState = new State<UploadDriverApplicationFileResponse>();
  uploadHAZMATCertificateState = new State<UploadDriverApplicationFileResponse>();

  hireDriverState = new State<MarkDriverApplicationHiredResponse>();

  saveDriverHiringState = new State<SavePreHiringDriverApplicationInfoResponse>();
  driverHiringForm!: FormGroup;


  ngOnInit() {
    this.initForm();
    this.initFormListener();
    this.handleDriverApplicationId();
    this.getCompanyInfo();
    this.getDriverApplicationInfo();
  }

  private initForm() {
    this.driverHiringForm = this.formBuilder.group({
      clearingHouseLimitedQueryPolicyFilePath: [null, Validators.required],
      preEmploymentClearingHouseFilePath: [null, Validators.required],
      preEmploymentClearingHouseExpiryDate: [null, Validators.required],
      preEmploymentClearingHouseCompletedDate: [null, [Validators.required, onlyPastDateValidator()]],
      preEmploymentClearingHouseUploadFrequency: [null, Validators.required],

      drugAndAlcoholPolicyFilePath: [null, Validators.required],
      drugAndAlcoholPolicyDate: [null, [Validators.required, onlyPastDateValidator()]],
      drugTestResultFilePath: [null, Validators.required],
      dmvPrintOutOrMVRFilePath: [null, Validators.required],
      drugTestResultCompletedDate: [null, [Validators.required, onlyPastDateValidator()]],
      drugTestCCFFilePath: [null, Validators.required],
      drugTestCCFCompletedDate: [null, [Validators.required, onlyPastDateValidator()]],
      mroVerificationDate: [null, Validators.required],
      isDrugTestResultPositive: [false, Validators.required],
      hasStartedSAPProgram: [true, Validators.required],
      isUnderSapProgram: [false],
      returnToDutyFilePath: [null, Validators.required],
      sapTestFrequency: [null],
      sapTestDuration: [null],
      medicalExaminerCertificateFilePath: [null, Validators.required],
      medicalExaminerNationalRegistryScreenshotFilePath: [null, Validators.required],
      medicalExaminerCertificateExpiryDate: [null, Validators.required],

      proficiencyTestResultFilePath: [null, Validators.required],
      employerPullNoticeFilePath: [null],

      hasSPABCertificate: [false],
      spabCertificateFilePath: [null],
      spabCertificateIssuedDate: [null, [onlyPastDateValidator()]],
      t01CertificateFilePath: [null],
      t01CertificateIssuedDate: [null, [onlyPastDateValidator()]],
      t02CertificateFilePath: [null],
      t02CertificateIssuedDate: [null, [onlyPastDateValidator()]],

      driverTransportsHazmat: [false],
      hazmatCertificateFilePath: [null],
      hazmatCertificateIssuedDate: [null, [onlyPastDateValidator()]],
      hazmatCertificateExpiryDate: [null],
      confirmationOfRequirements: [null],
    });
  }

  initFormListener() {
    this.driverHiringForm.controls['driverTransportsHazmat'].valueChanges.subscribe(value => {
      this.updateHazmatValidators();
    });

    this.driverHiringForm.controls['hasSPABCertificate'].valueChanges.subscribe(value => {
      this.updateSpabValidation();
    });

    this.driverHiringForm.controls['hasSPABCertificate'].valueChanges.subscribe(value => {
      this.updateSapProgramValidators();
    });

    this.driverHiringForm.controls['isUnderSapProgram'].valueChanges.subscribe(value => {
      this.updateReturnToDutyValidators();
    });

    this.driverHiringForm.controls['hasStartedSAPProgram'].valueChanges.subscribe(value => {
      this.updateReturnToDutyValidators();
    });
  }

  updateReturnToDutyValidators() {
    let isUnderSapProgram = this.driverHiringForm.get('isUnderSapProgram')?.value == true;
    let hasStartedSAPProgram = this.driverHiringForm.get('hasStartedSAPProgram')?.value == true;

    if (isUnderSapProgram || hasStartedSAPProgram) {
      this.driverHiringForm.controls['returnToDutyFilePath'].setValidators([Validators.required]);
    } else {
      this.driverHiringForm.controls['returnToDutyFilePath'].clearValidators();
    }
    this.driverHiringForm.controls['returnToDutyFilePath'].updateValueAndValidity();
  }

  updateSapProgramValidators() {
    if (this.driverHiringForm.get('hasStartedSAPProgram')?.value) {
      this.driverHiringForm.controls['sapTestFrequency'].setValidators([Validators.required]);
      this.driverHiringForm.controls['sapTestDuration'].setValidators([Validators.required]);
    } else {
      this.driverHiringForm.controls['sapTestFrequency'].clearValidators();
      this.driverHiringForm.controls['sapTestDuration'].clearValidators();
    }
    this.driverHiringForm.controls['spabCertificateFilePath'].updateValueAndValidity();
    this.driverHiringForm.controls['spabCertificateIssuedDate'].updateValueAndValidity();
  }

  updateHazmatValidators() {
    let companyInfo = this.companyInfoState.response()?.data;
    let transportsHazMat = companyInfo?.transportsHazwaste || companyInfo?.transportsHazmat;

    console.log(transportsHazMat);

    if (transportsHazMat && this.driverHiringForm.get('driverTransportsHazmat')?.value) {
      this.driverHiringForm.controls['hazmatCertificateFilePath'].setValidators([Validators.required]);
      this.driverHiringForm.controls['hazmatCertificateIssuedDate'].setValidators([Validators.required, onlyPastDateValidator()]);
      this.driverHiringForm.controls['hazmatCertificateExpiryDate'].setValidators([Validators.required]);
    } else {
      this.driverHiringForm.controls['hazmatCertificateFilePath'].clearValidators();
      this.driverHiringForm.controls['hazmatCertificateIssuedDate'].clearValidators();
      this.driverHiringForm.controls['hazmatCertificateExpiryDate'].clearValidators();
    }

    this.driverHiringForm.controls['hazmatCertificateFilePath'].updateValueAndValidity();
    this.driverHiringForm.controls['hazmatCertificateIssuedDate'].updateValueAndValidity();
    this.driverHiringForm.controls['hazmatCertificateExpiryDate'].updateValueAndValidity();
  }

  updateSpabValidation() {
    let driverApplicationInfo = this.driverApplicationInfoState.response()?.data;
    let companyInfo = this.companyInfoState.response()?.data;

    let hasSchoolBusEndorsement = driverApplicationInfo?.hasSchoolBus;
    let schoolBusCompany = companyInfo?.businessTypeId == 'BUS';

    if ((hasSchoolBusEndorsement && this.driverHiringForm.get('hasSPABCertificate')?.value) || schoolBusCompany) {
      this.driverHiringForm.controls['spabCertificateFilePath'].setValidators([Validators.required]);
      this.driverHiringForm.controls['spabCertificateIssuedDate'].setValidators([Validators.required, onlyPastDateValidator()]);
      this.driverHiringForm.controls['t01CertificateFilePath'].setValidators([Validators.required]);
      this.driverHiringForm.controls['t01CertificateIssuedDate'].setValidators([Validators.required, onlyPastDateValidator()]);
      this.driverHiringForm.controls['t02CertificateFilePath'].setValidators([Validators.required]);
      this.driverHiringForm.controls['t02CertificateIssuedDate'].setValidators([Validators.required, onlyPastDateValidator()]);
    } else {
      this.driverHiringForm.controls['spabCertificateFilePath'].clearValidators();
      this.driverHiringForm.controls['spabCertificateIssuedDate'].clearValidators();
    }
    this.driverHiringForm.controls['spabCertificateFilePath'].updateValueAndValidity();
    this.driverHiringForm.controls['spabCertificateIssuedDate'].updateValueAndValidity();
  }

  private getDriverApplicationInfo() {

    let request: GetDriverApplicationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationResponse>({
      request: this.apiService.getDriverApplication(request),
      state: this.driverApplicationInfoState,
      onSuccess: response => {
        let data: GetDriverApplication | null = response.data;
        let dmvPrintout = data?.dmvPrintOutDate;
        let preEmploymentClearingHouseExpirationDate = data?.preEmploymentClearingHouseExpiryDate;
        let preEmploymentClearingHouseCompleteDate = data?.preEmploymentClearingHouseCompletedDate;
        let drugAndAlcoholDate = data?.drugAndAlcoholPolicyDate;
        let drugTestResultCompleteDate = data?.drugTestResultCompletedDate;
        let drugTestCCFCompleteDate = data?.drugTestCCFCompletedDate;
        let mroVerifyDate = data?.mroVerificationDate;
        let medicalExaminerCertificateExpiryDate = data?.medicalCardExpirationDate;
        let spabCertificateIssueDate = data?.spabCertificateIssuedDate;
        let t01CertificateIssueDate = data?.t01CertificateIssuedDate;
        let t02CertificateIssueDate = data?.t02CertificateIssuedDate;
        let hazmatCertificateIssueDate = data?.hazmatCertificateIssuedDate;
        let hazmatCertificateExpirationDate = data?.hazmatCertificateExpiryDate;

        this.driverHiringForm.controls["preEmploymentClearingHouseFilePath"].setValue(data.preEmploymentClearingHouseFilePath);
        this.driverHiringForm.controls["preEmploymentClearingHouseExpiryDate"].setValue(preEmploymentClearingHouseExpirationDate);
        this.driverHiringForm.controls["preEmploymentClearingHouseCompletedDate"].setValue(preEmploymentClearingHouseCompleteDate);
        this.driverHiringForm.controls["preEmploymentClearingHouseUploadFrequency"].setValue(data.preEmploymentClearingHouseUploadFrequency);
        this.driverHiringForm.controls["drugAndAlcoholPolicyFilePath"].setValue(data.drugAndAlcoholPolicyFilePath);
        this.driverHiringForm.controls["drugAndAlcoholPolicyDate"].setValue(drugAndAlcoholDate);
        this.driverHiringForm.controls["drugTestResultFilePath"].setValue(data.drugTestResultFilePath);
        this.driverHiringForm.controls["drugTestResultCompletedDate"].setValue(drugTestResultCompleteDate);
        this.driverHiringForm.controls["drugTestCCFFilePath"].setValue(data.drugTestCCFFilePath);
        this.driverHiringForm.controls["drugTestCCFCompletedDate"].setValue(drugTestCCFCompleteDate);

        this.driverHiringForm.controls["hasStartedSAPProgram"].setValue(data.hasStartedSAPProgram ?? false);
        this.driverHiringForm.controls["sapTestFrequency"].setValue(data.sapTestFrequency);
        this.driverHiringForm.controls["sapTestDuration"].setValue(data.sapTestDuration);

        this.driverHiringForm.controls["returnToDutyFilePath"].setValue(data.returnToDutyFilePath);
        this.driverHiringForm.controls["clearingHouseLimitedQueryPolicyFilePath"].setValue(data.clearingHouseLimitedQueryPolicyFilePath);
        this.driverHiringForm.controls["dmvPrintOutOrMVRFilePath"].setValue(data.dmvPrintOutOrMVRFilePath);
        this.driverHiringForm.controls["mroVerificationDate"].setValue(mroVerifyDate);
        this.driverHiringForm.controls["medicalExaminerCertificateFilePath"].setValue(data.dotMedicalCertificateFilePath);
        this.driverHiringForm.controls["medicalExaminerNationalRegistryScreenshotFilePath"].setValue(data.medicalExaminerNationalRegistryScreenshotFilePath);
        this.driverHiringForm.controls["medicalExaminerCertificateExpiryDate"].setValue(medicalExaminerCertificateExpiryDate);
        this.driverHiringForm.controls["proficiencyTestResultFilePath"].setValue(data.proficiencyTestResultFilePath);
        this.driverHiringForm.controls["employerPullNoticeFilePath"].setValue(data.employerPullNoticeFilePath);

        this.driverHiringForm.controls["hasSPABCertificate"].setValue(data.hasSPABCertificate ?? false);
        this.driverHiringForm.controls["spabCertificateFilePath"].setValue(data.spabCertificateFilePath);
        this.driverHiringForm.controls["spabCertificateIssuedDate"].setValue(spabCertificateIssueDate);
        this.driverHiringForm.controls["t01CertificateFilePath"].setValue(data.t01CertificateFilePath);
        this.driverHiringForm.controls["t01CertificateIssuedDate"].setValue(t01CertificateIssueDate);
        this.driverHiringForm.controls["t02CertificateFilePath"].setValue(data.t02CertificateFilePath);
        this.driverHiringForm.controls["t02CertificateIssuedDate"].setValue(t02CertificateIssueDate);

        this.driverHiringForm.controls["driverTransportsHazmat"].setValue(data.driverTransportsHazmat ?? false);
        this.driverHiringForm.controls["hazmatCertificateFilePath"].setValue(data.hazmatCertificateFilePath);
        this.driverHiringForm.controls["hazmatCertificateIssuedDate"].setValue(hazmatCertificateIssueDate);
        this.driverHiringForm.controls["hazmatCertificateExpiryDate"].setValue(hazmatCertificateExpirationDate);
        this.driverHiringForm.controls["isUnderSapProgram"].setValue(data.isUnderSapProgram);
        this.driverHiringForm.controls["drugAndAlcoholPolicyFilePath"].setValue(data.drugAndAlcoholPolicyFilePath);

        this.updateSapProgramValidators();
        this.updateSpabValidation();
        this.updateHazmatValidators();
      }
    });
  }

  // onStartProficiencyTest() {
  //   this.router.navigate(["/proficiency-test"]);
  // }

  private handleDriverApplicationId() {
    if (this.token() != null) {
      this.handleToken();
    } else if (this.applicationId() != null) {
      this.tokenService.saveDriverApplicationId(this.applicationId()!);
    }
  }

  onStartProficiencyTest() {
    this.overlayService.openBackdrop(ProficiencyTestComponent);
  }

  handleToken() {
    let isValidToken = this.tokenService.isValidJWTToken(this.token());
    if (isValidToken) {
      this.tokenService.saveDriverApplicationToken(this.token());
    } else {
      this.toasterService.error("Invalid Driver Application");
      this.router.navigate(["/login"]);
    }
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
      onSuccess: response => {
        this.updateHazmatValidators()
        this.updateSpabValidation();
      }
    });
  }

  onFileSelectedForUpload($event: File, type: UploadDriverApplicationFileRequestType, state: State<UploadDriverApplicationFileResponse>) {
    let id = this.tokenService.getDriverApplicationId();
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: id,
      file: $event,
      type: type,
    };
    this.executeRequest<UploadDriverApplicationFileResponse>({
      state: state,
      request: this.apiService.uploadDriverApplicationFile(request),
    });
  }

  onSaveChangesClicked(hireAfterSave: boolean) {
    if (hireAfterSave) {
      this.validateForm(this.driverHiringForm);
      printInvalidFields(this.driverHiringForm);
      if (this.driverHiringForm.invalid) {
        return;
      }
    }

    let driverApplicationId = this.tokenService.getDriverApplicationId();

    let request: SavePreHiringDriverApplicationInfoRequest = {
      driverApplicationId: driverApplicationId,
      driverTransportsHazmat: this.driverHiringForm.get('driverTransportsHazmat')?.value ?? false,
      drugAndAlcoholPolicyDate: this.driverHiringForm.get('drugAndAlcoholPolicyDate')?.value,
      drugTestCCFCompletedDate: this.driverHiringForm.get('drugTestCCFCompletedDate')?.value,
      drugTestResultCompletedDate: this.driverHiringForm.get('drugTestResultCompletedDate')?.value,
      hasSPABCertificate: this.driverHiringForm.get('hasSPABCertificate')?.value ?? false,
      hasStartedSAPProgram: this.driverHiringForm.get('hasStartedSAPProgram')?.value ?? false,
      hazmatCertificateExpiryDate: this.driverHiringForm.get('hazmatCertificateExpiryDate')?.value,
      hazmatCertificateIssuedDate: this.driverHiringForm.get('hazmatCertificateIssuedDate')?.value,
      isDrugTestResultPositive: this.driverHiringForm.get('isDrugTestResultPositive')?.value ?? false,
      medicalExaminerCertificateExpiryDate: this.driverHiringForm.get('medicalExaminerCertificateExpiryDate')?.value,
      mroVerificationDate: this.driverHiringForm.get('mroVerificationDate')?.value ?? ''!,
      preEmploymentClearingHouseCompletedDate: this.driverHiringForm.get('preEmploymentClearingHouseCompletedDate')?.value,
      preEmploymentClearingHouseExpiryDate: this.driverHiringForm.get('preEmploymentClearingHouseExpiryDate')?.value,
      preEmploymentClearingHouseUploadFrequency: this.driverHiringForm.get('preEmploymentClearingHouseUploadFrequency')?.value,
      sapTestDuration: this.driverHiringForm.get('sapTestDuration')?.value,
      sapTestFrequency: this.driverHiringForm.get('sapTestFrequency')?.value,
      spabCertificateIssuedDate: this.driverHiringForm.get('spabCertificateIssuedDate')?.value,
      t01CertificateIssuedDate: this.driverHiringForm.get('t01CertificateIssuedDate')?.value,
      t02CertificateIssuedDate: this.driverHiringForm.get('t02CertificateIssuedDate')?.value
    };
    this.executeRequest({
      state: this.saveDriverHiringState,
      request: this.apiService.savePreHiringDriverApplicationInfo(request),
      handleSuccess: true,
      handleError: false,
      onSuccess: response => {
        if (hireAfterSave) {
          this.hireDriver();
        }
      }
    });
  }

  hireDriver() {
    this.validateForm(this.driverHiringForm);
    if (this.driverHiringForm.invalid) {
      return;
    }

    let driverApplicationId = this.tokenService.getDriverApplicationId()
    let request: MarkDriverApplicationHiredRequest = {
      driverApplicationId: driverApplicationId
    }
    this.executeRequest({
      state: this.hireDriverState,
      request: this.apiService.markDriverApplicationHired(request),
      successMessage: "Driver Hired Successfully",
      handleSuccess: true,
      handleError: false,
      onSuccess: response => {
        this.router.navigate(["/drivers"]);
      }
    });
  }

  protected readonly UploadDriverApplicationFileRequestType = UploadDriverApplicationFileRequestType;
}
