/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Api
 * OpenAPI spec version: 1.0
 */

export type SaveDriverApplicationEducationEducationType = typeof SaveDriverApplicationEducationEducationType[keyof typeof SaveDriverApplicationEducationEducationType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SaveDriverApplicationEducationEducationType = {
  HighSchool: 'HighSchool',
  College: 'College',
} as const;
