import {Component, ElementRef, inject, model, OnInit, QueryList, signal, ViewChild, ViewChildren} from '@angular/core';
import {DropdownComponent} from "../../shared/inputs/dropdown/dropdown.component";
import {ControlContainer, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "../../shared/inputs/outline-text-input/outline-text-input.component";
import {ButtonComponent} from "../../shared/components/button/button.component";
import {BaseComponent} from "../../shared/base/base-component";
import {DateSelectorInputComponent} from "../../shared/inputs/date-selector-input/date-selector-input.component";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "../../shared/inputs/outline-date-input/outline-date-input.component";
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef
} from "@angular/cdk/overlay";
import {CommonService} from "../../core/services/common/common.service";
import {State} from "../../shared/base/base-state";
import {CountriesResponse} from "../../core/services/common/countries/countries-response";
import {UserInfoComponent} from "./user-info/user-info.component";
import {CdkPortal, ComponentPortal} from "@angular/cdk/portal";
import {MultiSelectionFieldComponent} from "../../shared/inputs/multi-selection-field/multi-selection-field.component";
import {
  SingleSelectionFieldComponent
} from "../../shared/inputs/single-selection-field/single-selection-field.component";
import {RadioButtonComponent} from "../../shared/inputs/radio-button/radio-button.component";
import {SwitchComponent} from "../../shared/inputs/switch/switch.component";
import {CheckboxComponent} from "../../shared/inputs/checkbox/checkbox.component";
import {
  resolveTemplateWithObject, TemplateResolverFormat
} from "../../shared/common-utils/template-resolver";
import {
  MultiSelectDropdownAppearance,
  MultiSelectDropdownComponent
} from "../../shared/inputs/multi-select-dropdown/multi-select-dropdown.component";
import {ApiService} from "../../core/api/api.service";
import {GetCountryListResponse} from "../../core/api/model";
import {PaginationComponent} from "../../shared/components/pagination/pagination.component";
import {TimePickerComponent, TimePickerValue} from "../../shared/inputs/time-picker/time-picker.component";
import {DateSelectorViewComponent} from "../../shared/inputs/date-selector-view/date-selector-view.component";
import {NumberInputComponent} from "../../shared/inputs/number-input/number-input.component";

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    DropdownComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    DateSelectorInputComponent,
    OutlineDateInputComponent,
    UserInfoComponent,
    CdkPortal,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    DropdownComponent,
    DropdownComponent,
    MultiSelectionFieldComponent,
    SingleSelectionFieldComponent,
    RadioButtonComponent,
    SwitchComponent,
    CheckboxComponent,
    MultiSelectDropdownComponent,
    PaginationComponent,
    TimePickerComponent,
    DateSelectorViewComponent,
    NumberInputComponent
  ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  commonService = inject(CommonService);
  overlay = inject(Overlay);

  @ViewChild('triggerBtn') triggerBtn!: ElementRef;


  numberFieldValue = signal(10);

  time = signal<TimePickerValue>({
    hours: 5,
    minutes: 30,
  });

  person = signal({name: "Santhosh"});

  country = model(['India']);

  apiService = inject(ApiService);


  countriesState = new State<CountriesResponse>();
  countriesStateV2 = new State<GetCountryListResponse>();

  namesList = signal([
    {id: "1", name: 'John'},
    {id: "2", name: 'Doe'},
    {id: "3", name: 'Jane'},
    {id: "4", name: 'Smith'},
    {id: "5", name: 'Alex'},
    {id: "6", name: 'Michael'},
    {id: "7", name: 'Jordan'},
    {id: "8", name: 'David'},
  ]);

  form!: FormGroup;

  isOpen = signal(false);

  ngOnInit(): void {
    this.initForm();
    this.getCountries();
  }

  exp = signal('');
  private initForm() {

    let time: TimePickerValue = {
      hours: 14,
      minutes: 30,
    };

    this.form = this.formBuilder.group({
      numberValue: [],
      time: [time],
      names: [["3", "4"]],
      country: [[]],
      q: ['q'],
      w: ['w'],
      e: ['e'],
      r: ['r'],
      t: ['t'],
      y: ['y'],
      u: ['u'],
      i: ['i'],
      yeOrNo: [false],
      o: ['o', [Validators.required]],
      p: ['p', [Validators.required]],
      a: ['a', [Validators.required]],
      s: ['s', [Validators.required]],
      d: ['d', [Validators.required]],
      f: ['', [Validators.required]],
      g: ['', [Validators.required]],
      h: ['', [Validators.required]],
      j: ['', [Validators.required]],
      k: ['', [Validators.required]],
      l: ['', [Validators.required]],
      z: ['', [Validators.required]],
      x: ['', [Validators.required]],
    });
  }

  onValidate() {
    this.validateForm(this.form);
  }

  private getCountries() {

    this.executeRequest({
      request: this.apiService.getCountryList(),
      state: this.countriesStateV2,
      onSuccess: (response) => {

      }
    })
    this.executeRequest({
      request: this.commonService.getCountries(),
      state: this.countriesState
    })
  }

  openPopup() {
    // const positionStrategy = this.positionBuilder
    //   .flexibleConnectedTo(this.triggerBtn)
    //   .withPositions([
    //     {
    //       originX: 'start',
    //       originY: 'bottom',
    //       overlayX: 'start',
    //       overlayY: 'top',
    //     },
    //     {
    //       originX: 'end',
    //       originY: 'bottom',
    //       overlayX: 'end',
    //       overlayY: 'top',
    //     },
    //     {
    //       originX: 'start',
    //       originY: 'top',
    //       overlayX: 'start',
    //       overlayY: 'bottom',
    //     },
    //     {
    //       originX: 'end',
    //       originY: 'top',
    //       overlayX: 'end',
    //       overlayY: 'bottom',
    //     },
    //   ])
    //   .withPush(true)
    //   .withViewportMargin(10);
    //
    //
    // // Create the overlay
    // this.overlayRef = this.overlay.create({
    //   positionStrategy,
    //   hasBackdrop: true,  // Optional: Add backdrop if needed
    //   backdropClass: 'cdk-overlay-transparent-backdrop',
    //   scrollStrategy: this.overlay.scrollStrategies.block(),
    //   panelClass: ['custom-overlay-panel']
    // });
    //
    // // Attach the overlay content
    // const overlayPortal = new ComponentPortal(UserInfoComponent);
    // this.overlayRef.attach(overlayPortal);
    //
    // // Handle backdrop clicks
    // this.overlayRef.backdropClick().subscribe(() => this.overlayRef.dispose());
    this.isOpen.set(true);
  }

  closePopup() {
    this.isOpen.set(false);
  }

  multiValueChange() {

  }

  protected readonly MultiSelectDropdownAppearance = MultiSelectDropdownAppearance;
}

