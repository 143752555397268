<div class="px-4 space-y-4">
  <img src="../../../../../assets/images/branding/YSC-logo.png" />
  <div class="py-6 space-y-4">
    <p class="text-h6">Forgot Password?</p>
    <p class="text-caption text-neutral-500">Please enter you email and submit. We will send you the reset password link to entered email.</p>
  </div>
  <form [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordClicked()">
    <app-text-input formControlName="email" [label]="'Email'" placeholder="Ex: john.bravo@company.com" class="w-full" [fullWidth]="true"></app-text-input>
    <div class="mt-4">
      <app-button [fullWidth]="true" [loading]="forgotPasswordState.loading()" type="submit">Submit</app-button>
    </div>
    <div class="flex justify-center items-center py-6 gap-2">
      <p class="text-body2">Already have an account?</p>
      <a class="cursor-pointer text-info-500" (click)="onLoginClicked()">Login</a>
    </div>
  </form>
</div>
