<div class="flex py-4 px-4 border-b border-neutral-200">
  <div class="flex gap-3 items-center">
    <p class="text-body2">{{ currentMenu().label }}</p>
  </div>
  <div class="flex gap-5 justify-end items-center ml-auto">
    <div [matMenuTriggerFor]="accountMenu" class="flex gap-4 items-center cursor-pointer">
      <app-svg-icon class="text-neutral-400" src="assets/icons/account-circle.svg"></app-svg-icon>
      <p class="text-body2">{{ getUserName() }}</p>
      <app-svg-icon class="text-neutral-400" [size]="14" src="assets/icons/chevron-down.svg"></app-svg-icon>
    </div>
  </div>
</div>
<mat-menu class="mat-elevation-z3 mt-5" #accountMenu="matMenu">
  <div (click)="onAccountClicked()"
    class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100">
    <app-svg-icon src="assets/icons/account-circle.svg" class="text-neutral-500 cursor-pointer"
                  [size]="18"></app-svg-icon>
    <span class="text-body2">Account</span>
  </div>
  <div class="flex gap-3 items-center px-4 py-4 min-w-44 cursor-pointer hover:bg-neutral-100" (click)="onLogoutClick()">
    <app-svg-icon src="assets/icons/logout.svg" class="text-neutral-500 cursor-pointer" [size]="18"></app-svg-icon>
    <span class="text-body2">Logout</span>
  </div>
</mat-menu>
