import {Component, inject, OnInit, output} from "@angular/core";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {CheckboxComponent} from "../../../../../shared/inputs/checkbox/checkbox.component";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {State} from "../../../../../shared/base/base-state";
import type {
  GetCountryListResponse,
  GetDriverApplicationTrafficConvictionItem,
  GetDriverApplicationTrafficConvictionParams,
  GetDriverApplicationTrafficConvictionResponse, GetStateListResponse,
  SaveDriverApplicationTrafficConviction,
  SaveDriverApplicationTrafficConvictionRequest,
} from "../../../../../core/api/model";
import {DateUtils} from "../../../../../shared/utils/date-utils";
import {
  StateList,
  StateListResponse,
} from "../../../../../core/services/lookup/states/stateList-response";
import {LookupService} from "../../../../../core/services/lookup/lookup.service";
import {CountriesResponseV1} from "../../../../../core/services/lookup/countries/countries-response-v1";
import {ApiService} from "../../../../../core/api/api.service";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "../../../../../shared/inputs/outline-date-input/outline-date-input.component";

@Component({
  selector: "app-save-applicant-traffic-convictions",
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    OutlineDateInputComponent,
  ],
  templateUrl: "./save-applicant-traffic-convictions.component.html",
  styleUrl: "./save-applicant-traffic-convictions.component.scss",
})
export class SaveApplicantTrafficConvictionsComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  tokenService = inject(TokenStoreService);
  lookupService = inject(LookupService);
  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();

  trafficConvictionForm!: FormGroup;
  trafficConvictionFormArray!: FormArray;

  applicantTrafficConvictionsState =
    new State<GetDriverApplicationTrafficConvictionResponse>();
  saveTrafficConvictionsState = new State<any>();
  filteredStates: StateList[] = [];

  ngOnInit() {
    this.initForm();
    this.getTrafficConvictions();
    this.getCountry();
    this.getStateList();
  }

  private initForm() {
    this.trafficConvictionFormArray = this.formBuilder.array([]);
    this.trafficConvictionForm = this.formBuilder.group({
      convictedOfTrafficViolation: [false],
      hasMotorLicenseBeenDenied: [false],
      motorLicenseBeenDeniedReason: [""],
      hasLicenseBeenSuspendedOrRevoked: [false],
      licenseSuspendedOrRevokedReason: [""],
      trafficConvictions: this.trafficConvictionFormArray,
    });
  }

  getCountry() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
    });
  }

  private getTrafficConvictions() {
    let request: GetDriverApplicationTrafficConvictionParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationTrafficConvictionResponse>({
      state: this.applicantTrafficConvictionsState,
      request: this.apiService.getDriverApplicationTrafficConviction(request),
      onSuccess: (response) => {
        let convictedOfTrafficViolation = response.convictedOfTrafficViolation;
        let hasMotorLicenseBeenDenied = response.hasMotorLicenseBeenDenied;
        let motorLicenseBeenDeniedReason = response.motorLicenseBeenDeniedReason;
        let hasLicenseBeenSuspendedOrRevoked = response.hasLicenseBeenSuspendedOrRevoked;
        let licenseSuspendedOrRevokedReason = response.licenseSuspendedOrRevokedReason;

        this.trafficConvictionForm.controls["convictedOfTrafficViolation"].setValue(convictedOfTrafficViolation ?? '');
        this.trafficConvictionForm.controls["hasMotorLicenseBeenDenied"].setValue(hasMotorLicenseBeenDenied ?? '');
        this.trafficConvictionForm.controls["motorLicenseBeenDeniedReason"].setValue(motorLicenseBeenDeniedReason ?? '');
        this.trafficConvictionForm.controls["hasLicenseBeenSuspendedOrRevoked"].setValue(hasLicenseBeenSuspendedOrRevoked ?? '');
        this.trafficConvictionForm.controls["licenseSuspendedOrRevokedReason"].setValue(licenseSuspendedOrRevokedReason ?? '');

        this.populateForm(response.data ?? []);
      },
    });
  }

  private populateForm(
    trafficConvictions: GetDriverApplicationTrafficConvictionItem[]
  ) {
    if (trafficConvictions.length <= 0) {
      let form = this.createTrafficConvictionsForm(null);
      this.trafficConvictionFormArray.push(form);
    } else {
      trafficConvictions.forEach((trafficConviction) => {
        let form = this.createTrafficConvictionsForm(trafficConviction);
        this.trafficConvictionFormArray.push(form);
      });
    }
  }

  createTrafficConvictionsForm(
    trafficConviction: GetDriverApplicationTrafficConvictionItem | null
  ) {
    let dateUtils = new DateUtils();
    return this.formBuilder.group({
      id: [trafficConviction?.id],
      dateConvicted: [
        dateUtils.convertToMMDDYYYY(trafficConviction?.convictedDate),
        Validators.required,
      ],
      violation: [trafficConviction?.violation, Validators.required],
      stateOfViolation: [
        trafficConviction?.stateOfViolation,
        Validators.required,
      ],
      penalty: [trafficConviction?.penalty, Validators.required],
    });
  }

  onAddMoreTrafficConvictionsClicked() {
    let newForm = this.createTrafficConvictionsForm(null);
    this.trafficConvictionFormArray.push(newForm);
  }

  onDeleteClicked(index: number): void {
    if (this.trafficConvictionFormArray.length > 1)
      this.trafficConvictionFormArray.removeAt(index);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.trafficConvictionForm);
    let convictedOfTrafficViolation =
      this.trafficConvictionForm.value.convictedOfTrafficViolation;
    if (this.trafficConvictionForm.invalid && convictedOfTrafficViolation) {
      return;
    }

    let formValue = this.trafficConvictionForm.value;
    let request: SaveDriverApplicationTrafficConvictionRequest = {
      hasMotorLicenseBeenDenied: formValue.hasMotorLicenseBeenDenied,
      motorLicenseBeenDeniedReason: formValue.motorLicenseBeenDeniedReason,
      hasLicenseBeenSuspendedOrRevoked: formValue.hasLicenseBeenSuspendedOrRevoked,
      licenseSuspendedOrRevokedReason: formValue.licenseSuspendedOrRevokedReason,
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      convictedOfTrafficViolation: convictedOfTrafficViolation,
      trafficConvictions: []
    };

    if (convictedOfTrafficViolation) {
      this.trafficConvictionFormArray.controls.forEach((control, index) => {
        let trafficConviction = control.value;
        let dateUtils = new DateUtils();
        let driverTrafficConviction: SaveDriverApplicationTrafficConviction = {
          displayOrder: index+1,
          convictedDate: dateUtils.convertToDateTimeString(
            trafficConviction.dateConvicted) ?? '',
          penalty: trafficConviction.penalty,
          stateOfViolation: trafficConviction.stateOfViolation,
          violation: trafficConviction.violation,
        }
        request.trafficConvictions.push(driverTrafficConviction);
      });
    }

    this.executeRequest({
      state: this.saveTrafficConvictionsState,
      request: this.apiService.saveDriverApplicationTrafficConviction(request),
      showLoader: true,
      handleSuccess: true,
      handleError: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }

  protected readonly InputDateFormat = InputDateFormat;
}
