<div class="h-dvh w-full flex flex-col gap-5 items-center justify-center">

  @if (delegateTokenState.loading()) {
    <div class="flex flex-col gap-5 justify-center items-center">
      <div class="relative flex items-center justify-center h-[100px] w-[100px]">

        <div class="absolute inset-0 border-2 border-t-transparent border-success-200 rounded-full animate-spin"></div>

        <div
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[56px] w-[56px] rounded-full bg-success-500 flex justify-center items-center">
          <app-svg-icon class="text-white" [size]="24" src="assets/icons/security.svg"></app-svg-icon>
        </div>

      </div>
      <span class="text-body2 font-semibold">Authenticating</span>
      <span class="text-caption text-neutral-500 max-w-[200px] text-center">Please wait a moment while we complete your authentication…</span>
    </div>
  }

  @if (delegateTokenState.error()) {
    <div class="flex flex-col gap-5 justify-center items-center">
      <div class="relative flex items-center justify-center h-[100px] w-[100px]">

        <div class="absolute inset-0 border-2 border-error-200 rounded-full"></div>

        <div
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[56px] w-[56px] rounded-full bg-error-500 flex justify-center items-center">
          <app-svg-icon class="text-white" [size]="24" src="assets/icons/close-circle.svg"></app-svg-icon>
        </div>

      </div>
      <span class="text-body2 font-semibold">Authentication Failed</span>
      <span class="text-caption text-neutral-500 max-w-[200px] text-center">Sorry, authentication failed. Please verify your access.</span>
    </div>
  }

</div>


