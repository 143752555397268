import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import type {
  SignDrugAndAlcoholPolicyRequest,
  SignDrugAndAlcoholPolicyResponse
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {printInvalidFields} from "@shared/utils/form-utils";
import {DialogRef} from "@angular/cdk/dialog";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";

@Component({
  selector: 'app-drug-and-alcohol-policy-agreement',
  imports: [
    FormsModule,
    RadioButtonComponent,
    ReactiveFormsModule,
    SignaturePadComponent,
    CheckboxComponent,
    ButtonComponent,
    BaseOverlayComponent,
    DateInputComponent
],
  templateUrl: './drug-and-alcohol-policy-agreement.component.html',
  standalone: true,
  styleUrl: './drug-and-alcohol-policy-agreement.component.scss'
})
export class DrugAndAlcoholPolicyAgreementComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  dialogRef = inject(DialogRef);


  drugAndAlcoholPolicyAgreementState = new State<SignDrugAndAlcoholPolicyResponse>();

  drugAndAlcoholPolicyAgreementForm!: FormGroup;

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    let currentDate = new Date();

    this.drugAndAlcoholPolicyAgreementForm = this.formBuilder.group({
      name: ['', Validators.required],
      readPolicy: ['', Validators.required],
      signature: ['', Validators.required],
      signatureDate: [currentDate, Validators.required],
      acknowledgement: ['', Validators.required]
    })
  }

  onSubmitClicked() {
    printInvalidFields(this.drugAndAlcoholPolicyAgreementForm);
    if (this.drugAndAlcoholPolicyAgreementForm.invalid) {
      return;
    }
    let id = this.tokenService.getDriverApplicationId();
    let request: SignDrugAndAlcoholPolicyRequest = {
      name: this.drugAndAlcoholPolicyAgreementForm.value.name,
      signature: this.drugAndAlcoholPolicyAgreementForm.value.signature,
      signatureDate: this.drugAndAlcoholPolicyAgreementForm.value.signatureDate,
    }
    this.executeRequest({
      state: this.drugAndAlcoholPolicyAgreementState,
      request: this.apiService.signDrugAndAlcoholPolicy(id, request),
      handleSuccess: true,
      onSuccess: () => {
        this.dialogRef.close(true);
      }
    });
  }

  onSignatureSaved(signatureBase64: string) {
    this.drugAndAlcoholPolicyAgreementForm.controls["signature"].setValue(signatureBase64);
  }

  onSignatureCleared() {
    this.drugAndAlcoholPolicyAgreementForm.controls['signature'].setValue(null);
  }

}
