import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {Constants} from "@core/constants/constants";
import {StartSapProgramComponent} from "./start-sap-program/start-sap-program.component";
import {ColumnDef, DataTableComponent, TableActionEvent} from "@shared/components/data-table/data-table.component";
import {State} from "@shared/base/base-state";
import {UploadTestResultComponent} from "./upload-test-result/upload-test-result.component";
import {EndSapProgramComponent} from "./end-sap-program/end-sap-program.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  DriverTableExpandedInfoComponent
} from "../drivers-list/driver-table-expanded-info/driver-table-expanded-info.component";
import {
  GetActiveSapProgramResponse,
  type GetSapProgramTestResultListItem,
  GetSapProgramTestResultListParams,
  GetSapProgramTestResultListResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {CommonModule} from "@angular/common";
import {ShimmerComponent} from "@shared/components/shimmer/shimmer.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-driver-sap-program',
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    DataTableComponent,
    CommonModule,
    ShimmerComponent
  ],
  templateUrl: './driver-sap-program.component.html',
  standalone: true,
  styleUrl: './driver-sap-program.component.scss'
})
export class DriverSapProgramComponent extends BaseComponent implements OnInit {

  sapProgramId: string | null = null;
  driverId?: string;

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);

  getActiveSAPProgramState = new State<GetActiveSapProgramResponse>();
  getSapProgramTestResultListState = new State<GetSapProgramTestResultListResponse>();


  columnDefs: ColumnDef[] = [
    {
      title: 'Date', type: 'date', displayTemplate: '$programTestDate', alignment: 'center', dateConfig: {
        dateFormat: 'dd MMM, yyyy', showIcon: false
      }
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$programTestResult', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'Positive',
            displayText: 'Positive',
            backgroundColorClass: 'bg-error-50',
            indicatorColorClass: 'bg-error-500',
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          },
          {
            data: 'Negative',
            displayText: 'Negative',
            backgroundColorClass: 'bg-success-50',
            indicatorColorClass: "bg-success-500",
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
        ]
      }
    },
    {title: 'Notes', type: 'text', displayTemplate: '$notes', alignment: 'center'},
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        textMenuActions: [
          {iconPath: 'assets/icons/eye-outline.svg', actionKey: 'viewResult', label: 'Result'},
          {iconPath: 'assets/icons/eye-outline.svg', actionKey: 'viewCCF', label: 'CCF'},
        ]
      }
    }
  ];

  ngOnInit() {
    this.listenToRoute();
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? undefined;
      this.getActiveSapProgram();
    });
  }

  onStartSAPProgramClicked() {
    this.overlayService.openModal(StartSapProgramComponent, {
      data: {
        driverId: this.driverId,
      },
    }).closed.subscribe((value) => {
      if (value) {
        this.getActiveSapProgram();
      }
    });
  }

  getActiveSapProgram() {
    this.executeRequest<GetActiveSapProgramResponse>({
      state: this.getActiveSAPProgramState,
      request: this.apiService.getActiveSapProgram(this.driverId ?? ''),
      onSuccess: (response) => {
        let activeSapProgram = response.data;
        this.sapProgramId = activeSapProgram?.id;
        if (activeSapProgram) {
          this.getSapProgramTestResultList();
        }
      }
    });
  }

  getSapProgramTestResultList() {
    let request: GetSapProgramTestResultListParams = {
      sapProgramId: this.sapProgramId ?? ''
    }
    this.executeRequest({
      state: this.getSapProgramTestResultListState,
      request: this.apiService.getSapProgramTestResultList(request)
    });
  }

  onUploadClicked() {
    this.overlayService.openModal(UploadTestResultComponent, {
      data: {
        id: this.sapProgramId,
      },
    }).closed.subscribe((value) => {
      if (value) {
        this.getSapProgramTestResultList();
      }
    });
  }

  onViewSAPProgramHistoryClicked() {
    this.router.navigate([`/drivers/${this.driverId ?? ''}/sap-program-history`]);
  }


  onEndSapProgramClicked() {
    this.overlayService.openModal(EndSapProgramComponent, {
      data: {
        id: this.sapProgramId,
      }
    }).closed.subscribe((value) => {
      if (value) {
        this.getActiveSapProgram();
        this.getSapProgramTestResultList()
      }
    });
  }

  onTableActionPerformed($event: TableActionEvent) {
    let item: GetSapProgramTestResultListItem = $event.item;
    switch ($event.actionKey) {
      case 'viewResult':
        window.open(item.programTestFilePath, '_blank');
        break;
      case 'viewCCF':
        window.open(item.programTestCCFFilePath, '_blank');
        break;
    }
  }

  protected readonly DriverTableExpandedInfoComponent = DriverTableExpandedInfoComponent;
}
