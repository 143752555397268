import {Component, inject, OnInit} from '@angular/core';
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  GetDriverDocumentListItem, GetDriverDocumentSubType,
  UpdateDriverDocumentRequest, UpdateDriverDocumentResponse,
  UploadTempFileRequest, UploadTempFileResponse
} from "../../api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "../../api/api.service";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {TextAreaInputComponent} from "@shared/inputs/text-area-input/text-area-input.component";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: 'app-document-upload',
  standalone: true,
  imports: [
    FileUploaderComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    TextAreaInputComponent,
    TextInputComponent,
    BaseOverlayComponent,
    DateInputComponent
  ],
  templateUrl: './document-upload.component.html',
  styleUrl: './document-upload.component.scss'
})
export class DocumentUploadComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  data: GetDriverDocumentListItem = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  fileUploadForm!: FormGroup;

  tempFileUploadStates: State<UploadTempFileResponse>[] = [];
  uploadState = new State<UpdateDriverDocumentResponse>();

  documentSubTypesFormArray!: FormArray;

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.documentSubTypesFormArray = this.formBuilder.array([]);
    let subTypes = this.data.subTypes ?? [];

    subTypes.forEach(subType => {
      let attachFileState = new State<UploadTempFileResponse>();
      this.tempFileUploadStates.push(attachFileState);
    });

    subTypes.forEach(subType => {
      let attachmentForm = this.createSubTypeAttachmentForm(subType);
      this.documentSubTypesFormArray.push(attachmentForm)
    });

    this.fileUploadForm = this.formBuilder.group({
      driverDocumentAttachments: this.documentSubTypesFormArray,
      fileAttachment: [null, Validators.required],
      identificationNumber: [null],
      issuedDate: [null, [Validators.required, onlyPastDateValidator()]],
      expiryDate: [null, Validators.required],
      completedDate: [null, [Validators.required, onlyPastDateValidator()]],
      reviewedDate: [null, Validators.required, onlyPastDateValidator()],
      notes: [null]
    });
  }

  createSubTypeAttachmentForm(subType: GetDriverDocumentSubType) {
    return this.formBuilder.group({
      driverDocumentSubTypeId: [subType.id, [Validators.required]],
      filePath: [null, Validators.required]
    });
  }

  onAttachFileClicked($event: File, index: number, driverDocumentSubType: GetDriverDocumentSubType) {
    let request: UploadTempFileRequest = {
      file: $event
    }
    this.executeRequest({
      state: this.tempFileUploadStates[index],
      request: this.apiService.uploadTempFile(request),
      onSuccess: response => {
        let tempFilePath = response.filePath;
        let attachmentFormGroup = this.documentSubTypesFormArray.at(index) as FormGroup;
        attachmentFormGroup.controls['filePath'].setValue(tempFilePath);

        console.log(this.fileUploadForm.value);
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }

  onUploadClicked() {
    let formValue = this.fileUploadForm.value;
    let request: UpdateDriverDocumentRequest = {
      driverId: this.data.driverId,
      driverDocumentAttachments: formValue.driverDocumentAttachments ?? [],
      driverDocumentTypeId: this.data.documentTypeId,
      identificationNumber: formValue.identificationNumber,
      issuedDate: formValue.issuedDate,
      completedDate: formValue.completedDate,
      expiryDate: formValue.expiryDate,
      notes: formValue.notes,
    };

    this.executeRequest<UpdateDriverDocumentResponse>({
      state: this.uploadState,
      request: this.apiService.updateDriverDocument(request),
      onSuccess: (response) => {
        this.dialogRef.close(response.data);
      }
    });
  }
}


