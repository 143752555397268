import {Component, inject, OnInit} from '@angular/core';
import {BaseComponent} from "@shared/base/base-component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {DialogRef} from "@angular/cdk/dialog";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {
  CreateDriverRequest,
  CreateDriverResponse, type GetCompanyUserTerminalListResponse,
  GetCountryListItem,
  GetCountryListResponse, GetStateListItem,
  GetStateListResponse
} from "@core/api/model";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {LicenseClassesResponse} from "@core/services/common/license-classes/license-classes-response";
import {CommonService} from "@core/services/common/common.service";
import {SingleSelectionFieldComponent} from "@shared/inputs/single-selection-field/single-selection-field.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: 'app-create-new-driver',
  standalone: true,
  imports: [
    ButtonComponent,
    TextInputComponent,
    ReactiveFormsModule,
    RadioButtonComponent,
    DropdownComponent,
    SingleSelectionFieldComponent,
    BaseOverlayComponent,
    DateInputComponent
  ],
  templateUrl: './create-new-driver.component.html',
  styleUrl: './create-new-driver.component.scss'
})
export class CreateNewDriverComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  overlayService = inject(OverlayService);
  dialogRef = inject(DialogRef);
  apiService = inject(ApiService);
  commonService = inject(CommonService);

  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  licenseClassesState = new State<LicenseClassesResponse>();
  companyUserTerminalsState = new State<GetCompanyUserTerminalListResponse>();
  createDriverState = new State<CreateDriverResponse>();

  filteredStates: GetStateListItem[] = [];

  createDriverForm!: FormGroup;

  ngOnInit(): void {
    this.initForm();
    this.getCountries();
    this.getLicenseClasses();
    this.getCompanyUserTerminals();
  }

  private initForm() {
    this.createDriverForm = this.formBuilder.group({
      terminalId: [null, Validators.required],
      firstName: [null, Validators.required],
      middleName: [null],
      lastName: [null, Validators.required],
      employeeReferenceNumber: [null],
      phoneNumber: [null],
      email: [null],
      dateOfBirth: [null, [Validators.required, onlyPastDateValidator()]],
      dateOfHire: [null, [onlyPastDateValidator()]],
      hasUsWorkLegality: [true, Validators.required],
      licenseNo: [null, Validators.required],
      countryId: [null, Validators.required],
      stateId: [null, Validators.required],
      class: [null, Validators.required],
      issueDate: [null, [onlyPastDateValidator()]],
      expirationDate: [null, Validators.required]
    });
  }

  getCountries() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
      onSuccess: response => {
        this.getStateList();
      }
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
    });
  }

  getCompanyUserTerminals() {
    this.executeRequest<GetCompanyUserTerminalListResponse>({
      state: this.companyUserTerminalsState,
      request: this.apiService.getCompanyUserTerminalList(),
    });
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  private getLicenseClasses() {
    this.executeRequest<LicenseClassesResponse>({
      state: this.licenseClassesState,
      request: this.commonService.getLicenseClasses(),
    });
  }

  onSaveClicked() {

    this.validateForm(this.createDriverForm);
    if (this.createDriverForm.invalid) {
      return;
    }


    let formValue = this.createDriverForm.value;

    let request: CreateDriverRequest = {
      terminalId: formValue.terminalId,
      firstName: formValue.firstName,
      middleName: formValue.middleName,
      lastName: formValue.lastName,
      employeeReferenceNumber: formValue.employeeReferenceNumber,
      phoneNumber: formValue.phoneNumber,
      email: formValue.email,
      dateOfBirth: formValue.dateOfBirth ?? '',
      hiredDate: formValue.dateOfHire ?? '',
      hasUsWorkLegality: formValue.hasUsWorkLegality,
      licenseNo: formValue.licenseNo,
      countryId: formValue.countryId,
      stateId: formValue.stateId,
      class: formValue.class,
      issueDate: formValue.issueDate,
      expirationDate: formValue.expirationDate
    };

    this.executeRequest<CreateDriverResponse>({
      state: this.createDriverState,
      request: this.apiService.createDriver(request),
      handleSuccess: true,
      handleError: true,
      onSuccess: response => {
        this.dialogRef.close(true);
      }
    });
  }

  onCancelClicked() {
    this.dialogRef.close(false);
  }
}
