<app-base-overlay [title]="'Choose a Terminal'">
  <div body>
    <div class="flex flex-col">

      <app-listview
        [state]="companyUserTerminalsState"
        [component]="RowTerminalSelectionComponent"
        (onActionPerformed)="this.onListAction($event)"
        (onStateChanged)="getCompanyUserTerminals($event)">
      </app-listview>

    </div>
  </div>
  <div actions>
    <app-button [appearance]="'outline'" [fullWidth]="true" (click)="onClearAllClicked()">Clear All</app-button>
  </div>
</app-base-overlay>
