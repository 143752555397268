import {Component, inject, OnInit, signal} from '@angular/core';
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {environment} from "../../../../../environments/environment";
import {EnvironmentType} from "../../../../../environments/environment-types";
import {ButtonComponent} from "@shared/components/button/button.component";
import {Clipboard} from "@angular/cdk/clipboard";
import {DialogRef} from "@angular/cdk/dialog";
import {BaseComponent} from "@shared/base/base-component";
import {
    GetCompanyUserTerminalListItem,
    GetCompanyUserTerminalListParams,
    GetCompanyUserTerminalListResponse,
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {TableStateEvent} from "@shared/components/data-table/data-table.component";

import {Constants} from "@core/constants/constants";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
    selector: 'app-copy-job-application-link',
  imports: [
    DropdownComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    BaseOverlayComponent
  ],
    templateUrl: './copy-job-application-link.component.html',
    standalone: true,
    styleUrl: './copy-job-application-link.component.scss'
})
export class CopyJobApplicationLinkComponent extends BaseComponent implements OnInit {

    dialogRef = inject(DialogRef);
    apiService = inject(ApiService);
    clipboard = inject(Clipboard);

    companyUserTerminalsState = new State<GetCompanyUserTerminalListResponse>();

    selectedTerminal = signal<GetCompanyUserTerminalListItem | null>(null);
    terminalJobApplicationUrl = signal<string | null>(null);

    ngOnInit() {
        this.getCompanyUserTerminals(Constants.defaultTableStateEvent);
    }

    getCompanyUserTerminals($event: TableStateEvent) {
        let request: GetCompanyUserTerminalListParams = {
            pageNumber: $event.paginationEvent?.pageNumber,
            pageSize: $event.paginationEvent?.pageSize,
            search: $event.searchText
        };
        this.executeRequest<GetCompanyUserTerminalListResponse>({
            state: this.companyUserTerminalsState,
            request: this.apiService.getCompanyUserTerminalList(request),
        });
    }

    onTerminalSelectionChanged() {
        let terminalCode = this.selectedTerminal()?.code;
        let url = '';
        switch (environment.environment) {
            case EnvironmentType.LOCAL:
                url = `http://localhost:4200/apply-for-job/${terminalCode}`;
                break;
            case EnvironmentType.DEVELOPMENT:
                url = `https://dev-app.yoursafetycompliance.com/apply-for-job/${terminalCode}`;
                break;
            case EnvironmentType.UAT:
                url = `https://uat-app.yoursafetycompliance.com/apply-for-job/${terminalCode}`;
                break;
            case EnvironmentType.PRODUCTION:
                url = `https://app.yoursafetycompliance.com/apply-for-job/${terminalCode}`;
                break;
        }

        this.terminalJobApplicationUrl.set(url);
    }

    onCopyClicked() {
        if (this.selectedTerminal() == null) {
            this.toasterService.warning('Please select a terminal');
            return;
        }
        this.clipboard.copy(this.terminalJobApplicationUrl() ?? '');
        this.toasterService.success('Link copied successfully.');
    }

    onCloseClicked() {
        this.dialogRef.close();
    }
}

