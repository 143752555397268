/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API
 * 
  <img src="https://dev-app.yoursafetycompliance.com/assets/images/branding/YSC-logo.png" alt="Safety Compliance" width="150"/>
  <h3>📌 Welcome to the <strong>Your Safety Compliance API Documentation (Company Module)</strong> 🚀</h3>
  
 * OpenAPI spec version: 1.0
 */

export type GetExpiringDocumentsDriverDocumentExpiryType = typeof GetExpiringDocumentsDriverDocumentExpiryType[keyof typeof GetExpiringDocumentsDriverDocumentExpiryType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetExpiringDocumentsDriverDocumentExpiryType = {
  expired: 'expired',
  expiring: 'expiring',
  all: 'all',
} as const;
