import {Component, OnInit, inject, signal, OnDestroy} from "@angular/core";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {LookupService} from "@core/services/lookup/lookup.service";
import {CommonModule} from "@angular/common";
import {
  AccountType,
  AccountTypesResponse,
} from "@core/services/lookup/account-type/account-type-response";
import {
  CarrierType,
  CarrierTypeResponse,
} from "@core/services/lookup/carrier-type/carrier-type-response";
import {InvalidControlScrollDirective} from "@shared/directives/invalid-control-scroll.directive";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {ApiService} from "@core/api/api.service";

import {
  MultiSelectionFieldComponent
} from "@shared/inputs/multi-selection-field/multi-selection-field.component";
import {Subscription} from "rxjs";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {TokenStoreService} from "@core/services/auth/token-store.service";
import {
  BusinessSubTypeListResponse, CompleteProfileRequest,
  CompleteProfileResponse,
  GetBusinessSubTypeListParams,
  GetBusinessTypeListItem,
  GetBusinessTypeListParams,
  GetBusinessTypeListResponse,
  GetCompany,
  GetCompanyResponse,
  GetCompanyTypeListResponse, GetCountryListItem,
  GetCountryListResponse,
  GetStateListItem,
  GetStateListResponse, RefreshTokenRequest,
  RefreshTokenResponse
} from "@core/api/model";

@Component({
  selector: "app-setup-company-profile",
  standalone: true,
  templateUrl: "./setup-company-profile.component.html",
  styleUrl: "./setup-company-profile.component.scss",
  imports: [
    TextInputComponent,
    DropdownComponent,
    ButtonComponent,
    ReactiveFormsModule,
    CommonModule,
    InvalidControlScrollDirective,
    RadioButtonComponent,
    MultiSelectionFieldComponent,
    AppSvgIconComponent,
  ],
})
export class SetupCompanyProfileComponent extends BaseComponent implements OnInit, OnDestroy {
  formBuilder = inject(FormBuilder);
  lookupService = inject(LookupService);
  apiService = inject(ApiService);
  setupCompanyProfileForm!: FormGroup;

  setCompanyProfileState = new State<CompleteProfileResponse>();
  companyTypesState = new State<GetCompanyTypeListResponse>();
  accountTypeState = new State<AccountTypesResponse>();
  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  businessTypeState = new State<GetBusinessTypeListResponse>();
  businessSubTypeState = new State<BusinessSubTypeListResponse>();
  carrierTypeState = new State<CarrierTypeResponse>();
  companyInfoState = new State<GetCompanyResponse>();
  tokenState = new State<RefreshTokenResponse>();

  selectedAccountType: string | null | undefined;
  prefix: string | null | undefined;
  filteredStates: GetStateListItem[] = [];
  isInterStateSelected = signal<boolean>(false);
  isIntraStateSelected = signal<boolean>(false);
  private companyNameListener!: Subscription;

  ngOnInit(): void {
    this.initForm();
    this.listenFormValueChange();
    this.getCompanyInfo();
    this.getCompanyTypes();
    this.getAccountTypes();
    this.getBusinessTypes();
    this.getBusinessSubTypes();
    this.getCarrierTypes();
  }

  ngOnDestroy(): void {
    try {
      this.companyNameListener.unsubscribe();
    } catch (e) {
    }
  }

  getCompanyTypes() {
    this.executeRequest<GetCompanyTypeListResponse>({
      state: this.companyTypesState,
      request: this.apiService.getCompanyTypeList(),
    });
  }

  initForm(): void {
    this.setupCompanyProfileForm = this.formBuilder.group({
      companyName: [null, Validators.required],
      dba: [null],
      prefix: [null, Validators.required],
      accountType: [null, Validators.required],
      ownerSsnNumber: [null, Validators.required],
      einNumber: [null, Validators.required],
      email: [null, Validators.email],
      phoneNumber: [null, Validators.required],
      companyType: [null, Validators.required],
      addressLine1: [null, Validators.required],
      addressLine2: [null],
      countryId: [null, Validators.required],
      stateId: [null, Validators.required],
      city: [null, Validators.required],
      zipCode: [null, Validators.required],
      businessTypeId: [null, Validators.required],
      businessSubTypeId: [null],
      businessRepresentativeFirstName: [null, Validators.required],
      businessRepresentativeLastName: [null, Validators.required],
      dotNumber: [null, Validators.required],
      caNumber: [null],
      carrierType: [null, Validators.required],
      mcNumber: [null, Validators.required],
      mcpNumber: [null, Validators.required],
      transportsHazmat: [false, Validators.required],
      transportsHazwaste: [false, Validators.required],
    });
  }

  private handleInfoForm(companyInfo: GetCompany) {
    this.setupCompanyProfileForm.controls["companyName"].setValue(companyInfo.companyName ?? "");
    this.setupCompanyProfileForm.controls["dba"].setValue(companyInfo.dba);

    let companyPrefix = companyInfo.prefix;
    this.setupCompanyProfileForm.controls["prefix"].setValue(companyInfo.prefix);
    if (companyPrefix) {
      this.setupCompanyProfileForm.controls["prefix"].disable();
    } else {
      this.generatePrefix(companyInfo.companyName);
    }
    this.setupCompanyProfileForm.controls["accountType"].setValue(companyInfo.accountType ?? "");

    this.selectedAccountType = companyInfo.accountType;
    this.handleSSnAndEinNumber(companyInfo.accountType);

    this.setupCompanyProfileForm.controls["ownerSsnNumber"].setValue(companyInfo.ownerSsnNumber ?? "");
    this.setupCompanyProfileForm.controls["einNumber"].setValue(companyInfo.einNumber ?? "");
    this.setupCompanyProfileForm.controls["email"].setValue(companyInfo.email ?? "");
    this.setupCompanyProfileForm.controls["email"].disable();
    this.setupCompanyProfileForm.controls["phoneNumber"].setValue(companyInfo.phoneNumber ?? "");

    this.setupCompanyProfileForm.controls["companyType"].setValue(companyInfo.companyType);
    this.setupCompanyProfileForm.controls["addressLine1"].setValue(companyInfo.addressLine1 ?? "");
    this.setupCompanyProfileForm.controls["addressLine2"].setValue(companyInfo.addressLine2 ?? "");
    this.setupCompanyProfileForm.controls["countryId"].setValue(companyInfo.countryId);
    this.setupCompanyProfileForm.controls["stateId"].setValue(companyInfo.stateId);
    this.setupCompanyProfileForm.controls["city"].setValue(companyInfo.city ?? "");
    this.setupCompanyProfileForm.controls["zipCode"].setValue(companyInfo.zipCode ?? "");

    this.setupCompanyProfileForm.controls["businessTypeId"].setValue(companyInfo.businessTypeId);
    this.setupCompanyProfileForm.controls["businessSubTypeId"].setValue(companyInfo.businessSubTypeId);
    this.setupCompanyProfileForm.controls["businessRepresentativeFirstName"].setValue(companyInfo.businessRepFirstName);
    this.setupCompanyProfileForm.controls["businessRepresentativeLastName"].setValue(companyInfo.businessRepLastName)
    this.setupCompanyProfileForm.controls["dotNumber"].setValue(companyInfo.dotNumber);
    let isInterStateSelected = companyInfo.isInterState;

    let isIntraStateSelected = companyInfo.isIntraState;
    let carrierTypeValue = [];
    if (isInterStateSelected) {
      carrierTypeValue.push('INTERSTATE');
      this.isInterStateSelected.set(true);
    }
    if (isIntraStateSelected) {
      carrierTypeValue.push('INTRASTATE');
      this.isIntraStateSelected.set(true);

    }

    this.setupCompanyProfileForm.controls["caNumber"].setValue(companyInfo.caNumber);
    this.setupCompanyProfileForm.controls["carrierType"].setValue(carrierTypeValue);
    this.setupCompanyProfileForm.controls["mcNumber"].setValue(companyInfo.mcNumber);
    this.setupCompanyProfileForm.controls["mcpNumber"].setValue(companyInfo.mcpNumber);
    this.setupCompanyProfileForm.controls["transportsHazmat"].setValue(companyInfo.transportsHazmat);
    this.setupCompanyProfileForm.controls["transportsHazwaste"].setValue(companyInfo.transportsHazwaste);
  }

  private listenFormValueChange() {
    this.companyNameListener = this.setupCompanyProfileForm.controls['companyName'].valueChanges.subscribe((companyName) => {
      let savedPrefix = this.companyInfoState.response()?.data?.prefix;
      if (savedPrefix == null) {
        this.generatePrefix(companyName);
      }
    });
  }

  getAccountTypes() {
    this.executeRequest({
      state: this.accountTypeState,
      request: this.lookupService.getAccountTypes(),
    });
  }

  getCountries() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
      onSuccess: response => {
        let selectedCountryId = this.companyInfoState.response()?.data?.countryId;
        if (selectedCountryId) {
          const stateList = this.stateListState.response()?.data ?? [];
          this.filteredStates = stateList.filter(
            (data) => data.countryId == selectedCountryId
          );
        }
      }
    });
  }

  getBusinessTypes() {
    const request: GetBusinessTypeListParams = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<GetBusinessTypeListResponse>({
      state: this.businessTypeState,
      request: this.apiService.getBusinessTypeList(request),
    });
  }

  getBusinessSubTypes() {
    const request: GetBusinessSubTypeListParams = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<BusinessSubTypeListResponse>({
      state: this.businessSubTypeState,
      request: this.apiService.getBusinessSubTypeList(request),
    });
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
      onSuccess: (response) => {
        this.handleInfoForm(response.data);
        this.getCountries();
        this.getStateList();
      },
    });
  }

  generatePrefix(companyName: string): void {
    const companyNameWords = companyName.trim().split(/\s+/);
    let prefix = '';
    if (companyNameWords.length === 1) {
      prefix = companyNameWords[0].substring(0, 2).toUpperCase();
    } else if (companyNameWords.length > 1) {
      prefix = companyNameWords[0].charAt(0).toUpperCase()
        + companyNameWords[1].charAt(0).toUpperCase()
        + (companyNameWords[2] ? companyNameWords[2].charAt(0).toUpperCase() : '');
    }
    this.setupCompanyProfileForm.controls['prefix'].setValue(prefix);
  }

  getCarrierTypes() {
    this.executeRequest({
      state: this.carrierTypeState,
      request: this.lookupService.getCarrierType(),
    });
  }

  onAccountTypeChanged(selectedItem: AccountType | null) {
    this.selectedAccountType = selectedItem?.name;
    this.handleSSnAndEinNumber(selectedItem?.name);
  }

  handleSSnAndEinNumber(accountType: string | undefined) {
    switch (accountType) {
      case "Individual":
        this.setupCompanyProfileForm.controls["ownerSsnNumber"].enable();
        this.setupCompanyProfileForm.controls["einNumber"].disable();
        break;
      case "Company":
        this.setupCompanyProfileForm.controls["einNumber"].enable();
        this.setupCompanyProfileForm.controls["ownerSsnNumber"].disable();
        break;
    }
  }

  onBusinessTypeChanged($event: GetBusinessTypeListItem | null) {
    if ($event?.name != 'Bus') {
      this.setupCompanyProfileForm.controls["businessSubTypeId"].setValue(null);
    }
  }

  onSelectedCarrierChanged(selectedItems: any[] | null) {
    let selectedCarrierTypes = selectedItems ?? [];
    this.isInterStateSelected.set(selectedCarrierTypes.some(x => x == 'INTERSTATE'));
    this.isIntraStateSelected.set(selectedCarrierTypes.some(x => x == 'INTRASTATE'));

    if (this.isInterStateSelected()) {
      this.setupCompanyProfileForm.controls["mcNumber"].enable();
    } else {
      this.setupCompanyProfileForm.controls["mcNumber"].disable();
    }

    if (this.isIntraStateSelected()) {
      this.setupCompanyProfileForm.controls["mcpNumber"].enable();
    } else {
      this.setupCompanyProfileForm.controls["mcpNumber"].disable();
    }
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  onClickCompleteProfile() {
    this.validateForm(this.setupCompanyProfileForm);
    if (this.setupCompanyProfileForm.invalid) {
      return;
    }
    let formValue = this.setupCompanyProfileForm.value;
    let request: CompleteProfileRequest = {
      companyName: formValue.companyName,
      dba: formValue.dba,
      prefix: formValue.prefix,
      accountType: formValue.accountType,
      ownerSsnNumber: formValue.ownerSsnNumber,
      einNumber: formValue.einNumber,
      phoneNumber: formValue.phoneNumber,
      companyType: formValue.companyType,
      addressLine1: formValue.addressLine1,
      addressLine2: formValue.addressLine2 ?? '',
      countryId: formValue.countryId,
      stateId: formValue.stateId,
      city: formValue.city,
      zipCode: formValue.zipCode,
      businessTypeId: formValue.businessTypeId,
      businessSubTypeId: formValue?.businessSubTypeId,
      businessRepresentativeFirstName: formValue.businessRepresentativeFirstName,
      businessRepresentativeLastName: formValue.businessRepresentativeLastName,
      businessRepresentativeEmail: "",
      isIntraState: this.isIntraStateSelected(),
      isInterState: this.isInterStateSelected(),
      dotNumber: formValue.dotNumber,
      caNumber: formValue.caNumber,
      mcNumber: formValue.mcNumber,
      mcpNumber: formValue.mcpNumber,
      transportsHazmat: formValue.transportsHazmat,
      transportsHazwaste: formValue.transportsHazwaste,
      numberOfDriver: 0, numberOfEquipment: 0, numberOfUser: 0,
    };

    this.executeRequest({
      state: this.setCompanyProfileState,
      request: this.apiService.updateCompanyProfile(request),
      onSuccess: (response) => {
        this.setupCompanyProfileForm.reset();
        this.handleRenewAccessToken();
      },
      handleSuccess: true,
      successMessage: "You have successfully set your profile.",
    });
  }

  private handleRenewAccessToken() {
    let token = this.tokenService.getToken();
    let request: RefreshTokenRequest = {
      accessToken: token.accessToken ?? '',
      refreshToken: token.refreshToken ?? ''
    };
    this.executeRequest<RefreshTokenResponse>({
      state: this.tokenState,
      request: this.apiService.refreshToken(request),
      showLoader: true,
      onSuccess: response => {
        this.tokenService.saveToken(response.refreshToken, response.accessToken);
        this.router.navigate(["/dashboard"]);
      }
    });
  }
}


