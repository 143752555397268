import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";

import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {BaseComponent} from "@shared/base/base-component";
import {State} from "@shared/base/base-state";
import type {SignClearinghousePolicyRequest, SignClearinghousePolicyResponse} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {DialogRef} from "@angular/cdk/dialog";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";

@Component({
  selector: 'app-clearinghouse-policy-agreement',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SignaturePadComponent,
    ButtonComponent,
    CheckboxComponent,
    BaseOverlayComponent,
    DateInputComponent
],
  templateUrl: './clearinghouse-policy-agreement.component.html',
  standalone: true,
  styleUrl: './clearinghouse-policy-agreement.component.scss'
})
export class ClearinghousePolicyAgreementComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  dialogRef = inject(DialogRef);


  clearingHousePolicyAgreementState = new State<SignClearinghousePolicyResponse>();
  clearingHousePolicyAgreementForm!: FormGroup;

  ngOnInit() {
    let currentDate = new Date();
    this.clearingHousePolicyAgreementForm = this.formBuilder.group({
      name: ['', Validators.required],
      signatureDate: [currentDate, Validators.required],
      signature: ['', Validators.required],
      acknowledgement: ['', Validators.required]
    });
  }

  onSignatureSaved(signatureBase64: string) {
    this.clearingHousePolicyAgreementForm.controls["signature"].setValue(signatureBase64);
  }

  onSignatureCleared() {
    this.clearingHousePolicyAgreementForm.controls['signature'].setValue(null);
  }

  onSubmitClicked() {
    let id = this.tokenService.getDriverApplicationId()
    let request: SignClearinghousePolicyRequest = {
      name: this.clearingHousePolicyAgreementForm.value.name,
      signature: this.clearingHousePolicyAgreementForm.value.signature,
      signatureDate: this.clearingHousePolicyAgreementForm.value.signatureDate,
    }
    this.executeRequest(
      {
        state: this.clearingHousePolicyAgreementState,
        request: this.apiService.signClearinghousePolicy(id, request),
        handleSuccess: true,
        onSuccess: () => {
          this.dialogRef.close(true);
        }
      }
    )

  }
}
