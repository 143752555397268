import {Component, inject, OnInit} from '@angular/core';
import {
  DriverApplicationInfo
} from "@core/services/driver-application/driver-application-info/driver-application-info-response";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {NgClass} from "@angular/common";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-driver-incomplete-application',
  templateUrl: './driver-incomplete-application.component.html',
  styleUrls: ['./driver-incomplete-application.component.scss'],
  imports: [
    AppSvgIconComponent,
    ButtonComponent,
    NgClass,
    BaseOverlayComponent,
  ],
  standalone: true
})
export class DriverIncompleteApplicationComponent implements OnInit {

  data: DriverApplicationInfo = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  onCloseClicked() {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  protected readonly inject = inject;

}
