import {
  Component, ElementRef, inject, OnInit, signal, ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from "@core/api/api.service";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {TestOverlayComponent} from "./test-overlay/test-overlay.component";
import {TooltipPosition} from "@shared/components/tooltip/tooltip.enums";
import {
  ContextMenuButtonAction,
  ContextMenuButtonComponent
} from "@shared/components/context-menu-button/context-menu-button.component";
import {DocumentViewComponent} from "@core/components/document-view/document-view.component";
import {ButtonComponent} from "@shared/components/button/button.component";

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ContextMenuButtonComponent,
    DocumentViewComponent,
    ButtonComponent
  ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent extends BaseComponent implements OnInit {

  @ViewChild('trigger', {static: true}) trigger!: ElementRef;

  apiService = inject(ApiService);
  formBuilder = inject(FormBuilder);
  overlayService = inject(OverlayService);

  actions: ContextMenuButtonAction[] = [
    {label: 'Action 1', actionKey: 'action1'},
    {label: 'Action 2', actionKey: 'action2'},
    {label: 'Action 3', actionKey: 'action3'},
  ];

  public nameForm!: FormGroup;

  date = signal<Date | null>(null);

  disabledDates = [new Date(2025, 2, 15), new Date(2025, 2, 20), new Date(2025, 2, 13)];

  selectedDate = signal(new Date(2025, 5, 15));

  ngOnInit(): void {
    this.nameForm = this.formBuilder.group({
      name: [null, Validators.required],
      date: [null, Validators.required],
    });
  }


  onComponentClicked() {
    console.log(this.trigger);
    this.overlayService.openNearElement(TestOverlayComponent, this.trigger.nativeElement, {
      scrollStrategy: "reposition",
    });
  }


  onOpenNearElementClicked() {
    this.overlayService.openNearElement(TestOverlayComponent, this.trigger.nativeElement, {isMobileResponsive: true});
  }

  onOpenModalClicked() {
    this.overlayService.openModal(TestOverlayComponent);
  }

  onOpenBackdropClicked() {
    this.overlayService.openBackdrop(TestOverlayComponent);
  }

  onOpenBottomSheetClicked() {
    this.overlayService.openBottomSheet(TestOverlayComponent);
  }

  onOpenFullScreenClicked() {
    this.overlayService.openFullScreen(TestOverlayComponent);
  }

  onOpenSidePanelRightClicked() {
    this.overlayService.openSidePanelRight(TestOverlayComponent);
  }

  onOpenSidePanelLeftClicked() {
    this.overlayService.openSidePanelLeft(TestOverlayComponent);
  }

  goToDashboard() {

  }

  protected readonly TooltipPosition = TooltipPosition;

  onSubmit() {
    this.validateForm(this.nameForm);
  }
}

export interface RowData {
  id: string;
  name: string;
  status: string;
}

