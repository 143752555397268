<div class="px-4 space-y-4">
  <img src="../../../../../assets/images/branding/YSC-logo.png"/>
  <p class="text-h6 py-8">Set password for your account</p>
  <form class="flex flex-col gap-3" [formGroup]="setupPasswordForm">
    <div class="flex flex-col gap-3">
      <app-text-input [type]="'password'" formControlName="password" [label]="'Password'" placeholder="*********"
                      class="w-full" [fullWidth]="true"></app-text-input>
      <app-text-input [type]="'password'" formControlName="confirmPassword" [label]="'Confirm Password'"
                      placeholder="*********" class="w-full" [fullWidth]="true"></app-text-input>
      @if (setupPasswordForm.hasError('passwordsMismatch')) {
        <div class="text-error-500 text-caption">Please ensure both passwords are the same.</div>
      }
    </div>
    <app-button class="py-2" [fullWidth]="true" type="submit" [loading]="setupPasswordState.loading()"
                (click)="onSetPasswordClicked()">Set Password
    </app-button>
  </form>
</div>
