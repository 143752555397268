<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto m-8 px-4 overflow-y-scroll">
      <div class="flex gap-5 justify-end items-center ml-auto">
        <div class="flex gap-2 items-center border text-primary-500 rounded-lg">
          <app-button [iconSrc]="'assets/icons/plus.svg'"
                      [textButtonColor]="'text-primary-500'" (click)="createReminder()">New Reminder
          </app-button>
        </div>
      </div>
      @for (item of remindersState.response()?.data; track item.id; ) {
        <div class="m-4 p-4 border border-neutral-100 shadow-1 w-auto rounded-lg">
          <div class="flex items-center gap-4">
            <div class="px-4 py-1 border border-primary-300 rounded-lg w-fit bg-primary-300">
              <div class="flex items-center gap-4">
                <app-svg-icon src="assets/icons/calendar.svg" class="text-neutral-50"></app-svg-icon>
                <p class="text-caption text-neutral-50 text-center">{{ item.reminderDate | date:'MMM d, y' }}</p>
              </div>
            </div>
            <div class="px-4 py-1 border border-primary-300 rounded-lg w-fit bg-primary-300">
              <div class="flex items-center gap-2">
                <app-svg-icon src="assets/icons/alarm.svg" class="text-neutral-50"></app-svg-icon>
                <p class="text-caption text-neutral-50 text-center">{{ item.reminderDate | date:'shortTime' }}
                </p>
              </div>
            </div>
            <div class="ml-auto px-2">
              <div class="flex items-center gap-4">
                <app-svg-icon src="assets/icons/edit.svg" class="text-primary-400"></app-svg-icon>
                <app-svg-icon src="assets/icons/bin.svg" class="text-primary-400"></app-svg-icon>
              </div>
            </div>
          </div>
          <p class="py-4 text-neutral-800 text-caption">{{item.description}}</p>
        </div>
      }
    </div>
    <app-pagination [pageSize]="10"></app-pagination>
  </div>
</div>


