import {Component, inject} from '@angular/core';
import {GetDriverDocumentAttachment} from "@core/api/model";
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-document-subtype-list',
  imports: [
    AppSvgIconComponent,
    BaseOverlayComponent
  ],
  templateUrl: './document-subtype-list.component.html',
  styleUrl: './document-subtype-list.component.scss'
})
export class DocumentSubtypeListComponent {
  attachments: GetDriverDocumentAttachment[] = inject(DIALOG_DATA);

  onViewClicked(attachment: GetDriverDocumentAttachment) {
    window.open(attachment.filePath, '_blank');
  }

  onDownloadClicked(attachment: GetDriverDocumentAttachment) {
    window.open(attachment.filePath, '_blank');
  }
}
