import {Component, inject, OnInit, signal} from "@angular/core";
import {BaseComponent} from "@shared/base/base-component";

import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import {StatsCardComponent} from "./overview-card/stats-card.component";
import {
  GetDashboardResponse,
  GetDashboardStatsParams,
  GetDashboardStatsType,
} from "@core/api/model";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {FormsModule} from "@angular/forms";
import {Constants, DocumentExpirationFilter} from "@core/constants/constants";


@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    StatsCardComponent,
    DropdownComponent,
    FormsModule,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  statsState = new State<GetDashboardResponse>();

  selectedExpirationFilter = signal<DocumentExpirationFilter | null>(null);

  ngOnInit(): void {
    this.getStats();
    this.handleExpirationFilter();
  }

  private handleExpirationFilter() {
    let expirationFilter = Constants.documentExpirationFilter;
    if (expirationFilter.length > 0) {
      let defaultFilter = expirationFilter.find(x => x.default) ?? null;
      this.selectedExpirationFilter.set(defaultFilter);
    }
  }

  private getStats() {
    let request: GetDashboardStatsParams = {
      type: GetDashboardStatsType.nextDays,
      value: this.selectedExpirationFilter()?.value ?? '15',
    };

    this.executeRequest<GetDashboardResponse>({
      request: this.apiService.getDashboardStats(request),
      state: this.statsState,
    });
  }

  getDocumentExpiryCount(type: DocumentTypeEnum): number {
    let expiringDocumentsCountList = this.statsState?.response()?.data.documentExpiryDetails ?? [];
    let document = expiringDocumentsCountList.find(item => item.typeId.includes(type));
    let expiringCount = document?.expiringCount ?? 0;
    let expiredCount = document?.expiredCount ?? 0;
    return expiringCount + expiredCount;
  }

  onDropDownFilterSelected() {
    this.getStats();
  }

  onApplicationsClicked() {
    this.router.navigate(['/driver-applications']);
  }

  onDriversClicked() {
    this.router.navigate(['/drivers']);
  }

  onTerminalsClicked() {
    this.router.navigate(['/terminals']);
  }

  onViewClicked(documentType: DocumentTypeEnum) {
    this.router.navigate(["reports/expiring-documents"], {
      queryParams: {documentType: documentType, days: this.selectedExpirationFilter()?.value},
    });
  }

  protected readonly DocumentTypeEnum = DocumentTypeEnum;
  protected readonly Constants = Constants;
}

export enum DocumentTypeEnum {
  ANNUAL_EMPLOYER_PULL_NOTICE = 'ANNUAL_EMPLOYER_PULL_NOTICE',
  EMPLOYMENT_APPLICATION = 'EMPLOYMENT_APPLICATION',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  DOT_MEDICAL_EXAMINER_CERTIFICATE = 'DOT_MEDICAL_EXAMINER_CERTIFICATE',
  MEDICAL_EXAMINER_NATIONAL_REGISTRY_REFERENCE = 'MEDICAL_EXAMINER_NATIONAL_REGISTRY_REFERENCE',
  PRE_EMPLOYMENT_CLEARINGHOUSE_QUERY = 'PRE_EMPLOYMENT_CLEARINGHOUSE_QUERY',
  DMV_PRINTOUT = 'DMV_PRINTOUT',
  DRUG_AND_ALCOHOL_POLICY = 'DRUG_AND_ALCOHOL_POLICY',
  RETURN_TO_DUTY_DOCUMENT = 'RETURN_TO_DUTY_DOCUMENT',
  CLEARINGHOUSE_LIMITED_QUERY_POLICY = 'CLEARINGHOUSE_LIMITED_QUERY_POLICY',
  PROFICIENCY_TEST = 'PROFICIENCY_TEST',
  ANNUAL_LIMITED_CLEARINGHOUSE_QUERY = 'ANNUAL_LIMITED_CLEARINGHOUSE_QUERY',
  ANNUAL_REVIEW_OF_DRIVING_RECORD = 'ANNUAL_REVIEW_OF_DRIVING_RECORD',
  PRE_EMPLOYMENT_DRUG_TEST = 'PRE_EMPLOYMENT_DRUG_TEST',
  RANDOM_DRUG_TEST = 'RANDOM_DRUG_TEST',
  RANDOM_ALCOHOL_TEST = 'RANDOM_ALCOHOL_TEST',
  SCHOOL_BUS_CERTIFICATE = 'SCHOOL_BUS_CERTIFICATE',
  SPAB_CERTIFICATE = 'SPAB_CERTIFICATE',
  T_01_CERTIFICATE = 'T_01_CERTIFICATE',
  T_02_CERTIFICATE = 'T_02_CERTIFICATE',
  HAZMAT_CERTIFICATE = 'HAZMAT_CERTIFICATE',
  TSA_CLEARANCE_CERTIFICATE = 'TSA_CLEARANCE_CERTIFICATE'
}
