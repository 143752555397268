import {Component, inject, OnInit} from "@angular/core";

import {ButtonComponent} from "@shared/components/button/button.component";


import {Constants} from "@core/constants/constants";
import {BaseComponent} from "@shared/base/base-component";
import {CreateOrUpdateUserComponent} from "./create-or-update-user/create-or-update-user.component";
import {State} from "@shared/base/base-state";
import {CommonModule} from "@angular/common";

import {PageHeaderComponent} from "@core/components/page-header/page-header.component";
import {
  ColumnDef,
  DataTableComponent,
  TableActionEvent,
  TableStateEvent
} from "@shared/components/data-table/data-table.component";
import {HasPermissionDirective} from "@core/directives/has-permission.directive";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {
  DisableUserRequest,
  DisableUserResponse, EnableUserRequest,
  EnableUserResponse,
  GetUserListItem,
  GetUserListParams,
  GetUserListResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {TableCellUserActionsComponent} from "../components/table-cell-user-actions/table-cell-user-actions.component";

@Component({
  selector: "app-users",
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    PageHeaderComponent,
    DataTableComponent,
    HasPermissionDirective
  ],
  templateUrl: "./users.component.html",
  styleUrl: "./users.component.scss",
})
export class UsersComponent extends BaseComponent implements OnInit {

  apiService = inject(ApiService);
  overlayService = inject(OverlayService);

  userListState = new State<GetUserListResponse>();

  searchText: string = '';

  columnDefs: ColumnDef[] = [
    {
      title: 'Name',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$firstName $lastName',
      sortKey: 'name'
    },
    {
      title: 'Email',
      type: 'text',
      alignment: 'center',
      displayTemplate: '$email',
      sortKey: 'email'
    },
    {
      title: 'Phone Number',
      type: 'text',
      displayTemplate: '$phoneNumber',
      alignment: 'center',
      sortKey: 'phoneNumber',
      pinned: 'left'
    },
    {
      title: 'Role', type: 'badge', displayTemplate: '$roleId', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'COMPANY_ADMIN',
            displayText: 'Admin',
            backgroundColorClass: 'bg-info-50',
            borderColorClass: 'border-info-200',
            textColorClass: 'text-info-500'
          },
          {
            data: 'COMPANY_REGIONAL_MANAGER',
            displayText: 'Regional Manager',
            backgroundColorClass: 'bg-info-50',
            borderColorClass: 'border-info-200',
            textColorClass: 'text-info-500'
          },
          {
            data: 'COMPANY_TERMINAL_MANAGER',
            displayText: 'Terminal Manager',
            backgroundColorClass: 'bg-info-50',
            borderColorClass: 'border-info-200',
            textColorClass: 'text-info-500'
          },
        ]
      }
    },
    {
      title: 'Status', type: 'badge', displayTemplate: '$isActive', alignment: 'center', badgeConfig: {
        properties: [
          {
            data: 'true',
            displayText: 'Enabled',
            indicatorColorClass: 'bg-success-500',
            backgroundColorClass: 'bg-success-50',
            borderColorClass: 'border-success-200',
            textColorClass: 'text-success-500'
          },
          {
            data: 'false',
            displayText: 'Disabled',
            indicatorColorClass: 'bg-error-500',
            backgroundColorClass: 'bg-error-50',
            borderColorClass: 'border-error-200',
            textColorClass: 'text-error-500'
          }
        ]
      }
    },
    {
      title: 'Actions',
      type: 'actions',
      alignment: 'center',
      actionsConfig: {
        iconActions: [
          {
            iconPath: 'assets/icons/edit.svg',
            actionKey: 'edit', label: 'Update User'
          }
        ],
        components: [
          TableCellUserActionsComponent
        ]
      }
    }
  ];

  ngOnInit() {

  }

  getUserList($event: TableStateEvent) {
    let request: GetUserListParams = {
      pageNumber: $event?.paginationEvent?.pageNumber ?? 1,
      pageSize: $event?.paginationEvent?.pageSize ?? 50,
      search: $event.searchText
    };

    this.executeRequest<GetUserListResponse>({
      request: this.apiService.getUserList(request),
      state: this.userListState,
    });
  }

  onActionClicked($event: TableActionEvent) {
    let item: GetUserListItem = $event.item;
    switch ($event.actionKey) {
      case 'edit':
        this.onEditClicked(item);
        break;
      case  'enableUser':
        this.getUserList(Constants.defaultTableStateEvent);
        break;
      case 'disableUser':
        this.getUserList(Constants.defaultTableStateEvent);
    }
  }

  onAddUserClicked() {
    this.overlayService.openModal(CreateOrUpdateUserComponent).closed.subscribe((isSuccess) => {
      if (isSuccess) {
        this.getUserList(Constants.defaultTableStateEvent);
      }
    });
  }

  onEditClicked(user: GetUserListItem) {
    this.overlayService.openModal(CreateOrUpdateUserComponent, {
      data: user,
    }).closed.subscribe((isSuccess) => {
      if (isSuccess) {
        this.getUserList(Constants.defaultTableStateEvent);
      }
    });
  }
}
