<div class="container p-4 mx-auto flex flex-col min-h-full max-h-full gap-4">
  <div class="flex gap-5 justify-end items-center ml-auto">
    <div class="flex gap-2 items-center border text-primary-500 rounded-lg">
      <app-button [iconSrc]="'assets/icons/export.svg'" [buttonColor]="'bg-white'" [appearance]="'outline'"
                  [textButtonColor]="'text-primary-500'">Export
      </app-button>
    </div>
    <div class="flex gap-2 items-center bg-primary-500 rounded-lg">
      <app-button [iconSrc]="'assets/icons/plus.svg'" [matMenuTriggerFor]="menu">New Driver</app-button>
      <mat-menu #menu="matMenu" class="mat-elevation-z3 mt-4">
        <button mat-menu-item class="flex items-center" (click)="onSendDriverApplicationClicked()">
          <div class="flex items-center gap-2">
            <app-svg-icon class="text-neutral-400" [src]="'assets/icons/share.svg'"></app-svg-icon>
            <p class="text-body2">Send Driver Application</p>
          </div>
        </button>
        <button mat-menu-item class="flex items-center">
          <div class="flex items-center gap-2">
            <app-svg-icon class="text-neutral-400" [src]="'assets/icons/download.svg'"></app-svg-icon>
            <p class="text-body2">Download Application</p>
          </div>
        </button>
        <button mat-menu-item class="flex items-center">
          <div class="flex items-center gap-2">
            <app-svg-icon class="text-neutral-400" [src]="'assets/icons/plus.svg'"></app-svg-icon>
            <p class="text-body2">New Driver</p>
          </div>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="flex gap-2">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                            [fullWidth]="false"></app-outline-text-input>
  </div>
  <div class="h-full overflow-auto">
    <app-base-table appTableResizableColumns sortableTable [state]="driverListState">
      <ng-container head>
        <th class="p-6 text-left">FULL NAME</th>
        <th class="p-6 text-center ">CONTACT</th>
        <th class="p-6 text-center ">HIRED DATA</th>
        <th class="p-6 text-center ">LICENSE EXPIRING</th>
        <th class="p-6 text-center ">STATUS</th>
        <th class="p-6 text-center "></th>
      </ng-container>
      >
      <ng-container body>
        @for (item of driverListState.response()?.data; track item; ) {
          <tr class="border-b border-neutral-100 w-full">
            <td class="px-4 py-2 text-left">
              <div class="flex flex-col gap-2">
                <span class="text-body2 text-neutral-800">{{ item.fullName }}</span>
                <span class="text-caption text-neutral-500">{{ item.code }}</span>
              </div>
            </td>
            <td class="px-4 py-2 text-center">
              <div class="flex flex-col gap-2">
                <span class="text-body2 text-info-500">{{ item.email }}</span>
                <span class="text-body2 text-neutral-500">{{ item.phoneNumber }}</span>
              </div>
            </td>
            <td class="px-4 text-body2 text-center">{{ item.hiredDate | date:'MMM d, y h:mm a' }}</td>
            <td class="px-4 text-body2 text-center">{{ item.licenseExpiringDate |date:'MMM d, y h:mm a' }}</td>
            <td class="px-4">
              <div class="flex justify-center">
              <span
                class="px-4 py-2 rounded-full items-center justify-center text-info-500 text-nowrap"
                [ngClass]="{
                'bg-success-50 text-success-500': item.driverStatus === 'Active',
                'bg-error-50 text-error-500' : item.driverStatus === 'In-Active',
                }">
                {{ item.driverStatus }}
              </span>
              </div>
            </td>
            <td class="p-4 cursor-pointer items-center">
              <div class="flex justify-center items-center">
                <app-button (click)="onViewDriverClicked(item)" appearance="textType" textButtonColor="text-info-500">
                  View
                </app-button>
                <app-context-menu-icon></app-context-menu-icon>
              </div>
            </td>
          </tr>
        }
      </ng-container>
    </app-base-table>
  </div>
</div>
