<app-base-overlay [title]="'Thank you for applying for the driver position!'">
  <div body>
    <div class="flex flex-col items-center justify-center gap-3 py-3">
      <img class="h-20 w-20" src="../../../../../../assets/images/branding/mail-success.png"/>
      <p class="text-body2 text-center">Thank you for applying for the driver position!</p>
      <p class="overflow-hidden max-w-full text-caption text-success-500 text-center">We have sent the job
        application to your email. Kindly check your inbox. Thank you!</p>
    </div>

  </div>
  <div actions>
    <div class="flex flex-col items-center gap-4">
      <app-button (click)="closeDialog()">Close</app-button>
    </div>
  </div>
</app-base-overlay>


