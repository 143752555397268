<app-base-input #inputElement [title]="label()" [fullWidth]="fullWidth()" [isRequiredField]="hasRequiredValidator()">
  <div input class="relative flex items-center w-full text-neutral-400 bg-white shadow-1 rounded-md">
    @if (iconSrc()) {
      <app-svg-icon [size]="18" [src]="iconSrc()!"
                    class="absolute pointer-events-none ml-3 text-neutral-400"></app-svg-icon>
    }

<!--    <p>{{  formControl.getError('required') ? placeholder + ' ' : placeholder }}</p>-->

    <input
      [mask]="mask()"
      class="h-[46px] placeholder-neutral-400 text-black rounded-md border-none outline-none focus:border-none ring-1 ring-neutral-300 focus:ring-primary-500 focus:ring-2 transition-all duration-300"
      [class.text-neutral-400]="formControl.disabled"
      [class.disabled-placeholder]="formControl.disabled"
      [formControl]="formControl"
      [ngClass]="getClass()"
      [type]="type()"
      [placeholder]="placeholder()"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (input)="onValueChange($event)">

    @if (actionIcon()) {
      <div (click)="onActionClicked()"
           class="absolute right-2 p-2 flex justify-center items-center hover:bg-neutral-100 rounded-full">
        <app-svg-icon [size]="18" [src]="actionIcon()!" class="text-neutral-400 cursor-pointer">
        </app-svg-icon>
      </div>
    }
  </div>
</app-base-input>

@if (formControl.errors && hasErrors) {
  <div class="mt-1">
    <span class="text-caption text-red-500">{{ formControl.errors | humanizeFormMessages: errorMessages() }}</span>
  </div>
} @else {
  @if (showErrorSpace()) {
    <div class="mt-1">
      <span class="text-caption text-transparent">.</span>
    </div>
  }
}
