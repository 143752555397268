import {Component, inject,  OnInit, output} from "@angular/core";
import { ButtonComponent } from "../../../../../shared/components/button/button.component";
import { AppSvgIconComponent } from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { OutlineTextInputComponent } from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { DropdownComponent } from "../../../../../shared/inputs/dropdown/dropdown.component";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { RadioButtonComponent } from "../../../../../shared/inputs/radio-button/radio-button.component";
import { BaseComponent } from "../../../../../shared/base/base-component";
import { State } from "../../../../../shared/base/base-state";
import { CheckboxComponent } from "../../../../../shared/inputs/checkbox/checkbox.component";
import { CommonService } from "../../../../../core/services/common/common.service";
import { LicenseClassesResponse } from "../../../../../core/services/common/license-classes/license-classes-response";
import {
  CountriesResponse,
  Country,
  CountryState,
} from "../../../../../core/services/common/countries/countries-response";
import { DriverApplicationService } from "../../../../../core/services/driver-application/driver-application.service";
import { SaveApplicantLicensesInfoRequest } from "../../../../../core/services/driver-application/save-applicant-licenses/save-applicant-licenses-info-request";
import { TokenStoreService } from "../../../../../core/services/token/token-store.service";
import { DateUtils } from "../../../../../shared/utils/date-utils";
import {
  ApplicantLicense,
  ApplicantLicensesInfoResponse,
} from "../../../../../core/services/driver-application/applicant-licenses-info/applicant-licenses-info-response";
import {
  ApplicantGeneralInfoResponse
} from "../../../../../core/services/driver-application/applicant-general-info/applicant-general-info-response";

@Component({
  selector: "app-applicant-license-info",
  templateUrl: "./save-applicant-license-info.component.html",
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    OutlineTextInputComponent,
    DropdownComponent,
    ReactiveFormsModule,
    RadioButtonComponent,
    CheckboxComponent,
  ],
  styleUrls: ["./save-applicant-license-info.component.scss"],
})
export class SaveApplicantLicenseInfoComponent
  extends BaseComponent
  implements OnInit
{
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  commonService = inject(CommonService);
  driverApplicationService = inject(DriverApplicationService);
  tokenService = inject(TokenStoreService);

  countryListState = new State<CountriesResponse>();
  licenseClassesState = new State<LicenseClassesResponse>();
  licensesState = new State<ApplicantLicensesInfoResponse>();
  saveLicenseState = new State<any>();
  applicantGeneralInfoState = new State<ApplicantGeneralInfoResponse>();
  driverService = inject(DriverApplicationService);


  licenseForm!: FormGroup;
  currentLicenseForm!: FormGroup;
  licensesFormArray!: FormArray;

  statesList: CountryState[][] = [];

  ngOnInit(): void {
    this.initForm();
    this.getCountries();
    this.getLicenseClasses();
    this.getLicenses();
  }

  private initForm() {
    this.licensesFormArray = this.formBuilder.array([]);
    this.licenseForm = this.formBuilder.group({
      licenses: this.licensesFormArray,
    });
  }

  getApplicantGeneralInfo() {
    this.executeRequest<ApplicantGeneralInfoResponse>({
      state: this.applicantGeneralInfoState,
      request: this.driverService.getApplicationGeneralInfo(
        this.tokenService.getDriverApplicationId() ?? ""
      ),
      onSuccess: response => {
        let generalInfo= response.data;
        this.currentLicenseForm.controls["firstName"].setValue(generalInfo.firstName);
        this.currentLicenseForm.controls["lastName"].setValue(generalInfo.lastName);
      }
    });
  }

  getCountries() {
    this.executeRequest<CountriesResponse>({
      state: this.countryListState,
      request: this.commonService.getCountries(),
    });
  }

  private getLicenseClasses() {
    this.executeRequest<LicenseClassesResponse>({
      state: this.licenseClassesState,
      request: this.commonService.getLicenseClasses(),
    });
  }

  private getLicenses() {
    let request = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<ApplicantLicensesInfoResponse>({
      state: this.licensesState,
      request: this.driverApplicationService.getApplicantLicenses(request),
      onSuccess: (response) => {
        let licenses = response.data ?? [];
        this.populateForm(licenses);
        this.getApplicantGeneralInfo();
      },
    });
  }

  populateForm(licenses: ApplicantLicense[]) {
    let currentLicense = licenses.find((license) => license.isCurrent) ?? null;
    let additionalLicenses = licenses.filter((license) => !license.isCurrent);

    this.currentLicenseForm = this.createLicenseForm(currentLicense, true);
    this.licensesFormArray.push(this.currentLicenseForm);

    additionalLicenses.forEach((license) => {
      let newForm = this.createLicenseForm(license, false);
      this.licensesFormArray.push(newForm);
    });
  }

  createLicenseForm(
    license: ApplicantLicense | null,
    isCurrentLicense: boolean
  ): FormGroup {
    let countries = this.countryListState.response()?.data ?? [];
    let matchedCountry = countries.find(
      (country) => country.countryName === license?.country
    );
    if (matchedCountry) {
      this.statesList.push(matchedCountry.states);
    }

    let dateUtils = new DateUtils();
    let generalInfo = this.applicantGeneralInfoState.response()?.data;
    return this.formBuilder.group({
      id: [license?.id],
      firstName: [generalInfo?.firstName, Validators.required],
      lastName: [generalInfo?.lastName, Validators.required],
      licenseNo: [license?.licenseNo, Validators.required],
      class: [license?.class, Validators.required],
      country: [license?.country, Validators.required],
      state: [license?.state, Validators.required],
      issuedDate: [
        dateUtils.convertToMMDDYYYY(license?.issueDate),
        Validators.required,
      ],
      expirationDate: [
        dateUtils.convertToMMDDYYYY(license?.expirationDate),
        Validators.required,
      ],
      hasPlacardedHazmat: [license?.hasPlacardedHazmat ?? false],
      hasTankVehicles: [license?.hasTankVehicles ?? false],
      hasPassengers: [license?.hasPassengers ?? false],
      hasDoubleOrTripleTrailers: [license?.hasDoubleOrTripleTrailers ?? false],
      hasSchoolBus: [license?.hasSchoolBus ?? false],
      hasPlacardedHazmatAndTankVehicles: [
        license?.hasPlacardedHazmatAndTankVehicles ?? false,
      ],
      hasNameChange: [license?.hasNameChanged ?? false],
      previousName: [license?.previousName],
      isCurrent: [isCurrentLicense],
    });
  }

  onDeleteClicked(index: number): void {
    if (this.licensesFormArray.length > 1)
      this.licensesFormArray.removeAt(index);
  }


  onCountrySelected(selectedItem: Country | null, index: number) {
    let states = selectedItem?.states ?? [];
    const formGroup = this.licensesFormArray.at(index) as FormGroup;
    formGroup.patchValue({ state: null });
    this.statesList[index] = states;
  }

  onAddPreviousLicenseClicked() {
    let newForm = this.createLicenseForm(null, false);
    this.licensesFormArray.push(newForm);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.licenseForm);
    if (this.licenseForm.invalid) {
      return;
    }

    let request: SaveApplicantLicensesInfoRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      licenses: [],
    };

    let dateUtils = new DateUtils();
    this.licensesFormArray.controls.forEach((control) => {
      let license = control.value;
      request.licenses.push({
        firstName: license.firstName,
        lastName: license.lastName,
        licenseNo: license.licenseNo,
        class: license.class,
        country: license.country,
        state: license.state,
        issueDate: dateUtils.convertToDateTimeString(license.issuedDate),
        expirationDate: dateUtils.convertToDateTimeString(
          license.expirationDate
        ),
        isCurrent: license.isCurrent,
        hasPlacardedHazmat: license.hasPlacardedHazmat,
        hasTankVehicles: license.hasTankVehicles,
        hasPassengers: license.hasPassengers,
        hasDoubleOrTripleTrailers: license.hasDoubleOrTripleTrailers,
        hasSchoolBus: license.hasSchoolBus,
        hasPlacardedHazmatAndTankVehicles:
        license.hasPlacardedHazmatAndTankVehicles,
        hasNameChanged: license.hasNameChange,
        previousName: license.previousName,
      });
    });

    this.executeRequest({
      state: this.saveLicenseState,
      request: this.driverApplicationService.saveApplicantLicenses(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
