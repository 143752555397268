@let driverInfo = driverGeneralInfoState.response()?.data ;

<div class="flex flex-col h-full bg-white">




    <div class="flex items-center gap-5 p-4 justify-between bg-white">
      @if(driverGeneralInfoState.loading()) {
        <app-shimmer type="multiline"></app-shimmer>
      }
      @if(driverGeneralInfoState.success()) {
        <div class="flex flex-col gap-3">

          <div class="flex items-center gap-5">
            <p class="text-body2">{{ driverInfo?.firstName }} {{ driverInfo?.middleName }} {{ driverInfo?.lastName }}</p>
          </div>

          <div class="flex items-center gap-5">


            <div class="flex items-center gap-3">
              <app-svg-icon [src]="'assets/icons/mail.svg'" [size]="16" class="text-primary-500"></app-svg-icon>
              <p class="text-body2 text-neutral-500">{{ driverInfo?.email }}</p>
            </div>


            <div class="flex items-center gap-3">
              <app-svg-icon [src]="'assets/icons/phone.svg'" [size]="16" class="text-primary-500"></app-svg-icon>
              <p class="text-body2 text-neutral-500">{{ driverInfo?.phoneNumber }}</p>
            </div>


            <!--        <div class="flex items-center gap-2">-->
            <!--          <app-svg-icon [src]="'assets/icons/license-card.svg'" class="text-primary-500"></app-svg-icon>-->
            <!--          <p class="text-body2 text-primary-500">LICXXXXXX</p>-->
            <!--        </div>-->

          </div>
        </div>
        <app-button appearance="outline" [iconSrc]="'assets/icons/download-outline.svg'">
          Download All Documents
        </app-button>
      }

    </div>

  <div class="flex flex-col h-dvh bg-white overflow-hidden">
    <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)="onTabChange($event)"
                   [(selectedIndex)]="activeStepIndex">

      @for (step of steps; track step; let index = $index) {
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex items-center p-2">
              <p class="text-body2 text-neutral-900 select-none">{{ step.title }}</p>
            </div>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
    <div class="grow sticky bg-neutral-100 overflow-hidden">
      @switch (activeStep().type) {
        @case ('generalInfo') {
          <app-driver-general-info [driverId]="driverId()"></app-driver-general-info>
        }
        @case ('residencyInfo') {
          <app-driver-residency-info [driverId]="driverId()"></app-driver-residency-info>
        }
        @case ('licenseInfo') {
          <app-driver-license-info [driverId]="driverId()"></app-driver-license-info>
        }
        @case ('documents') {
          <app-driver-documents [driverId]="driverId()"></app-driver-documents>
        }
        @case ('reminders') {
          <app-driver-reminders [driverId]="driverId()"></app-driver-reminders>
        }
      }
    </div>
  </div>
</div>



