<div class="flex flex-col h-dvh bg-white">
  <div class="flex items-center gap-5 p-4 justify-between bg-white">
    <p class="text-body1 font-normal">Employment Application</p>
    <app-button [loading]="driverApplicationState.loading() && completedApplicationClicked()"
                (click)="onCompleteApplicationClicked()">Complete Application
    </app-button>
  </div>

  <mat-tab-group dynamicHeight (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="activeStepIndex">
    @for (step of steps; track step; let index = $index) {
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center gap-3">
            <p class="text-body2 text-neutral-900">{{ index + 1 }}. {{ step.title }}</p>

            <app-svg-icon [size]="20"
                          [src]="step.completed() ? 'assets/icons/check-circle-outline.svg' : 'assets/icons/alert-circle-outline.svg'"
                          class="pointer-events-none"
                          [ngClass]="step.completed() ? 'text-success-500' : 'text-neutral-400'">
            </app-svg-icon>
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>

  <div class="grow bg-neutral-100 overflow-hidden">
    @switch (activeStep().type) {
      @case ('general') {
        <app-save-applicant-general-info (onNextClick)="onNextClicked()"></app-save-applicant-general-info>
      }
      @case ('residency') {
        <app-save-applicant-residency-info (onNextClick)="onNextClicked()"
                                           (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-residency-info>
      }
      @case ('license') {
        <app-applicant-license-info (onNextClick)="onNextClicked()"
                                    (onPreviousClick)="onPreviousClicked()">
        </app-applicant-license-info>
      }
      @case ('driving-experience') {
        <app-save-driving-experience-info (onNextClick)="onNextClicked()"
                                          (onPreviousClick)="onPreviousClicked()">
        </app-save-driving-experience-info>
      }
      @case ('accidents') {
        <app-save-applicant-accident-info (onNextClick)="onNextClicked()"
                                          (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-accident-info>
      }
      @case ('traffic-convictions') {
        <app-save-applicant-traffic-convictions
          (onNextClick)="onNextClicked()"
          (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-traffic-convictions>
      }
      @case ('employments') {
        <app-save-applicant-employments-info (onNextClick)="onNextClicked()"
                                             (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-employments-info>
      }
      @case ('education') {
        <app-save-applicant-education-info (onPreviousClick)="onPreviousClicked()" (onNextClick)="onNextClicked()">
        </app-save-applicant-education-info>
      }
    }
  </div>
</div>
