<nav [ngClass]="layoutService.sidebarExpanded ? 'w-52 xl:w-64' : 'w-[70px]'" class="flex flex-col overflow-auto bg-primary-800 h-dvh transition-all duration-300">
  <div class="flex justify-center items-center gap-3 py-2">
    @if (layoutService.sidebarExpanded) {
      <img [height]="34" src="assets/images/branding/YSC-logo-white.svg" alt="Logo" />
    } @else {
      <img [height]="34" src="assets/images/branding/logo-white.svg" alt="Logo" />
    }
  </div>
  <div class="border-b border-gray-500 mb-5"></div>
  <div class="flex grow flex-col p-4">
    @if (layoutService.sidebarExpanded) {
      <p class="text-caption text-neutral-300 cursor-pointer mb-4">Main Menu</p>
    }
    <div class="flex flex-col gap-2">
      @for (menu of menus(); track menu) {
        <app-sidebar-menu [menuItem]="menu" (click)="onMenuClick(menu)">{{menu.label}}</app-sidebar-menu>
      }
    </div>
    <div class="grow"></div>
    @if (layoutService.sidebarExpanded) {
      <p class="text-caption text-neutral-300 cursor-pointer mb-4">Account</p>
    }
    <app-sidebar-menu class="mb-4" [menuItem]="settingsMenu()"></app-sidebar-menu>
    <div class="flex w-full justify-end">
      <div class="h-7 w-7 rounded-full flex justify-center items-center bg-white cursor-pointer" (click)="toggleSidebar()">
        @if (layoutService.sidebarExpanded) {
          <app-svg-icon class="text-gray-500" src="assets/icons/chevron-left.svg"></app-svg-icon>
        } @else {
          <app-svg-icon class="text-gray-500" src="assets/icons/chevron-right.svg"></app-svg-icon>
        }
      </div>
    </div>
  </div>
</nav>
