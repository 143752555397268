<app-base-overlay [title]="'Document Sub Types'">
  <div body>
    <div class="flex flex-col gap-4">
      @for (attachment of attachments; track $index) {
        <div class="flex items-center gap-4 justify-between bg-white px-4 py-3 shadow-1 rounded">
          <p class="text-body2 text-black">{{ attachment.documentSubTypeName }}</p>
          <div class="flex items-center gap-4">
            <div
              (click)="onViewClicked(attachment)"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/eye.svg"></app-svg-icon>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</app-base-overlay>
