import { Component, inject, OnInit } from "@angular/core";
import { SidebarComponent } from "../../layout/components/sidebar/sidebar.component";
import { AppSvgIconComponent } from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import { OverviewCardComponent } from "./overview-card/overview-card.component";
import { BaseComponent } from "../../../shared/base/base-component";
import { State } from "../../../shared/base/base-state";
import {NoDataComponent} from "../../../shared/components/no-data/no-data.component";

@Component({
  selector: "app-dashboard",
  standalone: true,
  imports: [
    NoDataComponent,
  ],
  templateUrl: "./dashboard.component.html",
  styleUrl: "./dashboard.component.scss",
})
export class DashboardComponent extends BaseComponent implements OnInit {
  // dashboardService = inject(DashboardService);

  // overViewState = new State<OverViewResponse>();
  // expiringDocumentState = new State<ExpiringDocumentsResponse>();

  ngOnInit(): void {
    this.getOverView();
    this.getExpiringDocuments();
  }

  onNewApplicationsClicked() {}
  private getExpiringDocuments() {
    // this.executeRequest({
    //   state: this.expiringDocumentState,
    //   request: this.dashboardService.getExpiringDocuments(),
    // });
  }

  private getOverView() {
    // this.executeRequest({
    //   state: this.overViewState,
    //   request: this.dashboardService.getOverviewData(),
    // });
  }
}
