<app-base-overlay [title]="'Thank you for signing up with us!'" class="mx-6">
  <div body>
    <div class="flex flex-col items-center gap-4">
      <div class="flex items-center gap-5">
        <img src="../../../../../../assets/images/branding/YSC-logo.png"/>
      </div>
      <img class="h-20 w-20 mt-4" src="../../../../../../assets/images/branding/mail-success.png"/>
      <p class="text-body2">Thank you for signing up with us!</p>
      <div class="flex flex-col py-4">
        <p class="overflow-hidden max-w-full text-caption text-success-500 text-center text-nowrap">We'll review your
          application and our business representative will contact you shortly</p>
      </div>
    </div>
  </div>
  <div actions>
    <div class="flex flex-col items-center gap-4">
      <app-button (click)="closeDialogue()">Close</app-button>
    </div>
  </div>
</app-base-overlay>


