<app-base-overlay [title]="dialogData ? 'Update Out Of Service Reason' : 'Create Out Of  Service Reason'">
  <div body>
    <form [formGroup]="createOrUpdateOutOfServiceReasonForm">
      <div class="flex flex-col gap-4 justify-center items-center my-6 w-full">
        <app-text-input formControlName="name" [label]="'Name'" type="text"
                                class="w-full mx-6" placeholder="Ex: Mechanical Breakdown"
                                [fullWidth]="true"></app-text-input>
        <app-text-area-input formControlName="description" [label]="'Description'" type="text"
                             class="w-full mx-6"
                             placeholder="Ex: The truck is out of service due to a mechanical failure."
                             [fullWidth]="true"></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'" [fullWidth]="true" (click)="onSaveClicked()"
                  [loading]="createOutOfServiceReasonState.loading() || updateOutOfServiceReasonState.loading()">
        {{ dialogData ? 'Update Out Of Service Reason' : 'Create Out Of Service Reason' }}
      </app-button>
    </div>
  </div>
</app-base-overlay>
