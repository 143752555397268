@let applicationInfo = driverApplicationInfoState.response()?.data;

<div class="h-dvh flex flex-col overflow-hidden">
  <div class="flex flex-col gap-3 bg-slate-50 flex-1 overflow-hidden">

    <div class="flex items-center gap-4 bg-white px-4 py-2">
      @if (userRole != UserRole.driverApplicant) {
        <div (click)="onBackClicked()"
             class="p-2 cursor-pointer rounded-full hover:shadow-2 transition-all duration-500">
          <app-svg-icon src="assets/icons/arrow-left.svg"></app-svg-icon>
        </div>
        <p class="text-body1 font-normal hidden md:inline">Complete Application</p>
      }
    </div>

    <div class="flex-1 w-full bg-slate-50 overflow-y-scroll">
      <div class="flex justify-center container mx-auto h-full w-full p-4">
        <div class="flex flex-col w-full rounded-md">
          <div class="grow overflow-auto p-8 px-4">
            <div class="py-6 space-y-4 text-center">
              <p class="text-body1">TO BE READ AND SIGNED BY APPLICANT</p>
            </div>
            <p class="text-body2 text-neutral-800 py-4">
              I authorize you to make investigations (including contacting current and prior employers) into my
              personal,
              employment, financial, medical history, and other related matters as may be necessary in arriving at an
              employment decision. I hereby release employers, schools, health care providers, and other persons from
              all
              liability in responding to inquiries and releasing information in connection with my application.
            </p>
            <p class="text-body2 text-neutral-800 py-4">
              In the event of employment, I understand that false or misleading information given in my application or
              interview(s) may result in discharge. I also understand that I am required to abide by all rules and
              regulations of the Company.
            </p>
            <p class="text-body2 text-neutral-800 py-4">
              I understand that the information I provide regarding my current and/or prior employers may be used, and
              those
              employer(s) will be contacted for the purpose of investigating my safety performance history as required
              by
              49
              CFR 391.23. I understand that I have the right to:
            </p>
            <ul class="text-body2 text-neutral-800 py-4 list-disc mx-2">
              <li>Review information provided by current/previous employers;</li>
              <li>Have errors in the information corrected by previous employers, and for those previous employers to
                resend
                the corrected information to the prospective employer;
              </li>
              <li>Have a rebuttal statement attached to the alleged erroneous information, if the previous employer(s)
                and
                I
                cannot agree on the accuracy of the information.
              </li>
            </ul>
            <p class="text-body2 text-neutral-800 py-4">
              This certifies that I completed this application, and that all entries on it and information in it are
              true
              and complete to the best of my knowledge. Note: A motor carrier may require an applicant to provide more
              information than that required by the Federal Motor Carrier Safety Regulations.
            </p>

            <form [formGroup]="completeApplicationForm">
              <div class="flex flex-col gap-6">
                <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
                  <p class="text-body1 font-normal text-info-500">DOT Medical Certificate Information</p>
                  <app-file-uploader
                    formControlName="licenseDotMedicalCertificateFileUrl"
                    label="Attach Current DOT Medical Certification (Medical card)"
                    [uploading]="uploadDotMedicalCertificateFileState.loading()"
                    (fileSelected)="onDotMedicalCertificatePrintoutFileSelected($event)">
                  </app-file-uploader>
                  <app-date-input formControlName="medicalCardExpirationDate"
                                  [label]="'DOT medical certificate expiry date'" class="w-full" [fullWidth]="true">
                  </app-date-input>
                </div>

                <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
                  <p class="text-body1 font-normal text-info-500">DMV Printout Information</p>
                  <app-file-uploader
                    formControlName="dmvPrintoutFileUrl"
                    label="Attach DMV Printout (Printout date should be within 30 days of application date)"
                    [uploading]="uploadDmvPrintoutFileState.loading()"
                    (fileSelected)="onDMVPrintoutFileSelected($event)">
                  </app-file-uploader>
                  <app-date-input formControlName="dmvPrintoutExpiryDate" [label]="'DMV printout date'"
                                  class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
                  </app-date-input>
                </div>

                <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
                  <p class="text-body1 font-normal text-info-500">Driving License Information</p>
                  <app-file-uploader
                    formControlName="currentDrivingLicenseFrontFilePath"
                    label="Attach Current Driving License Front Photo"
                    [uploading]="uploadCurrentDrivingLicenseFileState.loading()"
                    (fileSelected)="onAttachCurrentDrivingLicenseFrontFileSelected($event)">
                  </app-file-uploader>
                  <app-file-uploader
                    formControlName="currentDrivingLicenseBackFilePath"
                    label="Attach Current Driving License Back Photo"
                    [uploading]="uploadCurrentDrivingLicenseFileState.loading()"
                    (fileSelected)="onAttachCurrentDrivingLicenseBackFileSelected($event)">
                  </app-file-uploader>
                  <app-date-input formControlName="licenseExpiryDate"
                                  [label]="'Current Driving License Expiry Date'" class="w-full" [fullWidth]="true">
                  </app-date-input>
                </div>

                @if (driverApplicationInfoState.response()?.data?.hasSchoolBus) {
                  <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
                    <p class="text-body1 font-normal text-info-500">School Bus Information</p>
                    <div class="flex gap-3 items-center">
                      <app-svg-icon src="assets/icons/info.svg" [size]="18" class="text-neutral-400"></app-svg-icon>
                      <p class="text-caption text-neutral-500">Please provide the required school bus certificate
                        information, as your current license is endorsed with a School Bus</p>
                    </div>

                    <app-file-uploader
                      formControlName="schoolBusFileURL"
                      label="Attach School Bus Certificate"
                      [uploading]="uploadSchoolBusCertificateState.loading()"
                      (fileSelected)="onAttachSchoolBusFileCertificate($event)">
                    </app-file-uploader>
                    <div class="flex flex-col py-4 gap-4">
                      <app-text-input formControlName="schoolBusCertificateLicenceNo"
                                      [label]="'School bus certificate number'" placeholder="Ex: SB-2024-123456"
                                      class="w-full" [fullWidth]="true">
                      </app-text-input>
                      <app-date-input formControlName="schoolBusCertificateIssuedDate"
                                      [label]="'School bus certificate issue date'" class="w-full"
                                      [fullWidth]="true">
                      </app-date-input>
                      <app-date-input formControlName="schoolBusCertificateLicenceExpiryDate"
                                      [label]="'School bus certificate expiry date'" class="w-full"
                                      [fullWidth]="true">
                      </app-date-input>
                    </div>
                  </div>
                }

                @if (companyInfoState.response()?.data?.transportsHazmat) {
                  <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
                    <p class="text-body1 font-normal text-info-500">HAZMAT Information</p>
                    <p class="text-primary-600">Please upload the HAZMAT certificate if you have any</p>
                    <app-file-uploader
                      formControlName="hazmatFileURL"
                      label="HAZMAT Certificate"
                      [isRequired]="false"
                      [uploading]="uploadHazmatFileState.loading()"
                      (fileSelected)="onAttachHazmatCertificate($event)">
                    </app-file-uploader>
                  </div>
                }

                <div *hasPermission="permission.submitJobApplication"
                     class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
                  <p class="text-body1 font-normal text-info-500">Signature</p>
                  <div class="grid lg:grid-cols-2 gap-4">
                    <app-text-input formControlName="applicantName" [label]="'Applicant Name'"
                                    placeholder="Ex: John" class="w-full" [fullWidth]="true">
                    </app-text-input>
                    <app-date-input formControlName="applicantSignatureDate" [label]="'Signature Date'"
                                    class="w-full" [fullWidth]="true"
                    ></app-date-input>
                  </div>

                  <div class="line !bg-neutral-200 my-4"></div>

                  <div class="flex flex-col gap-5">
                    <div class="flex items-center gap-3">
                      <p class="text-body2">Please read and sign <span class="font-medium">Drug & Alcohol Policy</span>
                      </p>
                      @if (applicationInfo?.isDrugAndAlcoholPolicySigned) {
                        <div class="flex gap-2">
                          <app-svg-icon class="text-success-500" [size]="18"
                                        src="assets/icons/check-circle.svg"></app-svg-icon>
                          <p class="text-body2 text-success-500">Completed</p></div>
                      } @else {
                        <p class="text-body2 text-info-500 cursor-pointer" (click)="onDrugAndAlcoholPolicyClicked()">
                          Click
                          to sign</p>
                      }
                    </div>
                    <div class="flex items-center gap-3">
                      <p class="text-body2">Please read and sign <span class="font-medium">Clearinghouse Policy</span>
                      </p>
                      @if (applicationInfo?.isClearingHousePolicySigned) {
                        <div class="flex gap-2">
                          <app-svg-icon class="text-success-500" [size]="18"
                                        src="assets/icons/check-circle.svg"></app-svg-icon>
                          <p class="text-body2 text-success-500">Completed</p></div>
                      } @else {
                        <p class="text-body2 text-info-500 cursor-pointer" (click)="onClearingHousePolicyClicked()">
                          Click
                          to
                          sign</p>
                      }
                    </div>
                  </div>

                  <div class="flex flex-col gap-1 py-3">
                    <label class="text-body2 text-neutral-800">Signature</label>
                    <app-signature-pad [width]="'500'" [height]="'200'"
                                       [backgroundColor]="'rgba(255, 255, 255, 0)'" [penColor]="'black'"
                                       (signatureChanged)="onSignatureSaved($event)"
                                       (signatureCleared)="onSignatureCleared()">
                    </app-signature-pad>
                    @if (completeApplicationForm.controls['applicantSignature'].hasError('required')) {
                      <p class="text-caption text-error-500">Required</p>
                    }
                  </div>

                  <app-checkbox class="col-span-2" formControlName="acknowledgement"
                                title="I hereby acknowledge and sign solely as the candidate for the position outlined, confirming my understanding and acceptance."></app-checkbox>

                  <div class="py-2">
                    <app-button (buttonClick)="onSubmitClicked()" [fullWidth]="true"
                                [loading]="completeApplicationState.loading()" type="submit">Submit
                    </app-button>
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    @if (userRole == UserRole.yscAdmin || UserRole.companyAdmin || UserRole.companyRegionalManager || UserRole.companyTerminalManager) {
      <div
        class="container mx-auto flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
        <app-button class="w-full" [fullWidth]="true" (click)="onHireDriverClicked()">Hire Driver</app-button>
      </div>
    }
  </div>
</div>
