import {Component, inject} from '@angular/core';
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {
  type ActiveDriverStatusRequest,
  type ActiveDriverStatusResponse,
  GetDriverListItem,
  GetDriverListItemDriverStatus,
  type InactiveDriverStatusRequest,
  type InactiveDriverStatusResponse,
  type TerminateDriversStatusRequest, type TerminateDriverStatusResponse
} from "@core/api/model";


import {BaseRequestService} from "@shared/base/base-request.service";
import {ApiService} from "@core/api/api.service";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {TableActionEvent} from "@shared/components/data-table/data-table.component";
import {State} from "@shared/base/base-state";
import {ContextMenuAction} from "@shared/components/context-menu/context-menu.component";
import {ContextMenuButtonComponent} from "@shared/components/context-menu-button/context-menu-button.component";

@Component({
  selector: 'app-table-cell-driver-actions',
  imports: [
    ContextMenuButtonComponent
  ],
  templateUrl: './table-cell-driver-actions.component.html',
  standalone: true,
  styleUrl: './table-cell-driver-actions.component.scss'
})
export class TableCellDriverActionsComponent extends TableCustomComponent<GetDriverListItem> {

  overlayService = inject(OverlayService);
  apiService = inject(ApiService);
  requestService = inject(BaseRequestService);

  activeDriverState = new State<ActiveDriverStatusResponse>();
  inactiveDriverState = new State<InactiveDriverStatusResponse>();
  terminateDriverState = new State<TerminateDriverStatusResponse>();

  get driverActions(): ContextMenuAction[] {
    let actions: ContextMenuAction[] = [];


    let transferOption: ContextMenuAction = {label: 'Transfer', actionKey: 'transfer'};
    let enableOption: ContextMenuAction = {label: 'Active', actionKey: 'activeDriver'};
    let disableOption: ContextMenuAction = {label: 'Inactive', actionKey: 'inactiveDriver'};
    let terminateOption: ContextMenuAction = {label: 'Terminate', actionKey: 'terminateDriver'};

    switch (this.rowData.driverStatus) {
      case GetDriverListItemDriverStatus.Active:
        actions = [transferOption, disableOption, terminateOption];
        break;
      case GetDriverListItemDriverStatus.Inactive:
        actions = [transferOption, enableOption, terminateOption];
        break;
      case GetDriverListItemDriverStatus.DriverAtRisk:
        actions = [transferOption, disableOption, terminateOption];
        break;
      case GetDriverListItemDriverStatus.Terminated:
        actions = [enableOption];
        break;
    }

    return actions;
  }

  handleAction(actionType: any, item: GetDriverListItem) {
    switch (actionType) {
      case 'transfer':
        this.onTransferDriverClicked(this.rowData);
        break;
      case 'activeDriver':
        this.onActiveDriverClicked(this.rowData);
        break;
      case 'inactiveDriver':
        this.onInactiveDriverClicked(this.rowData);
        break;
      case 'terminateDriver':
        this.onTerminateDriverClicked(this.rowData);
    }
  }

  onTransferDriverClicked(item: GetDriverListItem) {
    let event: TableActionEvent = {
      actionKey: 'transfer',
      item: this.rowData,
    };
    this.actionPerformed.emit(event);
  }

  onActiveDriverClicked(driver: GetDriverListItem) {
    let request: ActiveDriverStatusRequest = {
      driverIds: [driver.id]
    }
    this.requestService.executeRequest({
      state: this.activeDriverState,
      request: this.apiService.activeDriversStatus(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.rowData.driverStatus = GetDriverListItemDriverStatus.Active;
      }
    });
  }

  onInactiveDriverClicked(driver: GetDriverListItem) {
    this.overlayService
      .openAlert('Inactive Driver', 'Are you sure you want to make this driver Inactive?').closed.subscribe((result) => {

      if (result) {
        let request: InactiveDriverStatusRequest = {
          driverIds: [driver.id]
        }
        this.requestService.executeRequest({
          state: this.inactiveDriverState,
          request: this.apiService.inActiveDriversStatus(request),
          handleSuccess: true,
          handleError: true,
          showLoader: true,
          onSuccess: (response) => {
            this.rowData.driverStatus = GetDriverListItemDriverStatus.Inactive;
          }
        });
      }
    });
  }

  onTerminateDriverClicked(driver: GetDriverListItem) {
    this.overlayService
      .openAlert('Terminate Driver', 'Are you sure you want to terminate this driver?')
      .closed.subscribe((result) => {
      if (result) {
        let request: TerminateDriversStatusRequest = {
          driverIds: [driver.id],
        };

        this.requestService.executeRequest({
          state: this.terminateDriverState,
          request: this.apiService.terminateDriversStatus(request),
          handleSuccess: true,
          handleError: true,
          showLoader: true,
          onSuccess: (response) => {
            this.rowData.driverStatus = GetDriverListItemDriverStatus.Terminated;
          }
        });
      }
    });
  }

}
