import {Component, inject} from '@angular/core';

import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {
  DisableUserRequest,
  DisableUserResponse,
  EnableUserRequest,
  EnableUserResponse, GetDriverListItemDriverStatus,
  GetUserListItem
} from "@core/api/model";

import {BaseRequestService} from "@shared/base/base-request.service";
import {ApiService} from "@core/api/api.service";
import {State} from "@shared/base/base-state";
import {ContextMenuButtonComponent} from "@shared/components/context-menu-button/context-menu-button.component";
import {ContextMenuAction} from "@shared/components/context-menu/context-menu.component";
import {TableActionEvent} from "@shared/components/data-table/data-table.component";

@Component({
  selector: 'app-table-cell-user-actions',
  imports: [
    ContextMenuButtonComponent
],
  templateUrl: './table-cell-user-actions.component.html',
  standalone: true,
  styleUrl: './table-cell-user-actions.component.scss'
})
export class TableCellUserActionsComponent extends TableCustomComponent<GetUserListItem> {

  requestService = inject(BaseRequestService);
  apiService = inject(ApiService);

  enableUserState = new State<EnableUserResponse>();
  disableUserState = new State<DisableUserResponse>();

  get userActions(): ContextMenuAction[] {
    let actions: ContextMenuAction[] = [];

    let enableOption: ContextMenuAction = {label: 'Enable', actionKey: 'enableUser'};
    let disableOption: ContextMenuAction = {label: 'Disable', actionKey: 'disableUser'};


    if (this.rowData.isActive) {
      actions.push(disableOption);
    } else {
      actions.push(enableOption);
    }

    return actions;
  }

  handleUserAction(actionType: any) {
    if (actionType === 'enableUser') {
      this.onEnableCLicked(this.rowData);
    }
    if (actionType === 'disableUser') {
      this.onDisableClicked(this.rowData);
    }
  }

  onEnableCLicked(user: GetUserListItem) {
    let request: EnableUserRequest = {
      userIds: [user.id],
      message: "Enabling User"
    }
    this.requestService.executeRequest<EnableUserResponse>({
      state: this.enableUserState,
      request: this.apiService.enableUser(request),
      handleSuccess: true,
      showLoader: true,
      onSuccess: (response) => {
        let event: TableActionEvent = {
          actionKey: 'enableUser',
          item: this.rowData,
        };
        this.actionPerformed.emit(event);
      }
    });
  }

  onDisableClicked(user: GetUserListItem) {
    let request: DisableUserRequest = {
      userIds: [user.id],
      message: "Disable User"
    }
    this.requestService.executeRequest <DisableUserResponse>({
      state: this.disableUserState,
      request: this.apiService.disableUser(request),
      handleSuccess: true,
      showLoader: true,
      onSuccess: (response) => {
        let event: TableActionEvent = {
          actionKey: 'disableUser',
          item: this.rowData,
        };
        this.actionPerformed.emit(event);
      }
    });
  }

}
