<div class="p-4 bg-neutral-100 rounded-lg">
  <div class="">
    <p class="text-body2">Santhosh</p>
    <p class="text-body2">Santhosh</p>
    <p class="text-body2">Santhosh</p>
    <p class="text-body2">Santhosh</p>
    <p class="text-body2">Santhosh</p>
    <p class="text-body2">Santhosh</p>
    <p class="text-body2">Santhosh</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer Middle</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer</p>
    <p class="text-body1 text-gray-500">Developer last</p>
  </div>

</div>
