<app-base-overlay [title]="data ? 'Update Terminal' : 'New Terminal'">
  <div body>
    <form [formGroup]="createOrUpdateTerminalForm">
      <div class="flex flex-col gap-4 justify-center items-center w-full">
        <app-text-input formControlName="name" [label]="'Name'" type="text"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="addressLine1" [label]="'Address Line 1'" type="text"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <app-text-input formControlName="addressLine2" [label]="'Address Line 2'" type="text"
                                class="w-full" [fullWidth]="true"></app-text-input>
        <div class="grid lg:grid-cols-2 gap-6 w-full">
          <app-text-input formControlName="city" [label]="'City'" placeholder="Ex: San Jose"
                                  class="w-full" [fullWidth]="true"></app-text-input>
          <app-dropdown
            formControlName="countryId"
            [title]="'Country'"
            [display]="'name'"
            [value]="'id'"
            [noDataMessage]="'No Options Available'"
            [enableSearch]="true"
            class="w-full"
            [fullWidth]="true"
            [items]="countryListState.response()?.data ?? []"
            (valueChanged)="onCountrySelected($event)">
          </app-dropdown>
        </div>
        <div class="grid lg:grid-cols-2 gap-6 w-full">
          <app-dropdown
            formControlName="stateId"
            [title]="'State'"
            [display]="'name'"
            [value]="'id'"
            [noDataMessage]="'No Options Available'"
            [enableSearch]="true"
            class="w-full"
            [fullWidth]="true"
            [items]="filteredStates">
          </app-dropdown>
          <app-text-input formControlName="zipCode" [label]="'Zip Code'" placeholder="Ex: 95148"
                                  class="w-full" [fullWidth]="true"></app-text-input>
        </div>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'"
                  [loading]="createTerminalState.loading() || updateTerminalState.loading()"
                  [fullWidth]="true"
                  (click)="onSubmitClicked()"> {{ data ? 'Update' : 'Create' }}
        Terminal
      </app-button>
    </div>
  </div>
</app-base-overlay>
