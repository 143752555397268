import {Component, inject} from '@angular/core';
import {DIALOG_DATA} from "@angular/cdk/dialog";
import {GetDocumentAttachmentItem} from "@core/api/model";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-expiring-document-subtype-list',
  imports: [BaseOverlayComponent, AppSvgIconComponent],
  templateUrl: './expiring-document-subtype-list.component.html',
  standalone: true,
  styleUrl: './expiring-document-subtype-list.component.scss'
})
export class ExpiringDocumentSubtypeListComponent {

  attachments: GetDocumentAttachmentItem[] = inject(DIALOG_DATA);

  onViewClicked(attachment: GetDocumentAttachmentItem) {
    window.open(attachment.documentFilePath, '_blank');
  }
}


