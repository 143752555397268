<div class="bg-white rounded-lg h-full">
  <table class="w-full h-full min-w-full bg-white" [ngClass]="isHorizontallyScrollable() ? 'overflow-x-auto' : ''">

    <!--    Table header-->
    <thead class="sticky top-0 bg-white z-10">
    <tr class="text-overline text-neutral-500 text-nowrap h-min">
      <ng-content select="[head]"></ng-content>
    </tr>
    <tr>
      <th colspan="100">
        <div class="border-b border-neutral-200"></div>
      </th>
    </tr>
    </thead>

    <!--    List Items-->
    <tbody class="h-min">
    <ng-content select="[body]"></ng-content>
    </tbody>

    <!--    Shimmer-->
    @if (state()?.loading()) {
      <tbody class="bg-white text-center py-14">
      <tr>
        <td colspan="100" class="h-min">
          <div class="flex flex-col gap-10 px-6 py-8">
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
            <app-shimmer type="multiline"></app-shimmer>
          </div>
        </td>
      </tr>
      </tbody>
    }

    <!--    No Table Indicator-->
    @if (state()?.success() && (state()?.response()?.data ?? []).length <= 0) {
      <tbody>
      <tr>
        <td colspan="1000">
          <div class="flex flex-col justify-center items-center gap-8 px-6 py-8">
            <app-no-data-table></app-no-data-table>
          </div>
        </td>
      </tr>
      </tbody>
    }

    <tbody class="h-full"></tbody>


    <!--    Paginator-->
    <tbody class="bottom-0 sticky">
    <tr>
      <td colspan="100">
        <div class="bg-white w-full">
          <app-pagination
            class="w-full"
            [pageSize]="itemsPerPage()"
            [totalItems]="state()?.response()?.totalCount ?? 0"
            (pageChange)="onPageChange($event)">
          </app-pagination>
        </div>
      </td>
    </tr>
    </tbody>

  </table>
</div>


<!--Example-->
<!--    <app-base-table appTableResizableColumns sortableTable [state]="driverListState">-->
<!--      <ng-container head>-->
<!--        <th class="p-4 text-left">FULL NAME</th>-->
<!--        <th class="p-4 text-center ">CONTACT</th>-->
<!--        <th class="p-4 text-center ">HIRED DATA</th>-->
<!--        &lt;!&ndash;        <th class="p-4 text-center ">LICENSE EXPIRING</th>&ndash;&gt;-->
<!--        <th class="p-4 text-center ">STATUS</th>-->
<!--        <th class="p-4 text-center "></th>-->
<!--      </ng-container>-->

<!--      <ng-container body>-->
<!--        @for (item of driverListState.response()?.data; track item; ) {-->
<!--          <tr class="border-b border-neutral-100 w-full">-->
<!--            <td class="px-4 py-2 text-left">-->
<!--              <div class="flex flex-col gap-2">-->
<!--                <span class="text-body2 text-neutral-800">{{ item.fullName }}</span>-->
<!--                <span class="text-caption text-neutral-500">{{ item.code }}</span>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td class="px-4 py-2 text-center">-->
<!--              <div class="flex flex-col gap-2">-->
<!--                <span class="text-body2 text-info-500">{{ item.email }}</span>-->
<!--                <span class="text-body2 text-neutral-500">{{ item.phoneNumber }}</span>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td class="px-4 text-body2 text-center">{{ item.hiredDate | date:'MMM d, y' }}</td>-->
<!--            &lt;!&ndash;            <td class="px-4 text-body2 text-center">{{ item.licenseExpiringDate |date:'MMM d, y h:mm a' }}</td>&ndash;&gt;-->
<!--            <td class="px-4">-->
<!--              <div class="flex justify-center">-->
<!--                <app-driver-status-badge [driver]="item"></app-driver-status-badge>-->
<!--              </div>-->
<!--            </td>-->
<!--            <td class="p-4 cursor-pointer items-center">-->
<!--              <div class="flex justify-center items-center">-->
<!--                <app-button (click)="onViewDriverClicked(item)" appearance="textType" textButtonColor="text-info-500">-->
<!--                  View-->
<!--                </app-button>-->
<!--                <app-context-menu-icon></app-context-menu-icon>-->
<!--              </div>-->
<!--            </td>-->
<!--          </tr>-->
<!--        }-->
<!--      </ng-container>-->
<!--    </app-base-table>-->
