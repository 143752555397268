<div class="app-container">
  <div class="app-page-content h-full overflow-auto">
    <app-data-table [columnDefs]="columnDefs"
                    [state]="getExpiringDocumentsReportsState"
                    (tableStateChanged)="getExpiringDocuments($event)"
                    [(ngModel)]="tableState"
                    [disableInitialLoad]="true">
      <div class="flex gap-3 items-center">
        <app-dropdown title="Document"
                      [items]="documentTypes()"
                      [display]="'name'"
                      [enableSearch]="true"
                      [(ngModel)]="selectedDocumentTypeFilter"
                      [isTitlePrefix]="true"
                      (valueChanged)="onDropDownFilterSelected()">
        </app-dropdown>
        <app-dropdown title="Expires In"
                      [items]="Constants.documentExpirationFilter"
                      [display]="'label'"
                      [(ngModel)]="selectedExpirationFilter"
                      [isTitlePrefix]="true"
                      (valueChanged)="onDropDownFilterSelected()">
        </app-dropdown>
      </div>
    </app-data-table>
  </div>
</div>

