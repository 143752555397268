import { environment } from '../../../environments/environment';
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API
 * 
  <img src="https://dev-app.yoursafetycompliance.com/assets/images/branding/YSC-logo.png" alt="Safety Compliance" width="150"/>
  <h3>📌 Welcome to the <strong>Your Safety Compliance API Documentation (Company Module)</strong> 🚀</h3>
  
 * OpenAPI spec version: 1.0
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  ActiveDriverStatusRequest,
  ActiveDriverStatusResponse,
  ApplyForJobRequest,
  ApplyForJobResponse,
  BusinessSubTypeListResponse,
  CompleteDriverApplicationRequest,
  CompleteDriverApplicationResponse,
  CompleteProfileRequest,
  CompleteProfileResponse,
  CreateDriverApplicationProficiencyRequest,
  CreateDriverApplicationProficiencyResponse,
  CreateDriverReminderRequest,
  CreateDriverReminderResponse,
  CreateDriverRequest,
  CreateDriverResponse,
  CreateEquipmentReminderRequest,
  CreateEquipmentReminderResponse,
  CreateOutOfServiceReasonRequest,
  CreateOutOfServiceReasonResponse,
  CreateRepairTypeRequest,
  CreateRepairTypeResponse,
  CreateServiceTypeRequest,
  CreateServiceTypeResponse,
  CreateTerminalRequest,
  CreateTerminalResponse,
  CreateUserReminderRequest,
  CreateUserReminderResponse,
  CreateUserRequest,
  CreateUserResponse,
  DeleteDriverDocumentResponse,
  DeleteDriverReminderResponse,
  DeleteEquipmentReminderResponse,
  DeleteOutOfServiceReasonResponse,
  DeleteRepairTypeResponse,
  DeleteServiceTypeResponse,
  DeleteUserReminderResponse,
  DisableUserRequest,
  DisableUserResponse,
  DownloadDriverApplicationFormResponse,
  DownloadDriverDocumentListResponse,
  DownloadDriverDocumentListSignedUrlResponse,
  DriverApplicationReSignRequest,
  DriverApplicationReSignResponse,
  DriverApplicationStartedRequest,
  DriverApplicationStartedResponse,
  EnableUserRequest,
  EnableUserResponse,
  EndSapProgramResponse,
  ExportXlsxDriverApplicationResponse,
  ExportXlsxDriverApplicationSignedUrlResponse,
  ExportXlsxDriverResponse,
  ExportXlsxDriverSignedUrlResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  GetActiveSapProgramResponse,
  GetBusinessSubTypeListParams,
  GetBusinessTypeListParams,
  GetBusinessTypeListResponse,
  GetCompanyApplicationStatusListResponse,
  GetCompanyResponse,
  GetCompanyRoleListResponse,
  GetCompanyStatusListResponse,
  GetCompanyTypeListResponse,
  GetCompanyUserResponse,
  GetCompanyUserTerminalListParams,
  GetCompanyUserTerminalListResponse,
  GetCountryListParams,
  GetCountryListResponse,
  GetDashboardResponse,
  GetDashboardStatsParams,
  GetDriverApplicationAccidentParams,
  GetDriverApplicationAccidentResponse,
  GetDriverApplicationEducationParams,
  GetDriverApplicationEducationResponse,
  GetDriverApplicationEmploymentHistoryParams,
  GetDriverApplicationEmploymentHistoryResponse,
  GetDriverApplicationExperienceParams,
  GetDriverApplicationExperienceResponse,
  GetDriverApplicationGeneralResponse,
  GetDriverApplicationLicenseParams,
  GetDriverApplicationLicenseResponse,
  GetDriverApplicationListParams,
  GetDriverApplicationListResponse,
  GetDriverApplicationParams,
  GetDriverApplicationProficiencyResponse,
  GetDriverApplicationResidencyParams,
  GetDriverApplicationResidencyResponse,
  GetDriverApplicationResponse,
  GetDriverApplicationTrafficConvictionParams,
  GetDriverApplicationTrafficConvictionResponse,
  GetDriverDocumentListByTypeParams,
  GetDriverDocumentListByTypeResponse,
  GetDriverDocumentListParams,
  GetDriverDocumentListResponse,
  GetDriverDocumentTypeListParams,
  GetDriverDocumentTypeListResponse,
  GetDriverGeneralResponse,
  GetDriverLicenseParams,
  GetDriverLicenseResponse,
  GetDriverListParams,
  GetDriverListResponse,
  GetDriverReminderListParams,
  GetDriverReminderListResponse,
  GetDriverReminderResponse,
  GetDriverResidencyParams,
  GetDriverResidencyResponse,
  GetDriverResponse,
  GetEquipmentReminderListResponse,
  GetEquipmentReminderResponse,
  GetExpiringDocumentsParams,
  GetExpiringDocumentsResponse,
  GetOutOfServiceReasonListParams,
  GetOutOfServiceReasonListResponse,
  GetOutOfServiceReasonResponse,
  GetRepairTypeListParams,
  GetRepairTypeListResponse,
  GetRepairTypeResponse,
  GetSapProgramListParams,
  GetSapProgramListResponse,
  GetSapProgramResponse,
  GetSapProgramTestResultListParams,
  GetSapProgramTestResultListResponse,
  GetServiceTypeListParams,
  GetServiceTypeListResponse,
  GetServiceTypeResponse,
  GetStateListParams,
  GetStateListResponse,
  GetTerminalInfoResponse,
  GetTerminalListParams,
  GetTerminalListResponse,
  GetTerminalResponse,
  GetUserListParams,
  GetUserListResponse,
  GetUserReminderListResponse,
  GetUserReminderResponse,
  GetUserResponse,
  InactiveDriverStatusRequest,
  InactiveDriverStatusResponse,
  LoginRequest,
  LoginResponse,
  LoginWithDelegationTokenRequest,
  LoginWithDelegationTokenResponse,
  MarkDriverApplicationHiredRequest,
  MarkDriverApplicationHiredResponse,
  MarkDriverApplicationPendingReviewRequest,
  MarkDriverApplicationPendingReviewResponse,
  MarkDriverApplicationRejectedRequest,
  MarkDriverApplicationRejectedResponse,
  MarkDriverApplicationResubmissionRequest,
  MarkDriverApplicationResubmissionResponse,
  RefreshTokenRequest,
  RefreshTokenResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SaveDriverApplicationAccidentRequest,
  SaveDriverApplicationAccidentResponse,
  SaveDriverApplicationEducationRequest,
  SaveDriverApplicationEducationResponse,
  SaveDriverApplicationEmploymentHistoryRequest,
  SaveDriverApplicationEmploymentHistoryResponse,
  SaveDriverApplicationExperienceRequest,
  SaveDriverApplicationExperienceResponse,
  SaveDriverApplicationGeneralRequest,
  SaveDriverApplicationLicenseRequest,
  SaveDriverApplicationLicenseResponse,
  SaveDriverApplicationResidencyRequest,
  SaveDriverApplicationTrafficConvictionRequest,
  SaveDriverApplicationTrafficConvictionResponse,
  SaveDriverGeneralRequest,
  SaveDriverLicenseRequest,
  SaveDriverLicenseResponse,
  SaveDriverRequest,
  SaveDriverResidencyRequest,
  SaveDriverResidencyResponse,
  SaveDriverResponse,
  SavePreHiringDriverApplicationInfoRequest,
  SavePreHiringDriverApplicationInfoResponse,
  SendDriverApplicationRequest,
  SendDriverApplicationResponse,
  SetupPasswordRequest,
  SetupPasswordResponse,
  SignClearinghousePolicyRequest,
  SignClearinghousePolicyResponse,
  SignDrugAndAlcoholPolicyRequest,
  SignDrugAndAlcoholPolicyResponse,
  SignOutRequest,
  SignOutResponse,
  SignUpRequest,
  SignUpResponse,
  StartSapProgramRequest,
  StartSapProgramResponse,
  SwitchTerminalResponse,
  TerminateDriverStatusResponse,
  TerminateDriversStatusRequest,
  TransferDriverRequest,
  TransferDriverResponse,
  UpdateDriverDocumentRequest,
  UpdateDriverDocumentResponse,
  UpdateDriverReminderRequest,
  UpdateDriverReminderResponse,
  UpdateEquipmentReminderRequest,
  UpdateEquipmentReminderResponse,
  UpdateNextDueFrequencyRequest,
  UpdateNextDueFrequencyResponse,
  UpdateOutOfServiceReasonRequest,
  UpdateOutOfServiceReasonResponse,
  UpdateRepairTypeRequest,
  UpdateRepairTypeResponse,
  UpdateServiceTypeRequest,
  UpdateServiceTypeResponse,
  UpdateTerminalRequest,
  UpdateTerminalResponse,
  UpdateUserReminderRequest,
  UpdateUserReminderResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileResponse,
  UploadSapProgramTestResultRequest,
  UploadSapProgramTestResultResponse,
  UploadTempFileRequest,
  UploadTempFileResponse
} from './model'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * Operation ID: updateUser


 */
 updateUser<TData = UpdateUserResponse>(
    id: string,
    updateUserRequest: UpdateUserRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/users/${id}`,
      updateUserRequest,options
    );
  }

/**
 * Operation ID: getUserList


 */
 getUserList<TData = GetUserListResponse>(
    params?: GetUserListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createUser


 */
 createUser<TData = CreateUserResponse>(
    createUserRequest: CreateUserRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/users`,
      createUserRequest,options
    );
  }

/**
 * Operation ID: getUserInfo


 */
 getUserInfo<TData = GetUserResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/users/info`,options
    );
  }

/**
 * Operation ID: getCompanyUserInfo


 */
 getCompanyUserInfo<TData = GetCompanyUserResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/users/company-info`,options
    );
  }

/**
 * Operation ID: getCompanyRoleList


 */
 getCompanyRoleList<TData = GetCompanyRoleListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/company-roles`,options
    );
  }

/**
 * Operation ID: enableUser


 */
 enableUser<TData = EnableUserResponse>(
    enableUserRequest: EnableUserRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/users/status/enable`,
      enableUserRequest,options
    );
  }

/**
 * Operation ID: disableUser


 */
 disableUser<TData = DisableUserResponse>(
    disableUserRequest: DisableUserRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/users/status/disable`,
      disableUserRequest,options
    );
  }

/**
 * Operation ID: TestEmailController_test


 */
 testEmailControllerTest<TData = void>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/test-emails`,options
    );
  }

/**
 * Operation ID: testEmail


 */
 testEmail<TData = unknown>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/test-emails`,undefined,options
    );
  }

/**
 * Operation ID: TestEmailController_delete


 */
 testEmailControllerDelete<TData = void>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/test-emails/generatePdddf`,options
    );
  }

/**
 * Operation ID: TestEmailController_test2


 */
 testEmailControllerTest2<TData = void>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/test-emails/test`,options
    );
  }

/**
 * Operation ID: TestEmailController_execute2


 */
 testEmailControllerExecute2<TData = void>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/test-emails/test-expection`,undefined,options
    );
  }

/**
 * Operation ID: updateTerminal


 */
 updateTerminal<TData = UpdateTerminalResponse>(
    id: string,
    updateTerminalRequest: UpdateTerminalRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/terminals/${id}`,
      updateTerminalRequest,options
    );
  }

/**
 * Operation ID: getTerminal


 */
 getTerminal<TData = GetTerminalResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/terminals/${id}`,options
    );
  }

/**
 * Operation ID: switchTerminal


 */
 switchTerminal<TData = SwitchTerminalResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/terminals/switch/${id}`,undefined,options
    );
  }

/**
 * Operation ID: getTerminalList


 */
 getTerminalList<TData = GetTerminalListResponse>(
    params?: GetTerminalListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/terminals`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createTerminal


 */
 createTerminal<TData = CreateTerminalResponse>(
    createTerminalRequest: CreateTerminalRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/terminals`,
      createTerminalRequest,options
    );
  }

/**
 * Operation ID: getTerminalInfo


 */
 getTerminalInfo<TData = GetTerminalInfoResponse>(
    terminalCode: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/terminals/info/${terminalCode}`,options
    );
  }

/**
 * Operation ID: getCompanyUserTerminalList


 */
 getCompanyUserTerminalList<TData = GetCompanyUserTerminalListResponse>(
    params?: GetCompanyUserTerminalListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/company-user-terminals`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: applyForJob


 */
 applyForJob<TData = ApplyForJobResponse>(
    applyForJobRequest: ApplyForJobRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/terminals/apply/job`,
      applyForJobRequest,options
    );
  }

/**
 * Operation ID: updateUserReminder


 */
 updateUserReminder<TData = UpdateUserReminderResponse>(
    id: string,
    updateUserReminderRequest: UpdateUserReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/reminders/users/${id}`,
      updateUserReminderRequest,options
    );
  }

/**
 * Operation ID: getUserReminder


 */
 getUserReminder<TData = GetUserReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminders/users/${id}`,options
    );
  }

/**
 * Operation ID: deleteUserReminder


 */
 deleteUserReminder<TData = DeleteUserReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/reminders/users/${id}`,options
    );
  }

/**
 * Operation ID: getUserReminderList


 */
 getUserReminderList<TData = GetUserReminderListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminders/users`,options
    );
  }

/**
 * Operation ID: createUserReminder


 */
 createUserReminder<TData = CreateUserReminderResponse>(
    createUserReminderRequest: CreateUserReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/reminders/users`,
      createUserReminderRequest,options
    );
  }

/**
 * Operation ID: updateEquipmentReminder


 */
 updateEquipmentReminder<TData = UpdateEquipmentReminderResponse>(
    id: string,
    updateEquipmentReminderRequest: UpdateEquipmentReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/reminders/equipment/${id}`,
      updateEquipmentReminderRequest,options
    );
  }

/**
 * Operation ID: getEquipmentReminder


 */
 getEquipmentReminder<TData = GetEquipmentReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminders/equipment/${id}`,options
    );
  }

/**
 * Operation ID: deleteEquipmentReminder


 */
 deleteEquipmentReminder<TData = DeleteEquipmentReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/reminders/equipment/${id}`,options
    );
  }

/**
 * Operation ID: getEquipmentReminderList


 */
 getEquipmentReminderList<TData = GetEquipmentReminderListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminders/equipment`,options
    );
  }

/**
 * Operation ID: createEquipmentReminder


 */
 createEquipmentReminder<TData = CreateEquipmentReminderResponse>(
    createEquipmentReminderRequest: CreateEquipmentReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/reminders/equipment`,
      createEquipmentReminderRequest,options
    );
  }

/**
 * Operation ID: updateServiceType


 */
 updateServiceType<TData = UpdateServiceTypeResponse>(
    id: string,
    updateServiceTypeRequest: UpdateServiceTypeRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/maintenance/service-types/${id}`,
      updateServiceTypeRequest,options
    );
  }

/**
 * Operation ID: getServiceType


 */
 getServiceType<TData = GetServiceTypeResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/maintenance/service-types/${id}`,options
    );
  }

/**
 * Operation ID: deleteServiceType


 */
 deleteServiceType<TData = DeleteServiceTypeResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/maintenance/service-types/${id}`,options
    );
  }

/**
 * Operation ID: getServiceTypeList


 */
 getServiceTypeList<TData = GetServiceTypeListResponse>(
    params?: GetServiceTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/maintenance/service-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createServiceType


 */
 createServiceType<TData = CreateServiceTypeResponse>(
    createServiceTypeRequest: CreateServiceTypeRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/maintenance/service-types`,
      createServiceTypeRequest,options
    );
  }

/**
 * Operation ID: updateRepairType


 */
 updateRepairType<TData = UpdateRepairTypeResponse>(
    id: string,
    updateRepairTypeRequest: UpdateRepairTypeRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/maintenance/repair-types/${id}`,
      updateRepairTypeRequest,options
    );
  }

/**
 * Operation ID: getRepairType


 */
 getRepairType<TData = GetRepairTypeResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/maintenance/repair-types/${id}`,options
    );
  }

/**
 * Operation ID: deleteRepairType


 */
 deleteRepairType<TData = DeleteRepairTypeResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/maintenance/repair-types/${id}`,options
    );
  }

/**
 * Operation ID: getRepairTypeList


 */
 getRepairTypeList<TData = GetRepairTypeListResponse>(
    params?: GetRepairTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/maintenance/repair-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createRepairType


 */
 createRepairType<TData = CreateRepairTypeResponse>(
    createRepairTypeRequest: CreateRepairTypeRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/maintenance/repair-types`,
      createRepairTypeRequest,options
    );
  }

/**
 * Operation ID: updateOutOfServiceReason


 */
 updateOutOfServiceReason<TData = UpdateOutOfServiceReasonResponse>(
    id: string,
    updateOutOfServiceReasonRequest: UpdateOutOfServiceReasonRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/maintenance/out-of-service-reasons/${id}`,
      updateOutOfServiceReasonRequest,options
    );
  }

/**
 * Operation ID: getOutOfServiceReason


 */
 getOutOfServiceReason<TData = GetOutOfServiceReasonResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/maintenance/out-of-service-reasons/${id}`,options
    );
  }

/**
 * Operation ID: deleteOutOfServiceReason


 */
 deleteOutOfServiceReason<TData = DeleteOutOfServiceReasonResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/maintenance/out-of-service-reasons/${id}`,options
    );
  }

/**
 * Operation ID: getOutOfServiceReasonList


 */
 getOutOfServiceReasonList<TData = GetOutOfServiceReasonListResponse>(
    params?: GetOutOfServiceReasonListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/maintenance/out-of-service-reasons`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createOutOfServiceReason


 */
 createOutOfServiceReason<TData = CreateOutOfServiceReasonResponse>(
    createOutOfServiceReasonRequest: CreateOutOfServiceReasonRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/maintenance/out-of-service-reasons`,
      createOutOfServiceReasonRequest,options
    );
  }

/**
 * Operation ID: getCompany


 */
 getCompany<TData = GetCompanyResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/info`,options
    );
  }

/**
 * Operation ID: updateCompanyProfile


 */
 updateCompanyProfile<TData = CompleteProfileResponse>(
    completeProfileRequest: CompleteProfileRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/complete-profile`,
      completeProfileRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationResubmission


 */
 markDriverApplicationResubmission<TData = MarkDriverApplicationResubmissionResponse>(
    markDriverApplicationResubmissionRequest: MarkDriverApplicationResubmissionRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/re-submission`,
      markDriverApplicationResubmissionRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationRejected


 */
 markDriverApplicationRejected<TData = MarkDriverApplicationRejectedResponse>(
    markDriverApplicationRejectedRequest: MarkDriverApplicationRejectedRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/rejected`,
      markDriverApplicationRejectedRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationPendingReview


 */
 markDriverApplicationPendingReview<TData = MarkDriverApplicationPendingReviewResponse>(
    markDriverApplicationPendingReviewRequest: MarkDriverApplicationPendingReviewRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/pending-review`,
      markDriverApplicationPendingReviewRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationHired


 */
 markDriverApplicationHired<TData = MarkDriverApplicationHiredResponse>(
    markDriverApplicationHiredRequest: MarkDriverApplicationHiredRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications/status/hired`,
      markDriverApplicationHiredRequest,options
    );
  }

/**
 * Operation ID: driverApplicationStarted


 */
 driverApplicationStarted<TData = DriverApplicationStartedResponse>(
    driverApplicationStartedRequest: DriverApplicationStartedRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/started`,
      driverApplicationStartedRequest,options
    );
  }

/**
 * Operation ID: saveDriverApplicationReSign


 */
 saveDriverApplicationReSign<TData = DriverApplicationReSignResponse>(
    id: string,
    driverApplicationReSignRequest: DriverApplicationReSignRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application/status/re-sign/${id}`,
      driverApplicationReSignRequest,options
    );
  }

/**
 * Operation ID: completeDriverApplication


 */
 completeDriverApplication<TData = CompleteDriverApplicationResponse>(
    completeDriverApplicationRequest: CompleteDriverApplicationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/complete-driver-applications`,
      completeDriverApplicationRequest,options
    );
  }

/**
 * Operation ID: savePreHiringDriverApplicationInfo


 */
 savePreHiringDriverApplicationInfo<TData = SavePreHiringDriverApplicationInfoResponse>(
    savePreHiringDriverApplicationInfoRequest: SavePreHiringDriverApplicationInfoRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/pre-hiring/info`,
      savePreHiringDriverApplicationInfoRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationProficiency


 */
 getDriverApplicationProficiency<TData = GetDriverApplicationProficiencyResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/proficiency/${id}`,options
    );
  }

/**
 * Operation ID: createDriverApplicationProficiency


 */
 createDriverApplicationProficiency<TData = CreateDriverApplicationProficiencyResponse>(
    createDriverApplicationProficiencyRequest: CreateDriverApplicationProficiencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications/proficiencies`,
      createDriverApplicationProficiencyRequest,options
    );
  }

/**
 * Operation ID: uploadDriverApplicationFile


 */
 uploadDriverApplicationFile<TData = UploadDriverApplicationFileResponse>(
    uploadDriverApplicationFileRequest: UploadDriverApplicationFileRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadDriverApplicationFileRequest.file)
formData.append('driverApplicationId', uploadDriverApplicationFileRequest.driverApplicationId)
formData.append('type', uploadDriverApplicationFileRequest.type)

    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application/upload/file`,
      formData,options
    );
  }

/**
 * Operation ID: signDrugAndAlcoholPolicy


 */
 signDrugAndAlcoholPolicy<TData = SignDrugAndAlcoholPolicyResponse>(
    id: string,
    signDrugAndAlcoholPolicyRequest: SignDrugAndAlcoholPolicyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/company/driver-applications/drug/alcohols/${id}`,
      signDrugAndAlcoholPolicyRequest,options
    );
  }

/**
 * Operation ID: signClearinghousePolicy


 */
 signClearinghousePolicy<TData = SignClearinghousePolicyResponse>(
    id: string,
    signClearinghousePolicyRequest: SignClearinghousePolicyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/company/driver-applications/clearinghouses/${id}`,
      signClearinghousePolicyRequest,options
    );
  }

/**
 * Operation ID: sendDriverApplication


 */
 sendDriverApplication<TData = SendDriverApplicationResponse>(
    sendDriverApplicationRequest: SendDriverApplicationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications/send`,
      sendDriverApplicationRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationList


 */
 getDriverApplicationList<TData = GetDriverApplicationListResponse[]>(
    params?: GetDriverApplicationListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDriverApplication


 */
 getDriverApplication<TData = GetDriverApplicationResponse>(
    params: GetDriverApplicationParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/info`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: exportXlsxDriverApplicationSignedUrl


 */
 exportXlsxDriverApplicationSignedUrl<TData = ExportXlsxDriverApplicationSignedUrlResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application/export/xlsx/signed/url`,options
    );
  }

/**
 * Operation ID: ExportXlsxDriverApplicationController_execute


 */
 exportXlsxDriverApplicationControllerExecute<TData = ExportXlsxDriverApplicationResponse>(
    token: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/export/xlsx/${token}`,options
    );
  }

/**
 * Operation ID: saveDriverApplicationTrafficConviction


 */
 saveDriverApplicationTrafficConviction<TData = SaveDriverApplicationTrafficConvictionResponse>(
    saveDriverApplicationTrafficConvictionRequest: SaveDriverApplicationTrafficConvictionRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-traffic-convictions`,
      saveDriverApplicationTrafficConvictionRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationTrafficConviction


 */
 getDriverApplicationTrafficConviction<TData = GetDriverApplicationTrafficConvictionResponse>(
    params: GetDriverApplicationTrafficConvictionParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-traffic-convictions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationResidency


 */
 saveDriverApplicationResidency<TData = unknown>(
    saveDriverApplicationResidencyRequest: SaveDriverApplicationResidencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-residencies`,
      saveDriverApplicationResidencyRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationResidency


 */
 getDriverApplicationResidency<TData = GetDriverApplicationResidencyResponse[]>(
    params: GetDriverApplicationResidencyParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-residencies`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationLicense


 */
 saveDriverApplicationLicense<TData = SaveDriverApplicationLicenseResponse>(
    saveDriverApplicationLicenseRequest: SaveDriverApplicationLicenseRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-licenses`,
      saveDriverApplicationLicenseRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationLicense


 */
 getDriverApplicationLicense<TData = GetDriverApplicationLicenseResponse>(
    params: GetDriverApplicationLicenseParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-licenses`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationGeneral


 */
 saveDriverApplicationGeneral<TData = unknown>(
    saveDriverApplicationGeneralRequest: SaveDriverApplicationGeneralRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-generals`,
      saveDriverApplicationGeneralRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationGeneral


 */
 getDriverApplicationGeneral<TData = GetDriverApplicationGeneralResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-generals/${id}`,options
    );
  }

/**
 * Operation ID: saveDriverApplicationExperience


 */
 saveDriverApplicationExperience<TData = SaveDriverApplicationExperienceResponse>(
    saveDriverApplicationExperienceRequest: SaveDriverApplicationExperienceRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-experiences`,
      saveDriverApplicationExperienceRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationExperience


 */
 getDriverApplicationExperience<TData = GetDriverApplicationExperienceResponse>(
    params: GetDriverApplicationExperienceParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-experiences`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationEmploymentHistory


 */
 saveDriverApplicationEmploymentHistory<TData = SaveDriverApplicationEmploymentHistoryResponse>(
    saveDriverApplicationEmploymentHistoryRequest: SaveDriverApplicationEmploymentHistoryRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-employment-history`,
      saveDriverApplicationEmploymentHistoryRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationEmploymentHistory


 */
 getDriverApplicationEmploymentHistory<TData = GetDriverApplicationEmploymentHistoryResponse>(
    params: GetDriverApplicationEmploymentHistoryParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-employment-history`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationEducation


 */
 saveDriverApplicationEducation<TData = SaveDriverApplicationEducationResponse>(
    saveDriverApplicationEducationRequest: SaveDriverApplicationEducationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-education`,
      saveDriverApplicationEducationRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationEducation


 */
 getDriverApplicationEducation<TData = GetDriverApplicationEducationResponse>(
    params: GetDriverApplicationEducationParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-education`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationAccident


 */
 saveDriverApplicationAccident<TData = SaveDriverApplicationAccidentResponse>(
    saveDriverApplicationAccidentRequest: SaveDriverApplicationAccidentRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-accidents`,
      saveDriverApplicationAccidentRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationAccident


 */
 getDriverApplicationAccident<TData = GetDriverApplicationAccidentResponse>(
    params: GetDriverApplicationAccidentParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-accidents`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: downloadDriverApplicationFormSignedUrl


 */
 downloadDriverApplicationFormSignedUrl<TData = DownloadDriverApplicationFormResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/form/download/signed/url`,options
    );
  }

/**
 * Operation ID: uploadSapProgramTestResult


 */
 uploadSapProgramTestResult<TData = UploadSapProgramTestResultResponse>(
    uploadSapProgramTestResultRequest: UploadSapProgramTestResultRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/sap-program-tests`,
      uploadSapProgramTestResultRequest,options
    );
  }

/**
 * Operation ID: getSapProgramTestResultList


 */
 getSapProgramTestResultList<TData = GetSapProgramTestResultListResponse>(
    params: GetSapProgramTestResultListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/sap-program-tests`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: startSapProgram


 */
 startSapProgram<TData = StartSapProgramResponse>(
    startSapProgramRequest: StartSapProgramRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/sap-program/start`,
      startSapProgramRequest,options
    );
  }

/**
 * Operation ID: getSapProgramList


 */
 getSapProgramList<TData = GetSapProgramListResponse>(
    params: GetSapProgramListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/sap-programs`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getSapProgram


 */
 getSapProgram<TData = GetSapProgramResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/sap-programs/${id}`,options
    );
  }

/**
 * Operation ID: getActiveSapProgram


 */
 getActiveSapProgram<TData = GetActiveSapProgramResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/sap-programs/active/${id}`,options
    );
  }

/**
 * Operation ID: endSapProgram


 */
 endSapProgram<TData = EndSapProgramResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/sap-programs/end/${id}`,undefined,options
    );
  }

/**
 * Operation ID: saveDriverResidency


 */
 saveDriverResidency<TData = SaveDriverResidencyResponse>(
    saveDriverResidencyRequest: SaveDriverResidencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-residencies`,
      saveDriverResidencyRequest,options
    );
  }

/**
 * Operation ID: getDriverResidency


 */
 getDriverResidency<TData = GetDriverResidencyResponse[]>(
    params: GetDriverResidencyParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-residencies`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: updateDriverReminder


 */
 updateDriverReminder<TData = UpdateDriverReminderResponse>(
    id: string,
    updateDriverReminderRequest: UpdateDriverReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/reminders/drivers/${id}`,
      updateDriverReminderRequest,options
    );
  }

/**
 * Operation ID: deleteDriverReminder


 */
 deleteDriverReminder<TData = DeleteDriverReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/reminders/drivers/${id}`,options
    );
  }

/**
 * Operation ID: getDriverReminderList


 */
 getDriverReminderList<TData = GetDriverReminderListResponse>(
    params?: GetDriverReminderListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminders/drivers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createDriverReminder


 */
 createDriverReminder<TData = CreateDriverReminderResponse>(
    createDriverReminderRequest: CreateDriverReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/reminders/drivers`,
      createDriverReminderRequest,options
    );
  }

/**
 * Operation ID: getDriverReminder


 */
 getDriverReminder<TData = GetDriverReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminder/drivers/${id}`,options
    );
  }

/**
 * Operation ID: saveDriverLicense


 */
 saveDriverLicense<TData = SaveDriverLicenseResponse>(
    saveDriverLicenseRequest: SaveDriverLicenseRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-licenses`,
      saveDriverLicenseRequest,options
    );
  }

/**
 * Operation ID: getDriverLicense


 */
 getDriverLicense<TData = GetDriverLicenseResponse>(
    params: GetDriverLicenseParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-licenses`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: transferDriverToTerminal


 */
 transferDriverToTerminal<TData = TransferDriverResponse>(
    transferDriverRequest: TransferDriverRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver/transfer/terminal`,
      transferDriverRequest,options
    );
  }

/**
 * Operation ID: terminateDriversStatus


 */
 terminateDriversStatus<TData = TerminateDriverStatusResponse>(
    terminateDriversStatusRequest: TerminateDriversStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/status/terminate`,
      terminateDriversStatusRequest,options
    );
  }

/**
 * Operation ID: saveDriver


 */
 saveDriver<TData = SaveDriverResponse>(
    id: string,
    saveDriverRequest: SaveDriverRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/drivers/${id}`,
      saveDriverRequest,options
    );
  }

/**
 * Operation ID: inActiveDriversStatus


 */
 inActiveDriversStatus<TData = InactiveDriverStatusResponse>(
    inactiveDriverStatusRequest: InactiveDriverStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/status/in-active`,
      inactiveDriverStatusRequest,options
    );
  }

/**
 * Operation ID: getDriverList


 */
 getDriverList<TData = GetDriverListResponse[]>(
    params?: GetDriverListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/drivers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createDriver


 */
 createDriver<TData = CreateDriverResponse>(
    createDriverRequest: CreateDriverRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/drivers`,
      createDriverRequest,options
    );
  }

/**
 * Operation ID: getDriver


 */
 getDriver<TData = GetDriverResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/${id}`,options
    );
  }

/**
 * Operation ID: exportXlsxDriverSignedUrl


 */
 exportXlsxDriverSignedUrl<TData = ExportXlsxDriverSignedUrlResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/drivers/export/xlsx/signed/url`,options
    );
  }

/**
 * Operation ID: ExportXlsxDriverController_execute


 */
 exportXlsxDriverControllerExecute<TData = ExportXlsxDriverResponse>(
    token: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/drivers/export/xlsx/${token}`,options
    );
  }

/**
 * Operation ID: activeDriversStatus


 */
 activeDriversStatus<TData = ActiveDriverStatusResponse>(
    activeDriverStatusRequest: ActiveDriverStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/status/active`,
      activeDriverStatusRequest,options
    );
  }

/**
 * Operation ID: saveDriverGeneral


 */
 saveDriverGeneral<TData = unknown>(
    saveDriverGeneralRequest: SaveDriverGeneralRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-generals`,
      saveDriverGeneralRequest,options
    );
  }

/**
 * Operation ID: getDriverGeneral


 */
 getDriverGeneral<TData = GetDriverGeneralResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-generals/${id}`,options
    );
  }

/**
 * Operation ID: updateNextDueFrequency


 */
 updateNextDueFrequency<TData = UpdateNextDueFrequencyResponse>(
    id: string,
    updateNextDueFrequencyRequest: UpdateNextDueFrequencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/document/next-due/frequencies/${id}`,
      updateNextDueFrequencyRequest,options
    );
  }

/**
 * Operation ID: updateDriverDocument


 */
 updateDriverDocument<TData = UpdateDriverDocumentResponse>(
    updateDriverDocumentRequest: UpdateDriverDocumentRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/documents`,
      updateDriverDocumentRequest,options
    );
  }

/**
 * Operation ID: getDriverDocumentTypeList


 */
 getDriverDocumentTypeList<TData = GetDriverDocumentTypeListResponse>(
    params?: GetDriverDocumentTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/section/document/types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDriverDocumentListByType


 */
 getDriverDocumentListByType<TData = GetDriverDocumentListByTypeResponse>(
    params: GetDriverDocumentListByTypeParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/section/documents/type`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDriverDocumentList


 */
 getDriverDocumentList<TData = GetDriverDocumentListResponse>(
    params: GetDriverDocumentListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/section/documents`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: downloadDriverDocumentListSignedUrl


 */
 downloadDriverDocumentListSignedUrl<TData = DownloadDriverDocumentListSignedUrlResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/${id}/documents/signed/url`,options
    );
  }

/**
 * Operation ID: DownloadDriverDocumentListController_execute


 */
 downloadDriverDocumentListControllerExecute<TData = DownloadDriverDocumentListResponse>(
    token: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/documents/download/${token}`,options
    );
  }

/**
 * Operation ID: deleteDriverDocument


 */
 deleteDriverDocument<TData = DeleteDriverDocumentResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/driver/documents/${id}`,options
    );
  }

/**
 * Operation ID: getExpiringDocuments


 */
 getExpiringDocuments<TData = GetExpiringDocumentsResponse>(
    params: GetExpiringDocumentsParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/report/expiring/documents`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDashboardStats


 */
 getDashboardStats<TData = GetDashboardResponse>(
    params: GetDashboardStatsParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/dashboard/stats`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: signUp


 */
 signUp<TData = SignUpResponse>(
    signUpRequest: SignUpRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/sign-up`,
      signUpRequest,options
    );
  }

/**
 * Operation ID: signOut


 */
 signOut<TData = SignOutResponse>(
    signOutRequest: SignOutRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/sign-out`,
      signOutRequest,options
    );
  }

/**
 * Operation ID: createPassword


 */
 createPassword<TData = SetupPasswordResponse>(
    setupPasswordRequest: SetupPasswordRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/setup-password`,
      setupPasswordRequest,options
    );
  }

/**
 * Operation ID: resetPassword


 */
 resetPassword<TData = ResetPasswordResponse>(
    resetPasswordRequest: ResetPasswordRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/reset-password`,
      resetPasswordRequest,options
    );
  }

/**
 * Operation ID: refreshToken


 */
 refreshToken<TData = RefreshTokenResponse>(
    refreshTokenRequest: RefreshTokenRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/refresh`,
      refreshTokenRequest,options
    );
  }

/**
 * Operation ID: loginWithDelegationToken


 */
 loginWithDelegationToken<TData = LoginWithDelegationTokenResponse>(
    loginWithDelegationTokenRequest: LoginWithDelegationTokenRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/login/delegation/token`,
      loginWithDelegationTokenRequest,options
    );
  }

/**
 * Operation ID: login


 */
 login<TData = LoginResponse>(
    loginRequest: LoginRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/login`,
      loginRequest,options
    );
  }

/**
 * Operation ID: resetPasswordRequest


 */
 resetPasswordRequest<TData = ForgotPasswordResponse>(
    forgotPasswordRequest: ForgotPasswordRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/forgot-password`,
      forgotPasswordRequest,options
    );
  }

/**
 * Operation ID: uploadTempFile


 */
 uploadTempFile<TData = UploadTempFileResponse>(
    uploadTempFileRequest: UploadTempFileRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadTempFileRequest.file)

    return this.http.put<TData>(
      `${environment.baseUrl}/common/upload/temp`,
      formData,options
    );
  }

/**
 * Operation ID: getStateList


 */
 getStateList<TData = GetStateListResponse>(
    params?: GetStateListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/states`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCountryList


 */
 getCountryList<TData = GetCountryListResponse>(
    params?: GetCountryListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/countries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCompanyTypeList


 */
 getCompanyTypeList<TData = GetCompanyTypeListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company/types`,options
    );
  }

/**
 * Operation ID: getCompanyStatusList


 */
 getCompanyStatusList<TData = GetCompanyStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company/status`,options
    );
  }

/**
 * Operation ID: getCompanyApplicationStatusList


 */
 getCompanyApplicationStatusList<TData = GetCompanyApplicationStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company-application-status`,options
    );
  }

/**
 * Operation ID: getBusinessTypeList


 */
 getBusinessTypeList<TData = GetBusinessTypeListResponse>(
    params?: GetBusinessTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/business-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getBusinessSubTypeList


 */
 getBusinessSubTypeList<TData = BusinessSubTypeListResponse>(
    params?: GetBusinessSubTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/business-sub-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

};

export type UpdateUserClientResult = NonNullable<UpdateUserResponse>
export type GetUserListClientResult = NonNullable<GetUserListResponse>
export type CreateUserClientResult = NonNullable<CreateUserResponse>
export type GetUserInfoClientResult = NonNullable<GetUserResponse>
export type GetCompanyUserInfoClientResult = NonNullable<GetCompanyUserResponse>
export type GetCompanyRoleListClientResult = NonNullable<GetCompanyRoleListResponse>
export type EnableUserClientResult = NonNullable<EnableUserResponse>
export type DisableUserClientResult = NonNullable<DisableUserResponse>
export type TestEmailControllerTestClientResult = NonNullable<void>
export type TestEmailClientResult = NonNullable<unknown>
export type TestEmailControllerDeleteClientResult = NonNullable<void>
export type TestEmailControllerTest2ClientResult = NonNullable<void>
export type TestEmailControllerExecute2ClientResult = NonNullable<void>
export type UpdateTerminalClientResult = NonNullable<UpdateTerminalResponse>
export type GetTerminalClientResult = NonNullable<GetTerminalResponse>
export type SwitchTerminalClientResult = NonNullable<SwitchTerminalResponse>
export type GetTerminalListClientResult = NonNullable<GetTerminalListResponse>
export type CreateTerminalClientResult = NonNullable<CreateTerminalResponse>
export type GetTerminalInfoClientResult = NonNullable<GetTerminalInfoResponse>
export type GetCompanyUserTerminalListClientResult = NonNullable<GetCompanyUserTerminalListResponse>
export type ApplyForJobClientResult = NonNullable<ApplyForJobResponse>
export type UpdateUserReminderClientResult = NonNullable<UpdateUserReminderResponse>
export type GetUserReminderClientResult = NonNullable<GetUserReminderResponse>
export type DeleteUserReminderClientResult = NonNullable<DeleteUserReminderResponse>
export type GetUserReminderListClientResult = NonNullable<GetUserReminderListResponse>
export type CreateUserReminderClientResult = NonNullable<CreateUserReminderResponse>
export type UpdateEquipmentReminderClientResult = NonNullable<UpdateEquipmentReminderResponse>
export type GetEquipmentReminderClientResult = NonNullable<GetEquipmentReminderResponse>
export type DeleteEquipmentReminderClientResult = NonNullable<DeleteEquipmentReminderResponse>
export type GetEquipmentReminderListClientResult = NonNullable<GetEquipmentReminderListResponse>
export type CreateEquipmentReminderClientResult = NonNullable<CreateEquipmentReminderResponse>
export type UpdateServiceTypeClientResult = NonNullable<UpdateServiceTypeResponse>
export type GetServiceTypeClientResult = NonNullable<GetServiceTypeResponse>
export type DeleteServiceTypeClientResult = NonNullable<DeleteServiceTypeResponse>
export type GetServiceTypeListClientResult = NonNullable<GetServiceTypeListResponse>
export type CreateServiceTypeClientResult = NonNullable<CreateServiceTypeResponse>
export type UpdateRepairTypeClientResult = NonNullable<UpdateRepairTypeResponse>
export type GetRepairTypeClientResult = NonNullable<GetRepairTypeResponse>
export type DeleteRepairTypeClientResult = NonNullable<DeleteRepairTypeResponse>
export type GetRepairTypeListClientResult = NonNullable<GetRepairTypeListResponse>
export type CreateRepairTypeClientResult = NonNullable<CreateRepairTypeResponse>
export type UpdateOutOfServiceReasonClientResult = NonNullable<UpdateOutOfServiceReasonResponse>
export type GetOutOfServiceReasonClientResult = NonNullable<GetOutOfServiceReasonResponse>
export type DeleteOutOfServiceReasonClientResult = NonNullable<DeleteOutOfServiceReasonResponse>
export type GetOutOfServiceReasonListClientResult = NonNullable<GetOutOfServiceReasonListResponse>
export type CreateOutOfServiceReasonClientResult = NonNullable<CreateOutOfServiceReasonResponse>
export type GetCompanyClientResult = NonNullable<GetCompanyResponse>
export type UpdateCompanyProfileClientResult = NonNullable<CompleteProfileResponse>
export type MarkDriverApplicationResubmissionClientResult = NonNullable<MarkDriverApplicationResubmissionResponse>
export type MarkDriverApplicationRejectedClientResult = NonNullable<MarkDriverApplicationRejectedResponse>
export type MarkDriverApplicationPendingReviewClientResult = NonNullable<MarkDriverApplicationPendingReviewResponse>
export type MarkDriverApplicationHiredClientResult = NonNullable<MarkDriverApplicationHiredResponse>
export type DriverApplicationStartedClientResult = NonNullable<DriverApplicationStartedResponse>
export type SaveDriverApplicationReSignClientResult = NonNullable<DriverApplicationReSignResponse>
export type CompleteDriverApplicationClientResult = NonNullable<CompleteDriverApplicationResponse>
export type SavePreHiringDriverApplicationInfoClientResult = NonNullable<SavePreHiringDriverApplicationInfoResponse>
export type GetDriverApplicationProficiencyClientResult = NonNullable<GetDriverApplicationProficiencyResponse>
export type CreateDriverApplicationProficiencyClientResult = NonNullable<CreateDriverApplicationProficiencyResponse>
export type UploadDriverApplicationFileClientResult = NonNullable<UploadDriverApplicationFileResponse>
export type SignDrugAndAlcoholPolicyClientResult = NonNullable<SignDrugAndAlcoholPolicyResponse>
export type SignClearinghousePolicyClientResult = NonNullable<SignClearinghousePolicyResponse>
export type SendDriverApplicationClientResult = NonNullable<SendDriverApplicationResponse>
export type GetDriverApplicationListClientResult = NonNullable<GetDriverApplicationListResponse[]>
export type GetDriverApplicationClientResult = NonNullable<GetDriverApplicationResponse>
export type ExportXlsxDriverApplicationSignedUrlClientResult = NonNullable<ExportXlsxDriverApplicationSignedUrlResponse>
export type ExportXlsxDriverApplicationControllerExecuteClientResult = NonNullable<ExportXlsxDriverApplicationResponse>
export type SaveDriverApplicationTrafficConvictionClientResult = NonNullable<SaveDriverApplicationTrafficConvictionResponse>
export type GetDriverApplicationTrafficConvictionClientResult = NonNullable<GetDriverApplicationTrafficConvictionResponse>
export type SaveDriverApplicationResidencyClientResult = NonNullable<unknown>
export type GetDriverApplicationResidencyClientResult = NonNullable<GetDriverApplicationResidencyResponse[]>
export type SaveDriverApplicationLicenseClientResult = NonNullable<SaveDriverApplicationLicenseResponse>
export type GetDriverApplicationLicenseClientResult = NonNullable<GetDriverApplicationLicenseResponse>
export type SaveDriverApplicationGeneralClientResult = NonNullable<unknown>
export type GetDriverApplicationGeneralClientResult = NonNullable<GetDriverApplicationGeneralResponse>
export type SaveDriverApplicationExperienceClientResult = NonNullable<SaveDriverApplicationExperienceResponse>
export type GetDriverApplicationExperienceClientResult = NonNullable<GetDriverApplicationExperienceResponse>
export type SaveDriverApplicationEmploymentHistoryClientResult = NonNullable<SaveDriverApplicationEmploymentHistoryResponse>
export type GetDriverApplicationEmploymentHistoryClientResult = NonNullable<GetDriverApplicationEmploymentHistoryResponse>
export type SaveDriverApplicationEducationClientResult = NonNullable<SaveDriverApplicationEducationResponse>
export type GetDriverApplicationEducationClientResult = NonNullable<GetDriverApplicationEducationResponse>
export type SaveDriverApplicationAccidentClientResult = NonNullable<SaveDriverApplicationAccidentResponse>
export type GetDriverApplicationAccidentClientResult = NonNullable<GetDriverApplicationAccidentResponse>
export type DownloadDriverApplicationFormSignedUrlClientResult = NonNullable<DownloadDriverApplicationFormResponse>
export type UploadSapProgramTestResultClientResult = NonNullable<UploadSapProgramTestResultResponse>
export type GetSapProgramTestResultListClientResult = NonNullable<GetSapProgramTestResultListResponse>
export type StartSapProgramClientResult = NonNullable<StartSapProgramResponse>
export type GetSapProgramListClientResult = NonNullable<GetSapProgramListResponse>
export type GetSapProgramClientResult = NonNullable<GetSapProgramResponse>
export type GetActiveSapProgramClientResult = NonNullable<GetActiveSapProgramResponse>
export type EndSapProgramClientResult = NonNullable<EndSapProgramResponse>
export type SaveDriverResidencyClientResult = NonNullable<SaveDriverResidencyResponse>
export type GetDriverResidencyClientResult = NonNullable<GetDriverResidencyResponse[]>
export type UpdateDriverReminderClientResult = NonNullable<UpdateDriverReminderResponse>
export type DeleteDriverReminderClientResult = NonNullable<DeleteDriverReminderResponse>
export type GetDriverReminderListClientResult = NonNullable<GetDriverReminderListResponse>
export type CreateDriverReminderClientResult = NonNullable<CreateDriverReminderResponse>
export type GetDriverReminderClientResult = NonNullable<GetDriverReminderResponse>
export type SaveDriverLicenseClientResult = NonNullable<SaveDriverLicenseResponse>
export type GetDriverLicenseClientResult = NonNullable<GetDriverLicenseResponse>
export type TransferDriverToTerminalClientResult = NonNullable<TransferDriverResponse>
export type TerminateDriversStatusClientResult = NonNullable<TerminateDriverStatusResponse>
export type SaveDriverClientResult = NonNullable<SaveDriverResponse>
export type InActiveDriversStatusClientResult = NonNullable<InactiveDriverStatusResponse>
export type GetDriverListClientResult = NonNullable<GetDriverListResponse[]>
export type CreateDriverClientResult = NonNullable<CreateDriverResponse>
export type GetDriverClientResult = NonNullable<GetDriverResponse>
export type ExportXlsxDriverSignedUrlClientResult = NonNullable<ExportXlsxDriverSignedUrlResponse>
export type ExportXlsxDriverControllerExecuteClientResult = NonNullable<ExportXlsxDriverResponse>
export type ActiveDriversStatusClientResult = NonNullable<ActiveDriverStatusResponse>
export type SaveDriverGeneralClientResult = NonNullable<unknown>
export type GetDriverGeneralClientResult = NonNullable<GetDriverGeneralResponse>
export type UpdateNextDueFrequencyClientResult = NonNullable<UpdateNextDueFrequencyResponse>
export type UpdateDriverDocumentClientResult = NonNullable<UpdateDriverDocumentResponse>
export type GetDriverDocumentTypeListClientResult = NonNullable<GetDriverDocumentTypeListResponse>
export type GetDriverDocumentListByTypeClientResult = NonNullable<GetDriverDocumentListByTypeResponse>
export type GetDriverDocumentListClientResult = NonNullable<GetDriverDocumentListResponse>
export type DownloadDriverDocumentListSignedUrlClientResult = NonNullable<DownloadDriverDocumentListSignedUrlResponse>
export type DownloadDriverDocumentListControllerExecuteClientResult = NonNullable<DownloadDriverDocumentListResponse>
export type DeleteDriverDocumentClientResult = NonNullable<DeleteDriverDocumentResponse>
export type GetExpiringDocumentsClientResult = NonNullable<GetExpiringDocumentsResponse>
export type GetDashboardStatsClientResult = NonNullable<GetDashboardResponse>
export type SignUpClientResult = NonNullable<SignUpResponse>
export type SignOutClientResult = NonNullable<SignOutResponse>
export type CreatePasswordClientResult = NonNullable<SetupPasswordResponse>
export type ResetPasswordClientResult = NonNullable<ResetPasswordResponse>
export type RefreshTokenClientResult = NonNullable<RefreshTokenResponse>
export type LoginWithDelegationTokenClientResult = NonNullable<LoginWithDelegationTokenResponse>
export type LoginClientResult = NonNullable<LoginResponse>
export type ResetPasswordRequestClientResult = NonNullable<ForgotPasswordResponse>
export type UploadTempFileClientResult = NonNullable<UploadTempFileResponse>
export type GetStateListClientResult = NonNullable<GetStateListResponse>
export type GetCountryListClientResult = NonNullable<GetCountryListResponse>
export type GetCompanyTypeListClientResult = NonNullable<GetCompanyTypeListResponse>
export type GetCompanyStatusListClientResult = NonNullable<GetCompanyStatusListResponse>
export type GetCompanyApplicationStatusListClientResult = NonNullable<GetCompanyApplicationStatusListResponse>
export type GetBusinessTypeListClientResult = NonNullable<GetBusinessTypeListResponse>
export type GetBusinessSubTypeListClientResult = NonNullable<BusinessSubTypeListResponse>
