import { Component, OnInit, inject } from "@angular/core";
import { OutlineTextInputComponent } from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import { DropdownComponent } from "../../../../shared/inputs/dropdown/dropdown.component";
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { State } from "../../../../shared/base/base-state";
import { BaseComponent } from "../../../../shared/base/base-component";
import { BaseInputComponent } from "../../../../shared/inputs/_base/base-input/base-input.component";
import { AppSvgIconComponent } from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import { CompanyTypesResponse } from "../../../../core/services/lookup/company-types/company-types-response";
import { CompanyTypesRequest } from "../../../../core/services/lookup/company-types/company-types-request";
import { LookupService } from "../../../../core/services/lookup/lookup.service";
import { CountriesRequest } from "../../../../core/services/lookup/countries/countries-request";
import {
  CountriesResponseV1,
  Country,
} from "../../../../core/services/lookup/countries/countries-response-v1";
import { StateListRequest } from "../../../../core/services/lookup/states/stateList-request";
import {
  StateList,
  StateListResponse,
} from "../../../../core/services/lookup/states/stateList-response";
import { BusinessTypesResponse } from "../../../../core/services/lookup/business-type/business-types-response";
import { BusinessTypeRequest } from "../../../../core/services/lookup/business-type/business-types-request";
import { BusinessSubTypeRequest } from "../../../../core/services/lookup/business-sub-type/business-sub-type-request";
import { BusinessSubTypeResponse } from "../../../../core/services/lookup/business-sub-type/business-sub-type-response";
import { CommonModule } from "@angular/common";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { SetCompanyProfileRequest } from "../../../../core/services/auth/set-company-profile/set-company-profile-request";
import {
  AccountType,
  AccountTypesResponse,
} from "../../../../core/services/auth/account-type/account-type-response";
import {
  CarrierType,
  CarrierTypeResponse,
} from "../../../../core/services/auth/company-info/carrier-type/carrier-type-response";
import { CompanyInfoResponse } from "../../../../core/services/auth/company-info/company-type-response";
import {InvalidControlScrollDirective} from "../../../../shared/directives/invalid-control-scroll.directive";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";

@Component({
  selector: "app-setup-company-profile",
  standalone: true,
  templateUrl: "./setup-company-profile.component.html",
  styleUrl: "./setup-company-profile.component.scss",
  imports: [
    OutlineTextInputComponent,
    DropdownComponent,
    ButtonComponent,
    ReactiveFormsModule,
    BaseInputComponent,
    AppSvgIconComponent,
    CommonModule,
    InvalidControlScrollDirective,
    RadioButtonComponent,
  ],
})
export class SetupCompanyProfileComponent
  extends BaseComponent
  implements OnInit
{
  authService = inject(AuthService);
  formBuilder = inject(FormBuilder);
  lookupService = inject(LookupService);
  setupCompanyProfileForm!: FormGroup;

  setCompanyProfileState = new State<SetCompanyProfileRequest>();
  companyTypesState = new State<CompanyTypesResponse>();
  accountTypeState = new State<AccountTypesResponse>();
  countryListState = new State<CountriesResponseV1>();
  stateListState = new State<StateListResponse>();
  businessTypeState = new State<BusinessTypesResponse>();
  businessSubTypeState = new State<BusinessSubTypeResponse>();
  carrierTypeState = new State<CarrierTypeResponse>();
  companyInfoState = new State<CompanyInfoResponse>();

  selectedItem: any | null;
  selectedAccountType: AccountType | null | undefined;
  selectedCarrierType: CarrierType | null | undefined;
  filteredStates: StateList[] = [];

  ngOnInit(): void {
    this.initForm();
    this.getCompanyInfo();
    this.getCompanyTypes();
    this.getAccountTypes();
    this.getCountry();
    this.getStateList();
    this.getBusinessType();
    this.getBusinessSubType();
    this.getCarrierTypes();
  }

  getCompanyTypes() {
    const request: CompanyTypesRequest = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<CompanyTypesResponse>({
      state: this.companyTypesState,
      request: this.lookupService.getCompanyTypes(),
    });
  }

  initForm(): void {
    this.setupCompanyProfileForm = this.formBuilder.group({
      companyName: ["", Validators.required],
      dba: [""],
      accountType: ["", Validators.required],
      ownerSsnNumber: ["", Validators.required],
      einNumber: ["", Validators.required],
      email: ["", Validators.email],
      phoneNumber: ["", Validators.required],
      companyType: ["", Validators.required],
      addressLine1: ["", Validators.required],
      addressLine2: ["", Validators.required],
      countryId: ["", Validators.required],
      stateId: ["", Validators.required],
      city: ["", Validators.required],
      zipCode: ["", Validators.required],
      businessTypeId: ["", Validators.required],
      businessSubTypeId: ["", Validators.required],
      businessRepresentativeFirstName: ["", Validators.required],
      businessRepresentativeLastName: ["", Validators.required],
      dotNumber: ["", Validators.required],
      caMcpNumber: ["", Validators.required],
      carrierType: ["", Validators.required],
      mcNumber: ["", Validators.required],
      transportsHazmat: [false,Validators.required],
      transportsHazwaste: [false,Validators.required],
    });
  }

  getAccountTypes() {
    this.executeRequest({
      state: this.accountTypeState,
      request: this.lookupService.getAccountTypes(),
    });
  }

  getCountry() {
    this.executeRequest<CountriesResponseV1>({
      state: this.countryListState,
      request: this.lookupService.getCountry(),
    });
  }

  getStateList() {
    this.executeRequest<StateListResponse>({
      state: this.stateListState,
      request: this.lookupService.getStateList(),
    });
  }

  getBusinessType() {
    const request: BusinessTypeRequest = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<BusinessTypesResponse>({
      state: this.businessTypeState,
      request: this.lookupService.getBusinessType(request),
    });
  }

  getBusinessSubType() {
    const request: BusinessSubTypeRequest = {
      pageNumber: 1,
      pageSize: 100,
    };
    this.executeRequest<BusinessSubTypeResponse>({
      state: this.businessSubTypeState,
      request: this.lookupService.getBusinessSubType(request),
    });
  }

  getCompanyInfo() {
    this.executeRequest<CompanyInfoResponse>({
      state: this.companyInfoState,
      request: this.lookupService.getCompanyInfo(),
      onSuccess: (response) => {
        this.handleInfoForm(response);
      },
    });
  }

  private handleInfoForm(response: CompanyInfoResponse) {
    if (response != null) {
      const companyInfo = response.data;

      this.setupCompanyProfileForm.controls["companyName"].setValue(
        companyInfo.companyName ?? ""
      );
      this.setupCompanyProfileForm.controls["phoneNumber"].setValue(
        companyInfo.phoneNumber ?? ""
      );
      this.setupCompanyProfileForm.controls["email"].setValue(
        companyInfo.email ?? ""
      );
      this.setupCompanyProfileForm.controls["email"].disable();

      const companyTypeItems = this.companyTypesState.response()?.data ?? [];

      const companyTypeItem = companyTypeItems.find(
        (x) => x.id === companyInfo.companyTypeId
      );

      if (companyTypeItem) {
        this.setupCompanyProfileForm.controls["companyType"].setValue(
          companyTypeItem.id
        );
      }
    }
  }

  getCarrierTypes() {
    this.executeRequest({
      state: this.carrierTypeState,
      request: this.lookupService.getCarrierType(),
    });
  }

  onAccountTypeChanged(selectedItem: AccountType | null) {
    console.log("Selected Item:", selectedItem);
    this.selectedAccountType = selectedItem;
    switch (selectedItem?.name) {
      case "Individual":
        this.setupCompanyProfileForm.controls["ownerSsnNumber"].enable();
        this.setupCompanyProfileForm.controls["einNumber"].disable();
        break;
      case "Company":
        this.setupCompanyProfileForm.controls["einNumber"].enable();
        this.setupCompanyProfileForm.controls["ownerSsnNumber"].disable();
        break;
    }
  }

  onSelectedCarrierChanged(selectedItem: CarrierType | null) {
    console.log("Selected Item:", selectedItem);
    switch (selectedItem?.name) {
      case "InterState":
        this.setupCompanyProfileForm.controls["mcNumber"].enable();
        break;
      case "IntraState":
        this.setupCompanyProfileForm.controls["mcNumber"].disable();
        break;
    }
    // this.setupCompanyProfileForm.controls['mcNumber'].disable();
    this.selectedCarrierType = selectedItem;
  }
  onCountrySelected(selectedItem: Country | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  onClickCompleteProfile() {
    // this.validateForm(this.setupCompanyProfileForm);
    if (this.setupCompanyProfileForm.invalid) {
      return;
    }
    let request = this.setupCompanyProfileForm.value;
    this.executeRequest({
      state: this.setCompanyProfileState,
      request: this.authService.setupCompanyProfile(request),
      onSuccess: (response) => {
        this.setupCompanyProfileForm.reset();
        this.router.navigate(["/dashboard"]);
      },
      handleSuccess: true,
      successMessage: "You have successfully set your profile.",
    });
  }
}
