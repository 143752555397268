import {Component, inject, input, OnInit, signal, WritableSignal} from '@angular/core';
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {MatTab, MatTabChangeEvent, MatTabGroup, MatTabLabel} from "@angular/material/tabs";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {BaseComponent} from "../../../../shared/base/base-component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {DriverGeneralInfoComponent} from "../driver-general-info/driver-general-info.component";
import {DriverDocumentsComponent} from "../driver-documents/driver-documents.component";
import {DriverResidencyInfoComponent} from "../driver-residency-info/driver-residency-info.component";
import {DriverLicenseInfoComponent} from "../driver-license-info/driver-license-info.component";
import {DriverRemindersComponent} from "../driver-reminders/driver-reminders.component";
import {ApiService} from "../../../../core/api/api.service";
import {type DownloadDriverDocumentListSignedUrlResponse, GetDriverGeneralResponse} from "../../../../core/api/model";
import {State} from "../../../../shared/base/base-state";
import {ShimmerComponent} from "../../../../shared/components/shimmer/shimmer.component";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";

@Component({
  selector: 'app-driver-info',
  standalone: true,
  imports: [
    ButtonComponent,
    MatTabGroup,
    MatTab,
    MatTabLabel,
    MatDialogModule,
    AppSvgIconComponent,
    DriverGeneralInfoComponent,
    DriverDocumentsComponent,
    DriverResidencyInfoComponent,
    DriverLicenseInfoComponent,
    DriverRemindersComponent,
    ShimmerComponent,
  ],
  templateUrl: './driver-info.component.html',
  styleUrl: './driver-info.component.scss'
})
export class DriverInfoComponent extends BaseComponent implements OnInit {

  http = inject(HttpClient);
  tokenService = inject(TokenStoreService);

  driverId = input<string>();

  dialog = inject(MatDialog);
  apiService = inject(ApiService);

  isGeneralInfoCompleted = signal(false);
  DocumentsCompleted = signal(false);
  ResidencyInfoCompleted = signal(false);
  LicenseInfoCompleted = signal(false);
  Reminders = signal(false);

  steps: DriverInformationStep[] = [
    {
      title: "General Info",
      completed: this.isGeneralInfoCompleted,
      type: "generalInfo",
    },
    {
      title: "Residency Info",
      completed: this.ResidencyInfoCompleted,
      type: "residencyInfo",
    },
    {
      title: "License Info",
      completed: this.LicenseInfoCompleted,
      type: "licenseInfo",
    },
    {
      title: "Documents",
      completed: this.DocumentsCompleted,
      type: "documents",
    },
    {
      title: "Reminders",
      completed: this.Reminders,
      type: "reminders",
    }
  ];

  activeStep = signal<DriverInformationStep>(this.steps[0]);
  activeStepIndex = signal(0);
  driverGeneralInfoState = new State<GetDriverGeneralResponse>();
  downloadDriverDocumentsState = new State<DownloadDriverDocumentListSignedUrlResponse>();

  ngOnInit(): void {
    this.getDriverGeneralInfo();
  }

  private getDriverGeneralInfo() {
    this.executeRequest<GetDriverGeneralResponse>({
      request: this.apiService.getDriverGeneral(this.driverId() ?? ''),
      state: this.driverGeneralInfoState
    });
  }

  onTabChange($event: MatTabChangeEvent) {
    this.activeStep.set(this.steps[$event.index]);
    this.activeStepIndex.set($event.index);
  }

  onDownloadAllClicked() {
    this.executeRequest<DownloadDriverDocumentListSignedUrlResponse>({
      request: this.apiService.downloadDriverDocumentListSignedUrl(this.driverId() ?? ''),
      state: this.downloadDriverDocumentsState,
      onSuccess: response => {
        if (response.data?.downloadUrl) {
          window.open(response.data.downloadUrl, '_blank');
        }
      }
    });
  }
}

export interface DriverInformationStep {
  title: string;
  completed: WritableSignal<boolean>;
  type:
    | "generalInfo"
    | "residencyInfo"
    | "licenseInfo"
    | "documents"
    | "reminders";
}

