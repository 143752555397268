import {Component, inject} from '@angular/core';
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {NgClass} from "@angular/common";
import {
  GetCompanyUserTerminalListItem,
  type GetDriverListItem,
  type TransferDriverRequest,
  type TransferDriverResponse
} from "@core/api/model";
import {arrayToCSV} from "@shared/utils/string-utils";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseRequestService} from "@shared/base/base-request.service";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-transfer-terminal-selection',
  imports: [
    NgClass,
    AppSvgIconComponent,
    ButtonComponent
  ],
  templateUrl: './row-transfer-terminal-selection.component.html',
  standalone: true,
  styleUrl: './row-transfer-terminal-selection.component.scss'
})
export class RowTransferTerminalSelectionComponent extends TableCustomComponent<GetCompanyUserTerminalListItem> {

  requestService = inject(BaseRequestService);
  apiService = inject(ApiService);

  transferTerminalState = new State<TransferDriverResponse>();

  getAddress(terminal: GetCompanyUserTerminalListItem): string {
    return arrayToCSV([terminal.addressLine1, terminal.addressLine2, terminal.city, terminal.stateName, terminal.countryName, terminal.zipCode]);
  }

  getSelectedTerminalId() {
    let data: GetDriverListItem = this.data;
    return data.homeTerminalId;
  }

  onTransferClicked(rowData: GetCompanyUserTerminalListItem) {
    let request: TransferDriverRequest = {
      driverId: this.data.id,
      toTerminalId: rowData.id
    }
    this.requestService.executeRequest<TransferDriverResponse>({
      state: this.transferTerminalState,
      request: this.apiService.transferDriverToTerminal(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.actionPerformed.emit({item: rowData, actionKey: 'selected'});
      }
    })
  }
}
