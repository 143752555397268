import {Component, inject} from "@angular/core";
import {DialogRef} from "@angular/cdk/dialog";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: "app-signup-success",
  standalone: true,
  imports: [
    ButtonComponent,
    BaseOverlayComponent,
  ],
  templateUrl: "./signup-success.component.html",
  styleUrl: "./signup-success.component.scss",
})
export class SignupSuccessComponent {
  readonly dialogRef = inject(DialogRef);

  closeDialogue() {
    this.dialogRef.close();
  }
}
