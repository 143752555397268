import {Component, OnInit, inject} from "@angular/core";
import {Clipboard, ClipboardModule} from '@angular/cdk/clipboard';
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {BaseDialogComponent} from "../../../../shared/components/_base/base-dialog/base-dialog.component";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {SendDriverApplicationResponse} from "../../../../core/api/model";

@Component({
  selector: "app-driver-application-sent-confirmation",
  standalone: true,
  imports: [
    ButtonComponent,
    BaseDialogComponent,
    AppSvgIconComponent,
    ClipboardModule
  ],
  templateUrl: "./driver-application-sent-confirmation.component.html",
  styleUrl: "./driver-application-sent-confirmation.component.scss",
})
export class DriverApplicationSentConfirmationComponent extends BaseComponent implements OnInit {

  data = inject(MAT_DIALOG_DATA);
  readonly dialog = inject(MatDialog);
  clipboard = inject(Clipboard);

  sendDriverApplicationResponse: SendDriverApplicationResponse | null = null;

  ngOnInit() {
    this.sendDriverApplicationResponse = this.data.message;
  }

  onCopyClicked(token: string | undefined) {
    let url = `https://app.yoursafetycompliance.com/driver-application/apply?token=${token}`;
    this.clipboard.copy(url);
    this.toasterService.success('Link copied successfully.');
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
