<div
  class="flex items-center gap-3 px-2 py-2 rounded-md cursor-pointer select-none"
  [matTooltip]="menuItem().label"
  matTooltipPosition="right"
  matTooltipClass="ml-3"
  [matTooltipDisabled]="layoutService.sidebarExpanded"
  [ngClass]="isActive() ? 'bg-primary-600' : 'bg-primary-800'"
  (click)="onMenuClick()">
  <app-svg-icon class="text-white" [src]="menuItem().iconPath" [size]="20"></app-svg-icon>
  @if (layoutService.sidebarExpanded) {
    <p class="text-body2 text-neutral-300 px-2">{{ menuItem().label }}</p>
  }
</div>
