import {Component, inject, OnInit} from '@angular/core';
import {BaseTableComponent} from "@shared/components/_base/base-table/base-table.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {ContextMenuIconComponent} from "@shared/components/context-menu-icon/context-menu-icon.component";
import {DatePipe} from "@angular/common";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {BaseComponent} from "@shared/base/base-component";
import {DriverApplicationService} from "@core/services/driver-application/driver-application.service";
import {State} from "@shared/base/base-state";
import {equipmentsResponse} from "@core/services/equipments/equipments-response";
import {CreateNewEquipmentComponent} from "../create-new-equipment/create-new-equipment.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: 'app-equipments',
  standalone: true,
  imports: [
    BaseTableComponent,
    ButtonComponent,
    ContextMenuIconComponent,
    DatePipe,
    TextInputComponent
  ],
  templateUrl: './equipments-list.component.html',
  styleUrl: './equipments-list.component.scss'
})
export class EquipmentsListComponent extends BaseComponent implements OnInit {

  driverService = inject(DriverApplicationService);
  overlayService = inject(OverlayService);

  equipmentsState = new State<equipmentsResponse>();

  ngOnInit() {
    this.getEquipmentsList();
  }

  getEquipmentsList() {
    this.executeRequest(
      {
        state: this.equipmentsState,
        request: this.driverService.getEquipmentsList()
      }
    );
  }

  onCreateNewEquipment() {
    this.overlayService.openModal(CreateNewEquipmentComponent);
  }

}
