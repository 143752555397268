import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {Constants} from "../../../../core/constants/constants";
import {MatDialog} from "@angular/material/dialog";
import {CreateReminderComponent} from "./create-reminder/create-reminder.component";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {State} from "../../../../shared/base/base-state";
import {LookupService} from "../../../../core/services/lookup/lookup.service";
import {DriverService} from "../../../../core/services/driver/driver.service";
import {
  RemindersResponse
} from "../../../../core/services/driver-application/driver-application-hire/reminders.response";
import {DriverApplicationService} from "../../../../core/services/driver-application/driver-application.service";
import {DatePipe} from "@angular/common";
import {PaginationComponent} from "../../../../shared/components/pagination/pagination.component";
import {ApiService} from "../../../../core/api/api.service";
import type {GetDriverReminderListResponse} from "../../../../core/api/model";

@Component({
  selector: 'app-driver-reminders',
  standalone: true,
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    DatePipe,
    PaginationComponent,
  ],
  templateUrl: './driver-reminders.component.html',
  styleUrl: './driver-reminders.component.scss'
})
export class DriverRemindersComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  driverService = inject(DriverApplicationService);
  apiService = inject(ApiService);

  remindersState = new State<GetDriverReminderListResponse>();

  driverId = input<string>();


  ngOnInit() {
    this.getReminders();
  }

  createReminder() {
    this.dialog.open(CreateReminderComponent, {
      ...Constants.defaultDialogConfig,
      data: { driverId: this.driverId(),
      },

    });
  }

  private getReminders() {
    this.executeRequest({
      state: this.remindersState,
      request: this.apiService.getDriverReminderList(),
    });
  }
}
