import {
  Component,
  inject,
  OnInit,
  output,
} from "@angular/core";
import {
  TextInputComponent
} from "@shared/inputs/text-input/text-input.component";
import {Location} from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {FileUploaderComponent} from "@shared/inputs/file-uploader/file-uploader.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  CompleteDriverApplicationRequest, type CompleteDriverApplicationResponse,
  type GetCompanyResponse, GetDriverApplicationLicenseParams, GetDriverApplicationLicenseResponse,
  GetDriverApplicationParams,
  GetDriverApplicationResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileRequestType, UploadDriverApplicationFileResponse,
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {ApiService} from "@core/api/api.service";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {
  DrugAndAlcoholPolicyAgreementComponent
} from "./drug-and-alcohol-policy-agreement/drug-and-alcohol-policy-agreement.component";
import {
  ClearinghousePolicyAgreementComponent
} from "./clearinghouse-policy-agreement/clearinghouse-policy-agreement.component";
import {HasPermissionDirective} from "@core/directives/has-permission.directive";
import {
  ApplicationSubmissionSuccessMessageComponent
} from "./application-submission-success-message/application-submission-success-message.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";
import {UserRole} from "@core/services/auth/token-store.service";

@Component({
  selector: "app-save-applicant-complete-application",
  standalone: true,
  imports: [
    TextInputComponent,
    ReactiveFormsModule,
    ButtonComponent,
    FileUploaderComponent,
    SignaturePadComponent,
    CheckboxComponent,
    AppSvgIconComponent,
    HasPermissionDirective,
    DateInputComponent
  ],
  templateUrl: "./save-applicant-complete-application.component.html",
  styleUrl: "./save-applicant-complete-application.component.scss",
})
export class SaveApplicantCompleteApplicationComponent extends BaseComponent implements OnInit {

  onNextClick = output<void>();

  location = inject(Location);
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  // backDrop = inject(BackdropDialogService);
  overlayService = inject(OverlayService);

  completeApplicationForm!: FormGroup;

  driverApplicationInfoState = new State<GetDriverApplicationResponse>();
  uploadDmvPrintoutFileState = new State<UploadDriverApplicationFileResponse>();
  uploadDotMedicalCertificateFileState = new State<UploadDriverApplicationFileResponse>();
  uploadCurrentDrivingLicenseFileState = new State<UploadDriverApplicationFileResponse>();
  uploadCurrentDrivingLicenseFrontFileState = new State<UploadDriverApplicationFileResponse>();
  uploadCurrentDrivingLicenseBackFileState = new State<UploadDriverApplicationFileResponse>();
  uploadSchoolBusCertificateState = new State<UploadDriverApplicationFileResponse>();
  uploadHazmatFileState = new State<UploadDriverApplicationFileResponse>();
  companyInfoState = new State<GetCompanyResponse>();
  completeApplicationState = new State<CompleteDriverApplicationResponse>();
  licensesState = new State<GetDriverApplicationLicenseResponse>();


  ngOnInit() {
    this.initForm();
    this.getLicenses();
    this.getCompanyInfo();
  }

  private initForm() {
    let currentDate = new Date();
    let month = String(currentDate.getMonth() + 1).padStart(2, '0');
    let day = String(currentDate.getDate()).padStart(2, '0');
    let year = currentDate.getFullYear();

    let signatureDate = `${month}-${day}-${year}`;

    this.completeApplicationForm = this.formBuilder.group({
      licenseDotMedicalCertificateFileUrl: [null, Validators.required],
      dmvPrintoutFileUrl: [null, Validators.required],
      currentDrivingLicenseFrontFilePath: [null, Validators.required],
      currentDrivingLicenseBackFilePath: [null, Validators.required],
      licenseExpiryDate: [null, Validators.required],
      dmvPrintoutExpiryDate: [null, [Validators.required, onlyPastDateValidator()]],
      medicalCardExpirationDate: [null, Validators.required],
      isUnderSapProgram: [false, Validators.required],
      schoolBusFileURL: [null],
      schoolBusCertificateLicenceNo: [null],
      schoolBusCertificateIssuedDate: [null],
      schoolBusCertificateLicenceExpiryDate: [null, Validators.required],
      applicantName: [null, Validators.required],
      applicantSignatureDate: [signatureDate, Validators.required],
      applicantSignature: [null, Validators.required],
      hazmatFileURL: [null],
      acknowledgement: [null, Validators.required],
    });
  }

  private getLicenses() {
    let request: GetDriverApplicationLicenseParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };
    this.executeRequest<GetDriverApplicationLicenseResponse>({
      state: this.licensesState,
      request: this.apiService.getDriverApplicationLicense(request),
      onSuccess: response => {
        this.getDriverApplicationInfo();
      }, onFailed: error => {
        this.getDriverApplicationInfo();
      }
    });
  }

  private getDriverApplicationInfo() {
    let request: GetDriverApplicationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationResponse>({
      request: this.apiService.getDriverApplication(request),
      state: this.driverApplicationInfoState,
      onSuccess: response => {
        let data = response.data;
        let applicantSignatureDate = data.applicantSignatureDate ? new Date(data.applicantSignatureDate) : null;
        let signatureDate = applicantSignatureDate ? new Date(applicantSignatureDate) : new Date();
        let medicalCardExpiryDate = data?.medicalCardExpirationDate ? new Date(data.medicalCardExpirationDate) : null;

        let licenses = this.licensesState.response()?.data ?? [];
        let currenDrivingLicense = licenses.find(license => license.isCurrent);

        let currentDrivingExpiryDate = currenDrivingLicense?.expirationDate ? new Date(currenDrivingLicense?.expirationDate) : null;

        let dmvPrintOutExpirationDate = data?.dmvPrintOutDate ? new Date(data?.dmvPrintOutDate) : null;


        this.completeApplicationForm.controls['licenseDotMedicalCertificateFileUrl'].setValue(data.dotMedicalCertificateFilePath);
        this.completeApplicationForm.controls['dmvPrintoutFileUrl'].setValue(data.dmvPrintOutOrMVRFilePath);
        this.completeApplicationForm.controls['currentDrivingLicenseFrontFilePath'].setValue(data.currentDrivingLicenseFrontFilePath);
        this.completeApplicationForm.controls['currentDrivingLicenseBackFilePath'].setValue(data.currentDrivingLicenseBackFilePath);
        this.completeApplicationForm.controls['licenseExpiryDate'].setValue(currentDrivingExpiryDate);
        this.completeApplicationForm.controls['medicalCardExpirationDate'].setValue(medicalCardExpiryDate);
        this.completeApplicationForm.controls['hazmatFileURL'].setValue(data.hazmatCertificateFilePath);
        this.completeApplicationForm.controls['dmvPrintoutExpiryDate'].setValue(dmvPrintOutExpirationDate);
        this.completeApplicationForm.controls['isUnderSapProgram'].setValue(data.isUnderSapProgram);

        this.completeApplicationForm.controls['schoolBusFileURL'].setValidators(data.hasSchoolBus ? Validators.required : null);
        this.completeApplicationForm.controls['schoolBusCertificateLicenceNo'].setValidators(data.hasSchoolBus ? Validators.required : null);
        this.completeApplicationForm.controls['schoolBusCertificateIssuedDate'].setValidators(data.hasSchoolBus ? Validators.required : null);
        this.completeApplicationForm.controls['schoolBusCertificateLicenceExpiryDate'].setValidators(data.hasSchoolBus ? Validators.required : null);

        this.completeApplicationForm.controls['schoolBusFileURL'].setValue(data.schoolBusCertificateFilePath);
        this.completeApplicationForm.controls['schoolBusCertificateLicenceNo'].setValue(data.schoolBusCertificateLicenceNo);
        this.completeApplicationForm.controls['schoolBusCertificateIssuedDate'].setValue(data.schoolBusCertificateLicenceIssuedDate);
        this.completeApplicationForm.controls['schoolBusCertificateLicenceExpiryDate'].setValue(data.schoolBusCertificateLicenceExpiryDate);

        this.completeApplicationForm.controls['applicantName'].setValue(data.applicantName);
        this.completeApplicationForm.controls['applicantSignatureDate'].setValue(signatureDate);
        this.completeApplicationForm.controls['applicantSignature'].setValue(data.applicantSignature);
      }
    });
  }

  getCompanyInfo() {
    this.executeRequest<GetCompanyResponse>({
      state: this.companyInfoState,
      request: this.apiService.getCompany(),
    });
  }

  onSignatureCleared() {
    this.completeApplicationForm.controls['applicantSignature'].setValue(null);
  }

  onDMVPrintoutFileSelected($event: File) {

    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.DMVPrintOutOrMVR
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadDmvPrintoutFileState,
    });
  }


  onDotMedicalCertificatePrintoutFileSelected($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.DOTMedicalCertificate
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadDotMedicalCertificateFileState,
    });
  }

  onAttachCurrentDrivingLicenseFrontFileSelected($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.CurrentDriverLicenseFront
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadCurrentDrivingLicenseFrontFileState,
    });
  }

  onAttachCurrentDrivingLicenseBackFileSelected($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.CurrentDriverLicenseBack
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadCurrentDrivingLicenseBackFileState,
    });
  }

  onAttachHazmatCertificate($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.HAZMATCertificate
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadHazmatFileState,
    });
  }

  onAttachSchoolBusFileCertificate($event: File) {
    let request: UploadDriverApplicationFileRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      file: $event,
      type: UploadDriverApplicationFileRequestType.SchoolBusCertificate
    };

    this.executeRequest<UploadDriverApplicationFileResponse>({
      request: this.apiService.uploadDriverApplicationFile(request),
      state: this.uploadSchoolBusCertificateState,
    });
  }

  onSignatureSaved(signatureBase64: string) {
    this.completeApplicationForm.controls["applicantSignature"].setValue(signatureBase64);
  }

  onDrugAndAlcoholPolicyClicked() {
    let dialogRef = this.overlayService.openBackdrop(DrugAndAlcoholPolicyAgreementComponent);
    dialogRef.closed.subscribe(signed => {
      if (signed) {
        this.driverApplicationInfoState.response()!.data!.isDrugAndAlcoholPolicySigned = true;
      }
    });
  }

  onClearingHousePolicyClicked() {
    let dialogRef = this.overlayService.openBackdrop(ClearinghousePolicyAgreementComponent);
    dialogRef.closed.subscribe(signed => {
      if (signed) {
        this.driverApplicationInfoState.response()!.data!.isClearingHousePolicySigned = true;
      }
    });
  }


  onSubmitClicked() {

    console.log(this.completeApplicationForm.value?.schoolBusCertificateIssuedDate);

    this.validateForm(this.completeApplicationForm);
    if (this.completeApplicationForm.invalid) {
      return;
    }

    let applicationInfo = this.driverApplicationInfoState.response()?.data;

    if (!applicationInfo?.isDrugAndAlcoholPolicySigned) {
      this.toasterService.error("Please sign the Drug and Alcohol Policy Agreement");
    }

    if (!applicationInfo?.isClearingHousePolicySigned) {
      this.toasterService.error("Please sign the Clearinghouse Policy Agreement");
    }

    const drivingLicenseExpirationDate = this.completeApplicationForm.controls["licenseExpiryDate"].value;
    const signatureDate = this.completeApplicationForm.controls["applicantSignatureDate"].value;
    const medicalCardExpirationDate = this.completeApplicationForm.controls ["medicalCardExpirationDate"].value;
    const schoolBusCertificateExpirationDate = this.completeApplicationForm.controls["schoolBusCertificateLicenceExpiryDate"].value;
    const schoolBusCertificateIssueDate = this.completeApplicationForm.controls["schoolBusCertificateIssuedDate"].value;

    let request: CompleteDriverApplicationRequest = {
      applicantName: this.completeApplicationForm.controls["applicantName"].value ?? '',
      dmvPrintOutDate: this.completeApplicationForm.controls["dmvPrintoutExpiryDate"].value ?? '',
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      drivingLicenseExpirationDate: drivingLicenseExpirationDate ?? '',
      medicalCardExpirationDate: medicalCardExpirationDate ?? '',
      applicantSignature: this.completeApplicationForm.controls["applicantSignature"].value,
      applicantSignatureDate: signatureDate ?? '',
      schoolBusCertificateLicenceExpiryDate: schoolBusCertificateExpirationDate,
      schoolBusCertificateLicenceIssuedDate: schoolBusCertificateIssueDate,
      schoolBusCertificateLicenceNo: this.completeApplicationForm.controls["schoolBusCertificateLicenceNo"].value,
    };

    this.executeRequest<CompleteDriverApplicationResponse>({
      state: this.completeApplicationState,
      request: this.apiService.completeDriverApplication(request),
      handleSuccess: true,
      onSuccess: (response => {
        this.showSuccessDialog();
      })
    });
  }

  showSuccessDialog() {
    let dialogRef = this.overlayService.openModal(ApplicationSubmissionSuccessMessageComponent);
    dialogRef.closed.subscribe(() => {
      this.router.navigate(['/login']);
    });
  }

  protected readonly UserRole = UserRole;

  onBackClicked() {
    this.location.back();
  }

  onHireDriverClicked() {
    this.router.navigate(['/hire-driver'], {queryParams: {applicationId: this.tokenService.getDriverApplicationId()},});
  }
}
