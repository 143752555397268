import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {BaseComponent} from "@shared/base/base-component";
import {
  TextInputComponent
} from "@shared/inputs/text-input/text-input.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import type {GetDriverGeneral, GetDriverGeneralResponse, SaveDriverGeneralRequest} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {DateUtils} from "@shared/utils/date-utils";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: 'app-driver-general-info',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TextInputComponent,
    RadioButtonComponent,
    ButtonComponent,
    DateInputComponent
  ],
  templateUrl: './driver-general-info.component.html',
  styleUrl: './driver-general-info.component.scss'
})
export class DriverGeneralInfoComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);

  saveGeneralInfoState = new State<any>();
  getDriverGeneralInfoState = new State<GetDriverGeneralResponse>();

  driverGeneralInfoForm!: FormGroup;

  driverId?: string;

  ngOnInit() {
    this.listenToRoute();
    this.initForm();
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? undefined;
      this.getDriverGeneralInfo();
    });
  }

  private initForm() {
    this.driverGeneralInfoForm = this.formBuilder.group({
        id: [null],
        driverApplicationId: [null],
        firstName: [null, Validators.required],
        middleName: [null],
        lastName: [null, Validators.required],
        employeeReferenceNumber: [null],
        phoneNumber: [null, Validators.required],
        email: [null, Validators.required],
        dateOfBirth: [null, [Validators.required, onlyPastDateValidator()]],
        hasUsWorkLegality: [true, Validators.required],
      }
    );
  }

  getDriverGeneralInfo() {
    this.executeRequest<GetDriverGeneralResponse>({
      state: this.getDriverGeneralInfoState,
      request: this.apiService.getDriverGeneral(this.driverId ?? ''),
      onSuccess: (response) => {
        let generalInfo: GetDriverGeneral | null = response.data;

        let dateOfBirth = generalInfo?.dateOfBirth ? new Date(generalInfo.dateOfBirth) : null;

        this.driverGeneralInfoForm.controls["id"].setValue(generalInfo.id);
        this.driverGeneralInfoForm.controls["driverApplicationId"].setValue(
          this.tokenService.getDriverApplicationId()
        );
        this.driverGeneralInfoForm.controls["firstName"].setValue(generalInfo?.firstName);
        this.driverGeneralInfoForm.controls["middleName"].setValue(generalInfo?.middleName);
        this.driverGeneralInfoForm.controls["lastName"].setValue(generalInfo?.lastName);
        this.driverGeneralInfoForm.controls["employeeReferenceNumber"].setValue(generalInfo?.employeeReferenceNumber);
        this.driverGeneralInfoForm.controls["phoneNumber"].setValue(generalInfo?.phoneNumber);
        this.driverGeneralInfoForm.controls["email"].setValue(generalInfo?.email);
        this.driverGeneralInfoForm.controls["dateOfBirth"].setValue(dateOfBirth);
        this.driverGeneralInfoForm.controls["hasUsWorkLegality"].setValue(generalInfo?.hasUsWorkLegality);

        this.driverGeneralInfoForm.controls["email"].disable();
      },
    });
  }

  onUpdateClicked() {
    if (this.driverGeneralInfoForm.invalid) {
      return;
    }

    let formValue = this.driverGeneralInfoForm.value;
    let dateUtils = new DateUtils();
    let generalInfo = this.getDriverGeneralInfoState.response()?.data;
    let request: SaveDriverGeneralRequest = {
      driverId: this.driverId ?? '',
      id: generalInfo?.id ?? '',
      dateOfBirth: formValue.dateOfBirth ?? '',
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      employeeReferenceNumber: formValue.employeeReferenceNumber,
      hasUsWorkLegality: formValue.hasUsWorkLegality,
      phoneNumber: formValue.phoneNumber,
      middleName: formValue.middleName,
    }

    this.executeRequest({
      state: this.saveGeneralInfoState,
      request: this.apiService.saveDriverGeneral(request),
      handleSuccess: true,
      handleError: true
    });
  }
}
