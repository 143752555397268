<app-base-overlay [title]="'Application Incomplete'">
  <div body class="py-6">
    <div class="flex flex-col items-center gap-4">
      <app-svg-icon [size]="42" src="assets/icons/close-circle.svg" class="text-error-500"></app-svg-icon>
      <p class="text-caption text-neutral-700 text-center">Your application is incomplete. Please ensure that all
        sections are fully completed before submission.</p>

      <div class="flex gap-8">
        <div class="flex flex-col gap-3">
          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isGeneralCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isGeneralCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">General Information</span>
          </div>

          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isResidencyCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isResidencyCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Residencies</span>

          </div>

          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isLicenseCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isLicenseCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Licenses</span>
          </div>

          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isExperienceCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isExperienceCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Experiences</span>
          </div>

        </div>

        <div class="flex flex-col gap-3">
          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isAccidentCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isAccidentCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Accidents</span>
          </div>

          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isTrafficConvictionCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isTrafficConvictionCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Traffic Convictions</span>
          </div>

          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isEmploymentCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isEmploymentCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Employment History</span>
          </div>

          <div class="flex gap-3 items-center">
            <app-svg-icon
              [src]="data.isEducationCompleted ? 'assets/icons/check-circle.svg' : 'assets/icons/close-circle.svg'"
              [ngClass]="data.isEducationCompleted ? 'text-success-500' : 'text-error-500'">
            </app-svg-icon>
            <span class="text-body2">Education</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div actions>
    <app-button appearance="outline" [fullWidth]="true" class="w-full" (click)="onCloseClicked()">Close</app-button>
  </div>
</app-base-overlay>


