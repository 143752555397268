<app-base-overlay [title]="'End SAP Program'">
  <div body>
    <p class="my-2">Are you sure want to end the SAP Program?</p>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [appearance]="'outline'" (buttonClick)="onCancelClicked()">Cancel
      </app-button>
      <app-button [appearance]="'outline'" [outlineColor]="'border-error-500'" [textButtonColor]="'text-error-500'"
                  (buttonClick)="onEndSAPProgramClicked()" [loading]="onEndSAPProgramState.loading()">End Program
      </app-button>
    </div>
  </div>
</app-base-overlay>




