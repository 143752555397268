<app-base-overlay [title]="data?.reminder ? 'Update Reminder' : 'New Reminder'">
  <div body>
    <form [formGroup]="createOrUpdateReminderForm">
      <div class="flex flex-col gap-4 justify-center items-center w-full">
        <app-date-input formControlName="reminderDate" [label]="'Reminder Date'"
                        class="w-full" [fullWidth]="true" [allowOnlyFuture]="true"></app-date-input>
        <app-text-input formControlName="reminderTime" [label]="'Reminder Time'" type="time"
                        class="w-full" [fullWidth]="true"></app-text-input>

        <app-text-area-input formControlName="reminderMessage" class="w-full" [fullWidth]="true"
                             label="Reminder message"></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'" [fullWidth]="true"
                  [loading]="createReminderState.loading() || updateReminderState.loading()"
                  (click)="onSaveClicked()">
        {{ data?.reminder ? 'Update Reminder' : 'Create Reminder' }}
      </app-button>
    </div>
  </div>
</app-base-overlay>
