import {Component, inject, OnInit} from '@angular/core';
import {ButtonComponent} from "@shared/components/button/button.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {BaseComponent} from "@shared/base/base-component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {LicenseClassesResponse} from "@core/services/common/license-classes/license-classes-response";
import {State} from "@shared/base/base-state";
import {CommonService} from "@core/services/common/common.service";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import type {
  GetCountryListItem,
  GetCountryListResponse,
  GetDriverLicenseParams,
  GetDriverLicenseResponse, GetStateListItem, GetStateListResponse,
  SaveDriverLicense,
  SaveDriverLicenseRequest,
  SaveDriverLicenseResponse
} from "@core/api/model";
import {ApiService} from "@core/api/api.service";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";

@Component({
  selector: 'app-driver-license-info',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    TextInputComponent,
    ReactiveFormsModule,
    DropdownComponent,
    CheckboxComponent,
    DateInputComponent
  ],
  templateUrl: './driver-license-info.component.html',
  styleUrl: './driver-license-info.component.scss'
})
export class DriverLicenseInfoComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  commonService = inject(CommonService);

  licenseClassesState = new State<LicenseClassesResponse>();
  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  updateLicenseInfoState = new State<SaveDriverLicenseResponse>();
  driverLicenseInfoState = new State<GetDriverLicenseResponse>();

  driverLicenseInfoForm!: FormGroup;

  filteredStates: GetStateListItem[] = [];

  driverId?: string;

  async ngOnInit() {
    this.listenToRoute();
    this.initForm();
    this.getLicenseClasses();
    this.getCountry();
  }

  listenToRoute() {
    this.route.parent?.paramMap.subscribe((params) => {
      this.driverId = params.get('driverId') ?? undefined;
    });
  }

  private initForm() {
    this.driverLicenseInfoForm = this.formBuilder.group({
      id: [null],
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      licenseNo: [null, Validators.required],
      class: [null, Validators.required],
      countryId: [null, Validators.required],
      stateId: [null, Validators.required],
      issuedDate: [null, [Validators.required, onlyPastDateValidator()]],
      expirationDate: [null, Validators.required],
      hasPlacardedHazmat: [false],
      hasTankVehicles: [false],
      hasPassengers: [false],
      hasDoubleOrTripleTrailers: [false],
      hasSchoolBus: [false],
      hasPlacardedHazmatAndTankVehicles: [false],
    });
  }

  private getLicenseClasses() {
    this.executeRequest<LicenseClassesResponse>({
      state: this.licenseClassesState,
      request: this.commonService.getLicenseClasses(),
    });
  }

  getCountry() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
      onSuccess: response => {
        this.getStateList();
      }
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
      onSuccess: response => {
        this.getDriverLicense();
      }
    });
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    let stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(state => state.countryId === selectedItem?.id);
    this.driverLicenseInfoForm.controls["stateId"].setValue(null);
  }

  getDriverLicense() {
    let request: GetDriverLicenseParams = {
      driverId: this.driverId ?? '',
    }
    this.executeRequest({
      state: this.driverLicenseInfoState,
      request: this.apiService.getDriverLicense(request),
      showLoader: true,
      onSuccess: (response) => {

        let licenseInfo = response.data;

        let countryList = this.countryListState.response()?.data ?? [];
        let country = countryList?.find((value) => value.id === licenseInfo.countryId);

        if (country != null) {
          this.onCountrySelected(country);
        }
        let stateList = this.stateListState.response()?.data ?? [];

        this.filteredStates = stateList.filter(x => x.countryId === licenseInfo.countryId);

        this.driverLicenseInfoForm.controls["class"].setValue(licenseInfo.class);
        this.driverLicenseInfoForm.controls["expirationDate"].setValue(new Date(licenseInfo.expirationDate));
        this.driverLicenseInfoForm.controls["firstName"].setValue(licenseInfo.firstName);
        this.driverLicenseInfoForm.controls["lastName"].setValue(licenseInfo.lastName);
        this.driverLicenseInfoForm.controls["issuedDate"].setValue(new Date(licenseInfo.issueDate));
        this.driverLicenseInfoForm.controls["licenseNo"].setValue(licenseInfo.licenseNo);
        this.driverLicenseInfoForm.controls["stateId"].setValue(licenseInfo.stateId);
        this.driverLicenseInfoForm.controls["countryId"].setValue(licenseInfo.countryId);
        this.driverLicenseInfoForm.controls["hasDoubleOrTripleTrailers"].setValue(licenseInfo.hasDoubleOrTripleTrailers ?? false);
        this.driverLicenseInfoForm.controls["hasPassengers"].setValue(licenseInfo.hasPassengers ?? false);
        this.driverLicenseInfoForm.controls["hasPlacardedHazmat"].setValue(licenseInfo.hasPlacardedHazmat ?? false);
        this.driverLicenseInfoForm.controls["hasSchoolBus"].setValue(licenseInfo.hasSchoolBus ?? false);
        this.driverLicenseInfoForm.controls["hasTankVehicles"].setValue(licenseInfo.hasTankVehicles ?? false);
        this.driverLicenseInfoForm.controls["hasPlacardedHazmatAndTankVehicles"].setValue(licenseInfo.hasPlacardedHazmatAndTankVehicles ?? false);
      }
    });
  }

  onUpdateClicked() {
    if (this.driverLicenseInfoForm.invalid) {
      return;
    }
    let formValue = this.driverLicenseInfoForm.value;
    let license: SaveDriverLicense = {
      id: this.driverLicenseInfoState.response()?.data.id,
      class: formValue.class,
      countryId: formValue.countryId,
      expirationDate: formValue.expirationDate,
      firstName: formValue.firstName,
      hasDoubleOrTripleTrailers: formValue.hasTankVehicles,
      hasPassengers: formValue.hasPassengers,
      hasPlacardedHazmat: formValue.hasPlacardedHazmat,
      hasPlacardedHazmatAndTankVehicles: formValue.hasPlacardedHazmatAndTankVehicles,
      hasSchoolBus: formValue.hasSchoolBus,
      hasTankVehicles: formValue.hasTankVehicles,
      issueDate: formValue.issuedDate,
      lastName: formValue.lastName,
      licenseNo: formValue.licenseNo,
      stateId: formValue.stateId,
    }
    let request: SaveDriverLicenseRequest =
      {
        driverId: this.driverId ?? '',
        license: license
      }
    this.executeRequest({
      state: this.updateLicenseInfoState,
      request: this.apiService.saveDriverLicense(request),
      handleSuccess: true,
      handleError: true
    });
  }
}
