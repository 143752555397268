@let driverInfo = driverGeneralInfoState.response()?.data;

<div class="flex flex-col h-full bg-white">

  <div class="flex items-center gap-5 p-4 justify-between bg-white">
    @if (driverGeneralInfoState.loading()) {
      <app-shimmer type="multiline"></app-shimmer>
    }
    @if (driverGeneralInfoState.success()) {
      <div class="flex flex-col gap-3">

        <div class="flex items-center gap-5">
          <p class="text-body2">{{ driverInfo?.firstName }} {{ driverInfo?.middleName }} {{ driverInfo?.lastName }}</p>
        </div>

        <div class="flex items-center gap-5">
          <div class="flex items-center gap-4 px-4 py-1 rounded-md bg-neutral-100">
            <app-svg-icon [src]="'assets/icons/card.svg'" [size]="16" class="text-primary-500"></app-svg-icon>
            <p class="text-body2 text-primary-500">{{ currentLicense()?.licenseNo ?? 'N/A' }}</p>
          </div>
          <div class="flex items-center gap-3">
            <app-svg-icon [src]="'assets/icons/mail.svg'" [size]="16" class="text-primary-500"></app-svg-icon>
            <p class="text-body2 text-neutral-500">{{ driverInfo?.email }}</p>
          </div>

          <div class="flex items-center gap-3">
            <app-svg-icon [src]="'assets/icons/phone.svg'" [size]="16" class="text-primary-500"></app-svg-icon>
            <p class="text-body2 text-neutral-500">{{ driverInfo?.phoneNumber }}</p>
          </div>
        </div>
      </div>
      <app-button (click)="onDownloadAllClicked()"
                  [loading]="downloadDriverDocumentsState.loading()"
                  appearance="outline"
                  [iconSrc]="'assets/icons/download-outline.svg'">Download All Documents
      </app-button>
    }

  </div>

  <div class="flex flex-col h-dvh bg-white overflow-hidden">
    <mat-tab-group mat-stretch-tabs="false" (selectedTabChange)="onTabChange($event)"
                   [(selectedIndex)]="activeStepIndex">

      @for (step of steps; track step; let index = $index) {
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="flex items-center p-2">
              <p class="text-body2 text-neutral-900 select-none">{{ step.title }}</p>
            </div>
          </ng-template>
        </mat-tab>
      }
    </mat-tab-group>
    <div class="grow sticky bg-neutral-100 overflow-hidden">

      <router-outlet/>

      <!--      @switch (activeStep()?.type) {-->
      <!--        @case ('general') {-->
      <!--          <app-driver-general-info [driverId]="driverId()"></app-driver-general-info>-->
      <!--        }-->
      <!--        @case ('residencies') {-->
      <!--          <app-driver-residency-info [driverId]="driverId()"></app-driver-residency-info>-->
      <!--        }-->
      <!--        @case ('licenses') {-->
      <!--          <app-driver-license-info [driverId]="driverId()"></app-driver-license-info>-->
      <!--        }-->
      <!--        @case ('documents') {-->
      <!--          <app-driver-documents [driverId]="driverId()"></app-driver-documents>-->
      <!--        }-->
      <!--        @case ('sapProgram') {-->
      <!--          <app-driver-sap-program [driverId]="driverId()"></app-driver-sap-program>-->
      <!--        }-->
      <!--        @case ('reminders') {-->
      <!--          <app-driver-reminders [driverId]="driverId()"></app-driver-reminders>-->
      <!--        }-->
      <!--        @default {-->

      <!--        }-->
      <!--      }-->
    </div>
  </div>
</div>



