import {
  Component,
  inject, OnInit,
  output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {AppSvgIconComponent} from "../../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../../shared/components/button/button.component";
import {CheckboxComponent} from "../../../../../shared/inputs/checkbox/checkbox.component";
import {DropdownComponent} from "../../../../../shared/inputs/dropdown/dropdown.component";
import {
  OutlineTextInputComponent
} from "../../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {RadioButtonComponent} from "../../../../../shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "../../../../../shared/base/base-component";
import {TokenStoreService} from "../../../../../core/services/token/token-store.service";
import {State} from "../../../../../shared/base/base-state";
import {ApiService} from "../../../../../core/api/api.service";
import {
  GetDriverApplicationEducationItem,
  GetDriverApplicationEducationParams,
  GetDriverApplicationEducationResponse,
  SaveDriverApplicationEducationRequest,
  SaveDriverApplicationEducationEducationType, type SaveDriverApplicationEducation,
} from "../../../../../core/api/model";
import {NgIf} from "@angular/common";

@Component({
  selector: "app-save-applicant-education-info",
  standalone: true,
  imports: [
    ButtonComponent,
    OutlineTextInputComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: "./save-applicant-education-info.component.html",
  styleUrls: ["./save-applicant-education-info.component.scss"],
})
export class SaveApplicantEducationInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  private formBuilder = inject(FormBuilder);
  private tokenService = inject(TokenStoreService);
  private apiService = inject(ApiService);

  highSchoolForm!: FormGroup;
  collegeForm!: FormGroup;
  saveEducationState = new State<any>();
  getDriverEducationState = new State<GetDriverApplicationEducationResponse>();

  ngOnInit() {
    this.initForm();
    this.getEducationInfo();
  }

  private initForm() {
    this.highSchoolForm = this.createEducationForm(
      null,
      SaveDriverApplicationEducationEducationType.HighSchool
    );
    this.collegeForm = this.createEducationForm(
      null,
      SaveDriverApplicationEducationEducationType.College
    );
  }

  private getEducationInfo() {
    const request: GetDriverApplicationEducationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest({
      request: this.apiService.getDriverApplicationEducation(request),
      state: this.getDriverEducationState,
      onSuccess: (response) => {
        const education = response.data || [];
        this.populateForm(education);
      },
    });
  }

  private populateForm(education: GetDriverApplicationEducationItem[]) {
    if (!this.highSchoolForm || !this.collegeForm) {
      return;
    }

    const highSchool = education.find((item) => item.educationType === SaveDriverApplicationEducationEducationType.HighSchool) ?? null;
    const college = education.find((item) => item.educationType === SaveDriverApplicationEducationEducationType.College) ?? null;

    if (highSchool) {
      this.highSchoolForm.controls['shareHighSchoolDetails'].setValue(true);
      this.highSchoolForm.controls['name'].setValue(highSchool.name);
      this.highSchoolForm.controls['course'].setValue(highSchool.course);
      this.highSchoolForm.controls['completedYear'].setValue(highSchool.completedYear);
      this.highSchoolForm.controls['hasGraduated'].setValue(highSchool.hasGraduated);
      this.highSchoolForm.controls['educationType'].setValue(highSchool.educationType);
    }

    if (college) {
      this.collegeForm.controls['shareCollegeDetails'].setValue(true);
      this.collegeForm.controls['name'].setValue(college.name);
      this.collegeForm.controls['course'].setValue(college.course);
      this.collegeForm.controls['completedYear'].setValue(college.completedYear);
      this.collegeForm.controls['hasGraduated'].setValue(college.hasGraduated);
      this.collegeForm.controls['educationType'].setValue(college.educationType);
    }
  }

  private createEducationForm(
    education: GetDriverApplicationEducationItem | null,
    educationType: SaveDriverApplicationEducationEducationType
  ): FormGroup {
    return this.formBuilder.group({
      shareHighSchoolDetails: [false],
      shareCollegeDetails: [false],
      id: [education?.id],
      name: [education?.name || "", Validators.required],
      course: [education?.course || "", Validators.required],
      completedYear: [education?.completedYear || "", Validators.required],
      hasGraduated: [education?.hasGraduated || false, Validators.required],
      details: [education?.details || ""],
      educationType: [educationType, Validators.required],
    });
  }

  onSaveClicked() {
    this.validateForm(this.highSchoolForm);
    this.validateForm(this.collegeForm);

    let checkShareHighSchool = this.highSchoolForm.controls['shareHighSchoolDetails'].value;
    let checkShareCollege = this.collegeForm.controls['shareCollegeDetails'].value;
    const educationData = [];

    if (checkShareHighSchool) {
      const highSchoolValues = this.highSchoolForm.value;
      let highSchool: SaveDriverApplicationEducation = {
        name: highSchoolValues.name,
        displayOrder: 1,
        course: highSchoolValues.course,
        completedYear: parseInt(highSchoolValues.completedYear, 10),
        hasGraduated: highSchoolValues.hasGraduated,
        details: highSchoolValues.details,
        educationType: SaveDriverApplicationEducationEducationType.HighSchool,
      };
      educationData.push(highSchool);
    }

    if (checkShareCollege) {
      const collegeValues = this.collegeForm.value;
      let college: SaveDriverApplicationEducation = {
        name: collegeValues.name,
        displayOrder: 2,
        course: collegeValues.course,
        completedYear: parseInt(collegeValues.completedYear, 10),
        hasGraduated: collegeValues.hasGraduated,
        details: collegeValues.details,
        educationType: SaveDriverApplicationEducationEducationType.College,
      };
      educationData.push(college);
    }

    const request: SaveDriverApplicationEducationRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      education: educationData,
    };

    this.executeRequest({
      state: this.saveEducationState,
      request: this.apiService.saveDriverApplicationEducation(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
        this.populateForm(response.educations ?? []);
      },
    });
  }

  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
