import { Component, inject, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormArray, Validators, ReactiveFormsModule} from '@angular/forms';
import { DriverApplicationService } from "@core/services/driver-application/driver-application.service";
import { State } from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {
  EquipmentsInspectionSafetyMaintenance,
  EquipmentsInspectionSafetyMaintenanceResponse
} from "@core/services/equipments/equipment-inspection-safety-maintenance-response";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {BaseTableComponent} from "@shared/components/_base/base-table/base-table.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {ButtonComponent} from "@shared/components/button/button.component";

@Component({
  selector: 'app-bus-maintenance-safety-inspection',
  standalone: true,
  templateUrl: './bus-maintenance-safety-inspection.component.html',
  imports: [
    ReactiveFormsModule,
    TextInputComponent,
    BaseTableComponent,
    RadioButtonComponent,
    ButtonComponent
  ],
  styleUrls: ['./bus-maintenance-safety-inspection.component.scss']
})
export class BusMaintenanceSafetyInspectionComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  driverService = inject(DriverApplicationService);

  busMaintenanceSafetyForm!: FormGroup;
  inspectionsFormArray!: FormArray;

  inspectionMaintenanceState = new State<EquipmentsInspectionSafetyMaintenanceResponse>();

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.inspectionsFormArray = this.formBuilder.array([]);
    this.busMaintenanceSafetyForm = this.formBuilder.group({
      inspections: this.inspectionsFormArray,
    });
    this.loadInspectionData();
  }

  private loadInspectionData() {
    this.executeRequest({
      state: this.inspectionMaintenanceState,
      request: this.driverService.getInspectionsSafetyMaintenance(),
      onSuccess: (response) => {
        this.populateFormItems();
      }
    });
  }

  private populateFormItems() {
    let data = this.inspectionMaintenanceState.response()?.data ?? [];
    data.forEach(item => {
      const inspectionGroup = this.createInspectionItem(item);
      this.inspectionsFormArray.push(inspectionGroup);
    });
  }

  private createInspectionItem(item: EquipmentsInspectionSafetyMaintenance): FormGroup {
    return this.formBuilder.group({
      id: [item?.id],
      title: [item?.title, Validators.required],
      ok: [item?.ok],
      def: [item?.def],
      notes: [item?.notes]
    });
  }
}
