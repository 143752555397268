import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {TokenStoreService} from "../services/token/token-store.service";

export const authGuard: CanActivateFn = (route, state) => {
  const tokenService = inject(TokenStoreService);
  const router = inject(Router);

  if (!tokenService.isLoggedIn()) {
    router.navigate(['/']).then();
    return false;
  }

  return true;
};
