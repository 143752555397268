import {
  Component,
  inject, OnInit,
  output,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  TextInputComponent
} from "@shared/inputs/text-input/text-input.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "@shared/base/base-component";
import {TokenStoreService, UserRole} from "@core/services/auth/token-store.service";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {
  GetDriverApplicationEducationItem,
  GetDriverApplicationEducationParams,
  GetDriverApplicationEducationResponse,
  SaveDriverApplicationEducationRequest,
  SaveDriverApplicationEducationEducationType, type SaveDriverApplicationEducation,
} from "@core/api/model";
import {
  DriverApplicationInfoRequest
} from "@core/services/driver-application/driver-application-info/driver-application-info-request";
import {
  DriverApplicationInfo,
  DriverApplicationInfoResponse
} from "@core/services/driver-application/driver-application-info/driver-application-info-response";
import {
  DriverIncompleteApplicationComponent
} from "../driver-incomplete-application/driver-incomplete-application.component";
import {Constants} from "@core/constants/constants";
import {OverlayService} from "@shared/components/overlay/overlay.service";

@Component({
  selector: "app-save-applicant-education-info",
  standalone: true,
  imports: [
    ButtonComponent,
    TextInputComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
  templateUrl: "./save-applicant-education-info.component.html",
  styleUrls: ["./save-applicant-education-info.component.scss"],
})
export class SaveApplicantEducationInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  private formBuilder = inject(FormBuilder);
  private apiService = inject(ApiService);
  overlayService = inject(OverlayService);

  highSchoolForm!: FormGroup;
  collegeForm!: FormGroup;
  saveEducationState = new State<any>();
  getDriverEducationState = new State<GetDriverApplicationEducationResponse>();
  driverApplicationState = new State<DriverApplicationInfoResponse>();

  ngOnInit() {
    this.initForm();
    this.getEducationInfo();
  }

  private initForm() {
    this.highSchoolForm = this.createEducationForm(
      null,
      SaveDriverApplicationEducationEducationType.HighSchool
    );
    this.collegeForm = this.createEducationForm(
      null,
      SaveDriverApplicationEducationEducationType.College
    );
  }

  private getEducationInfo() {
    const request: GetDriverApplicationEducationParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest({
      request: this.apiService.getDriverApplicationEducation(request),
      state: this.getDriverEducationState,
      onSuccess: (response) => {
        const education = response.data || [];
        this.populateForm(education);
      },
    });
  }

  private populateForm(education: GetDriverApplicationEducationItem[]) {
    if (!this.highSchoolForm || !this.collegeForm) {
      return;
    }

    const highSchool = education.find((item) => item.educationType === SaveDriverApplicationEducationEducationType.HighSchool) ?? null;
    const college = education.find((item) => item.educationType === SaveDriverApplicationEducationEducationType.College) ?? null;

    if (highSchool) {
      this.highSchoolForm.controls['shareHighSchoolDetails'].setValue(true);
      this.highSchoolForm.controls['name'].setValue(highSchool.name);
      this.highSchoolForm.controls['course'].setValue(highSchool.course);
      this.highSchoolForm.controls['completedYear'].setValue(highSchool.completedYear);
      this.highSchoolForm.controls['hasGraduated'].setValue(highSchool.hasGraduated);
      this.highSchoolForm.controls['educationType'].setValue(highSchool.educationType);
      this.highSchoolForm.controls ['details'].setValue(highSchool.details);
    }

    if (college) {
      this.collegeForm.controls['shareCollegeDetails'].setValue(true);
      this.collegeForm.controls['name'].setValue(college.name);
      this.collegeForm.controls['course'].setValue(college.course);
      this.collegeForm.controls['completedYear'].setValue(college.completedYear);
      this.collegeForm.controls['hasGraduated'].setValue(college.hasGraduated);
      this.collegeForm.controls['educationType'].setValue(college.educationType);
      this.collegeForm.controls ['details'].setValue(college.educationType);

    }
  }

  private createEducationForm(
    education: GetDriverApplicationEducationItem | null,
    educationType: SaveDriverApplicationEducationEducationType
  ): FormGroup {
    return this.formBuilder.group({
      shareHighSchoolDetails: [false],
      shareCollegeDetails: [false],
      id: [education?.id],
      name: [education?.name || "", Validators.required],
      course: [education?.course || "", Validators.required],
      completedYear: [education?.completedYear || "", Validators.required],
      hasGraduated: [education?.hasGraduated || false, Validators.required],
      details: [education?.details || ""],
      educationType: [educationType, Validators.required],
    });
  }

  onSaveClicked() {
    this.validateForm(this.highSchoolForm);
    this.validateForm(this.collegeForm);

    let checkShareHighSchool = this.highSchoolForm.controls['shareHighSchoolDetails'].value;
    let checkShareCollege = this.collegeForm.controls['shareCollegeDetails'].value;
    const educationData = [];

    if (checkShareHighSchool) {
      const highSchoolValues = this.highSchoolForm.value;
      let highSchool: SaveDriverApplicationEducation = {
        name: highSchoolValues.name,
        displayOrder: 1,
        course: highSchoolValues.course,
        completedYear: parseInt(highSchoolValues.completedYear, 10),
        hasGraduated: highSchoolValues.hasGraduated,
        details: highSchoolValues.details,
        educationType: SaveDriverApplicationEducationEducationType.HighSchool,
      };
      educationData.push(highSchool);
    }

    if (checkShareCollege) {
      const collegeValues = this.collegeForm.value;
      let college: SaveDriverApplicationEducation = {
        name: collegeValues.name,
        displayOrder: 2,
        course: collegeValues.course,
        completedYear: parseInt(collegeValues.completedYear, 10),
        hasGraduated: collegeValues.hasGraduated,
        details: collegeValues.details,
        educationType: SaveDriverApplicationEducationEducationType.College,
      };
      educationData.push(college);
    }

    const request: SaveDriverApplicationEducationRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      education: educationData,
    };

    this.executeRequest({
      state: this.saveEducationState,
      request: this.apiService.saveDriverApplicationEducation(request),
      handleSuccess: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
        this.populateForm(response.educations ?? []);
        this.handleDriverApplicationInfo();
      },
    });
  }

  private handleDriverApplicationInfo() {
    let driverApplicationId = this.tokenService.getDriverApplicationId();
    let request: DriverApplicationInfoRequest = {
      driverApplicationId: driverApplicationId,
    };

    this.executeRequest<DriverApplicationInfoResponse>({
      state: this.driverApplicationState,
      request: this.apiService.getDriverApplication(request),
      onSuccess: (response) => {
        this.handleCompleteApplication(response.data);
      },
    });
  }

  private handleCompleteApplication(info: DriverApplicationInfo) {
    let canCompleteApplication = this.canCompleteApplication(info);
    if (canCompleteApplication) {
      this.router.navigate([`/complete-driver-application`]);
    } else {
      this.overlayService.openModal(DriverIncompleteApplicationComponent, {
        data: info
      });
    }
  }

  canCompleteApplication(info: DriverApplicationInfo) {
    if (!info.isGeneralCompleted) {
      return false;
    }

    if (!info.isResidencyCompleted) {
      return false;
    }

    if (!info.isLicenseCompleted) {
      return false;
    }

    if (!info.isAccidentCompleted) {
      return false;
    }

    if (!info.isTrafficConvictionCompleted) {
      return false;
    }

    if (!info.isEmploymentCompleted) {
      return false;
    }

    return info.isEducationCompleted;
  }


  onPreviousClicked() {
    this.onPreviousClick.emit();
  }

  protected readonly UserRole = UserRole;
}
