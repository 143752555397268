<div class="app-container !p-0">

  <div class="flex p-4 gap-5 justify-between bg-white items-center">

    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                            [fullWidth]="false"></app-outline-text-input>

    <div class="flex gap-3 items-center">
      <app-button
        (click)="onExportClicked()"
        [iconSrc]="'assets/icons/export.svg'"
        [buttonColor]="'bg-white'"
        [appearance]="'outline'"
        [textButtonColor]="'text-primary-500'"
        [loading]="exportXlsxDriverApplicationSignedUrlState.loading()">
        Export
      </app-button>
      <app-button [iconSrc]="'assets/icons/plus.svg'" [matMenuTriggerFor]="menu">New Application</app-button>
    </div>
  </div>
  <div class="app-container-padding h-full overflow-auto">
    <app-base-table sortableTable (sortChange)="sortData($event)" [state]="applicationState"
                    (pageChange)="onPageChange($event)">
      <ng-container head>
        <th class="p-4 text-left" data-sortable-key="email">
          EMAIL
        </th>
        <th class="p-4 text-center" data-sortable-key="applicationDate">APPLICATION DATE</th>
        <th class="p-4 text-center" data-sortable-key="invitedBy">INVITED BY</th>
        <th class="p-4 text-center" data-sortable-key="status">STATUS</th>
        <th class="p-4 text-center"></th>
      </ng-container>
      <ng-container body>
        @for (item of applicationState.response()?.data; track item.id; ) {
          <tr class="border-b border-neutral-100 w-full">
            <td class="px-4 text-body2 text-left text-info-500 cursor-pointer ali"
                (click)="onEmailClick(item)">{{ item.email }}
            </td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.applicationDate | date:'MMM d, y h:mm a' }}</td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.invitedBy }}</td>
            <td class="px-4 py-2 text-center">
               <span
                 class="inline-flex items-center justify-center gap-2 bg-info-50 rounded-md py-2 px-4 w-min text-info-500 text-nowrap cursor-pointer select-none"
                 [ngClass]="{
                  'bg-info-50 text-info-500': item.applicationStatus === 'Invited',
                  'bg-success-50 text-success-500': item.applicationStatus === 'Hired',
                  'bg-error-50 text-error-500' : item.applicationStatus === 'Rejected',
                  'bg-yellow-50 text-yellow-500' : item.applicationStatus === 'Application Started',
                  'bg-orange-50 text-orange-500' : item.applicationStatus === 'Needs Approval',
                  'bg-primary-50 text-primary-500' : item.applicationStatus === 'Under Verification',
                  'bg-orange-100 text-orange-600' : item.applicationStatus === 'Resubmission Requested',
                  'bg-green-50 text-green-500' : item.applicationStatus === 'Re-Verification Pending'
                  }"
                 [matMenuTriggerFor]="item.applicationStatus !== 'Hired' && item.applicationStatus !== 'Rejected' ? statusMenu : null">
                  {{ item.applicationStatus }}
                 <ng-container *ngIf="item.applicationStatus !== 'Hired' && item.applicationStatus !== 'Rejected'">
                    <app-svg-icon src="assets/icons/menu-down.svg" class="text-neutral-400" [size]="18"></app-svg-icon>
                  </ng-container>
                </span>
              <mat-menu #statusMenu="matMenu" class="mat-elevation-z3 mt-4">
                <ng-container *ngFor="let action of getApplicationStatus(item.applicationStatus)">
                  <button mat-menu-item class="flex items-center" (click)="onStatusChange(item, action)">
                    <div class="flex items-center gap-2">
                      <p class="text-body2">{{ action }}</p>
                    </div>
                  </button>
                </ng-container>
              </mat-menu>
            </td>
            <td class="px-4 py-2 cursor-pointer items-center">
              <div class="flex justify-center items-center gap-3">
                <app-text-button-small (click)="onEmailClick(item)">View</app-text-button-small>
                <app-context-menu-icon class="flex gap-4 items-center cursor-pointer"></app-context-menu-icon>
              </div>
            </td>
          </tr>
        }
      </ng-container>
    </app-base-table>
  </div>
</div>

<mat-menu #menu="matMenu" class="mat-elevation-z3 mt-4">
  <button mat-menu-item class="flex items-center" (click)="onSendDriverApplicationClicked()">
    <div class="flex items-center gap-2">
      <app-svg-icon class="text-neutral-400" [src]="'assets/icons/share.svg'"></app-svg-icon>
      <p class="text-body2">Send Driver Application</p>
    </div>
  </button>
  <button (click)="onDownloadDriverApplicationClicked()" mat-menu-item class="flex items-center">
    <div class="flex items-center gap-2">
      <app-svg-icon class="text-neutral-400" [src]="'assets/icons/download.svg'"></app-svg-icon>
      <p class="text-body2">Download Application</p>
    </div>
  </button>
</mat-menu>
