<app-base-overlay [title]="'Document Sub types'">
  <div body>
    <div class="flex flex-col gap-4">
      @for (attachment of attachments; track $index) {
        <div class="flex items-center gap-4 justify-between bg-white px-4 py-3 shadow-1 rounded">
          <p class="text-body2 text-black">{{ attachment.name }}</p>
          <div class="flex items-center gap-4">

            <div
              (click)="onViewClicked(attachment)"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/eye.svg"></app-svg-icon>
<!--              <span class="text-caption text-info-500"></span>-->
            </div>

            <div
              (click)="onDownloadClicked(attachment)"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/download.svg"></app-svg-icon>
<!--              <span class="text-caption text-info-500"></span>-->
            </div>

          </div>
        </div>
      }
    </div>
  </div>
</app-base-overlay>
