import {Component, inject} from '@angular/core';
import {
  DriverApplicationInfo
} from "@core/services/driver-application/driver-application-info/driver-application-info-response";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {ButtonComponent} from "@shared/components/button/button.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-driver-verification-needed-info-overlay',
  imports: [
    ButtonComponent,
    AppSvgIconComponent,
    BaseOverlayComponent
  ],
  templateUrl: './driver-verification-needed-info-overlay.component.html',
  styleUrl: './driver-verification-needed-info-overlay.component.scss'
})
export class DriverVerificationNeededInfoOverlayComponent {
  data: DriverApplicationInfo = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);


  onOkClicked() {
    this.dialogRef.close();
  }
}
