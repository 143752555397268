import {Component, inject, Input} from '@angular/core';
import {TableCustomComponent} from "@shared/components/data-table/table-custom-component/table-custom.component";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import type {GetExpiringDocumentsItem} from "@core/api/model";
import {
  ExpiringDocumentSubtypeListComponent
} from "../expiring-document-subtype-list/expiring-document-subtype-list.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";

@Component({
  selector: 'app-table-cell-expiring-documents-link',
  imports: [
    AppSvgIconComponent
  ],
  templateUrl: './table-cell-expiring-documents-link.component.html',
  standalone: true,
  styleUrl: './table-cell-expiring-documents-link.component.scss'
})
export class TableCellExpiringDocumentsLinkComponent extends TableCustomComponent<GetExpiringDocumentsItem> {

  overlayService = inject(OverlayService);

  onViewClicked() {
    const attachments = this.rowData.documentAttachments
    if (attachments.length == 1) {
      window.open(attachments[0].documentFilePath, '_blank');
    }

    if (attachments.length > 1) {
      this.overlayService.openModal(ExpiringDocumentSubtypeListComponent, {
        data: attachments
      });
    }
  }

}
