import {Component} from '@angular/core';
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {ButtonComponent} from "@shared/components/button/button.component";

@Component({
  selector: 'app-test-overlay',
  imports: [
    BaseOverlayComponent,
    ButtonComponent,
  ],
  templateUrl: './test-overlay.component.html',
  styleUrl: './test-overlay.component.scss'
})
export class TestOverlayComponent {
}
