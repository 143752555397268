import {Component, inject, signal} from '@angular/core';
import {
  DriverApplicationInfo
} from "@core/services/driver-application/driver-application-info/driver-application-info-response";
import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog";
import {CheckboxComponent} from "@shared/inputs/checkbox/checkbox.component";
import {ApiService} from "@core/api/api.service";
import {FormsModule} from "@angular/forms";
import {SignaturePadComponent} from "@core/components/signature-pad/signature-pad.component";
import {ToastService} from "@shared/components/toast/toast.service";
import {ButtonComponent} from "@shared/components/button/button.component";
import {BaseComponent} from "@shared/base/base-component";
import type {DriverApplicationReSignRequest, DriverApplicationReSignResponse} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";

@Component({
  selector: 'app-driver-application-re-sign',
  imports: [
    CheckboxComponent,
    FormsModule,
    SignaturePadComponent,
    ButtonComponent,
    BaseOverlayComponent
  ],
  templateUrl: './driver-application-re-sign.component.html',
  styleUrl: './driver-application-re-sign.component.scss'
})
export class DriverApplicationReSignComponent extends BaseComponent {

  apiService = inject(ApiService);
  toastService = inject(ToastService);
  data: DriverApplicationInfo = inject(DIALOG_DATA);
  dialogRef = inject(DialogRef);

  isAgreed = signal(false);
  signatureBase64 = signal<string | null>(null);
  reSignState = new State<DriverApplicationReSignResponse>();

  onSignatureChanged($event: string) {
    this.signatureBase64.set($event);
  }

  onReSignClicked() {
    if (!this.isAgreed()) {
      this.toastService.warning('Please confirm the information by checking the box before signing.');
      return;
    }

    if (this.signatureBase64() == null || this.signatureBase64() == '') {
      this.toastService.warning('Please provide your signature.');
      return;
    }

    this.reSignApplication();
  }

  private reSignApplication() {
    let request: DriverApplicationReSignRequest = {signature: this.signatureBase64() ?? ''};

    this.executeRequest<DriverApplicationReSignResponse>({
      request: this.apiService.saveDriverApplicationReSign(this.data.id, request),
      state: this.reSignState,
    });
  }

  onCancelClicked() {
    this.dialogRef.close();
  }
}
