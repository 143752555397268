@let companyInfo = companyInfoState.response()?.data;

<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col w-full rounded-md px-4">
    <div class="grow overflow-auto overflow-y-scroll">
      <div class="mx-auto py-4">
        <p class="p-2 text-body1 text-center">HIRE APPLICANT</p>
        <p class="p-2 text-body2 text-neutral-800 text-center">Please upload the required documents to complete the
          hiring
          process</p>
      </div>
      <form [formGroup]="driverHiringForm">
        <div class="flex flex-col gap-6 overflow-x-clip">

          <!--Clearing house details-->
          <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
            <p class="text-body1 font-normal text-info-500">Clearinghouse Details</p>
            <app-file-uploader formControlName="clearingHouseLimitedQueryPolicyFilePath"
                               label="Clearinghouse Limited Query Policy"
                               [uploading]="uploadClearingHouseLimitedQueryPolicyState.loading()"
                               (fileSelected)="onFileSelectedForUpload($event, UploadDriverApplicationFileRequestType.ClearingHouseLimitedQueryPolicy, uploadClearingHouseLimitedQueryPolicyState)">
            </app-file-uploader>
            <app-file-uploader formControlName="preEmploymentClearingHouseFilePath"
                               label="Attach Pre-employment Clearinghouse Query" [isRequired]="true"
                               [uploading]="uploadPreEmploymentClearingHouseQueryState.loading()"
                               (fileSelected)="onFileSelectedForUpload($event, UploadDriverApplicationFileRequestType.ClearingHouseQuery, uploadPreEmploymentClearingHouseQueryState)"></app-file-uploader>
            <div class="flex flex-col gap-4">
              <p class="text-body2 text-neutral-800">Please complete full clearinghouse using the provided link
                below</p>
              <a
                href="https://clearinghouse.fmcsa.dot.gov"
                class="text-body2 text-info-500 cursor-pointer underline"
                target="_blank"
                rel="noopener noreferrer">
                https://clearinghouse.fmcsa.dot.gov
              </a>

            </div>
            <div class="grid lg:grid-cols-2 gap-4">
              <app-date-input formControlName="preEmploymentClearingHouseExpiryDate"
                              [label]="'Pre-employment clearinghouse  query expiry date'"
                              class="w-full" [fullWidth]="true"></app-date-input>
            </div>
            <div class="grid lg:grid-cols-2 gap-4">
              <app-date-input formControlName="preEmploymentClearingHouseCompletedDate"
                              [label]="'Full clearinghouse query completed date'"
                              class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
              </app-date-input>

              <app-text-input formControlName="preEmploymentClearingHouseUploadFrequency"
                              [label]="'Clearinghouse query upload frequency'" placeholder="Ex: Every 90 days"
                              class="w-full" [fullWidth]="true"></app-text-input>

            </div>
          </div>


          <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
            <p class="text-body1 font-normal text-info-500">Drug and Alcohol Policy Details</p>
            <app-file-uploader formControlName="drugAndAlcoholPolicyFilePath" label="Drug & Alcohol policy"
                               [isRequired]="true" [uploading]="uploadDrugAlcoholPolicyState.loading()"
                               (fileSelected)="onFileSelectedForUpload($event, UploadDriverApplicationFileRequestType.DrugAlcoholPolicy, uploadDrugAlcoholPolicyState)"></app-file-uploader>
            <div class="grid lg:grid-cols-2 gap-4">
              <app-date-input formControlName="drugAndAlcoholPolicyDate"
                              [label]="'Drug & Alcohol policy date'"
                              class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
              </app-date-input>
            </div>

          </div>

          <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
            <p class="text-body1 font-normal text-info-500">Drug and Alcohol Test Details</p>

            <app-file-uploader formControlName="drugTestResultFilePath" label="Attach Drug Test Result"
                               [isRequired]="true" [uploading]="uploadDrugTestResultState.loading()"
                               (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.PreEmploymentDrugTestResult, uploadDrugTestResultState)"></app-file-uploader>

            <div class="grid lg:grid-cols-2 gap-6">
              <app-date-input formControlName="drugTestResultCompletedDate"
                              [label]="'Drug test completed date'"
                              class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
              </app-date-input>
            </div>

            <app-file-uploader formControlName="drugTestCCFFilePath" label="Attach Drug Test CCF (Custody Control Form)"
                               [isRequired]="true" [uploading]="uploadDrugTestCCFState.loading()"
                               (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.PreEmploymentDrugTestCCF, uploadDrugTestCCFState)"></app-file-uploader>
            <div class="grid lg:grid-cols-2 gap-4">
              <app-date-input formControlName="drugTestCCFCompletedDate"
                              [label]="'Drug test CCF completed date'"
                              class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
              </app-date-input>
            </div>

            <div class="grid lg:grid-cols-2 gap-4">

              <app-date-input formControlName="mroVerificationDate" [label]="'MRO Verification Date'"
                              class="w-full" [fullWidth]="true">
              </app-date-input>
            </div>

            <div class="flex flex-col py-4 gap-3">
              <app-base-input [title]="'Please provide the drug test result'">
                <div input class="flex gap-3">
                  <app-radio-button formControlName="isDrugTestResultPositive" groupName="isDrugTestResultPositive"
                                    title="Positive"
                                    [value]="true"></app-radio-button>
                  <app-radio-button formControlName="isDrugTestResultPositive" groupName="isDrugTestResultPositive"
                                    title="Negative"
                                    [value]="false"></app-radio-button>
                </div>
              </app-base-input>
            </div>

            <app-base-input [title]="'Do you want to start the SAP program for this applicant?'">
              <div input class="flex gap-3">
                <app-radio-button formControlName="hasStartedSAPProgram" groupName="hasStartedSAPProgram" title="Yes"
                                  [value]="true"></app-radio-button>

                <app-radio-button formControlName="hasStartedSAPProgram" groupName="hasStartedSAPProgram" title="No"
                                  [value]="false"></app-radio-button>
              </div>
            </app-base-input>

            @if (driverHiringForm.get('hasStartedSAPProgram')?.value) {
              <div class="grid lg:grid-cols-2 gap-3 py-2">
                <app-text-input formControlName="sapTestDuration"
                                [label]="'SAP test duration'" placeholder="Ex: 365"
                                class="w-full" [fullWidth]="true"></app-text-input>
                <app-text-input formControlName="sapTestFrequency"
                                [label]="'SAP test frequency'" placeholder="Ex: 30"
                                class="w-full" [fullWidth]="true"></app-text-input>
              </div>
            }

          </div>
          <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
            <p class="text-body1 font-normal text-info-500">Other Details</p>
            @if (driverHiringForm.get('hasStartedSAPProgram')?.value ||
            driverHiringForm.get('isDrugTestResultPositive')?.value) {
              <div class="flex flex-col py-3 gap-6">
                <p class="text-body2 text-neutral-800">Please upload the "Return to Duty" if the applicant was already
                  in
                  SAP Program</p>
                <app-file-uploader formControlName="returnToDutyFilePath"
                                   label="Return to Duty Document" [uploading]="uploadReturnToDutyState.loading()"
                                   (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.ReturnToDuty, uploadReturnToDutyState)">
                </app-file-uploader>
              </div>
            }
            <div class="flex flex-col py-3 gap-6">
              <app-file-uploader formControlName="dmvPrintOutOrMVRFilePath"
                                 label="DMV Printout / Motor Vehicle Record (MVR) "
                                 [isRequired]="true" [uploading]="uploadDmvPrintOutOrMVRState.loading()"
                                 (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.DMVPrintOutOrMVR, uploadDmvPrintOutOrMVRState)"></app-file-uploader>
              <app-file-uploader formControlName="medicalExaminerCertificateFilePath"
                                 label="Medical Examiner Certificate"
                                 [isRequired]="true" [uploading]="uploadMedicalExaminerCertificateState.loading()"
                                 (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.DOTMedicalCertificate, uploadMedicalExaminerCertificateState)"
              ></app-file-uploader>
              <div class="grid lg:grid-cols-2 gap-4">
                <app-date-input formControlName="medicalExaminerCertificateExpiryDate"
                                [label]="'Medical examiner certificate expiration date'"
                                class="w-full" [fullWidth]="true">
                </app-date-input>
              </div>
              <p class="text-body2 text-neutral-800">Please verify the medical examiner's listed on the National
                Registry</p>
              <a
                href="https://nationalregistry.fmcsa.dot.gov/search-medical-examiners"
                class="text-body2 text-info-500 cursor-pointer underline"
                target="_blank"
                rel="noopener noreferrer">
                https://nationalregistry.fmcsa.dot.gov/search-medical-examiners
              </a>


              <app-file-uploader formControlName="medicalExaminerNationalRegistryScreenshotFilePath"
                                 label="Screenshot of National Registry Reference"
                                 [uploading]="uploadScreenshotOfNationalRegistryReferenceState.loading()"
                                 (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.ScreenshotOfNationalRegistryReference, uploadScreenshotOfNationalRegistryReferenceState)">
              </app-file-uploader>
            </div>
          </div>

          <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
            <p class="text-body1 font-normal text-info-500">Proficiency / Road Test Details</p>
            <app-file-uploader formControlName="proficiencyTestResultFilePath"
                               label="Proficiency Test Result (Road test)"
                               [isRequired]="true" [uploading]="uploadProficiencyTestResultState.loading()"
                               (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.ProficiencyTestResult, uploadProficiencyTestResultState)"></app-file-uploader>
            <!--            <p class="mx-auto items-center text-body2">OR</p>-->
            <!--            <div-->
            <!--              class="p-2 flex justify-center items-center w-full border rounded-lg  border-success-500 cursor-pointer"-->
            <!--              (click)="onStartProficiencyTest()">-->
            <!--              <app-svg-icon src="assets/icons/menu-right.svg" class="text-success-500" [size]="32"></app-svg-icon>-->
            <!--              <a class="text-success-500 text-body1">Start Proficiency Test (Road Test)</a>-->
            <!--            </div>-->
          </div>


          @if (companyInfoState.response()?.data?.stateId === 'CALIFORNIA') {

            <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
              <p class="text-body1 font-normal text-info-500">California company related document</p>
              <app-file-uploader formControlName="employerPullNoticeFilePath"
                                 label="Employer Pull Notice (EPN)"
                                 [uploading]="uploadEmployerPullNoticeState.loading()"
                                 (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.EmployerPullNotice, uploadEmployerPullNoticeState)"></app-file-uploader>

            </div>
          }

          @if(companyInfo?.businessTypeId == 'BUS') {
            <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
              <p class="text-body1 font-normal text-info-500">School Bus Related Details</p>
              <p class="text-body1 text-primary-600">Required for SPAB (or) School Bus Company</p>

              <app-base-input [title]="'Does the driver has a SPAB Certificate?'">
                <div input class="flex py-2 gap-3">
                  <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="Yes"
                                    [value]="true"></app-radio-button>
                  <app-radio-button formControlName="hasSPABCertificate" groupName="hasSPABCertificate" title="No"
                                    [value]="false"></app-radio-button>
                </div>
              </app-base-input>

              @if (driverHiringForm.get('hasSPABCertificate')?.value ?? false) {

                <app-file-uploader formControlName="spabCertificateFilePath" label="SPAB Certificate"
                                   [isRequired]="true" [uploading]="uploadSpabCertificateState.loading()"
                                   (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.SPABCertificate, uploadSpabCertificateState)"></app-file-uploader>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-date-input formControlName="spabCertificateIssuedDate"
                                  [label]="'SPAB certificate issued date'"
                                  class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
                  </app-date-input>
                </div>
                <app-file-uploader formControlName="t01CertificateFilePath"
                                   label="T-01 Certification (Optional)" [uploading]="uploadT01CertificateState.loading()"
                                   (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.T01Certificate, uploadT01CertificateState)"></app-file-uploader>

                <div class="grid lg:grid-cols-2 gap-4">
                  <app-date-input formControlName="t01CertificateIssuedDate"
                                  [label]="'T-01 certificate issued date'"
                                  class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
                  </app-date-input>
                </div>
                <app-file-uploader formControlName="t02CertificateFilePath"
                                   label="T-02 Certification (Optional)" [uploading]="uploadT02CertificateState.loading()"
                                   (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.T02Certificate, uploadT02CertificateState)"></app-file-uploader>

                <div class="grid lg:grid-cols-2 gap-4">
                  <app-date-input formControlName="t02CertificateIssuedDate"
                                  [label]="'T-02 certificate issued date'"
                                  class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
                  </app-date-input>
                </div>
              }
            </div>
          }

          @if (companyInfo?.transportsHazmat || companyInfo?.transportsHazwaste) {
            <div class="flex flex-col gap-4 bg-white rounded-lg px-6 py-6">
              <p class="text-body1 font-normal text-info-500">HAZMAT Related Details</p>
              <div class="flex flex-col py-2 gap-3">
                <app-base-input [title]="'Would this driver transport HAZMAT material?'">
                  <div input class="flex gap-3">
                    <app-radio-button formControlName="driverTransportsHazmat" groupName="hazMat" title="Yes"
                                      [value]="true"></app-radio-button>
                    <app-radio-button formControlName="driverTransportsHazmat" groupName="hazMat" title="No"
                                      [value]="false"></app-radio-button>
                  </div>
                </app-base-input>
              </div>
              @if (driverHiringForm.get('driverTransportsHazmat')?.value ?? false) {
                <div class="py-4">
                  <app-file-uploader formControlName="hazmatCertificateFilePath" label="HAZMAT Certificate"
                                     [isRequired]="true" [uploading]="uploadHAZMATCertificateState.loading()"
                                     (fileSelected)="onFileSelectedForUpload($event,UploadDriverApplicationFileRequestType.HAZMATCertificate, uploadHAZMATCertificateState)"></app-file-uploader>
                </div>
                <div class="grid lg:grid-cols-2 gap-4">
                  <app-date-input formControlName="hazmatCertificateIssuedDate"
                                  [label]="'HAZMAT certificate issued date'"
                                  class="w-full" [fullWidth]="true" [allowOnlyPast]="true">
                  </app-date-input>

                  <app-date-input formControlName="hazmatCertificateExpiryDate"
                                  [label]="'HAZMAT expiration date'"
                                  class="w-full" [fullWidth]="true">
                  </app-date-input>
                </div>
              }
            </div>
          }

        </div>
      </form>
    </div>

    <div class="flex flex-col gap-3 justify-end bg-white rounded-lg px-4 py-4 mt-5">

      <form [formGroup]="driverHiringForm" class="flex items-center gap-3 justify-between">
        <app-checkbox formControlName="confirmationOfRequirements"
                      title="I confirm the applicant’s details are verified, and they meet the requirements."></app-checkbox>
        <div class="flex items-center gap-4">
          <app-button [fullWidth]="true" [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'"
                      appearance="outline"
                      (click)="onSaveChangesClicked(false)"
                      [loading]="saveDriverHiringState.loading()">SAVE CHANGES
          </app-button>
          <app-button [fullWidth]="true" type="submit" [loading]="hireDriverState.loading()"
                      (click)="onSaveChangesClicked(true)">HIRE APPLICANT
          </app-button>
        </div>
      </form>

    </div>
  </div>
</div>


