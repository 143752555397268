import {Component, inject, input, OnInit} from '@angular/core';
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {RadioButtonComponent} from "../../../../shared/inputs/radio-button/radio-button.component";
import {BaseComponent} from "../../../../shared/base/base-component";
import {DropdownComponent} from "../../../../shared/inputs/dropdown/dropdown.component";
import {LicenseClassesResponse} from "../../../../core/services/common/license-classes/license-classes-response";
import {State} from "../../../../shared/base/base-state";
import {CommonService} from "../../../../core/services/common/common.service";
import {CheckboxComponent} from "../../../../shared/inputs/checkbox/checkbox.component";
import type {
  GetCountryListItem,
  GetCountryListResponse,
  GetDriverLicenseParams,
  GetDriverLicenseResponse, GetStateListItem, GetStateListResponse,
  SaveDriverLicense,
  SaveDriverLicenseRequest,
  SaveDriverLicenseResponse
} from "../../../../core/api/model";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {ApiService} from "../../../../core/api/api.service";
import {LookupService} from "../../../../core/services/lookup/lookup.service";
import {DateUtils} from "../../../../shared/utils/date-utils";
import {
  InputDateFormat,
  OutlineDateInputComponent
} from "../../../../shared/inputs/outline-date-input/outline-date-input.component";

@Component({
  selector: 'app-driver-license-info',
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    OutlineTextInputComponent,
    ReactiveFormsModule,
    DropdownComponent,
    CheckboxComponent,
    OutlineDateInputComponent
  ],
  templateUrl: './driver-license-info.component.html',
  styleUrl: './driver-license-info.component.scss'
})
export class DriverLicenseInfoComponent extends BaseComponent implements OnInit {

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  lookupService = inject(LookupService);
  commonService = inject(CommonService);
  tokenService = inject(TokenStoreService);

  licenseClassesState = new State<LicenseClassesResponse>();
  countryListState = new State<GetCountryListResponse>();
  stateListState = new State<GetStateListResponse>();
  updateLicenseInfoState = new State<SaveDriverLicenseResponse>();
  driverLicenseInfoState = new State<GetDriverLicenseResponse>();

  driverLicenseInfoForm!: FormGroup;

  filteredStates: GetStateListItem[] = [];

  driverId = input<string>();

  async ngOnInit() {
    this.initForm();
    this.getLicenseClasses();
    this.getCountry();
  }

  private initForm() {
    this.driverLicenseInfoForm = this.formBuilder.group({
      id: [""],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      licenseNo: ["", Validators.required],
      class: ["", Validators.required],
      countryId: ["", Validators.required],
      stateId: ["", Validators.required],
      issuedDate: ["", Validators.required],
      expirationDate: ["", Validators.required],
      hasPlacardedHazmat: [false],
      hasTankVehicles: [false],
      hasPassengers: [false],
      hasDoubleOrTripleTrailers: [false],
      hasSchoolBus: [false],
      hasPlacardedHazmatAndTankVehicles: [false],
    });
  }

  private getLicenseClasses() {
    this.executeRequest<LicenseClassesResponse>({
      state: this.licenseClassesState,
      request: this.commonService.getLicenseClasses(),
    });
  }

  getCountry() {
    this.executeRequest<GetCountryListResponse>({
      state: this.countryListState,
      request: this.apiService.getCountryList(),
      onSuccess: response => {
        this.getStateList();
      }
    });
  }

  getStateList() {
    this.executeRequest<GetStateListResponse>({
      state: this.stateListState,
      request: this.apiService.getStateList(),
      onSuccess: response => {
        this.getDriverLicense();
      }
    });
  }

  onCountrySelected(selectedItem: GetCountryListItem | null) {
    const stateList = this.stateListState.response()?.data ?? [];
    this.filteredStates = stateList.filter(
      (data) => data.countryId == selectedItem?.id
    );
  }

  getDriverLicense() {
    let request: GetDriverLicenseParams = {
      driverId: this.driverId() ?? '',
    }
    this.executeRequest({
      state: this.driverLicenseInfoState,
      request: this.apiService.getDriverLicense(request),
      onSuccess: (response) => {
        let dateUtils = new DateUtils();

        let licenseInfo = response.data;

        let countryList = this.countryListState.response()?.data ?? [];
        let country = countryList?.find((value) => value.id === licenseInfo.country);

        if (country != null) {
          this.onCountrySelected(country);
        }

        console.log(response.data);
        let stateList = this.stateListState.response()?.data ?? [];
        console.log(stateList);
        let state = stateList?.find(value => value.id === licenseInfo.state);

        this.filteredStates = stateList.filter(x => x.countryId === licenseInfo.country);

        let issueDate = dateUtils.convertToMMDDYYYY(licenseInfo.issueDate);
        let expiryDate = dateUtils.convertToMMDDYYYY(licenseInfo.expirationDate);

        console.log(response);

        this.driverLicenseInfoForm.controls["class"].setValue(licenseInfo.class);
        this.driverLicenseInfoForm.controls["expirationDate"].setValue(expiryDate);
        this.driverLicenseInfoForm.controls["firstName"].setValue(licenseInfo.firstName);
        this.driverLicenseInfoForm.controls["lastName"].setValue(licenseInfo.lastName);
        this.driverLicenseInfoForm.controls["hasDoubleOrTripleTrailers"].setValue(licenseInfo.hasDoubleOrTripleTrailers ?? false);
        this.driverLicenseInfoForm.controls["hasPassengers"].setValue(licenseInfo.hasPassengers ?? false);
        this.driverLicenseInfoForm.controls["hasPlacardedHazmat"].setValue(licenseInfo.hasPlacardedHazmat ?? false);
        this.driverLicenseInfoForm.controls["hasSchoolBus"].setValue(licenseInfo.hasSchoolBus ?? false);
        this.driverLicenseInfoForm.controls["hasTankVehicles"].setValue(licenseInfo.hasTankVehicles ?? false);
        this.driverLicenseInfoForm.controls["issuedDate"].setValue(issueDate);
        this.driverLicenseInfoForm.controls["licenseNo"].setValue(licenseInfo.licenseNo);
        this.driverLicenseInfoForm.controls["stateId"].setValue(licenseInfo.state);
        this.driverLicenseInfoForm.controls["countryId"].setValue(licenseInfo.country);
      }
    });
  }

  onUpdateClicked() {
    if (this.driverLicenseInfoForm.invalid) {
      return;
    }
    let dateUtils = new DateUtils();
    let formValue = this.driverLicenseInfoForm.value;
    let license: SaveDriverLicense = {
      id: this.driverLicenseInfoState.response()?.data.id,
      class: formValue.class,
      country: formValue.countryId,
      expirationDate: dateUtils.convertToDateTimeString(formValue.expirationDate) ?? '',
      firstName: formValue.firstName,
      hasDoubleOrTripleTrailers: formValue.hasTankVehicles,
      hasPassengers: formValue.hasPassengers,
      hasPlacardedHazmat: formValue.hasPlacardedHazmat,
      hasPlacardedHazmatAndTankVehicles: formValue.hasPlacardedHazmatAndTankVehicles,
      hasSchoolBus: formValue.hasSchoolBus,
      hasTankVehicles: formValue.hasTankVehicles,
      issueDate: dateUtils.convertToDateTimeString(formValue.issuedDate) ?? '',
      lastName: formValue.lastName,
      licenseNo: formValue.licenseNo,
      state: formValue.stateId,
    }
    let request: SaveDriverLicenseRequest =
      {
        driverId: this.driverId() ?? '',
        license: license
      }
    this.executeRequest({
      state: this.updateLicenseInfoState,
      request: this.apiService.saveDriverLicense(request),
      handleSuccess: true,
      handleError: true
    });
  }

  protected readonly InputDateFormat = InputDateFormat;
}
