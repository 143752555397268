<app-base-overlay [title]="'Choose a Terminal'">
  <div body>
    <div class="flex flex-col">
      <app-listview
        [data]="data"
        [state]="companyUserTerminalsState"
        [component]="RowTransferTerminalSelectionComponent"
        [enableSearch]="true"
        (onActionPerformed)="this.onListAction($event)"
        (onStateChanged)="getCompanyUserTerminals($event)">
      </app-listview>
    </div>
  </div>
</app-base-overlay>
