<app-base-overlay [title]="getTitle()">
  <div body>
    <form [formGroup]="changeDriverApplicationMessageForm">
      <div class="flex flex-col gap-4">
        <span class="text-body2">Please enter a reason to explain this action.</span>
        <app-text-area-input formControlName="message" viewType="text-area"
                             placeholder="Reason" label="Reason"></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [loading]="rejectApplicationState.loading()" [type]="'submit'"
                  (click)="onSubmitClicked()">
        {{ getActionText() }}
      </app-button>
    </div>
  </div>
</app-base-overlay>

