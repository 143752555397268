import {Component, inject} from '@angular/core';
import {BaseComponent} from "@shared/base/base-component";
import {ApiService} from '@core/api/api.service';
import {
  GetActiveTerminal,
  type GetCompanyUserTerminalListItem,
  type GetCompanyUserTerminalListParams,
  GetCompanyUserTerminalListResponse,
} from "@core/api/model";
import {ReactiveFormsModule} from "@angular/forms";
import {ListviewComponent} from "@shared/new/listview/listview.component";
import {PaginationState} from "@shared/base/base-pagination-state";
import {RowTerminalSelectionComponent} from "./row-terminal-selection/row-terminal-selection.component";
import {TableActionEvent, TableStateEvent} from "@shared/components/data-table/data-table.component";
import {BaseOverlayComponent} from "@shared/components/overlay/base-overlay/base-overlay.component";
import {DialogRef} from "@angular/cdk/dialog";
import {ButtonComponent} from "@shared/components/button/button.component";
import {AppDataService} from "@core/services/app-data/app-data.service";
import {TerminalsService} from "@core/services/terminals/terminals.service";


@Component({
  selector: 'app-terminal-overlay-selection',
  imports: [
    ReactiveFormsModule,
    ListviewComponent,
    BaseOverlayComponent,
    ButtonComponent
  ],
  templateUrl: './terminal-selection-overlay.component.html',
  standalone: true,
  styleUrl: './terminal-selection-overlay.component.scss'
})
export class TerminalSelectionOverlayComponent extends BaseComponent {

  terminalsService = inject(TerminalsService);
  apiService = inject(ApiService);
  dialogRef = inject(DialogRef);
  appDataService = inject(AppDataService);

  companyUserTerminalsState = new PaginationState<GetCompanyUserTerminalListResponse>();

  getCompanyUserTerminals($event: TableStateEvent) {
    let request: GetCompanyUserTerminalListParams = {
      pageNumber: $event.paginationEvent?.pageNumber,
      pageSize: $event.paginationEvent?.pageSize,
      search: $event.searchText
    };
    this.executeRequest<GetCompanyUserTerminalListResponse>({
      state: this.companyUserTerminalsState,
      request: this.apiService.getCompanyUserTerminalList(request),
    });
  }

  onClearAllClicked() {
    this.appDataService.saveSelectedTerminal(null);
    this.dialogRef.close(true);
    this.terminalsService.onTerminalSelected(null);
  }

  onListAction($event: TableActionEvent) {
    let terminal = $event.data as GetCompanyUserTerminalListItem;
    if ($event.actionKey === 'selected') {
      this.dialogRef.close(true);
    }
  }

  protected readonly RowTerminalSelectionComponent = RowTerminalSelectionComponent;
}
