import {Component, inject, input, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {SidebarMenuComponent,} from "../sidebar/sidebar-menu/sidebar-menu.component";
import {RouterLink} from "@angular/router";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {TokenStoreService} from "../../../../core/services/token/token-store.service";
import {LayoutService} from "../../layout.service";
import {BaseComponent} from "../../../../shared/base/base-component";
import {ApiService} from "../../../../core/api/api.service";
import {State} from "../../../../shared/base/base-state";
import type {GetUserResponse} from "../../../../core/api/model";

@Component({
  selector: "app-top-bar",
  standalone: true,
  imports: [
    AppSvgIconComponent,
    CommonModule,
    SidebarMenuComponent,
    RouterLink,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
  ],
  templateUrl: "./top-bar.component.html",
  styleUrl: "./top-bar.component.scss",
})
export class TopBarComponent extends BaseComponent implements OnInit {
  tokenService = inject(TokenStoreService);
  layoutService = inject(LayoutService);
  apiService = inject(ApiService);

  userInfoState = new State<GetUserResponse>();

  currentMenu = this.layoutService.currentMenu;

  ngOnInit() {
    this.getUserInfo();
  }

  onLogoutClick() {
    this.tokenService.logout();
  }

  getUserInfo() {
    this.executeRequest({
      state: this.userInfoState,
      request: this.apiService.getUserInfo(),
      onSuccess: (response) => {
        const userData = response.data;
        localStorage.setItem('user', JSON.stringify(userData));
      }
    });
  }

   getUserName() {
     return `${this.userInfoState.response()?.data?.firstName ?? ''} ${this.userInfoState.response()?.data?.lastName ?? ''}`;
  }
}
