<div class="flex flex-col h-dvh bg-white">
  <div class="flex items-center gap-5 p-4 justify-between bg-white">
    <div class="flex items-center gap-4">
      @if (userRole != UserRole.driverApplicant) {
        <div (click)="onBackClicked()"
             class="p-2 cursor-pointer rounded-full bg-white hover:shadow-2 transition-all duration-500">
          <app-svg-icon src="assets/icons/arrow-left.svg"></app-svg-icon>
        </div>
      }
      <p class="text-body1 font-normal hidden md:inline">Employment Application</p>
    </div>

    <div class="flex items-center gap-5">
      @let status = driverApplicationState.response()?.data?.applicationStatus;
      @if (status == GetDriverApplicationListItemApplicationStatusEnum.DriverVerificationPending && userRole == UserRole.driverApplicant) {
        <div class="flex items-center gap-2">
          <span
            (click)="onReSignClicked()"
            class="px-4 py-1.5 border border-error-500 bg-error-50 whitespace-nowrap rounded-md text-caption text-error-500 cursor-pointer">
            Click to Re-sign
          </span>
          <app-svg-icon (click)="onDriverVerificationNeededInfoClicked()" src="assets/icons/info.svg"
                        class="text-neutral-400 cursor-pointer" [size]="22"></app-svg-icon>
        </div>
      }

      <app-button [loading]="driverApplicationState.loading() && completedApplicationClicked()"
                  (click)="onCompleteApplicationClicked()">
        @if (userRole == UserRole.driverApplicant) {
          Save & Complete Application
        } @else {
          Review & Hire Driver
        }
      </app-button>

    </div>

  </div>

  <mat-tab-group dynamicHeight (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="activeStepIndex">
    @for (step of steps; track step; let index = $index) {
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="flex items-center gap-3">
            <p class="text-body2 text-neutral-900">{{ index + 1 }}. {{ step.title }}</p>

            <app-svg-icon [size]="20"
                          [src]="step.completed() ? 'assets/icons/check-circle-outline.svg' : 'assets/icons/alert-circle-outline.svg'"
                          class="pointer-events-none"
                          [ngClass]="step.completed() ? 'text-success-500' : 'text-neutral-400'">
            </app-svg-icon>
          </div>
        </ng-template>
      </mat-tab>
    }
  </mat-tab-group>

  <div class="grow bg-neutral-100 overflow-hidden">
    @switch (activeStep().type) {
      @case ('general') {
        <app-save-applicant-general-info (onNextClick)="onNextClicked()"></app-save-applicant-general-info>
      }
      @case ('residency') {
        <app-save-applicant-residency-info (onNextClick)="onNextClicked()"
                                           (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-residency-info>
      }
      @case ('license') {
        <app-applicant-license-info (onNextClick)="onNextClicked()"
                                    (onPreviousClick)="onPreviousClicked()">
        </app-applicant-license-info>
      }
      @case ('driving-experience') {
        <app-save-driving-experience-info (onNextClick)="onNextClicked()"
                                          (onPreviousClick)="onPreviousClicked()">
        </app-save-driving-experience-info>
      }
      @case ('accidents') {
        <app-save-applicant-accident-info (onNextClick)="onNextClicked()"
                                          (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-accident-info>
      }
      @case ('traffic-convictions') {
        <app-save-applicant-traffic-convictions
          (onNextClick)="onNextClicked()"
          (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-traffic-convictions>
      }
      @case ('employments') {
        <app-save-applicant-employments-info (onNextClick)="onNextClicked()"
                                             (onPreviousClick)="onPreviousClicked()">
        </app-save-applicant-employments-info>
      }
      @case ('education') {
        <app-save-applicant-education-info (onPreviousClick)="onPreviousClicked()" (onNextClick)="onNextClicked()">
        </app-save-applicant-education-info>
      }
    }
  </div>
</div>



