<div class="h-full flex flex-col gap-4 items-center justify-center">
  <app-context-menu-button [actions]="actions" [positionPreference]="'center'"></app-context-menu-button>
  <app-document-view></app-document-view>

  <form class="flex flex-col items-center gap-4" [formGroup]="nameForm">


    <app-button (click)="onSubmit()">Submit</app-button>
  </form>

</div>

