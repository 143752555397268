import {Component, OnInit, inject, signal} from "@angular/core";
import {TooltipDirective} from "../../../../shared/components/tooltip/tooltip.directive";
import {AppSvgIconComponent} from "../../../../shared/components/app-svg-icon/app-svg-icon.component";
import {ButtonComponent} from "../../../../shared/components/button/button.component";
import {OutlineTextInputComponent} from "../../../../shared/inputs/outline-text-input/outline-text-input.component";
import {State} from "../../../../shared/base/base-state";
import {BaseComponent} from "../../../../shared/base/base-component";
import {CommonModule} from "@angular/common";
import {
  MatTab,
  MatTabChangeEvent,
  MatTabGroup,
  MatTabLabel,
} from "@angular/material/tabs";
import {MatMenuModule} from "@angular/material/menu";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {ReactiveFormsModule} from "@angular/forms";
import {SendDriverApplicationComponent} from "../../overlays/send-driver-application/send-driver-application.component";
import {Constants} from "../../../../core/constants/constants";
import {BaseTableComponent} from "../../../../shared/components/_base/base-table/base-table.component";
import {ContextMenuIconComponent} from "../../../../shared/components/context-menu-icon/context-menu-icon.component";
import {DriverApplicationService} from "../../../../core/services/driver-application/driver-application.service";
import type {
  GetDriverListItem,
  GetDriverListParams,
  GetDriverListResponse
} from "../../../../core/api/model";
import {DriversListResponse} from "../../../../core/services/driver/drivers/drivers-list-response";
import {SortableTableDirective} from "../../../../shared/components/_base/base-table/sortable-table.directive";
import {
  TableResizableColumnsDirective
} from "../../../../shared/components/_base/base-table/table-resizable-columns.directive";
import {ApiService} from "../../../../core/api/api.service";

@Component({
  selector: "app-drivers-list",
  standalone: true,
  imports: [
    TooltipDirective,
    AppSvgIconComponent,
    ButtonComponent,
    OutlineTextInputComponent,
    CommonModule,
    MatTabGroup,
    MatTab,
    ReactiveFormsModule,
    MatTabLabel,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    SendDriverApplicationComponent,
    BaseTableComponent,
    ContextMenuIconComponent,
    SortableTableDirective,
    TableResizableColumnsDirective,
  ],
  templateUrl: "./drivers-list.component.html",
  styleUrl: "./drivers-list.component.scss",
})
export class DriversListComponent extends BaseComponent implements OnInit {

  dialog = inject(MatDialog);
  apiService = inject(ApiService);

  driverListState = new State<GetDriverListResponse>();


  ngOnInit(): void {
    this.getDriverList(1, 10);
  }

  onSendDriverApplicationClicked() {
    this.dialog.open(SendDriverApplicationComponent, {
      ...Constants.defaultDialogConfig,
    });
  }

  getDriverList(pageNumber: number, pageSize: number) {
    const request: GetDriverListParams = {
      pageNumber: pageNumber,
      pageSize: pageSize,
    };
    this.executeRequest<GetDriverListResponse>({
      state: this.driverListState,
      request: this.apiService.getDriverList(request),
    });
  }

  onViewDriverClicked(item: GetDriverListItem) {
    this.router.navigate(['drivers', item.id]).then();
  }
}
