<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-2xl">
    <div class="flex p-4 items-center justify-between w-full border-b border-neutral-100">
      <p class="text-body1 text-info-500">SAP Program Result</p>
      <app-button class="px-2"
                  appearance="textType"
                  textButtonColor="text-neutral-600" [iconColor]="'text-neutral-500'"
                  [iconSrc]="'assets/icons/arrow-left.svg'" (buttonClick)="onBackClicked()">Back
      </app-button>
    </div>

    <div class="flex flex-col h-full">
      <div class="flex justify-between items-center m-4 border-b border-neutral-100">
        <table class="w-1/2 border-collapse">
          <thead class="text-neutral-500">
          <tr>
            <th class="p-3 text-body2 text-left">Code</th>
            <th class="p-3 text-body2 text-left">Started At</th>
            <th class="p-3 text-body2 text-left">Duration</th>
            <th class="p-3 text-body2 text-left">Frequency</th>
          </tr>
          </thead>
          <tbody>
          <td class="p-3 text-body2 text-nowrap">{{ getSapProgramState.response()?.data?.code ?? '' }}</td>
          <td class="p-3 text-body2 text-nowrap">
            {{ getSapProgramState.response()?.data?.startDate }}
          </td>

          <td class="p-3 text-body2 text-nowrap">{{ getSapProgramState.response()?.data?.duration ?? '' }}</td>
          <td class="p-3 text-body2 text-nowrap">{{ getSapProgramState.response()?.data?.frequency ?? '' }}</td>
          </tbody>
        </table>
      </div>
      <div class="grow flex flex-col overflow-auto">
        <app-data-table
          class="w-full flex-grow"
          [state]="getSapProgramResultsState"
          [columnDefs]="columnDefs"
          [enableColumnsConfig]="true"
          (tableStateChanged)="getSapProgramResults($event)"
          (onActionPerformed)="onTableActionPerformed($event)">
        </app-data-table>
      </div>
    </div>
  </div>
</div>
