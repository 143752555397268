import {inject, Injectable} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "./alert-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class AlertDialogService {
  private dialog = inject(MatDialog);

  show(title: string, message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '300px',
      data: {
        title: title,
        message: message
      }
    });

    return dialogRef.afterClosed();
  }
}
