<app-base-dialog [title]="'New Reminder'" class="mx-6">
  <div body>
    <form [formGroup]="createReminderForm">
      <div class="flex flex-col gap-4 justify-center items-center my-6 w-full">
        <app-outline-date-input formControlName="reminderDate" [label]="'Reminder Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
        <app-outline-text-input formControlName="reminderTime" [label]="'Reminder Time'"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>
        <div class="w-full max-w-lg">
          <label class="block text-body2 text-neutral-800">Message</label>
          <textarea rows="4"
                    class="block w-full my-2 px-2 py-3  border border-gray-300 rounded-md focus:border-primary-500 focus:ring-primary-500"
                    placeholder="Enter your message" formControlName="reminderMessage"></textarea>
        </div>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'" [fullWidth]="true" (click)="onSaveClicked()"
      >
        Save
      </app-button>
    </div>
  </div>
</app-base-dialog>
