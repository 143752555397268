import {Component, forwardRef, OnInit, Optional, Self} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-date-selector-view',
  standalone: true,
  imports: [
    JsonPipe
  ],
  templateUrl: './date-selector-view.component.html',
  styleUrl: './date-selector-view.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSelectorViewComponent),
      multi: true,
    },
  ],
})
export class DateSelectorViewComponent implements ControlValueAccessor, OnInit{
  value: any;
  formControl: any;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};



  ngOnInit(): void {
    // Access the form control from the parent component
    // this.formControl = this.ngControl?.control;
  }

  // Required by ControlValueAccessor
  writeValue(value: any): void {
    console.log('writeValue', value);
    this.value = value;
  }

  // Required by ControlValueAccessor
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Required by ControlValueAccessor
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Required by ControlValueAccessor
  setDisabledState(isDisabled: boolean): void {
    // Handle disabled state if needed
  }
}
