<div class="app-container !p-0">

  <div class="p-4 bg-white">
    <div class="container mx-auto flex gap-5 justify-between items-center">
      <app-page-header title="Terminals" description="View and manage all Terminals."></app-page-header>

      <div class="flex gap-3 items-center">
        <app-button *hasPermission="permission.createTerminal"
                    [iconSrc]="'assets/icons/plus.svg'"
                    (click)="onNewTerminalClicked()">
          New Terminal
        </app-button>
      </div>
    </div>
  </div>

  <div class="app-page-content grow overflow-y-scroll">
    <app-data-table
      [state]="getCompanyUserTerminalListState"
      [columnDefs]="columnDefs"
      (onActionPerformed)="onActionClicked($event)"
      [enableColumnsConfig]="true"
      (tableStateChanged)="getTerminalList($event)">
    </app-data-table>
  </div>
</div>
