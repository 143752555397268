import {HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {TokenStoreService} from "../../services/token/token-store.service";
import {catchError} from "rxjs/operators";
import {throwError} from "rxjs";
import {environment} from "../../../../environments/environment";

export const httpTokenInterceptor: HttpInterceptorFn = (req, next) => {

  const tokenService = inject(TokenStoreService);

  const token = tokenService.getToken();
  const driverToken = tokenService.getDriverApplicationToken();

  if (req.url.startsWith(environment.baseUrl)) {
    if (token.accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token.accessToken}`,
        },
      });
    } else if (driverToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${driverToken}`,
        },
      });
    }
  }

  return next(req)
    .pipe(catchError((e: HttpErrorResponse) => {
      if (e.status === 401) {
        tokenService.logout();
      }
      // const errorMessages = e?.error?.errorMessages ?? [];
      // const errorMessage = errorMessages.length > 0 ? errorMessages[0] : e.error?.message || e.statusText;
      return throwError(() => e);
    }));
};
