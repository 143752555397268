import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {

  static IS_SIDEBAR_EXPANDED = "is_sidebar_expanded";

  storage: Storage;

  isSidebarExpanded = signal(true);

  constructor() {
    this.storage = localStorage;
    const isSidebarExpanded = this.storage.getItem(AppDataService.IS_SIDEBAR_EXPANDED);
    switch (isSidebarExpanded) {
      case "true":
        this.isSidebarExpanded.update(() => true);
        break;
      case "false":
        this.isSidebarExpanded.update(() => false);
        break;
      default:
        this.isSidebarExpanded.update(() => true);
        break;
    }
  }

  toggleSidebar() {
    this.isSidebarExpanded.update((curr) => !curr);
    this.storage.setItem(AppDataService.IS_SIDEBAR_EXPANDED, this.isSidebarExpanded().toString());
  }
}
