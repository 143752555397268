import { Injectable } from '@angular/core';
import { GetTerminalListItem } from '@core/api/model/getTerminalListItem';

@Injectable({
  providedIn: 'root'
})
export class TerminalsService {

  terminalSelected: (terminal: GetTerminalListItem | null) => void = () => {};

  constructor() { }

  onTerminalSelected(terminal: GetTerminalListItem | null) {
    this.terminalSelected(terminal);
  }
}
