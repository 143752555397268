import {Injectable} from "@angular/core";
import {BaseApiService} from "../../../shared/base/base-api.service";
import {URLs} from "../../api/urls";
import {SaveApplicantGeneralInfoRequest} from "./save-applicant-general-info/save-applicant-general-info-request";
import {SendDriverApplicationResponse} from "../driver/send-driver-application/send-driver-application-response";
import {ApplicantResidenciesRequest} from "./applicant-residency-info/applicant-residencies-request";
import {ApplicantExperienceRequest} from "./applicant-experience-info/applicant-experience-request";
import {SaveApplicantExperienceRequest} from "./save-applicant-experience/save-applicant-experience-request";
import {SaveApplicantLicensesInfoRequest} from "./save-applicant-licenses/save-applicant-licenses-info-request";
import {ApplicantLicensesInfoRequest} from "./applicant-licenses-info/applicant-licenses-info-request";
import {ApplicantExperienceResponse} from "./applicant-experience-info/applicant-experience-response";
import {ApplicantResidenciesResponse} from "./applicant-residency-info/applicant-residencies-response";
import {ApplicantLicensesInfoResponse} from "./applicant-licenses-info/applicant-licenses-info-response";
import {SaveApplicantAccidentsRequest} from "./save-applicant-accidents/save-applicant-accidents-request";
import {DriverApplicationInfoRequest} from "./driver-application-info/driver-application-info-request";
import {ApplicantEmploymentRequest} from "./applicant-employment-info/applicant-employment-request";
import {ApplicantEducationInfoRequest} from "./applicant-education-info/applicant-education-info-request";
import type {
  CompleteDriverApplicationRequest,
  GetDriverApplicationListParams, GetDriverListParams
} from "../../api/model";
import {DriverApplicationResponse} from "./application/driver-application-response";
import {Observable} from "rxjs";
import {
  EquipmentsInspectionSafetyMaintenance,
  EquipmentsInspectionSafetyMaintenanceResponse
} from "../equipments/equipment-inspection-safety-maintenance-response";

@Injectable({
  providedIn: "root",
})
export class DriverApplicationService extends BaseApiService {

  getDriverList(request: GetDriverListParams) {
    return this.get<DriverApplicationResponse>(URLs.getDriverList, request);
  }

  getApplicationGeneralInfo(driverApplicationId: string) {
    return this.get(URLs.getDriverApplicationGeneralInfo(driverApplicationId));
  }

  saveApplicationGeneralInfo(request: SaveApplicantGeneralInfoRequest) {
    return this.put(URLs.saveApplicantGeneralInfo, request);
  }

  getApplicantResidencies(request: ApplicantResidenciesRequest) {
    return this.get<ApplicantResidenciesResponse>(URLs.getApplicantResidencies, request);
  }

  saveApplicantResidencies(request: ApplicantResidenciesRequest) {
    return this.put(URLs.saveApplicantResidencies, request);
  }

  getApplicantLicenses(request: ApplicantLicensesInfoRequest) {
    return this.get<ApplicantLicensesInfoResponse>(URLs.getApplicantLicenses, request);
  }

  saveApplicantLicenses(request: SaveApplicantLicensesInfoRequest) {
    return this.put(URLs.saveApplicantLicenses, request);
  }

  getApplicantExperiences(request: ApplicantExperienceRequest) {
    return this.get<ApplicantExperienceResponse>(URLs.getApplicantExperiences, request
    );
  }

  saveApplicantExperiences(request: SaveApplicantExperienceRequest) {
    return this.put(URLs.saveApplicantExperiences, request);
  }

  getApplicantAccidents(request: ApplicantExperienceRequest) {
    return this.get<ApplicantExperienceResponse>(URLs.getApplicantAccidents, request);
  }

  saveApplicantAccidents(request: SaveApplicantAccidentsRequest) {
    return this.put(URLs.saveApplicantAccidents, request);
  }

  getDriverApplication(request: GetDriverApplicationListParams) {
    return this.get<SendDriverApplicationResponse>(URLs.getDriverApplication, request);
  }

  getDriverData() {
    return this.fromFile("assets/json/mock-data/driver-response.json");
  }

  saveApplicantEmployment(request: ApplicantEmploymentRequest) {
    return this.put(URLs.saveApplicantEmploymentHistory, request);
  }

  saveCompleteApplication(request: CompleteDriverApplicationRequest) {
    return this.put(URLs.saveCompleteApplication, request);
  }

  getCompleteApplicationInfo(driverApplicationId: string) {
    return this.get(URLs.getDriverApplicationGeneralInfo(driverApplicationId));
  }

  getReminders() {
    return this.fromFile('assets/json/mock-data/reminders.json');
  }

  getDocuments() {
    return this.fromFile('assets/json/mock-data/documents.json');
  }

  getEquipmentsList() {
    return this.fromFile('assets/json/mock-data/equipments.json');
  }

  getMaintenance() {
    return this.fromFile('assets/json/mock-data/maintenance.json');
  }

  getEquipmentRepairs() {
    return this.fromFile('assets/json/mock-data/repairs.json');
  }

  getInspections() {
    return this.fromFile('assets/json/mock-data/inspections.json');
  }

  getEquipmentReminders() {
    return this.fromFile('assets/json/mock-data/equipment-reminder.json');
  }

  getInspectionsSafetyMaintenance() {
    return this.fromFile('assets/json/mock-data/equipment-maintenance-safety-inspection.json');
  }
}
