import {Component, OnInit, inject} from "@angular/core";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {State} from "@shared/base/base-state";
import {BaseComponent} from "@shared/base/base-component";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {SignupSuccessComponent} from "./signup-success/signup-success.component";
import {Constants} from "@core/constants/constants";
import {ApiService} from "@core/api/api.service";
import type {GetCompanyTypeListResponse, SignUpResponse} from "@core/api/model";
import {OverlayService} from "@shared/components/overlay/overlay.service";
import {UnsavedAwareDirective} from "@shared/base/unsaved-aware.directive";

@Component({
  selector: "app-sign-up",
  standalone: true,
  templateUrl: "./sign-up.component.html",
  styleUrl: "./sign-up.component.scss",
  imports: [
    TextInputComponent,
    ButtonComponent,
    ReactiveFormsModule,
    DropdownComponent,
    UnsavedAwareDirective
  ],
})
export class SignUpComponent extends BaseComponent implements OnInit {
  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  readonly overlayService = inject(OverlayService);

  signUpForm!: FormGroup;

  companyTypesState = new State<GetCompanyTypeListResponse>();
  signUpState = new State<SignUpResponse>();

  ngOnInit(): void {
    this.initForm();
    this.getCompanyTypes();
  }

  initForm() {
    this.signUpForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: [""],
      companyType: ["", Validators.required],
      phoneNumber: ["", Validators.required],
      email: ["", Validators.email],
      companyName: ["", Validators.required],
      dotNumber: ["", Validators.required],
    });
  }

  getCompanyTypes() {
    this.executeRequest<GetCompanyTypeListResponse>({
      state: this.companyTypesState,
      request: this.apiService.getCompanyTypeList(),
    });
  }

  onLoginClicked() {
    this.router.navigate(["/login"]);
  }

  onSignUpClicked() {
    this.validateForm(this.signUpForm);
    if (this.signUpForm.invalid) {
      return;
    }

    let request = this.signUpForm.value;
    this.executeRequest({
      state: this.signUpState,
      request: this.apiService.signUp(request),
      onSuccess: (response) => {
        this.signUpForm.reset();
        this.showSuccessDialog();
      },
    });
  }

  showSuccessDialog() {
    this.overlayService.openModal(SignupSuccessComponent);
  }

}
