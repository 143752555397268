import {Component, inject, OnInit, output} from "@angular/core";
import {ButtonComponent} from "@shared/components/button/button.component";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import {TextInputComponent} from "@shared/inputs/text-input/text-input.component";
import {RadioButtonComponent} from "@shared/inputs/radio-button/radio-button.component";
import {AppSvgIconComponent} from "@shared/components/app-svg-icon/app-svg-icon.component";
import {BaseComponent} from "@shared/base/base-component";
import {
  GetDriverApplicationEmploymentHistoryItem,
  GetDriverApplicationEmploymentHistoryParams,
  GetDriverApplicationEmploymentHistoryResponse, GetStateListItem, GetStateListResponse,
  type SaveDriverApplicationEmploymentHistoryRequest,
  type SaveDriverEmploymentHistory,
} from "@core/api/model";
import {State} from "@shared/base/base-state";
import {ApiService} from "@core/api/api.service";
import {printInvalidFields} from "@shared/utils/form-utils";
import {DateInputComponent} from "@shared/inputs/date-input/date-input.component";
import {AppErrorComponent} from "@shared/inputs/app-error/app-error.component";
import {onlyPastDateValidator} from "@shared/validators/only-past-date-validator";
import {DropdownComponent} from "@shared/inputs/dropdown/dropdown.component";
import {CountriesResponse, Country, CountryState} from "@core/services/common/countries/countries-response";
import {CommonService} from "@core/services/common/common.service";

@Component({
  selector: "app-save-applicant-employments-info",
  standalone: true,
  imports: [
    ButtonComponent,
    FormsModule,
    TextInputComponent,
    RadioButtonComponent,
    ReactiveFormsModule,
    AppSvgIconComponent,
    DateInputComponent,
    AppErrorComponent,
    DropdownComponent
  ],
  templateUrl: "./save-applicant-employments-info.component.html",
  styleUrl: "./save-applicant-employments-info.component.scss",
})
export class SaveApplicantEmploymentsInfoComponent extends BaseComponent implements OnInit {
  onNextClick = output<void>();
  onPreviousClick = output<void>();

  formBuilder = inject(FormBuilder);
  apiService = inject(ApiService);
  commonService = inject(CommonService);

  employmentsState = new State<GetDriverApplicationEmploymentHistoryResponse>();
  countryListState = new State<CountriesResponse>();

  employmentForm!: FormGroup;
  employmentsFormArray!: FormArray;
  currentOrRecentEmploymentForm!: FormGroup;
  saveEmploymentState = new State<any>();

  statesList: CountryState[][] = [];


  ngOnInit() {
    this.initForm();
    this.getCountries();
    this.getEmployments();
    this.initFormListeners();
  }

  private initForm() {
    this.employmentsFormArray = this.formBuilder.array([]);
    this.employmentForm = this.formBuilder.group({
      shareEmploymentHistory: [true, Validators.required],
      reason: [null],
      employments: this.employmentsFormArray,
    });
  }

  initFormListeners() {
    this.employmentForm.controls["shareEmploymentHistory"].valueChanges.subscribe((value) => {
        this.updateEmployeeFormValidation();
      }
    );
  }

  updateEmployeeFormValidation() {
    let shareEmploymentHistory = this.employmentForm.controls["shareEmploymentHistory"].value;
    if (shareEmploymentHistory) {
      this.employmentForm.controls['employments'].enable();
      this.employmentForm.controls['reason'].setValidators([]);
      this.employmentForm.controls['reason'].updateValueAndValidity();
    } else {
      this.employmentForm.controls['employments'].disable();
      this.employmentForm.controls['reason'].setValidators([Validators.required]);
      this.employmentForm.controls['reason'].updateValueAndValidity();
    }
  }

  getEmployments() {
    let request: GetDriverApplicationEmploymentHistoryParams = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
    };

    this.executeRequest<GetDriverApplicationEmploymentHistoryResponse>({
      state: this.employmentsState,
      request: this.apiService.getDriverApplicationEmploymentHistory(request),
      onSuccess: (response) => {
        let employments = response.data ?? [];
        this.populateForm(employments);

        if (employments.length === 0) {
          this.employmentForm.controls['shareEmploymentHistory'].setValue(true);
          this.employmentForm.controls['reason'].setValue(response.reason);
        }
      },
    });
  }

  getCountries() {
    this.executeRequest<CountriesResponse>({
      state: this.countryListState,
      request: this.commonService.getCountries(),
    });
  }

  onCountrySelected(selectedItem: Country | null, index: number) {
    console.log(index);
    this.employmentsFormArray.controls[index].patchValue({stateId: null});
    let states = selectedItem?.states ?? [];
    const formGroup = this.employmentsFormArray.at(index) as FormGroup;
    formGroup.patchValue({state: null});
    this.statesList[index] = states;
  }

  private populateForm(employments: GetDriverApplicationEmploymentHistoryItem[]) {
    let currentOrMostRecentEmployment =
      employments.find((license) => license?.isCurrentOrMostRecentEmployer) ??
      null;
    let otherEmployers = employments.filter((license) => !license?.isCurrentOrMostRecentEmployer);

    this.currentOrRecentEmploymentForm = this.createApplicantEmployment(
      currentOrMostRecentEmployment,
      true
    );
    this.employmentsFormArray.push(this.currentOrRecentEmploymentForm);

    otherEmployers.forEach((employer) => {
      let newForm = this.createApplicantEmployment(employer, false);
      this.employmentsFormArray.push(newForm);
    });
  }

  createApplicantEmployment(employment: GetDriverApplicationEmploymentHistoryItem | null, isCurrentOrMostRecentEmployment: boolean): FormGroup {

    let jobStartedDate = employment?.jobJoinDate ? new Date(employment?.jobJoinDate) : null;
    let jobEndDate = employment?.jobEndDate ? new Date(employment?.jobEndDate) : null;

    let countries = this.countryListState.response()?.data ?? [];
    let matchedCountry = countries.find((country) => country.id === employment?.countryId);
    if (matchedCountry) {
      this.statesList.push(matchedCountry.states);
    }

    return this.formBuilder.group({
      id: [employment?.id],
      name: [employment?.name, Validators.required],
      phone: [employment?.phone, Validators.required],
      address: [employment?.address, Validators.required],
      countryId: [employment?.countryId, Validators.required],
      stateId: [employment?.stateId, Validators.required],
      positionHeld: [employment?.positionHeld, Validators.required],
      jobJoinDate: [jobStartedDate, [Validators.required, onlyPastDateValidator()]],
      jobEndDate: [jobEndDate, [Validators.required, onlyPastDateValidator()]],
      leavingReason: [employment?.leavingReason, Validators.required],
      salary: [employment?.salary],
      gapsExplain: [employment?.gapsExplain],
      fmcsaCompliance: [employment?.fmcsaCompliance, Validators.required],
      safetySensitiveTesting: [employment?.safetySensitiveTesting, Validators.required],
      isCurrentOrMostRecentEmployer: [isCurrentOrMostRecentEmployment],
    });
  }

  onAddMoreEmploymentsClicked() {
    let newForm = this.createApplicantEmployment(null, false);
    this.employmentsFormArray.push(newForm);
  }

  onDeleteClicked(index: number): void {
    if (this.employmentsFormArray.length > 1)
      this.employmentsFormArray.removeAt(index);
  }

  onSaveAndNextClicked() {
    this.validateForm(this.employmentForm);
    printInvalidFields(this.employmentForm);


    if (this.employmentForm.invalid) {
      return;
    }

    let hasEmployments = this.employmentForm.controls['shareEmploymentHistory'].value !== false;

    let request: SaveDriverApplicationEmploymentHistoryRequest = {
      driverApplicationId: this.tokenService.getDriverApplicationId(),
      employmentHistories: [],
      hasEmployments: hasEmployments,
      reason: this.employmentForm.controls["reason"].value
    };

    let employmentsFormValue: any[] = this.employmentsFormArray.value ?? [];

    if (this.employmentForm.controls['shareEmploymentHistory'].value === false) {
      employmentsFormValue = [];
    }

    employmentsFormValue.forEach((employmentFormValue, index) => {
      let employee: SaveDriverEmploymentHistory = {
        name: employmentFormValue.name,
        displayOrder: index,
        phone: employmentFormValue.phone,
        address: employmentFormValue.address,
        countryId: employmentFormValue.countryId,
        stateId: employmentFormValue.stateId,
        positionHeld: employmentFormValue.positionHeld,
        jobJoinDate: employmentFormValue.jobJoinDate,
        jobEndDate: employmentFormValue.jobEndDate,
        leavingReason: employmentFormValue.leavingReason,
        salary: employmentFormValue.salary,
        gapsExplain: employmentFormValue.gapsExplain,
        fmcsaCompliance: employmentFormValue.fmcsaCompliance,
        safetySensitiveTesting: employmentFormValue.safetySensitiveTesting,
        isCurrentOrMostRecentEmployer: employmentFormValue.isCurrentOrMostRecentEmployer,
      };
      request?.employmentHistories?.push(employee);
    });

    this.executeRequest({
      state: this.saveEmploymentState,
      request: this.apiService.saveDriverApplicationEmploymentHistory(request),
      handleSuccess: true,
      handleError: true,
      showLoader: true,
      onSuccess: (response) => {
        this.onNextClick.emit();
      },
    });
  }


  onPreviousClicked() {
    this.onPreviousClick.emit();
  }
}
