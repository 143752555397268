@let attachments = rowData.attachments;
<!--@let isMultiple = _document()?.isMultiple;-->

<div class="document flex flex-col gap-2 px-3 py-2.5 shadow-1 rounded-lg border border-neutral-100 mb-5">
  <div class="flex items-center gap-5 w-full">

    <div class="flex justify-center items-center rounded-lg h-16 w-16 bg-info-50">
      <app-svg-icon class="text-info-700" [src]="'assets/icons/file.svg'"></app-svg-icon>
    </div>

    <div class="flex flex-col gap-1 grow">

      <div class="flex items-center w-full gap-3">
        <span class="text-body2 font-semibold grow">{{ rowData.documentName }}</span>
        <div class="flex gap-3 items-center">

          @if (true) {
            <span
              class="text-caption text-neutral-500">Uploaded on {{ rowData.uploadedDate | date:'short' }}</span>
          }

          @if (true) {
            <div
              (click)="onDeleteClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-neutral-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-error-500" [size]="18" src="assets/icons/bin.svg"></app-svg-icon>
              <span class="text-body2 text-error-500">Delete</span>
            </div>
          }

        </div>
      </div>

      <div class="flex items-center w-full gap-3">

        <div class="flex items-center gap-3 grow">
          @if (attachments.length <= 0) {
            <span class="text-caption text-neutral-400">No file(s) uploaded</span>
          }

          @if (rowData.completedDate) {
            <div class="flex items-center gap-1 bg-success-50 px-3 py-2 rounded-md">
              <span
                class="text-caption text-success-500"> {{ rowData.completedDateFieldLabel ?? 'Completed on' }} </span>
              <span class="text-caption text-success-500"> : </span>
              <span
                class="text-caption text-success-600"> {{ rowData.completedDate | date:'dd MMM yyyy' }} </span>
            </div>
          }

          @if (rowData.expiryDate) {
            <div class="flex items-center gap-1 bg-error-50 px-3 py-2 rounded-md">
              <span class="text-caption text-error-500"> {{ rowData.expiryDateFieldLabel ?? 'Expires on' }} </span>
              <span class="text-caption text-error-500"> : </span>
              <span
                class="text-caption text-error-600"> {{ rowData.expiryDate | date:'dd MMM yyyy' }} </span>
            </div>
          }

          @if (rowData.issuedDate) {
            <div class="flex items-center gap-1 bg-info-50 px-3 py-2 rounded-md">
              <span class="text-caption text-info-500"> {{ rowData.issuedDateFieldLabel ?? 'Issued date' }} </span>
              <span class="text-caption text-info-500"> : </span>
              <span
                class="text-caption text-info-600"> {{ rowData.issuedDate | date:'dd MMM yyyy' }} </span>
            </div>
          }

          @if (rowData.reviewedDate) {
            <div class="flex items-center gap-1 bg-info-50 px-3 py-2 rounded-md">
              <span
                class="text-caption text-info-500"> {{ rowData.reviewedDateFieldLabel ?? 'Reviewed on' }} </span>
              <span class="text-caption text-info-500"> : </span>
              <span
                class="text-caption text-info-600"> {{ rowData.reviewedDate | date:'dd MMM yyyy' }} </span>
            </div>
          }
        </div>

        <div class="flex items-center gap-3">


          <div
            (click)="onViewClicked()"
            class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
            <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/eye.svg"></app-svg-icon>
            <span class="text-caption text-info-500">View</span>
          </div>


          @if (true) {
            <div
              (click)="onDownloadClicked()"
              class="flex items-center gap-2 px-2 py-2 hover:bg-info-50 cursor-pointer transition-all duration-500 rounded-lg">
              <app-svg-icon class="text-info-500" [size]="18" src="assets/icons/download.svg"></app-svg-icon>
              <span class="text-caption text-info-500">Download</span>
            </div>
          }

        </div>


      </div>

    </div>

  </div>

  @if (rowData.notes != null) {
    <div class="border-b border-neutral-100 w-full"></div>
  }

  @if (rowData.notes != null) {
    <div class="flex items-center gap-1">
      <span class="text-caption text-neutral-400">Notes</span>
      <span class="text-caption text-neutral-400">:</span>
      <span class="text-caption text-neutral-500">{{ rowData.notes }}</span>
    </div>
  }
</div>

