@let terminalInfo = getTerminalInfoState.response()?.data;

<div class="p-8 w-full flex flex-col gap-5 items-center h-screen relative">

  <div class="absolute top-0 inset-x-0 h-1/2 bg-neutral-100 z-0"></div>

  <div class="flex flex-col gap-3 max-w-[800px] z-10">
    <p class="text-h6 text-primary-400 text-center my-6">Apply to Be a Driver
      at {{ terminalInfo?.companyName ?? '' }}</p>
    <p class="text-body2 text-neutral-600 text-center">Join our team of professional drivers
      at {{ terminalInfo?.companyName ?? '' }}.
      We are
      currently hiring experienced and reliable drivers for
      our {{ terminalInfo?.name ?? '' }} in {{ terminalInfo?.city ?? '' }}.
      If you're looking for a stable job with great benefits, apply today!</p>
  </div>

  <form class="bg-white shadow-3 rounded-lg p-8 mt-10 z-10 min-w-[450px] max-w-[600px]"
        [formGroup]="driverApplicationForm">
    <div class="flex flex-col gap-4 justify-center items-center w-full">
      <app-text-input formControlName="firstName" [label]="'First Name'" type="text"
                      class="w-full" placeholder="Ex: John" [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="lastName" [label]="'Last Name'" type="text"
                      class="w-full" placeholder="Ex: Bravo" [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="email" [label]="'Email'" type="text"
                      class="w-full" placeholder="Ex: john.bravo@company.com"
                      [fullWidth]="true"></app-text-input>
      <app-text-input formControlName="phoneNumber" [label]="'Phone Number'" type="text"
                      class="w-full" [placeholder]="Constants.phoneNumberPlaceholder"
                      [fullWidth]="true"></app-text-input>
      <app-button
        class="py-4 w-full"
        [buttonColor]="'bg-primary-600'"
        [fullWidth]="true"
        [loading]="applyForJobState.loading()"
        (click)="onApplyForJobClicked()">Submit Application
      </app-button>
    </div>
  </form>

</div>
