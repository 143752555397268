<div class="flex justify-center container mx-auto h-full w-full p-4">
  <div class="flex flex-col bg-white w-full rounded-md">
    <div class="grow overflow-auto p-8 px-4 overflow-y-scroll no-scrollbar">
      <div class="py-4 space-y-4">
        <p class="text-h6 text-info-500">Education</p>
        <p class="text-caption text-neutral-500">Please provide us your education details</p>
      </div>
      <form [formGroup]="highSchoolForm">
        <div class="flex items-center gap-3 w-full py-4">
          <span class="text-body2">Would you like to share your High-School education details?</span>
          <app-radio-button formControlName="shareHighSchoolDetails" groupName="shareHighSchoolDetails" title="Yes"
                            [value]="true"></app-radio-button>
          <app-radio-button formControlName="shareHighSchoolDetails" groupName="shareHighSchoolDetails" title="No"
                            [value]="false"></app-radio-button>
        </div>
        @if (highSchoolForm.controls['shareHighSchoolDetails'].value) {
        <div class="flex flex-col gap-3 my-4">
          <span class="text-body2 text-info-500">High School</span>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-text-input formControlName="name" [label]="'Name & Location'"
                                    placeholder="Ex: XYZ School, California" class="w-full"
                                    [fullWidth]="true"></app-text-input>
            <app-text-input formControlName="course" [label]="'Course of Study'" placeholder="Ex: Grade 12"
                                    class="w-full" [fullWidth]="true"></app-text-input>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-text-input formControlName="completedYear" type="number" [label]="'Years Completed'"
                                    placeholder="Ex: 2" class="w-full" [fullWidth]="true"></app-text-input>
          </div>

          <div class="flex items-center gap-3 w-full">
            <span class="text-body2">Graduate</span>
            <app-radio-button title="Yes" [value]="true" formControlName="hasGraduated"
                              groupName="hashighGraduated"></app-radio-button>
            <app-radio-button title="No" [value]="false" formControlName="hasGraduated"
                              groupName="hashighGraduated"></app-radio-button>
          </div>
          <app-text-input formControlName="details" [label]="'Details'" class="w-full"
                                  [fullWidth]="true"></app-text-input>
        </div>
        <div class="line"></div>
        }
      </form>
      <form [formGroup]="collegeForm">
        <div class="flex items-center gap-3 w-full py-4">
          <span class="text-body2">Would you like to share your college education details?</span>
          <app-radio-button formControlName="shareCollegeDetails" groupName="shareCollegeDetails" title="Yes"
                            [value]="true"></app-radio-button>
          <app-radio-button formControlName="shareCollegeDetails" groupName="shareCollegeDetails" title="No"
                            [value]="false"></app-radio-button>
        </div>
        @if (collegeForm.controls['shareCollegeDetails'].value === true) {
        <div class="flex flex-col gap-3 my-4">
          <span class="text-body2 text-info-500">College</span>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-text-input formControlName="name" [label]="'Name & Location'"
                                    placeholder="Ex: XYZ School, California" class="w-full"
                                    [fullWidth]="true"></app-text-input>
            <app-text-input formControlName="course" [label]="'Course of Study'" placeholder="Ex: Grade 12"
                                    class="w-full" [fullWidth]="true"></app-text-input>
          </div>
          <div class="grid lg:grid-cols-2 gap-4">
            <app-text-input formControlName="completedYear" type="number" [label]="'Years Completed'"
                                    placeholder="Ex: 2" class="w-full" [fullWidth]="true"></app-text-input>
          </div>

          <div class="flex items-center gap-3 w-full">
            <span class="text-body2">Graduate</span>
            <app-radio-button title="Yes" [value]="true" formControlName="hasGraduated"
                              groupName="hashighGraduated"></app-radio-button>
            <app-radio-button title="No" [value]="false" formControlName="hasGraduated"
                              groupName="hashighGraduated"></app-radio-button>
          </div>
          <app-text-input formControlName="details" [label]="'Details'" class="w-full"
                                  [fullWidth]="true"></app-text-input>
        </div>
        <div class="line"></div>
        }
      </form>
    </div>
    <div class="flex justify-between border-t border-neutral-200 px-4 py-4">
      <app-button appearance="outline" (click)="onPreviousClicked()">Previous</app-button>
      <app-button type="submit" (click)="onSaveClicked()" [loading]="saveEducationState.loading()">
        @if(userRole == UserRole.driverApplicant) {
          Save & Complete Application
        } @else {
          Save & Review
        }
      </app-button>
    </div>
  </div>
</div>
