<nav [ngClass]="layoutService.sidebarExpanded ? 'w-52 xl:w-64' : 'w-[70px]'"
     class="flex flex-col overflow-auto bg-primary-800 h-dvh transition-all duration-300">
  <div class="flex justify-center items-center gap-3 py-2">
    @if (layoutService.sidebarExpanded) {
      <img [height]="34" src="assets/images/branding/YSC-logo-white.svg" alt="Logo"/>
    } @else {
      <img [height]="34" src="assets/images/branding/logo-white.svg" alt="Logo"/>
    }
  </div>
  <div class="border-b border-gray-500 mb-5"></div>

  <div class="flex grow flex-col gap-4 p-4">

    <div (click)="onSelectTerminalClicked()">
      @if (layoutService.sidebarExpanded) {
        <div class="w-full flex gap-4 px-4 py-3 bg-primary-500 rounded-lg cursor-pointer">
          <p class="grow text-caption text-white">{{ appDataService.selectedTerminal()?.name ?? 'All Terminals' }}</p>
          <app-svg-icon class="text-white" src="assets/icons/unfold_more_horizontal.svg" [size]="18"></app-svg-icon>
        </div>
      } @else {
        <div class="w-full flex justify-center gap-4 px-1 py-3 bg-primary-500 rounded-lg cursor-pointer">
          <p class="grow text-caption text-white text-center">{{ getTerminalPrefix() }}</p>
        </div>
      }
    </div>

    @if (!layoutService.sidebarExpanded) {
      <div class="line !bg-primary-500"></div>
    }

    <div class="flex flex-col grow gap-2">

      @if (layoutService.sidebarExpanded) {
        <p class="text-caption text-neutral-300 cursor-pointer mb-2">Main Menu</p>
      }

      @for (menu of menus(); track menu) {
        @if (menu.isEnabled) {
          <app-sidebar-menu [menuItem]="menu" (click)="onMenuClick(menu)">{{ menu.label }}</app-sidebar-menu>
        }
      }
    </div>

<!--    @if (layoutService.sidebarExpanded) {-->
<!--      <p class="text-caption text-neutral-300 cursor-pointer mb-4">Account</p>-->
<!--    }-->

<!--    @if (settingsMenu().isEnabled) {-->
<!--      <app-sidebar-menu class="mb-4" [menuItem]="settingsMenu()"></app-sidebar-menu>-->
<!--    }-->
    <div class="flex w-full justify-end">
      <div class="h-7 w-7 rounded-full flex justify-center items-center bg-white cursor-pointer"
           (click)="toggleSidebar()">
        @if (layoutService.sidebarExpanded) {
          <app-svg-icon class="text-gray-500" src="assets/icons/chevron-left.svg"></app-svg-icon>
        } @else {
          <app-svg-icon class="text-gray-500" src="assets/icons/chevron-right.svg"></app-svg-icon>
        }
      </div>
    </div>
  </div>
</nav>
