<app-base-dialog [title]="'New Reminder'" class="mx-6">
  <div body>
    <form [formGroup]="createReminderForm">
      <div class="flex flex-col gap-4 justify-center items-center my-6 w-full">
        <app-outline-date-input formControlName="reminderDate" [label]="'Reminder Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
        <app-outline-text-input formControlName="reminderTime" [label]="'Reminder Time'" type="time"
                                class="w-full" [fullWidth]="true"></app-outline-text-input>

        <app-text-area-input formControlName="reminderMessage" class="w-full" [fullWidth]="true" label="Reminder message"></app-text-area-input>
      </div>
    </form>
  </div>
  <div actions>
    <div class="flex justify-end gap-2">
      <app-button [buttonColor]="'bg-primary-600'" [appearance]="'outline'" [fullWidth]="true"
                  [textButtonColor]="'text-primary-600'" [outlineColor]="'border-primary-500'" (click)="closeDialog()">
        Cancel
      </app-button>
      <app-button [buttonColor]="'bg-primary-600'" [fullWidth]="true" (click)="checkUpdateorSaveRequest()">Save
      </app-button>
    </div>
  </div>
</app-base-dialog>
