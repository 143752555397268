import { environment } from '../../../environments/environment';
/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Api
 * OpenAPI spec version: 1.0
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  ActiveDriverStatusRequest,
  ActiveDriverStatusResponse,
  BusinessSubTypeListResponse,
  CompleteDriverApplicationRequest,
  CompleteDriverApplicationResponse,
  CompleteProfileRequest,
  CompleteProfileResponse,
  CreateDriverApplicationProficiencyRequest,
  CreateDriverApplicationProficiencyResponse,
  CreateDriverApplicationResponse,
  CreateDriverReminderRequest,
  CreateDriverReminderResponse,
  CreateDriverRequest,
  CreateDriverResponse,
  CreateUserRequest,
  CreateUserResponse,
  DeleteDriverDocumentResponse,
  DeleteDriverReminderResponse,
  DownloadDriverApplicationFormResponse,
  DownloadDriverDocumentListResponse,
  DownloadDriverDocumentListSignedUrlResponse,
  DriverApplicationStartedRequest,
  DriverApplicationStartedResponse,
  ExportXlsxDriverApplicationResponse,
  ExportXlsxDriverApplicationSignedUrlResponse,
  ExportXlsxDriverResponse,
  ExportXlsxDriverSignedUrlResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  GetBusinessSubTypeListParams,
  GetBusinessTypeListParams,
  GetBusinessTypeListResponse,
  GetCompanyApplicationStatusListResponse,
  GetCompanyResponse,
  GetCompanyStatusListResponse,
  GetCompanyTypeListResponse,
  GetCountryListParams,
  GetCountryListResponse,
  GetDriverApplicationAccidentParams,
  GetDriverApplicationAccidentResponse,
  GetDriverApplicationEducationParams,
  GetDriverApplicationEducationResponse,
  GetDriverApplicationEmploymentHistoryParams,
  GetDriverApplicationEmploymentHistoryResponse,
  GetDriverApplicationExperienceParams,
  GetDriverApplicationExperienceResponse,
  GetDriverApplicationGeneralResponse,
  GetDriverApplicationLicenseParams,
  GetDriverApplicationLicenseResponse,
  GetDriverApplicationListParams,
  GetDriverApplicationListResponse,
  GetDriverApplicationParams,
  GetDriverApplicationProficiencyResponse,
  GetDriverApplicationResidencyParams,
  GetDriverApplicationResidencyResponse,
  GetDriverApplicationResponse,
  GetDriverApplicationTrafficConvictionParams,
  GetDriverApplicationTrafficConvictionResponse,
  GetDriverDocumentListByTypeParams,
  GetDriverDocumentListByTypeResponse,
  GetDriverDocumentListParams,
  GetDriverDocumentListResponse,
  GetDriverGeneralResponse,
  GetDriverLicenseParams,
  GetDriverLicenseResponse,
  GetDriverListParams,
  GetDriverListResponse,
  GetDriverReminderListParams,
  GetDriverReminderListResponse,
  GetDriverReminderResponse,
  GetDriverResidencyParams,
  GetDriverResidencyResponse,
  GetDriverResponse,
  GetRoleListResponse,
  GetStateListParams,
  GetStateListResponse,
  GetUserListParams,
  GetUserListResponse,
  GetUserResponse,
  InactiveDriverStatusRequest,
  InactiveDriverStatusResponse,
  LoginRequest,
  LoginResponse,
  LoginWithDelegationTokenRequest,
  LoginWithDelegationTokenResponse,
  MarkDriverActiveRequest,
  MarkDriverActiveResponse,
  MarkDriverApplicationHiredRequest,
  MarkDriverApplicationHiredResponse,
  MarkDriverApplicationRejectedRequest,
  MarkDriverApplicationRejectedResponse,
  MarkDriverApplicationResubmissionRequest,
  MarkDriverApplicationResubmissionResponse,
  MarkDriverApplicationUnderVerificationRequest,
  MarkDriverApplicationUnderVerificationResponse,
  MarkDriverInactiveRequest,
  MarkDriverInactiveResponse,
  MarkDriverTerminatedRequest,
  MarkDriverTerminatedResponse,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SaveDriverApplicationAccidentRequest,
  SaveDriverApplicationAccidentResponse,
  SaveDriverApplicationEducationRequest,
  SaveDriverApplicationEducationResponse,
  SaveDriverApplicationEmploymentHistoryRequest,
  SaveDriverApplicationEmploymentHistoryResponse,
  SaveDriverApplicationExperienceRequest,
  SaveDriverApplicationExperienceResponse,
  SaveDriverApplicationGeneralRequest,
  SaveDriverApplicationLicenseRequest,
  SaveDriverApplicationLicenseResponse,
  SaveDriverApplicationResidencyRequest,
  SaveDriverApplicationTrafficConvictionRequest,
  SaveDriverApplicationTrafficConvictionResponse,
  SaveDriverGeneralRequest,
  SaveDriverLicenseRequest,
  SaveDriverLicenseResponse,
  SaveDriverRequest,
  SaveDriverResidencyRequest,
  SaveDriverResidencyResponse,
  SaveDriverResponse,
  SavePreHiringDriverApplicationInfoRequest,
  SavePreHiringDriverApplicationInfoResponse,
  SendDriverApplicationRequest,
  SendDriverApplicationResponse,
  SetupPasswordRequest,
  SetupPasswordResponse,
  SignOutRequest,
  SignOutResponse,
  SignUpRequest,
  SignUpResponse,
  UpdateDriverDocumentRequest,
  UpdateDriverDocumentResponse,
  UpdateDriverReminderRequest,
  UpdateDriverReminderResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  UploadDriverApplicationFileRequest,
  UploadDriverApplicationFileResponse,
  UploadDriverCurrentDrivingLicenseRequest,
  UploadDriverCurrentDrivingLicenseResponse,
  UploadDriverDmvPrintoutRequest,
  UploadDriverDmvPrintoutResponse,
  UploadDriverDotMedicalCertificateRequest,
  UploadDriverDotMedicalCertificateResponse,
  UploadDriverMedicalCardRequest,
  UploadDriverMedicalCardResponse,
  UploadTempFileRequest,
  UploadTempFileResponse
} from './model'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {}/**
 * Operation ID: loginWithDelegationToken


 */
 loginWithDelegationToken<TData = LoginWithDelegationTokenResponse>(
    loginWithDelegationTokenRequest: LoginWithDelegationTokenRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/login/delegation/token`,
      loginWithDelegationTokenRequest,options
    );
  }

/**
 * Operation ID: deleteDriverDocument


 */
 deleteDriverDocument<TData = DeleteDriverDocumentResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/driver/documents/${id}`,options
    );
  }

/**
 * Operation ID: downloadDriverApplicationFormSignedUrl


 */
 downloadDriverApplicationFormSignedUrl<TData = DownloadDriverApplicationFormResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/form/download/signed/url`,options
    );
  }

/**
 * Operation ID: DownloadDriverDocumentListController_execute


 */
 downloadDriverDocumentListControllerExecute<TData = DownloadDriverDocumentListResponse>(
    token: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/documents/download/${token}`,options
    );
  }

/**
 * Operation ID: downloadDriverDocumentListSignedUrl


 */
 downloadDriverDocumentListSignedUrl<TData = DownloadDriverDocumentListSignedUrlResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/${id}/documents/signed/url`,options
    );
  }

/**
 * Operation ID: ExportXlsxDriverController_execute


 */
 exportXlsxDriverControllerExecute<TData = ExportXlsxDriverResponse>(
    token: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/drivers/export/xlsx/${token}`,options
    );
  }

/**
 * Operation ID: exportXlsxDriverSignedUrl


 */
 exportXlsxDriverSignedUrl<TData = ExportXlsxDriverSignedUrlResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/drivers/export/xlsx/signed/url`,options
    );
  }

/**
 * Operation ID: exportXlsxDriverApplicationSignedUrl


 */
 exportXlsxDriverApplicationSignedUrl<TData = ExportXlsxDriverApplicationSignedUrlResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application/export/xlsx/signed/url`,options
    );
  }

/**
 * Operation ID: ExportXlsxDriverApplicationController_execute


 */
 exportXlsxDriverApplicationControllerExecute<TData = ExportXlsxDriverApplicationResponse>(
    token: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/export/xlsx/${token}`,options
    );
  }

/**
 * Operation ID: markDriverTerminated


 */
 markDriverTerminated<TData = MarkDriverTerminatedResponse>(
    id: string,
    markDriverTerminatedRequest: MarkDriverTerminatedRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/status/terminated/${id}`,
      markDriverTerminatedRequest,options
    );
  }

/**
 * Operation ID: markDriverInactive


 */
 markDriverInactive<TData = MarkDriverInactiveResponse>(
    id: string,
    markDriverInactiveRequest: MarkDriverInactiveRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/${id}/status/inactive`,
      markDriverInactiveRequest,options
    );
  }

/**
 * Operation ID: markDriverActive


 */
 markDriverActive<TData = MarkDriverActiveResponse>(
    id: string,
    markDriverActiveRequest: MarkDriverActiveRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/${id}/status/active`,
      markDriverActiveRequest,options
    );
  }

/**
 * Operation ID: createDriver


 */
 createDriver<TData = CreateDriverResponse>(
    createDriverRequest: CreateDriverRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/drivers`,
      createDriverRequest,options
    );
  }

/**
 * Operation ID: getDriverList


 */
 getDriverList<TData = GetDriverListResponse[]>(
    params?: GetDriverListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/drivers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getUserInfo


 */
 getUserInfo<TData = GetUserResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/users/info`,options
    );
  }

/**
 * Operation ID: updateDriverDocument


 */
 updateDriverDocument<TData = UpdateDriverDocumentResponse>(
    updateDriverDocumentRequest: UpdateDriverDocumentRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver/documents`,
      updateDriverDocumentRequest,options
    );
  }

/**
 * Operation ID: getDriverDocumentList


 */
 getDriverDocumentList<TData = GetDriverDocumentListResponse>(
    params: GetDriverDocumentListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/documents`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDriverDocumentListByType


 */
 getDriverDocumentListByType<TData = GetDriverDocumentListByTypeResponse>(
    params: GetDriverDocumentListByTypeParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/documents/type`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDriverApplicationProficiency


 */
 getDriverApplicationProficiency<TData = GetDriverApplicationProficiencyResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/proficiency/${id}`,options
    );
  }

/**
 * Operation ID: createDriverApplicationProficiency


 */
 createDriverApplicationProficiency<TData = CreateDriverApplicationProficiencyResponse>(
    createDriverApplicationProficiencyRequest: CreateDriverApplicationProficiencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications/proficiencies`,
      createDriverApplicationProficiencyRequest,options
    );
  }

/**
 * Operation ID: savePreHiringDriverApplicationInfo


 */
 savePreHiringDriverApplicationInfo<TData = SavePreHiringDriverApplicationInfoResponse>(
    savePreHiringDriverApplicationInfoRequest: SavePreHiringDriverApplicationInfoRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/pre-hiring/info`,
      savePreHiringDriverApplicationInfoRequest,options
    );
  }

/**
 * Operation ID: updateUser


 */
 updateUser<TData = UpdateUserResponse>(
    id: string,
    updateUserRequest: UpdateUserRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/users/${id}`,
      updateUserRequest,options
    );
  }

/**
 * Operation ID: getUserList


 */
 getUserList<TData = GetUserListResponse>(
    params?: GetUserListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/users`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createUser


 */
 createUser<TData = CreateUserResponse>(
    createUserRequest: CreateUserRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/users`,
      createUserRequest,options
    );
  }

/**
 * Operation ID: getRoleList


 */
 getRoleList<TData = GetRoleListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/roles`,options
    );
  }

/**
 * Operation ID: getDriverReminderList


 */
 getDriverReminderList<TData = GetDriverReminderListResponse>(
    params?: GetDriverReminderListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminders/drivers`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: createDriverReminder


 */
 createDriverReminder<TData = CreateDriverReminderResponse>(
    createDriverReminderRequest: CreateDriverReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/reminders/drivers`,
      createDriverReminderRequest,options
    );
  }

/**
 * Operation ID: getDriverReminder


 */
 getDriverReminder<TData = GetDriverReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/reminder/drivers/${id}`,options
    );
  }

/**
 * Operation ID: deleteDriverReminder


 */
 deleteDriverReminder<TData = DeleteDriverReminderResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `${environment.baseUrl}/company/reminders/drivers/${id}`,options
    );
  }

/**
 * Operation ID: updateDriverReminder


 */
 updateDriverReminder<TData = UpdateDriverReminderResponse>(
    id: string,
    updateDriverReminderRequest: UpdateDriverReminderRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/reminders/drivers/${id}`,
      updateDriverReminderRequest,options
    );
  }

/**
 * Operation ID: inactiveDriverStatus


 */
 inactiveDriverStatus<TData = InactiveDriverStatusResponse>(
    id: string,
    inactiveDriverStatusRequest: InactiveDriverStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/status/in-active/${id}`,
      inactiveDriverStatusRequest,options
    );
  }

/**
 * Operation ID: activeDriverStatus


 */
 activeDriverStatus<TData = ActiveDriverStatusResponse>(
    id: string,
    activeDriverStatusRequest: ActiveDriverStatusRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/status/active/${id}`,
      activeDriverStatusRequest,options
    );
  }

/**
 * Operation ID: saveDriverLicense


 */
 saveDriverLicense<TData = SaveDriverLicenseResponse>(
    saveDriverLicenseRequest: SaveDriverLicenseRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-licenses`,
      saveDriverLicenseRequest,options
    );
  }

/**
 * Operation ID: getDriverLicense


 */
 getDriverLicense<TData = GetDriverLicenseResponse>(
    params: GetDriverLicenseParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-licenses`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverResidency


 */
 saveDriverResidency<TData = SaveDriverResidencyResponse>(
    saveDriverResidencyRequest: SaveDriverResidencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-residencies`,
      saveDriverResidencyRequest,options
    );
  }

/**
 * Operation ID: getDriverResidency


 */
 getDriverResidency<TData = GetDriverResidencyResponse[]>(
    params: GetDriverResidencyParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-residencies`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverGeneral


 */
 saveDriverGeneral<TData = unknown>(
    saveDriverGeneralRequest: SaveDriverGeneralRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-generals`,
      saveDriverGeneralRequest,options
    );
  }

/**
 * Operation ID: getDriverGeneral


 */
 getDriverGeneral<TData = GetDriverGeneralResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-generals/${id}`,options
    );
  }

/**
 * Operation ID: uploadDriverCurrentDrivingLicenseFile


 */
 uploadDriverCurrentDrivingLicenseFile<TData = UploadDriverCurrentDrivingLicenseResponse>(
    uploadDriverCurrentDrivingLicenseRequest: UploadDriverCurrentDrivingLicenseRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadDriverCurrentDrivingLicenseRequest.file)
formData.append('driverId', uploadDriverCurrentDrivingLicenseRequest.driverId)

    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-current-driving-licenses`,
      formData,options
    );
  }

/**
 * Operation ID: uploadDriverDmvPrintoutFile


 */
 uploadDriverDmvPrintoutFile<TData = UploadDriverDmvPrintoutResponse>(
    uploadDriverDmvPrintoutRequest: UploadDriverDmvPrintoutRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadDriverDmvPrintoutRequest.file)
formData.append('driverId', uploadDriverDmvPrintoutRequest.driverId)

    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-dmv-printouts`,
      formData,options
    );
  }

/**
 * Operation ID: uploadDriverDotMedicalCertificate


 */
 uploadDriverDotMedicalCertificate<TData = UploadDriverDotMedicalCertificateResponse>(
    uploadDriverDotMedicalCertificateRequest: UploadDriverDotMedicalCertificateRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadDriverDotMedicalCertificateRequest.file)
formData.append('driverId', uploadDriverDotMedicalCertificateRequest.driverId)

    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-dot-medical-certificates`,
      formData,options
    );
  }

/**
 * Operation ID: uploadDriverMedicalCardFile


 */
 uploadDriverMedicalCardFile<TData = UploadDriverMedicalCardResponse>(
    uploadDriverMedicalCardRequest: UploadDriverMedicalCardRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadDriverMedicalCardRequest.file)
formData.append('driverId', uploadDriverMedicalCardRequest.driverId)

    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-medical-cards`,
      formData,options
    );
  }

/**
 * Operation ID: saveDriver


 */
 saveDriver<TData = SaveDriverResponse>(
    id: string,
    saveDriverRequest: SaveDriverRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/drivers/${id}`,
      saveDriverRequest,options
    );
  }

/**
 * Operation ID: getDriver


 */
 getDriver<TData = GetDriverResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver/${id}`,options
    );
  }

/**
 * Operation ID: markDriverApplicationRejected


 */
 markDriverApplicationRejected<TData = MarkDriverApplicationRejectedResponse>(
    markDriverApplicationRejectedRequest: MarkDriverApplicationRejectedRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/rejected`,
      markDriverApplicationRejectedRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationUnderVerification


 */
 markDriverApplicationUnderVerification<TData = MarkDriverApplicationUnderVerificationResponse>(
    markDriverApplicationUnderVerificationRequest: MarkDriverApplicationUnderVerificationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/under-verification`,
      markDriverApplicationUnderVerificationRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationResubmission


 */
 markDriverApplicationResubmission<TData = MarkDriverApplicationResubmissionResponse>(
    markDriverApplicationResubmissionRequest: MarkDriverApplicationResubmissionRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/re-submission`,
      markDriverApplicationResubmissionRequest,options
    );
  }

/**
 * Operation ID: markDriverApplicationHired


 */
 markDriverApplicationHired<TData = MarkDriverApplicationHiredResponse>(
    markDriverApplicationHiredRequest: MarkDriverApplicationHiredRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications/status/hired`,
      markDriverApplicationHiredRequest,options
    );
  }

/**
 * Operation ID: completeDriverApplication


 */
 completeDriverApplication<TData = CompleteDriverApplicationResponse>(
    completeDriverApplicationRequest: CompleteDriverApplicationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/complete-driver-applications`,
      completeDriverApplicationRequest,options
    );
  }

/**
 * Operation ID: getDriverApplication


 */
 getDriverApplication<TData = GetDriverApplicationResponse>(
    params: GetDriverApplicationParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications/info`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationEducation


 */
 saveDriverApplicationEducation<TData = SaveDriverApplicationEducationResponse>(
    saveDriverApplicationEducationRequest: SaveDriverApplicationEducationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-education`,
      saveDriverApplicationEducationRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationEducation


 */
 getDriverApplicationEducation<TData = GetDriverApplicationEducationResponse>(
    params: GetDriverApplicationEducationParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-education`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getDriverApplicationTrafficConviction


 */
 getDriverApplicationTrafficConviction<TData = GetDriverApplicationTrafficConvictionResponse>(
    params: GetDriverApplicationTrafficConvictionParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-traffic-convictions`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationTrafficConviction


 */
 saveDriverApplicationTrafficConviction<TData = SaveDriverApplicationTrafficConvictionResponse>(
    saveDriverApplicationTrafficConvictionRequest: SaveDriverApplicationTrafficConvictionRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-traffic-convictions`,
      saveDriverApplicationTrafficConvictionRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationEmploymentHistory


 */
 getDriverApplicationEmploymentHistory<TData = GetDriverApplicationEmploymentHistoryResponse>(
    params: GetDriverApplicationEmploymentHistoryParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-employment-history`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationEmploymentHistory


 */
 saveDriverApplicationEmploymentHistory<TData = SaveDriverApplicationEmploymentHistoryResponse>(
    saveDriverApplicationEmploymentHistoryRequest: SaveDriverApplicationEmploymentHistoryRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-employment-history`,
      saveDriverApplicationEmploymentHistoryRequest,options
    );
  }

/**
 * Operation ID: saveDriverApplicationAccident


 */
 saveDriverApplicationAccident<TData = SaveDriverApplicationAccidentResponse>(
    saveDriverApplicationAccidentRequest: SaveDriverApplicationAccidentRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-accidents`,
      saveDriverApplicationAccidentRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationAccident


 */
 getDriverApplicationAccident<TData = GetDriverApplicationAccidentResponse>(
    params: GetDriverApplicationAccidentParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-accidents`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationExperience


 */
 saveDriverApplicationExperience<TData = SaveDriverApplicationExperienceResponse>(
    saveDriverApplicationExperienceRequest: SaveDriverApplicationExperienceRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-experiences`,
      saveDriverApplicationExperienceRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationExperience


 */
 getDriverApplicationExperience<TData = GetDriverApplicationExperienceResponse>(
    params: GetDriverApplicationExperienceParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-experiences`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationLicense


 */
 saveDriverApplicationLicense<TData = SaveDriverApplicationLicenseResponse>(
    saveDriverApplicationLicenseRequest: SaveDriverApplicationLicenseRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-licenses`,
      saveDriverApplicationLicenseRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationLicense


 */
 getDriverApplicationLicense<TData = GetDriverApplicationLicenseResponse>(
    params: GetDriverApplicationLicenseParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-licenses`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: driverApplicationStarted


 */
 driverApplicationStarted<TData = DriverApplicationStartedResponse>(
    driverApplicationStartedRequest: DriverApplicationStartedRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-applications/status/started`,
      driverApplicationStartedRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationList


 */
 getDriverApplicationList<TData = GetDriverApplicationListResponse[]>(
    params?: GetDriverApplicationListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-applications`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: driverApplications


 */
 driverApplications<TData = CreateDriverApplicationResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications`,undefined,options
    );
  }

/**
 * Operation ID: sendDriverApplication


 */
 sendDriverApplication<TData = SendDriverApplicationResponse>(
    sendDriverApplicationRequest: SendDriverApplicationRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/driver-applications/send`,
      sendDriverApplicationRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationResidency


 */
 getDriverApplicationResidency<TData = GetDriverApplicationResidencyResponse[]>(
    params: GetDriverApplicationResidencyParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-residencies`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: saveDriverApplicationResidency


 */
 saveDriverApplicationResidency<TData = unknown>(
    saveDriverApplicationResidencyRequest: SaveDriverApplicationResidencyRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-residencies`,
      saveDriverApplicationResidencyRequest,options
    );
  }

/**
 * Operation ID: getDriverApplicationGeneral


 */
 getDriverApplicationGeneral<TData = GetDriverApplicationGeneralResponse>(
    id: string, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/driver-application-generals/${id}`,options
    );
  }

/**
 * Operation ID: saveDriverApplicationGeneral


 */
 saveDriverApplicationGeneral<TData = unknown>(
    saveDriverApplicationGeneralRequest: SaveDriverApplicationGeneralRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application-generals`,
      saveDriverApplicationGeneralRequest,options
    );
  }

/**
 * Operation ID: resetPassword


 */
 resetPassword<TData = ResetPasswordResponse>(
    resetPasswordRequest: ResetPasswordRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/reset-password`,
      resetPasswordRequest,options
    );
  }

/**
 * Operation ID: resetPasswordRequest


 */
 resetPasswordRequest<TData = ForgotPasswordResponse>(
    forgotPasswordRequest: ForgotPasswordRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/forgot-password`,
      forgotPasswordRequest,options
    );
  }

/**
 * Operation ID: createPassword


 */
 createPassword<TData = SetupPasswordResponse>(
    setupPasswordRequest: SetupPasswordRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/setup-password`,
      setupPasswordRequest,options
    );
  }

/**
 * Operation ID: updateProfile


 */
 updateProfile<TData = CompleteProfileResponse>(
    completeProfileRequest: CompleteProfileRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/complete-profile`,
      completeProfileRequest,options
    );
  }

/**
 * Operation ID: getCompany


 */
 getCompany<TData = GetCompanyResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/info`,options
    );
  }

/**
 * Operation ID: signUp


 */
 signUp<TData = SignUpResponse>(
    signUpRequest: SignUpRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/sign-up`,
      signUpRequest,options
    );
  }

/**
 * Operation ID: login


 */
 login<TData = LoginResponse>(
    loginRequest: LoginRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/auth/login`,
      loginRequest,options
    );
  }

/**
 * Operation ID: signOut


 */
 signOut<TData = SignOutResponse>(
    signOutRequest: SignOutRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.put<TData>(
      `${environment.baseUrl}/company/auth/sign-out`,
      signOutRequest,options
    );
  }

/**
 * Operation ID: TestEmailController_test


 */
 testEmailControllerTest<TData = void>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/test-emails`,options
    );
  }

/**
 * Operation ID: testEmail


 */
 testEmail<TData = unknown>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `${environment.baseUrl}/company/test-emails`,undefined,options
    );
  }

/**
 * Operation ID: TestEmailController_delete


 */
 testEmailControllerDelete<TData = void>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/company/test-emails/generatePdddf`,options
    );
  }

/**
 * Operation ID: uploadDriverApplicationFile


 */
 uploadDriverApplicationFile<TData = UploadDriverApplicationFileResponse>(
    uploadDriverApplicationFileRequest: UploadDriverApplicationFileRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadDriverApplicationFileRequest.file)
formData.append('driverApplicationId', uploadDriverApplicationFileRequest.driverApplicationId)
formData.append('type', uploadDriverApplicationFileRequest.type)

    return this.http.put<TData>(
      `${environment.baseUrl}/company/driver-application/upload/file`,
      formData,options
    );
  }

/**
 * Operation ID: uploadTempFile


 */
 uploadTempFile<TData = UploadTempFileResponse>(
    uploadTempFileRequest: UploadTempFileRequest, options?: HttpClientOptions
  ): Observable<TData>  {const formData = new FormData();
formData.append('file', uploadTempFileRequest.file)

    return this.http.put<TData>(
      `${environment.baseUrl}/common/upload/temp`,
      formData,options
    );
  }

/**
 * Operation ID: getBusinessSubTypeList


 */
 getBusinessSubTypeList<TData = BusinessSubTypeListResponse>(
    params?: GetBusinessSubTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/business-sub-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getBusinessTypeList


 */
 getBusinessTypeList<TData = GetBusinessTypeListResponse>(
    params?: GetBusinessTypeListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/business-types`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getStateList


 */
 getStateList<TData = GetStateListResponse>(
    params?: GetStateListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/states`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCountryList


 */
 getCountryList<TData = GetCountryListResponse>(
    params?: GetCountryListParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/countries`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }

/**
 * Operation ID: getCompanyTypeList


 */
 getCompanyTypeList<TData = GetCompanyTypeListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company/types`,options
    );
  }

/**
 * Operation ID: getCompanyStatusList


 */
 getCompanyStatusList<TData = GetCompanyStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company/status`,options
    );
  }

/**
 * Operation ID: getCompanyApplicationStatusList


 */
 getCompanyApplicationStatusList<TData = GetCompanyApplicationStatusListResponse>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `${environment.baseUrl}/common/company-application-status`,options
    );
  }

};

export type LoginWithDelegationTokenClientResult = NonNullable<LoginWithDelegationTokenResponse>
export type DeleteDriverDocumentClientResult = NonNullable<DeleteDriverDocumentResponse>
export type DownloadDriverApplicationFormSignedUrlClientResult = NonNullable<DownloadDriverApplicationFormResponse>
export type DownloadDriverDocumentListControllerExecuteClientResult = NonNullable<DownloadDriverDocumentListResponse>
export type DownloadDriverDocumentListSignedUrlClientResult = NonNullable<DownloadDriverDocumentListSignedUrlResponse>
export type ExportXlsxDriverControllerExecuteClientResult = NonNullable<ExportXlsxDriverResponse>
export type ExportXlsxDriverSignedUrlClientResult = NonNullable<ExportXlsxDriverSignedUrlResponse>
export type ExportXlsxDriverApplicationSignedUrlClientResult = NonNullable<ExportXlsxDriverApplicationSignedUrlResponse>
export type ExportXlsxDriverApplicationControllerExecuteClientResult = NonNullable<ExportXlsxDriverApplicationResponse>
export type MarkDriverTerminatedClientResult = NonNullable<MarkDriverTerminatedResponse>
export type MarkDriverInactiveClientResult = NonNullable<MarkDriverInactiveResponse>
export type MarkDriverActiveClientResult = NonNullable<MarkDriverActiveResponse>
export type CreateDriverClientResult = NonNullable<CreateDriverResponse>
export type GetDriverListClientResult = NonNullable<GetDriverListResponse[]>
export type GetUserInfoClientResult = NonNullable<GetUserResponse>
export type UpdateDriverDocumentClientResult = NonNullable<UpdateDriverDocumentResponse>
export type GetDriverDocumentListClientResult = NonNullable<GetDriverDocumentListResponse>
export type GetDriverDocumentListByTypeClientResult = NonNullable<GetDriverDocumentListByTypeResponse>
export type GetDriverApplicationProficiencyClientResult = NonNullable<GetDriverApplicationProficiencyResponse>
export type CreateDriverApplicationProficiencyClientResult = NonNullable<CreateDriverApplicationProficiencyResponse>
export type SavePreHiringDriverApplicationInfoClientResult = NonNullable<SavePreHiringDriverApplicationInfoResponse>
export type UpdateUserClientResult = NonNullable<UpdateUserResponse>
export type GetUserListClientResult = NonNullable<GetUserListResponse>
export type CreateUserClientResult = NonNullable<CreateUserResponse>
export type GetRoleListClientResult = NonNullable<GetRoleListResponse>
export type GetDriverReminderListClientResult = NonNullable<GetDriverReminderListResponse>
export type CreateDriverReminderClientResult = NonNullable<CreateDriverReminderResponse>
export type GetDriverReminderClientResult = NonNullable<GetDriverReminderResponse>
export type DeleteDriverReminderClientResult = NonNullable<DeleteDriverReminderResponse>
export type UpdateDriverReminderClientResult = NonNullable<UpdateDriverReminderResponse>
export type InactiveDriverStatusClientResult = NonNullable<InactiveDriverStatusResponse>
export type ActiveDriverStatusClientResult = NonNullable<ActiveDriverStatusResponse>
export type SaveDriverLicenseClientResult = NonNullable<SaveDriverLicenseResponse>
export type GetDriverLicenseClientResult = NonNullable<GetDriverLicenseResponse>
export type SaveDriverResidencyClientResult = NonNullable<SaveDriverResidencyResponse>
export type GetDriverResidencyClientResult = NonNullable<GetDriverResidencyResponse[]>
export type SaveDriverGeneralClientResult = NonNullable<unknown>
export type GetDriverGeneralClientResult = NonNullable<GetDriverGeneralResponse>
export type UploadDriverCurrentDrivingLicenseFileClientResult = NonNullable<UploadDriverCurrentDrivingLicenseResponse>
export type UploadDriverDmvPrintoutFileClientResult = NonNullable<UploadDriverDmvPrintoutResponse>
export type UploadDriverDotMedicalCertificateClientResult = NonNullable<UploadDriverDotMedicalCertificateResponse>
export type UploadDriverMedicalCardFileClientResult = NonNullable<UploadDriverMedicalCardResponse>
export type SaveDriverClientResult = NonNullable<SaveDriverResponse>
export type GetDriverClientResult = NonNullable<GetDriverResponse>
export type MarkDriverApplicationRejectedClientResult = NonNullable<MarkDriverApplicationRejectedResponse>
export type MarkDriverApplicationUnderVerificationClientResult = NonNullable<MarkDriverApplicationUnderVerificationResponse>
export type MarkDriverApplicationResubmissionClientResult = NonNullable<MarkDriverApplicationResubmissionResponse>
export type MarkDriverApplicationHiredClientResult = NonNullable<MarkDriverApplicationHiredResponse>
export type CompleteDriverApplicationClientResult = NonNullable<CompleteDriverApplicationResponse>
export type GetDriverApplicationClientResult = NonNullable<GetDriverApplicationResponse>
export type SaveDriverApplicationEducationClientResult = NonNullable<SaveDriverApplicationEducationResponse>
export type GetDriverApplicationEducationClientResult = NonNullable<GetDriverApplicationEducationResponse>
export type GetDriverApplicationTrafficConvictionClientResult = NonNullable<GetDriverApplicationTrafficConvictionResponse>
export type SaveDriverApplicationTrafficConvictionClientResult = NonNullable<SaveDriverApplicationTrafficConvictionResponse>
export type GetDriverApplicationEmploymentHistoryClientResult = NonNullable<GetDriverApplicationEmploymentHistoryResponse>
export type SaveDriverApplicationEmploymentHistoryClientResult = NonNullable<SaveDriverApplicationEmploymentHistoryResponse>
export type SaveDriverApplicationAccidentClientResult = NonNullable<SaveDriverApplicationAccidentResponse>
export type GetDriverApplicationAccidentClientResult = NonNullable<GetDriverApplicationAccidentResponse>
export type SaveDriverApplicationExperienceClientResult = NonNullable<SaveDriverApplicationExperienceResponse>
export type GetDriverApplicationExperienceClientResult = NonNullable<GetDriverApplicationExperienceResponse>
export type SaveDriverApplicationLicenseClientResult = NonNullable<SaveDriverApplicationLicenseResponse>
export type GetDriverApplicationLicenseClientResult = NonNullable<GetDriverApplicationLicenseResponse>
export type DriverApplicationStartedClientResult = NonNullable<DriverApplicationStartedResponse>
export type GetDriverApplicationListClientResult = NonNullable<GetDriverApplicationListResponse[]>
export type DriverApplicationsClientResult = NonNullable<CreateDriverApplicationResponse>
export type SendDriverApplicationClientResult = NonNullable<SendDriverApplicationResponse>
export type GetDriverApplicationResidencyClientResult = NonNullable<GetDriverApplicationResidencyResponse[]>
export type SaveDriverApplicationResidencyClientResult = NonNullable<unknown>
export type GetDriverApplicationGeneralClientResult = NonNullable<GetDriverApplicationGeneralResponse>
export type SaveDriverApplicationGeneralClientResult = NonNullable<unknown>
export type ResetPasswordClientResult = NonNullable<ResetPasswordResponse>
export type ResetPasswordRequestClientResult = NonNullable<ForgotPasswordResponse>
export type CreatePasswordClientResult = NonNullable<SetupPasswordResponse>
export type UpdateProfileClientResult = NonNullable<CompleteProfileResponse>
export type GetCompanyClientResult = NonNullable<GetCompanyResponse>
export type SignUpClientResult = NonNullable<SignUpResponse>
export type LoginClientResult = NonNullable<LoginResponse>
export type SignOutClientResult = NonNullable<SignOutResponse>
export type TestEmailControllerTestClientResult = NonNullable<void>
export type TestEmailClientResult = NonNullable<unknown>
export type TestEmailControllerDeleteClientResult = NonNullable<void>
export type UploadDriverApplicationFileClientResult = NonNullable<UploadDriverApplicationFileResponse>
export type UploadTempFileClientResult = NonNullable<UploadTempFileResponse>
export type GetBusinessSubTypeListClientResult = NonNullable<BusinessSubTypeListResponse>
export type GetBusinessTypeListClientResult = NonNullable<GetBusinessTypeListResponse>
export type GetStateListClientResult = NonNullable<GetStateListResponse>
export type GetCountryListClientResult = NonNullable<GetCountryListResponse>
export type GetCompanyTypeListClientResult = NonNullable<GetCompanyTypeListResponse>
export type GetCompanyStatusListClientResult = NonNullable<GetCompanyStatusListResponse>
export type GetCompanyApplicationStatusListClientResult = NonNullable<GetCompanyApplicationStatusListResponse>
