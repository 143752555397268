<app-base-dialog [title]="data.documentName">
  <div body>
    <form class="flex flex-col gap-3 my-2" [formGroup]="fileUploadForm">
      <app-file-uploader formControlName="fileAttachment"
                         label="Attach File" [isRequired]="true" [uploading]="attachFileState.loading()"
                         (fileSelected)="onAttachFileClicked($event)"></app-file-uploader>


      @if (data.hasIssuedDate) {
        <app-outline-date-input formControlName="issuedDate" [label]="'Issued Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
      }
      @if (data.hasExpiryDate) {
        <app-outline-date-input formControlName="expiryDate" [label]="'Expiry Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
      }
      @if (data.hasCompletedDate) {
        <app-outline-date-input formControlName="completedDate" [label]="'Completed Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
      }
      @if (data.hasReviewedDate) {
        <app-outline-date-input formControlName="reviewedDate" [label]="'Reviewed Date'"
                                class="w-full" [fullWidth]="true"
                                [inputDateFormat]="InputDateFormat.mmddyyyy"></app-outline-date-input>
      }

      <app-text-area-input formControlName="notes"
                           class="w-full"
                           [label]="'Notes'"
                           [fullWidth]="true">
      </app-text-area-input>


    </form>
  </div>
  <div actions class="flex justify-end gap-3">
    <app-button [appearance]="'outline'" [buttonColor]="'border-primary-500'" (click)="onCancelClicked()">Cancel
    </app-button>
    <app-button (click)="onUploadClicked()" [loading]="uploadState.loading()">Upload</app-button>
  </div>
</app-base-dialog>

