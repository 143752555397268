import {
  AfterContentInit,
  Component,
  computed,
  EventEmitter, inject,
  input,
  model,
  OnInit,
  Optional,
  Output,
  Self,
  signal
} from '@angular/core';
import {AppSvgIconComponent} from "../../components/app-svg-icon/app-svg-icon.component";
import {DatePipe} from "@angular/common";
import {MatDatepicker, MatDatepickerInput, MatDatepickerInputEvent} from "@angular/material/datepicker";
import {MatInput} from "@angular/material/input";
import {ControlValueAccessor, FormBuilder, FormControl, FormsModule, NgControl} from "@angular/forms";
import {BaseInputComponent} from "../_base/base-input/base-input.component";
import {BaseControlValueAccessor} from "../../base/base-control-value-accessor";

@Component({
  selector: 'app-date-selector-input',
  standalone: true,
  imports: [
    AppSvgIconComponent,
    DatePipe,
    MatDatepicker,
    MatDatepickerInput,
    MatInput,
    FormsModule,
    BaseInputComponent
  ],
  templateUrl: './date-selector-input.component.html',
  styleUrl: './date-selector-input.component.scss'
})
export class DateSelectorInputComponent extends BaseControlValueAccessor implements AfterContentInit {
  title = input.required<string | null>();
  minDate = input<Date | null>();
  maxDate = input<Date | null>();
  value = input<Date | null>();

  @Output()
  valueChanged = new EventEmitter<Date>();

  ngControl = inject(NgControl, {optional: true, self: true});

  constructor() {
    super();
    if (this.ngControl) {
      this.ngControl!.valueAccessor = this;
    }
  }

  override onWriteValue(value: any): void {

  }

  ngAfterContentInit(): void {
    let formControl = this.ngControl?.control as FormControl;
    if (formControl) {
      this.formControl = this.ngControl?.control as FormControl;
    }
  }

  onDateSelected($event: MatDatepickerInputEvent<any, any>) {
    this.markAsTouched();
    const date = $event.value.toDate() as Date;
    this.onChange(date);
    this.valueChanged.emit(date);
  }
}
