<!--<div class="p-4">-->
<!--  <div class="flex flex-col gap-4 bg-white p-4 rounded-md">-->
<!--    <section class="overview flex flex-col gap-4">-->
<!--      <p class="text-body1">Overview</p>-->
<!--      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">-->
<!--        <app-overview-card [iconPath]="'assets/icons/drivers.svg'" [label]="'New Applications'"-->
<!--                           [count]="overViewState.response()?.data?.newApplicationsCount ?? 0" [color]="'bg-info-500'"-->
<!--                           (onViewClick)="onNewApplicationsClicked()">-->
<!--        </app-overview-card>-->

<!--        <app-overview-card [iconPath]="'assets/icons/drivers.svg'" [label]="'In Progress Applications'"-->
<!--                           [count]="overViewState.response()?.data?.inProgressApplicationsCount ?? 0"-->
<!--                           [color]="'bg-green-500'"></app-overview-card>-->

<!--        <app-overview-card [iconPath]="'assets/icons/drivers.svg'" [label]="'Active Drivers'"-->
<!--                           [count]="overViewState.response()?.data?.activeDrivers ?? 0"-->
<!--                           [color]="'bg-purple-400'"></app-overview-card>-->

<!--        <app-overview-card [iconPath]="'assets/icons/drivers.svg'" [label]="'Active Equipments'"-->
<!--                           [count]="overViewState.response()?.data?.activeEquipment ?? 0" [color]="'bg-orange-300'"-->
<!--                           (onViewClick)="onNewApplicationsClicked()">-->
<!--        </app-overview-card>-->
<!--      </div>-->
<!--    </section>-->
<!--    <section class="expiring-documents flex flex-col gap-5">-->
<!--      <div class="flex text-neutral-900">-->
<!--        <div class="flex gap-3 items-center">-->
<!--          <p class="text-body1">Expiring Documents</p>-->
<!--          <p class="text-red-500">(in 30 days)</p>-->
<!--        </div>-->
<!--        <div class="flex flex-grow text-right items-center gap-2">-->
<!--          <p class="ml-auto text-caption text-neutral-700">Expiring In:</p>-->
<!--          <div class="flex gap-3 justify-center items-center border rounded-full p-2">-->
<!--            <p class="text-caption text-neutral-700">In 30 Days</p>-->
<!--            <app-svg-icon class="cursor-pointer text-neutral-500" [size]="10"-->
<!--                          src="assets/icons/chevron-down.svg"></app-svg-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">-->
<!--        <app-expiring-documents-card-->
<!--          [iconPath]="'assets/icons/drivers.svg'"-->
<!--          [label]="'Expiring Licences'"-->
<!--          [count]="expiringDocumentState.response()?.data?.expiringLicences ?? 0"-->
<!--          [color]="'bg-pink-300'">-->
<!--        </app-expiring-documents-card>-->
<!--        <app-expiring-documents-card-->
<!--          [iconPath]="'assets/icons/drivers.svg'"-->
<!--          [label]="'Expiring MedCards'"-->
<!--          [count]="expiringDocumentState.response()?.data?.expiringMedCards ?? 0"-->
<!--          [color]="'bg-pink-300'">-->
<!--        </app-expiring-documents-card>-->
<!--        <app-expiring-documents-card-->
<!--          [iconPath]="'assets/icons/drivers.svg'"-->
<!--          [label]="'Expiring Clearinghouse'"-->
<!--          [count]="expiringDocumentState.response()?.data?.expiringclearingHouse ?? 0"-->
<!--          [color]="'bg-pink-300'">-->
<!--        </app-expiring-documents-card>-->
<!--        <app-expiring-documents-card-->
<!--          [iconPath]="'assets/icons/drivers.svg'"-->
<!--          [label]="'Expiring Drug Test'"-->
<!--          [count]="expiringDocumentState.response()?.data?.ExpiringDrugTest ?? 0"-->
<!--          [color]="'bg-pink-300'">-->
<!--        </app-expiring-documents-card>-->
<!--      </div>-->
<!--    </section>-->
<!--  </div>-->
<!--</div>-->


<div class="h-full flex justify-center items-center">
  <app-no-data [message]="'DEVELOPMENT IS IN PROGRESS'"></app-no-data>
</div>
