<div class="container p-4 mx-auto flex flex-col gap-4 min-h-full max-h-full">
  <div class="flex gap-5 justify-end items-center ml-auto">
    <div class="flex gap-2 items-center bg-primary-500 rounded-lg">
      <app-button [iconSrc]="'assets/icons/plus.svg'" (click)="onCreateNewEquipment()">New Equipment</app-button>
    </div>
  </div>
  <div class="flex gap-2">
    <app-outline-text-input [iconSrc]="'assets/icons/magnify.svg'" placeholder="Search..." type="search"
                            [fullWidth]="false"></app-outline-text-input>
  </div>
  <div class="h-full overflow-auto">
    <app-base-table>
      <ng-container head>
        <th class="p-4 text-center" data-sortable-key="#">
          #
        </th>
        <th class="p-4 text-center" data-sortable-key="equipmentType">EQUIPMENT TYPE</th>
        <th class="p-4 text-center" data-sortable-key="unit">UNIT #</th>
        <th class="p-4 text-center" data-sortable-key="licensePlate">LICENSE PLATE #</th>
        <th class="p-4 text-center" data-sortable-key="nextServiceDueDate">NEXT SERVICE DUE DATE</th>
        <th class="p-4 text-center" data-sortable-key="createdDate">CREATED DATE</th>
        <th class="p-4 text-center"></th>
      </ng-container>
      <ng-container body>
        @for (item of equipmentsState.response()?.data; track item.no; ) {
          <tr class="border-b border-neutral-100 w-full">
            <td class="px-4 text-body2 text-center text-info-500 cursor-pointer underline"
            >{{ item.no }}
            </td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.equipmentType }}</td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.unit }}</td>
            <div class="m-2 p-2 bg-neutral-800 flex items-center justify-center rounded-md">
              <td class="px-4 py-2 text-body2 text-center text-neutral-100">{{ item.licensePlate }}</td>
            </div>
            <td class="px-4 py-2 text-body2 text-center">{{ item.nextServiceDueDate  | date:'MMM d, y h:mm a' }}</td>
            <td class="px-4 py-2 text-body2 text-center">{{ item.createdDate  | date:'MMM d, y h:mm a' }}</td>
            <td class="px-4 py-2 cursor-pointer items-center">
              <app-context-menu-icon class="flex gap-4 items-center cursor-pointer"></app-context-menu-icon>
            </td>
          </tr>
        }
      </ng-container>
    </app-base-table>
  </div>
</div>
